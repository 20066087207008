import React, { forwardRef, useState, useRef, useImperativeHandle } from 'react';
import { useTooltip } from '../../../hooks'
import { keyCode, formatData } from '../../../helpers'
import { Checkbox } from '../../'

const _InlineSelect = ({name, label, value, options, onChange, onBlur, disabled, isMulti=true, tooltip, inputRef}, ref) => {
	const [showMenu, setShowMenu] = useState(false)

  const {showTooltip, hideTooltip} = useTooltip()

  const refSelectInput = useRef()
	const refOptionsContainer = useRef()
  const refOptions = useRef([])
  const refOptionsCheckbox = useRef([])
  const refToggleMenuButton = useRef()
  const refSelectAll = useRef()
  const refSelectAllCheckbox = useRef()

  useImperativeHandle(ref, () => {
    return refSelectInput.current
  }, []);
  
   const handleKeyDown = (e, val, selectAll) => {
		e.stopPropagation()

    switch (e.keyCode) {
      case keyCode.ENTER:
      case keyCode.SPACE:
        val && handleSelectOption(val, true)
        selectAll && handleSelectAll(e)
        toggleMenu(undefined, true)
        break;

      case keyCode.DOWN_ARROW:
        focusNextListItem(keyCode.DOWN_ARROW);
        break;

      case keyCode.UP_ARROW:
        focusNextListItem(keyCode.UP_ARROW);
        break;

      case keyCode.ESCAPE:
        toggleMenu(undefined, false);
        //focusInput()
        inputRef.current.focus()
        break;

      default:
        break;
    }
  }

  const focusNextListItem = (direction) => {
    const activeElement = document.activeElement;

    const activeElementIndex = refOptions.current.findIndex(el => el === activeElement)
    const activeOptionIndex = activeElementIndex < 0 ? (options.findIndex(el => value.find(x => x.value === el.value)) || (options.length === value.length && -1)) : activeElementIndex

    if (activeOptionIndex >= 0) {
      if (direction === keyCode.DOWN_ARROW && activeOptionIndex+1 < refOptions.current.length) {
        refOptions.current[activeOptionIndex+1].focus()
      } else if (direction === keyCode.UP_ARROW && activeOptionIndex > 0) {
        refOptions.current[activeOptionIndex-1].focus()
      }
    } else {
      refOptions.current[0].focus()
    }
  }

  const toggleMenu = (e, mode = undefined) => {
    if (mode === undefined) {
      setShowMenu(!showMenu)
    } else {
      setShowMenu(mode)
    }
  }

  const focusInput = () => {
    refSelectInput.current.focus()
  }

  const handleSelectAll = (e) => {
    e.preventDefault()
    const allIsSelected = options.filter(x => {return optionIsSelected(x.value)}).length === options.length ? true : false

    const selected = options.map(x => ({...x, checked: !allIsSelected}))

    onChange( selected )
  }

  const handleSelectOption = (val, noFocus) => {
  	!noFocus && focusInput()
    if (isMulti) {
      onChange({name: name, value: val?.value || '', option: val})  
    } else {      
      toggleMenu(undefined, false)
      onChange({name: name, value: val?.value || '', option: val})      
    }  
    
  }

  const handleSelectCheckbox = (event) => {
    
    const value = parseInt(event.target.name.split("-")[1])

    // add item to selection array
    onChange({name: name, value: value})
  }

   const handleBlur = (e) => {
    const activeElement = e.relatedTarget
    if (activeElement === null || 
        !( activeElement === refSelectInput.current 
        || activeElement === refToggleMenuButton.current 
        || activeElement === refSelectAll.current
        || activeElement === refSelectAllCheckbox.current
        || refOptions.current.findIndex(el => el === activeElement) >= 0
        || (refOptionsCheckbox && refOptionsCheckbox.current.findIndex(el => el === activeElement) >= 0)
      )) {
			if (onBlur) { // execute onBlur prop function if provided 
	      onBlur(e)
	    }
      toggleMenu(undefined, false)
    } 
  }

  const handleMouseEnter = (e, option) => {
    e.preventDefault()
    option.tooltip && showTooltip({title: option.tooltip})

  }
  const handleMouseLeave = (e, option) => {
    e.preventDefault()
    hideTooltip()
  }

  const optionIsSelected = (match) => {
    return value.find(x => x.value === match && x.checked) ? true : false
  }

  const getValue = () => {
  	let res = {label: "0 columns"}
  	if (value && value.length > 0) {
  		if (value.length === options.length) {
	  		res = {label: "All columns"}
	  	} else {
	  		res = {label: (value.length.toString() + " columns")}
	  	}
			/*if (value.length === options.length) {
	  		res = {label: "All columns"}
	  	} else if (value.length === 1 ) {
	  		res = value[0]
	  	} else {
	  		res = {label: value[0].label + " + " + (value.length - 1).toString()}
	  	}*/
  	}

  	const icon = res.icon ? <div className="select-option-icon"> { formatData(res.icon,'icon') }</div> : ""
    return <div className="select-value">{ icon }<div className="select-value-label">{ res.label }</div></div>
  }

  const getOptionValue = (val) => {
    return (val !== undefined && val !== null && options.length > 0) 
                ? options.find(option => option.value.toString() === val.value.toString())
                : undefined
  }

  const getOptionLabel = (val) => {
    const res = getOptionValue(val)
    if (res !== undefined) {
      const icon = res.icon ? <div className="select-option-icon"> { formatData(res.icon,'icon') }</div> : ""
      return <div className="select-value">{ icon }<div className="select-value-label">{ res.label }</div></div>
    }
    return;
  }

	const menuState = showMenu ? 'show' : ''
  const noValue = (isMulti) ? true : value === '' || value === undefined || value === null
  const allIsSelected = options.filter(x => {return optionIsSelected(x.value)}).length === options.length ? true : false

  return (
    <div className={
    	"CustomInputSelect" + 
    	(disabled ? " disabled" : "") + 
    	(allIsSelected ? " all-selected" : "")
    }>

      <div className={"select-container " + menuState }>
        
        <div 
          className="select-input-container"   
          onClick={ toggleMenu }
          tabIndex= {0}
          ref={ refSelectInput }
          onBlur = { handleBlur }
          onKeyDown = { handleKeyDown }
          onMouseEnter={ () => showTooltip({title: tooltip}) } 
          onMouseLeave={ hideTooltip }
        >

        { !disabled &&
          <div className="select-button-container">
	          <div 
	            className="select-button button-toggle-menu" 
	            onClick={ toggleMenu }
	            ref={ refToggleMenuButton }
	            
	          >
            	<div className="button-toggle-menu-icon"></div>
          	</div>
          </div>
        }
				</div>

        <div 
        	className={ "select-value-container" + (noValue ? " placeholder" : "") + (disabled ? " disabled" : "") }
        	>
          { getValue() }
        </div>

        <div 
          className={"select-options"}
          ref={ refOptionsContainer }
          role="listbox"
        >


            <div 
              className="select-option select-all" 
              onClick={(e) => { handleSelectAll(e) }} 
              ref={ el => refOptions.current[0] = el } 
              tabIndex={ 0 }
              aria-label="(All)"
              aria-selected={ allIsSelected }
              onKeyDown={ (e) => handleKeyDown(e, undefined, true) }
              role="option"
              onBlur = { handleBlur }
              onMouseEnter={e => handleMouseEnter(e, {tooltip: allIsSelected ? "Deselect all" : "Select all"}) }
              onMouseLeave={e => handleMouseLeave(e) }
            >
            	<div className="select-option-checkbox">
	              <Checkbox
	                className=""
	                name=""
	                ref={ el => refOptionsCheckbox.current[0] = el }
	                onChange={ handleSelectCheckbox }
	                checked={ allIsSelected }
	              />
              </div>
              <div className="select-option-label">
                <div>{ "(All)" }</div>
              </div>
            </div> 
      
            {
              options.map( (option, i) => 
                <div 
                  className={ 
                    "select-option"+ 
                    (optionIsSelected(option.value) ? " active": "") + 
                    (option.secondaryLabel === undefined ? "" : " narrow-padding") + 
                    (option.disabled ? " disabled" : "")
                  } 
                  key={ option.value}
                  ref={ el => refOptions.current[i+1] = el }
                  aria-label={ option.label }
                  aria-selected={ optionIsSelected(option.value) }
                  role="option"
                  tabIndex={ 0 }
                  onKeyDown={ (e) => !option.disabled && handleKeyDown(e, option) }
                  onClick={ (e) => {e.preventDefault(); e.stopPropagation(); !option.disabled && handleSelectOption(option)} }
                  onBlur = { handleBlur }
                  onMouseEnter={e => handleMouseEnter(e, option) }
                  onMouseLeave={e => handleMouseLeave(e, option) }
                > 
                  { isMulti && 
                    <div className="select-option-checkbox">
                    <Checkbox
                      className=""
                      name={ `selected-${option.value}` }
                      ref={ el => refOptionsCheckbox.current[i+1] = el }
                      onChange={ handleSelectCheckbox }
                      checked={ (value.find(x => x.value === option.value)) || (option.value && option.disabled) ? true : false }
                      disabled={option.disabled ? true : false }
                    />
                    </div>
                  }

                  { option.icon && 
                    <div className="select-option-icon">{ formatData(option.icon,'icon') }</div>
                  }
                  <div className="select-option-label">
                    <div>{ option.label }</div>
                    <div className="select-option-secondary-label">{ option.secondaryLabel }</div>
                  </div>
                </div>
              )
            }

        </div>
      </div>   
    </div>
  );

}

export const InlineSelect = forwardRef(_InlineSelect);