import React from "react";
import { Link, useLocation } from "react-router-dom";
import useBreadcrumbs from 'use-react-router-breadcrumbs';
import { useGlobalState, useQueryParams } from  '../hooks';
import { routesConfig } from  '../routesConfig';

export const Breadcrumbs = () => {

  const { defaultParams } = useQueryParams()
  const breadcrumbs = useBreadcrumbs(routesConfig)
  const location = useLocation()

  const [currentBusinessTerm] = useGlobalState('currentBusinessTerm')
  const [currentDataset] = useGlobalState('currentDataset')
  const [currentDatasetGroup] = useGlobalState('currentDatasetGroup')
  const [currentDatasource] = useGlobalState('currentDatasource')
  const [currentField] = useGlobalState('currentField')
  const [currentFieldDescription] = useGlobalState('currentFieldDescription')
  const [currentGlossary] = useGlobalState('currentGlossary')
  const [currentSystem] = useGlobalState('currentSystem')
  const [currentTask] = useGlobalState('currentTask')
  const [currentUser] = useGlobalState('currentUser')
  const [currentUserGroup] = useGlobalState('currentUserGroup')

  return (
    <div className="Breadcrumbs">
      {
      /*          
      <Link className="breadcrumb-item link" to="/">
        <Icon name='katalogue' tooltip='Home' />
      </Link>
      */
      }

      { /*breadcrumbs.length === 1 &&
        <span className="breadcrumb-item-container">
          <span className="default-breadcrumb">
            katalogue
          </span>
        </span>*/
      }
      {breadcrumbs.map(({ match, breadcrumb, tooltip }, key) => 
        key === 0 ? (
           ""
        ) : (
        key + 1 === breadcrumbs.length 
        ? (
        
        <span key={ key } className="breadcrumb-item-container">
          <span key={ match.pathname } className="breadcrumb-item">
            { breadcrumb }
          </span>
        </span>
        ) : (
        <span key={ key } className="breadcrumb-item-container">
          <Link key={ match.pathname } className="breadcrumb-item link" to={ match.pathname } state={{...defaultParams, from: location.pathname}} title={ tooltip || "" }>
            { breadcrumb }
          </Link>
          <span className="breadcrumb-item divider">></span>
        </span>
        ))
      )}
    </div>
  );
};
