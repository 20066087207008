import React, { useState } from 'react';
import { useSideDialog } from '../hooks'
import { SkeletonLoader } from './'


const withContent = (WrappedComponent, props, loadingSideDialog) => {

  // Use a HOC to add loading state. 
  // Putting this in the Context or SideDialog component causes the "body" component to lose state when the loading state changes

  return () => {
    const [loading, setLoading] = useState(false)
    const {submitLabel, submitLabelLoading, cancelLabel, onSubmit, onCancel, formRef} = props || {}

    return (
      <React.Fragment>

        {
          (WrappedComponent && 
            <div className="side-dialog-content">
              <WrappedComponent {...props} loading={loading} setLoading={setLoading} ref={formRef} />
            </div>
          ) || 
            <React.Fragment><SkeletonLoader /><ul><SkeletonLoader width={150}/></ul></React.Fragment>
        }

        <div className="side-dialog-footer">
          <button 
            onClick={ onSubmit }
            className={"button main-button" + (loading ? ' loading' : '')} 
            disabled={loadingSideDialog || loading}
          >
            <span>{loading ? submitLabelLoading : submitLabel}</span>
          </button>
          <button 
            className="button" 
            onClick={ onCancel }
            disabled={loadingSideDialog || loading}
          >
            <span>{cancelLabel}</span>
          </button>
        </div>

      </React.Fragment>
    )
  }
}

export const SideDialog = () => {

  const { show, sideDialog, loadingSideDialog } = useSideDialog() 

  const {title, Content, onCancel} = sideDialog || {}

  const Component = withContent(Content, sideDialog, loadingSideDialog)

  const portalRef = document.getElementById("portal")

  return (
  	<div id="SideDialog" className={"SideDialog" + (show ? " show" : "")}>
  		<div className={"side-dialog-container"}>
        <div className="side-dialog-header">
          <h2>{ title }</h2>
          <div className="close-button" onClick={ onCancel }></div>
        </div>       

        <Component />

      </div>
    </div>
  )
}