export const getBatchEditValue = (data, key) => {
	let response = data[0][key]

	for (let i = 1; i < data.length; i++) {
		if (data[i][key] !== response) { 
			response = "multiple"
			break 
		}
	}

	return response 
}