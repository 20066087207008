import React from 'react';
import { Photo, SkeletonLoader, TextareaReadOnly, textareaInitialState } from './'
import { useTooltip } from '../hooks';

export const ObjectDetails = ({title, photo, secondaryTitle, subtitleIcon, subtitleText, description, details, fromReferences, showDescription=true, type, loading, children}) => {

  const {showTooltip, hideTooltip} = useTooltip()

  const renderSubtitleIcon = (icon, index) => {
    if (!icon) { return null}
    return <div className="object-subtitle-icon" key={index}>
            { !loading 
              ? icon
              : <SkeletonLoader circle={true} width="1.2em" height="1.2em"/>
            }
            </div>
  }

  const isUser = (type === 'USER' || type === 'USER_GROUP')

  return (
  	<div className={"ObjectDetails" + (isUser ? " center" : "")}>
      
      { !isUser &&
        <div className="object-type">
          { type }
        </div>
      }

      { isUser &&
        <Photo
          type={ type }
          size='large'
          data={ photo }
          loading={ loading }
        />
      }
      

      <div className="object-title">
        <h1>{ title || <SkeletonLoader width="10vw" /> }</h1>
      </div>

      {secondaryTitle &&
        <div className="object-secondary-title">
          { secondaryTitle }
        </div>
      }

      { (subtitleIcon || subtitleText) &&
        <div className="object-subtitle">
          { subtitleIcon && Array.isArray(subtitleIcon) 
            ? subtitleIcon.map((icon, index) => {return renderSubtitleIcon(icon, index)}) 
            : renderSubtitleIcon(subtitleIcon)
          }
          { subtitleText &&
            <div className="object-subtitle-text">
              { !loading 
                ? subtitleText
                : <React.Fragment>&nbsp;&nbsp;&nbsp;&nbsp; <SkeletonLoader width="10vw" /></React.Fragment>
              }
            </div>
          }
        </div>
      }

      { showDescription &&
        <div className="object-description">
          { !loading 
            ? (!description) 
              ? <span className="no-result-text">No description available</span> 
              : <TextareaReadOnly
                  value={ textareaInitialState({value: description, readOnly: true, descriptions: fromReferences }) } 
                />
            : <SkeletonLoader width="10vw" count={2} />
          }
        </div>
      }

    <div className="object-content">
  		{ children }
  	</div>

    { details && !loading &&
      <div className="object-details">
        <table>
          <tbody>

          { details.map((detail, index) => {
            if (detail.data) {
              return (
                <tr key={index}>
                  <td className="object-details-title">{detail.title}</td>
                  <td 
                    className="object-details-data"
                    onMouseEnter={ () => detail.tooltip && showTooltip({title: detail.tooltip})} 
                    onMouseLeave={ hideTooltip }
                  >
                    {detail.data}
                  </td>
                </tr>
              )
            }
          })}
          </tbody>
        </table>
      </div>
    }


  </div>
  	)
}

