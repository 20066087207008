import React, { useState, useEffect, useLayoutEffect, useRef } from 'react';
import Skeleton from 'react-loading-skeleton';
import { formatData, keyCode } from '../helpers'
import { Checkbox, Icon, textareaToPlainText } from '../components';
import { useTooltip } from '../hooks'

export const formatSelectOptions = ({options, optionValue, optionLabel, optionSecondaryLabel, optionIcon, defaultOption, optionDisabled, tooltip}) => {
  let arr = defaultOption !== undefined ? [defaultOption] : []

  let tmp = {}
  options.forEach( item => {
    tmp = {value: item[optionValue], label: item[optionLabel], secondaryLabel: item[optionSecondaryLabel] }
    if (optionIcon) {
      tmp = {...tmp, icon: item[optionIcon] }
    }
    if (optionDisabled) {
      tmp = {...tmp, disabled: item[optionDisabled] }
    }
    if (tooltip) {
      tmp = {...tmp, tooltip: textareaToPlainText(item[tooltip]) }
    }
    arr.push(tmp)
  })

  return arr
}

const formatOptionTooltip = option => {

  if (!option) return ""
    
  let title = option.icon && option.label ? <React.Fragment><Icon name={option.icon}/>{ option.label }</React.Fragment> : option.label
  let subTitle = option.secondaryLabel
  let bodyText = option.tooltip && option.icon && !option.label ? <React.Fragment><Icon name={option.icon}/>{ option.tooltip }</React.Fragment> : option.tooltip
  
  return {title, subTitle, bodyText}
}

// ---------------------------------------------------------------------------------
//
// Select
//
// ---------------------------------------------------------------------------------
export const Select = ({
  name, 
  value, 
  label,
  options, 
  onInputChange, 
  loadMoreOptions, 
  loading, 
  resultCount, 
  searchTerm, 
  clearSearchResults, 
  onChange, 
  onBlur, 
  onFocus,
  onMouseEnterOption, 
  onMouseLeaveOption, 
  onSelectAll,
  showSearchResultCount=false,
  isNullable=false, 
  isClearable=false, 
  isMulti=false, 
  icon, 
  iconAsButton=false,
  expandedByDefault=true,
  title,
  placeholder="Type to filter...", 
  setFocus=false, 
  disabled=false
}) => {
  
  const {showTooltip, hideTooltip} = useTooltip()

  const [_searchTerm, _setSearchTerm] = useState("")
  const [filteredOptions, setFilteredOptions] = useState([])
  const [showMenu, setShowMenu] = useState(false)
  const [menuDirection, setMenuDirection] = useState("")
  const [loadMoreData, setLoadMoreData] = useState(false)
  const [expanded, setExpanded] = useState(false)

  const refOptionsContainer = useRef()
  const refOptions = useRef([])
  const refOptionsCheckbox = useRef([])
  const refInput = useRef()
  const refClearButton = useRef()
  const refToggleMenuButton = useRef()
  const refLoadMoreOptions = useRef()
  const refIcon = useRef()
  const refSelectAll = useRef()
  const refSelectAllCheckbox = useRef()

  const filterTerm = searchTerm || _searchTerm
  const setFilterTerm = _setSearchTerm

  useLayoutEffect(() => {

    setExpanded(expandedByDefault ? true : false)

  }, [value]);

  useEffect(() => {

    if (loadMoreOptions) {
      const element = refOptionsContainer.current
      element.addEventListener('scroll', trackScrolling)
      
      return () => {
        element.removeEventListener('scroll', trackScrolling)
      }
    }
  }, []);

  useEffect(() => {
    if (loadMoreData && loadMoreOptions) {    
      loadMoreOptions()
      //setLoadMoreData(prev => {return false})
    }

  }, [loadMoreData]);

  useEffect(() => {
    setLoadMoreData(prev => {return false})     
  }, [options]);

  useEffect( () => {
    // execute focusInput prop function if provided
    if (setFocus) focusInput()
  }, [setFocus])

  useEffect( () => {

    // set ref to each option
    refOptions.current = new Array(options.length)
    if (isMulti) {
      refOptionsCheckbox.current = new Array(options.length)
    }
  }, [filterTerm])

  useEffect( () => {

    let results = []

    // Use internal filter function if external is not provided
    if (!searchTerm) {
      results = options.filter(option => 
        option.label.toLowerCase().includes(filterTerm.toLowerCase()) ||
        ( option.secondaryLabel && option.secondaryLabel.toLowerCase().includes(filterTerm.toLowerCase()) ) || 
        option.value.toString().toLowerCase().includes(filterTerm.toLowerCase())
      );
      setFilteredOptions(sortResults(results));

    } else {
      setFilteredOptions(options);
    }

  }, [options, filterTerm, showMenu])


  useLayoutEffect( () => {

    if (showMenu && filterTerm==="") { // check on filterTerm because we do not want the dropdown to flip between upwards and downwards when filtering
      setMenuDirection((window.innerHeight 
                        - refInput.current.getBoundingClientRect().top 
                        - refInput.current.offsetHeight 
                        - refOptionsContainer.current.offsetHeight 
                       < 0) ? " up" : "")
    }

  }, [showMenu, filteredOptions])


  const sortResults = (results) => {

    // Sort search results
    return results.sort((a, b) => {

      if (filterTerm) { 
        // If the search term matches the beginning of the option label, give it a higher sort order 
        if (
          a.label.toLowerCase().indexOf(filterTerm.toLowerCase()) === 0 && 
          b.label.toLowerCase().indexOf(filterTerm.toLowerCase()) !== 0) 
        {
          return -1
        } else if (
          a.label.toLowerCase().indexOf(filterTerm.toLowerCase()) !== 0 && 
          b.label.toLowerCase().indexOf(filterTerm.toLowerCase()) === 0) 
        {
          return 1
        } 
      }
      
      // Sort normally in asc order if none of the above cases apply
      if (a.label.toLowerCase() > b.label.toLowerCase()) {
        return 1
      } else if (a.label.toLowerCase() < b.label.toLowerCase()) {
        return -1
      } else {
        return 0;
      } 
    })
  }

  const toggleMenu = (mode = undefined) => {

    if (mode === undefined) {
      setShowMenu(!showMenu)
    } else {
      setShowMenu(mode)
    }
  
  }

  const handleKeyDown = (e, val) => {
    switch (e.keyCode) {
      case keyCode.ENTER:
      case keyCode.SPACE:
        if (val !== undefined) {
          handleSelectOption(val);
        } else {
          toggleMenu(true)
        }
        break;

      case keyCode.DOWN_ARROW:
        focusNextListItem(keyCode.DOWN_ARROW);
        break;

      case keyCode.UP_ARROW:
        focusNextListItem(keyCode.UP_ARROW);
        break;

      case keyCode.ESCAPE:
        toggleMenu(false);
        focusInput()
        break;

      default:
        break;
    }
  }
  
  const focusNextListItem = (direction) => {
    const activeElement = document.activeElement;

    const activeElementIndex = refOptions.current.findIndex(el => el === activeElement)
    const activeOptionIndex = activeElementIndex < 0 ? options.findIndex(el => el.value === value) : activeElementIndex

    if (activeOptionIndex >= 0) {
      if (direction === keyCode.DOWN_ARROW && activeOptionIndex+1 < refOptions.current.length) {
        refOptions.current[activeOptionIndex+1].focus()
      } else if (direction === keyCode.UP_ARROW && activeOptionIndex > 0) {
        refOptions.current[activeOptionIndex-1].focus()
      }
    } else {
      refOptions.current[0].focus()
    }
  }

  const focusInput = () => {
    refInput.current.focus()
  }

  const isVisible = (el) => {
    return el.getBoundingClientRect().top <= window.innerHeight
  }

  const trackScrolling = () => {
    const loadingElement = refLoadMoreOptions.current;

    if (loadingElement && isVisible(loadingElement)) {
      setLoadMoreData(prev => {return true})
    }
  }

  const handleInputChange = async (event) => {
    let value = event.target.value
    if (value === " " && filterTerm === "") {
      // do nothing
    } else {
      
      setFilterTerm( value )
      refOptionsContainer.current.scrollTop=0

      if (onInputChange) {
        onInputChange(value)
      }

    }  
    toggleMenu(true)
  }

  const handleClearSelection = () => {
    if (isNullable) { 
      handleSelectOption(null) 
    } else if (isClearable) {
      setFilterTerm("")
      setFilteredOptions([])
      refInput.current.focus()
      
      if (clearSearchResults) { // execute clearSearchResults prop function if provided 
        clearSearchResults()
      }

    }
  }

  const handleSelectOption = (val) => {
    if (isMulti) {
      onChange({name: name, value: val?.value || '', option: val})  
    } else {
      
      toggleMenu(false)
      onChange({name: name, value: val?.value || '', option: val})
      
      if (clearSearchResults) { // execute clearSearchResults prop function if provided 
        clearSearchResults()
      }

      if (iconAsButton) {
        setExpanded(false)
      } else {
        setFilterTerm("")
        focusInput()
      }
    }  
  }

  const handleSelectAll = (e) => {
    e.preventDefault()
    onSelectAll && onSelectAll()
    focusInput()
  }

  const handleSelectCheckbox = (event) => {
    
    const value = parseInt(event.target.name.split("-")[1])

    // add item to selection array
    onChange({name: name, value: value})
  }

  const handleBlur = (e) => {
    const activeElement = e.relatedTarget

    if (activeElement === null || 
        !(activeElement === refInput.current 
        || activeElement === refToggleMenuButton.current 
        || activeElement === refIcon.current
        || activeElement === refClearButton.current 
        || activeElement === refLoadMoreOptions.current
        || activeElement === refSelectAll.current
        || activeElement === refSelectAllCheckbox.current
        || refOptions.current.findIndex(el => el === activeElement) >= 0
        || (refOptionsCheckbox && refOptionsCheckbox.current.findIndex(el => el === activeElement) >= 0)
      )) {
      toggleMenu(false)
      
      
      if (clearSearchResults) { // execute clearSearchResults prop function if provided 
        //clearSearchResults()
      }

      if (onBlur) { // execute onBlur prop function if provided 
        onBlur()
      }

      if (iconAsButton) {
        setExpanded(false)
      }

      if (!iconAsButton) {
        setFilterTerm("")
        setFilteredOptions([])
      }
    }
  }

  const handleMouseEnter = (e, option) => {
    if (onMouseEnterOption) onMouseEnterOption(e, option); 
    e.preventDefault()

    showTooltip(formatOptionTooltip(option))

  }
  const handleMouseLeave = (e, option) => {
    e.preventDefault()
    hideTooltip()
    if (onMouseLeaveOption) onMouseLeaveOption(e, option)
  }

  const handleExpand = (el) => {
    onFocus && onFocus(el)

    refInput.current.focus()
    setExpanded(true)  
  }

  /*const handleBlur = (el) => {
    if (iconAsButton && value === "") {
      setExpanded(false)
    }
    onBlur && onBlur(el)
  }*/

  const optionIsSelected = (match) => {
    if (isMulti) {
      return (value.find(x => x.value === match)) ? true : false
    } else {
      return (value === match) ? true : false
    }
  }

  const getOptionValue = (val) => {

    return (val !== undefined && val !== null && options.length > 0) 
                ? options.find(option => option.value.toString() === val.toString()) 
                : undefined
  }

  const getOptionLabel = (val) => {

    const res = getOptionValue(val)

    if (res !== undefined) {
      const icon = res.icon ? <div className="select-option-icon"> { formatData(res.icon,'icon') }</div> : ""
      return <div className="select-value">{ icon }<div className="select-value-label">{ res.label }</div></div>
    }
    return;
  }

  const inputShowTooltip = () => {

    const label = getOptionValue(value)?.label
    if (title) {
      showTooltip({title: title})
    } else if (label) {
      showTooltip({title: label})
    }
  }

  const selectOptionsDirection = menuDirection
  const menuState = showMenu ? 'show' : ''
  const noValue = (isMulti) ? true : value === '' || value === undefined || value === null
  const selectLabel = filterTerm === "" ? (noValue || isMulti ? placeholder : getOptionLabel(value)) : ""
  const allIsSelected = (onSelectAll || showSearchResultCount) && (filteredOptions && filteredOptions.length > 0) && filteredOptions.filter(x => {return optionIsSelected(x.value)}).length === filteredOptions.length ? true : false

  return (
    <div className={"CustomSelect" + (icon ? " with-icon" : "") + (iconAsButton  ? " icon-as-button" : "") + (iconAsButton && !expanded ? " collapsed" : "") + (disabled ? " disabled" : "")}>
      { label && 
        <label 
          htmlFor={name}  
          onMouseEnter={ () => title && showTooltip({title: title}) } 
          onMouseLeave={ () => title && hideTooltip() }       
        >
          <span>{label}</span>
          {title && <span className="icon-description">i</span>}
        </label>
      }

      <div className={"select-container " + menuState + selectOptionsDirection }>
        
        <div 
          className="select-input-container" 
          onMouseEnter={ inputShowTooltip } 
          onMouseLeave={ () => title && hideTooltip() }
        >

        { !disabled &&
          <div className="select-button-container">
          { ((isNullable && value) || (isClearable && filterTerm)) && 
            <div 
              className="select-button button-clear-selection" 
              onClick={ handleClearSelection }
              ref={ refClearButton }
              tabIndex= {1}
            >
              <div className="button-clear-selection-icon"></div>
            </div>
          }
          { expanded &&
          <div 
            className="select-button button-toggle-menu" 
            onClick={ () => { focusInput(); toggleMenu(); }}
            ref={ refToggleMenuButton }
            tabIndex= {1}
          >
            <div className="button-toggle-menu-icon"></div>
          </div>
        }
          </div>
        }
        { icon &&
          <div className="input-icon" ref={refIcon} onClick={ () => { focusInput(); toggleMenu(); }}>
            <Icon name={icon} tooltip={title} />
          </div>
        }
        <input 
          type="text"
          className="select-input"
          tabIndex={ 0 }
          //aria-expanded={ showMenu }
          aria-haspopup="listbox"
          aria-autocomplete="list"
          ref={ refInput }
          name={ name }
          value={ filterTerm } 
          disabled={disabled}
          autoComplete='new-password' // prevent browsers from autofilling
          onChange = { handleInputChange } 
          onKeyDown = { handleKeyDown }
          onClick = { () => toggleMenu() }
          onBlur = { handleBlur }
          onFocus={ handleExpand }
          />
        </div>

        <div className={ "select-value-container" + (noValue ? " placeholder" : "") + (disabled ? " disabled" : "") }>
          { selectLabel }
        </div>

        <div 
          className={"select-options"}
          ref={ refOptionsContainer }
          role="listbox"
        >

        { filteredOptions.length > 0
          ?
            <React.Fragment>
            { (onSelectAll || showSearchResultCount) &&
              <div className="select-options-header">
                {onSelectAll && 
                  <div 
                    className="button-select-all" 
                    onClick={(e) => { handleSelectAll(e) }} 
                    ref={refSelectAll} 
                    tabIndex={ 0 }
                  >
                    <Checkbox
                      className=""
                      name=""
                      label="Select All"
                      ref={ refSelectAllCheckbox }
                      onChange={ handleSelectCheckbox }
                      checked={ allIsSelected }
                    />
                  </div> 
                }
                <div className="select-options-count">{resultCount} assets found</div>
              </div>
            }
            {
              filteredOptions.map( (option, i) => 
                <div 
                  className={ 
                    "select-option"+ 
                    //(optionIsSelected(option.value) || (option.value && option.disabled) ? " active": "") + 
                    (optionIsSelected(option.value) ? " active": "") + 
                    (option.secondaryLabel === undefined ? "" : " narrow-padding") + 
                    //(option.selectedByOther || (option.value && option.disabled) ? " selected" : "") +
                    (option.selectedByOther ? " selected" : "") +
                    (option.disabled ? " disabled" : "")
                  } 
                  key={ option.value}
                  ref={ el => refOptions.current[i] = el }
                  aria-label={ option.label }
                  aria-selected={ optionIsSelected(option.value) }
                  role="option"
                  tabIndex={ 0 }
                  onClick={ (e) => {e.preventDefault(); !option.disabled && handleSelectOption(option)} }
                  onKeyDown={ (e) => !option.disabled && handleKeyDown(e, option) }
                  onMouseEnter={e => handleMouseEnter(e, option) }
                  onMouseLeave={e => handleMouseLeave(e, option) }
                  onBlur = { handleBlur }
                > 
                  { isMulti && 
                    <div className="select-option-checkbox">
                    <Checkbox
                      className={ (option.selectedByOther ? "dim-selected" : "")}
                      name={ `selected-${option.value}` }
                      ref={ el => refOptionsCheckbox.current[i] = el }
                      onChange={ handleSelectCheckbox }
                      checked={ (value.find(x => x.value === option.value)) || (option.value && option.disabled) ? true : false }
                      disabled={option.disabled ? true : false }
                    />
                    </div>
                  }

                  { option.icon && 
                    <div className="select-option-icon">{ formatData(option.icon,'icon') }</div>
                  }
                  <div className="select-option-label">
                    <div>{ option.label }</div>
                    <div className="select-option-secondary-label">{ option.secondaryLabel }</div>
                  </div>
                </div>
              )
            }
            </React.Fragment>
          : searchTerm === undefined 
            ? filterTerm.length > 0 && <div className="select-options-empty">No results</div>
            : filteredOptions.length === 0 && !loading && filterTerm.length > 1 && <div className="select-options-empty">No results</div>
        }

        { ((loading && filteredOptions.length === 0) || (resultCount && filteredOptions.length < resultCount && filteredOptions.length > 0)) &&
          <div 
            className="select-option"
            ref={ refLoadMoreOptions }
            onClick={ () => setLoadMoreData(prev => {return true}) }
            title="Click to load more items"
          >
            <div className="select-option-label">
              <div><Skeleton width="200px" /></div>
            </div>
          </div>
        }
        </div>

      </div>
    
    </div>
  );
};


// ---------------------------------------------------------------------------------
//
// SelectButtons
//
// ---------------------------------------------------------------------------------
export const SelectButtons = ({name, value, label, options, onChange, isNullable, disabled=false, title}) => {

  const {showTooltip, hideTooltip} = useTooltip()

  const refOptions = useRef([])

  const SPACEBAR_KEY_CODE = 32 //[0, 32];
  const ENTER_KEY_CODE = 13
  const LEFT_ARROW_KEY_CODE = 37
  const RIGHT_ARROW_KEY_CODE = 39
  const ESCAPE_KEY_CODE = 27;

  useEffect( () => {

    refOptions.current = new Array(options.length)

  }, [])


  const focusNextListItem = (direction) => {
    const activeElement = document.activeElement;

    const activeElementIndex = refOptions.current.findIndex(el => el === activeElement)
    const activeOptionIndex = activeElementIndex < 0 ? options.findIndex(el => el.value === value) : activeElementIndex

    if (activeOptionIndex >= 0) {
      if (direction === RIGHT_ARROW_KEY_CODE && activeOptionIndex+1 < refOptions.current.length) {
        refOptions.current[activeOptionIndex+1].focus()
      } else if (direction === LEFT_ARROW_KEY_CODE && activeOptionIndex > 0) {
        refOptions.current[activeOptionIndex-1].focus()
      }
    } else {
      refOptions.current[0].focus()
    }
  }

  const handleSelectOption = (val) => {  
    if (val === value && isNullable) val = null
    onChange({name: name, value: val})   
  }

  const handleKeyDown = (e, val) => {
    switch (e.keyCode) {
      case ENTER_KEY_CODE:
      case SPACEBAR_KEY_CODE:
        if (val !== undefined) {
          handleSelectOption(val);
        } 
        break;

      case LEFT_ARROW_KEY_CODE:
        focusNextListItem(LEFT_ARROW_KEY_CODE);
        break;

      case RIGHT_ARROW_KEY_CODE:
        focusNextListItem(RIGHT_ARROW_KEY_CODE);
        break;

      case ESCAPE_KEY_CODE:

        break;

      default:
        break;
    }
  }

  const optionIsSelected = (match) => {
    return (value === match) ? true : false
  }

  return (
    <div className="CustomSelectButtons">

      { label && 
        <label 
          htmlFor={name}
          onMouseEnter={ () => title && showTooltip({title: title})} 
          onMouseLeave={ () => title && hideTooltip() }
        >
          <span>{label}</span>
          {title && <span className="icon-description">i</span>}
        </label>
      }

      <div className={"select-container" + (disabled ? " disabled" : "")}>

        <div 
          className="select-options"
          role="listbox"
        >
        { options.length > 0 &&
            options.map( (option, i) => 
              <div 
                className={
                  "select-option" + 
                  (optionIsSelected(option.value) ? " active": "") + 
                  (option.disabled ? " disabled": "") +
                  (option.label ? "": " no-label") 
                } 
                key={option.value}
                ref={el => refOptions.current[i] = el}
                aria-label={option.label}
                aria-selected={optionIsSelected(option.value)}
                role="option"
                tabIndex={ 0 }
                onClick={() => { if (!disabled && !option.disabled) handleSelectOption(option.value) }}
                onKeyDown={ (e) => { if (!disabled && !option.disabled) handleKeyDown(e, option.value) }}
                onMouseEnter={ () => showTooltip(formatOptionTooltip(option))} 
                onMouseLeave={ hideTooltip }
              >
                { option.icon && 
                  <div className="select-option-icon"> <Icon name={option.icon} /></div>
                }
                { option.label && 
                  <div className="select-option-label">{option.label}</div>
                }
              </div>
            )
        }
        </div>

      </div>
    
    </div>
  );
};