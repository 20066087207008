import React, { useState, useEffect } from 'react';
import { fieldDescriptionService } from '../services';
import { DataTable } from '../components';
import { useSideDialog, useModalDialog, useGlobalState, usePhotos, useMessage } from '../hooks';
import { FieldSensitivity, Role } from '../helpers';

export const FieldDescriptions = () => {

  const [fieldDescriptions, setFieldDescriptions] = useState([]);
  const [loggedInUser, setLoggedInUser] = useGlobalState('loggedInUser');
  const [selectedItems, setSelectedItems] = useState([]);
  const [loading, setLoading] = useState(true);

  const clearSelectedItems = () => {
    setSelectedItems([])
  }

  const { showMessage } = useMessage()
  const { showSideDialog, hideSideDialog } = useSideDialog(clearSelectedItems)
  const { showModalDialog, hideModalDialog } = useModalDialog()
  const { fetchPhotos } = usePhotos()

  useEffect(() => {

    fetchItems()

  }, []);

  const fetchItems = async () => {

    const promise = new Promise(  (resolve, reject) => {
      fieldDescriptionService.getAll()
        .then(res => {

          const result = res.field_descriptions.map(x => {return {...x, pii: (x.is_pii ? 'PII' : null), pii_tooltip: (x.is_pii ? 'Classified as Personally Identifiable Information (PII)' : null)}})
          setFieldDescriptions(result) 
          setLoading(false)
          fetchPhotos(result, 'field_description_owner_user_id', setFieldDescriptions)
          resolve(result)
        })
        .catch(err => {
          showMessage(err, 'error')
          setLoading(false)
        })
    })

    Promise.all([promise, fetchFieldCount()])
      .then( result => {
        updateFieldCount(result[0], result[1])
      })
      .catch(err => {showMessage(err, 'error')})
  }

  const fetchFieldCount = async () => {
    return new Promise(  (resolve, reject) => {
      fieldDescriptionService.getFieldCount()
        .then(res => {

          resolve(res.field_count)

        })
        .catch(err => {
          reject(err)
        });
    })
  }

  const updateFieldCount = (data, statistics) => {

    let dataTmp = data
    let statistic = {}
    let tmp = null

    let maxValue = 0

    statistics.forEach( row => {
      if (row.field_count > maxValue) {
        maxValue = row.field_count
      }
    })

    data.forEach( (row, index) => {
      statistic = statistics.find(x => x.field_description_id === row.field_description_id)
      tmp = dataTmp[index]
      dataTmp[index] = {
        ...tmp, 
        associated_field_count: (statistic ? statistic.field_count : 0), 
        associated_field_count_max: maxValue
      }
    })

    setFieldDescriptions([...dataTmp])
  }

  const addFieldDescription = async data => {
    try {
    const res = await fieldDescriptionService.create(data).catch(e => { throw e })
    
    await fetchItems()
        showMessage(res.message)
    } catch (err) {
      showMessage(err, 'error')
      throw err
    } 
  }

  const editFieldDescription = async data => {

    await fieldDescriptionService.update(data)
      .then(async res => { 

        fetchItems()
        showMessage(res.message)
        clearSelectedItems()
        hideSideDialog()
      })
      .catch(err => {
        showMessage(err, 'error')
        throw err
      });
  }

  const deleteFieldDescription = async (data) => {    
    await fieldDescriptionService.destroy({ field_descriptions: data })
      .then(async res => { 

        hideModalDialog()
        clearSelectedItems()
        fetchItems()
        showMessage(res.message)

      })
      .catch(err => {
        showMessage(err, 'error')
      });
  }

  const editable = loggedInUser && (loggedInUser.user_role_name === Role.admin || loggedInUser.user_role_name === Role.editor)

  return (
    <div className="column wide-margin">

        <DataTable
          columns={[
            {id: 'field_role_name', name: '', type: 'icon', tooltip: 'field_role_name'},
            {id: 'field_description_name', name: 'Name', link: '/browse_field_descriptions/:field_description_id', className:"bold", tooltip: "field_description_description"},
            {id: 'field_unit_code', name: 'Field Unit', tooltip: 'field_unit_name'},
            {id: 'field_description_description', name: 'Description', type: 'description', viewMode: 'descriptions'},
            {id: 'associated_field_count', name: 'Associated Fields', type: 'chart', chartType: 'bar', data: {data:'associated_field_count', max: 'associated_field_count_max'}, viewMode: 'statistics'},
            {id: 'user_photo', name: '', type: 'user-photo', link: '/users/:field_description_owner_user_id', tooltip: 'field_description_owner_user_fullname'},            
            {id: 'field_description_owner_user_fullname', name: 'Owner', link: '/users/:field_description_owner_user_id'},
            {id: 'modified_timestamp', name: 'Modified', type: 'datetime', viewMode: 'statistics'},
            {id: 'pii', name: '', type: 'icon', tooltip: 'pii_tooltip', className: 'no-right-margin'},
            {id: 'field_sensitivity_level', name: 'Data Sensitivity', type: 'pill', value: 'field_sensitivity_level', values: FieldSensitivity, tooltip: 'field_sensitivity_name'}
          ]}
          buttons={[ 
            {label: "Add +", action: "add", mainButton: true, onClick: (items) => showSideDialog('addFieldDescription', items || selectedItems, addFieldDescription) },
            {label: "Edit", action: "edit", onClick: (items) => showSideDialog('editFieldDescription', items || selectedItems, editFieldDescription) },
            {label: "Delete", action: "delete", onClick: (items) => showModalDialog('deleteFieldDescription', items || selectedItems, deleteFieldDescription) }
          ]}
          title="Field Descriptions"
          data={fieldDescriptions }
          idColumn='field_description_id'
          selectedItems={selectedItems}
          setSelectedItems={setSelectedItems}
          loading={loading}
          editable={editable}
          filterable={true}
          filterObjects={['field_description_name', 'field_description_description', 'field_role_name', 'field_description_owner_user_fullname', 'field_sensitivity_name', 'pii']}
          showViewModes={true}
        />

      </div>
  );
}