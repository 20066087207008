import React from 'react';
import { 
  Icon,
  SkeletonLoader } from './components'
import { Role, subjects, formatData } from './helpers';
import { useDocumentTitle } from './hooks';
import { 
  BusinessTerm,
  Dataset,
  DatasetGroup,
  Datasource,
  Field,
  FieldDescription,
  FieldDescriptions,
  FieldUnits,
  Glossaries,
  Glossary,
  Home,
  Job,
  Profile,
  Settings,
  System,
  Systems,
  Task,
  Tasks,
  User,
  Users,
  UserGroup,
  UserGroups } from './views';

const CurrentField = ({ match }) => {
  const res = subjects.currentField.value

  const loading = res === "not_found" || (res?.field_id && res.field_id.toString() === match.params.field_id)
  const title = loading ? res?.field_name || match.params.field_id : undefined

  useDocumentTitle(title, "Field")

  if (loading) {
    return (
      <span>
      <Icon name={res.datatype_category} tooltip={ res.datatype_fullname } />
      <span className="breadcrumb-item-text">{title}</span>
      </span>
      )
  }
  return <SkeletonLoader width="7.5vw" />
}

const CurrentDataset = ({ match }) => {
  const res = subjects.currentDataset.value

  const loading = res === "not_found" || (res?.dataset_id && res.dataset_id.toString() === match.params.dataset_id)
  const title = loading ? res?.dataset_name || match.params.dataset_id : undefined

  useDocumentTitle(title, "Dataset")

  if (loading) {
    return (
      <span>
      <Icon name={res.dataset_type_name} tooltip={ res.dataset_type_name } />
      <span className="breadcrumb-item-text">{title}</span>
      </span>
      )
  }
  return <SkeletonLoader width="7.5vw" />
}

const CurrentDatasetGroup = ({ match }) => {
  const res = subjects.currentDatasetGroup.value

  const loading = res === "not_found" || (res?.dataset_group_id && res.dataset_group_id.toString() === match.params.dataset_group_id)
  const title = loading ? res?.dataset_group_name || match.params.dataset_group_id : undefined

  useDocumentTitle(title, "Dataset Group")

  if (loading) {
    return (
      <span>
      <span className="breadcrumb-item-text">{title}</span>
      </span>
      )
  }
  return <SkeletonLoader width="7.5vw" />
}

const CurrentDatasource = ({ match }) => {
  const res = subjects.currentDatasource.value

  const loading = res === "not_found" || (res?.datasource_id && res.datasource_id.toString() === match.params.datasource_id)
  const title = loading ? res?.datasource_name || match.params.datasource_id : undefined

  useDocumentTitle(title, "Datasource")

  if (loading) {
    return (
      <span>
      <Icon name={res.datasource_type_category} tooltip={ res.datasource_type_name } />
      <span className="breadcrumb-item-text">{title}</span>
      </span>
      )
  }
  return <SkeletonLoader width="7.5vw" />
}

const CurrentSystem = ({ match }) => {
  const res = subjects.currentSystem.value

  const loading = res === "not_found" || (res?.system_id && res.system_id.toString() === match.params.system_id)
  const title = loading ? res?.system_name || match.params.system_id : undefined

  useDocumentTitle(title, "System")

  if (loading) {
    return (
      <span>
      <span className="breadcrumb-item-text">{title}</span>
      </span>
      )
  }
  return <SkeletonLoader width="7.5vw" />
}

const CurrentFieldDescription = ({ match }) => {
  const res = subjects.currentFieldDescription.value
  
  const loading = res === "not_found" || (res?.field_description_id && res.field_description_id.toString() === match.params.field_description_id)
  const title = loading ? res?.field_description_name || match.params.field_description_id : undefined

  useDocumentTitle(title, "Field Description")

  if (loading) {
    return (
      <span>
      <Icon name={res.field_role_name} tooltip={ res.field_role_name } />
      <span className="breadcrumb-item-text">{title}</span>
      </span>
      )
  }
  return <SkeletonLoader width="7.5vw" />
}

const CurrentBusinessTerm = ({ match }) => {
  const res = subjects.currentBusinessTerm.value

  const loading = res === "not_found" || (res?.business_term_id && res.business_term_id.toString() === match.params.business_term_id)
  const title = loading ? res?.business_term_name || match.params.business_term_id : undefined

  useDocumentTitle(title, "Business Term")

  if (loading) {
    return (
      <span>
      <span className="breadcrumb-item-text">{title}</span>
      </span>
      )
  }
  return <SkeletonLoader width="7.5vw" />
}

const CurrentGlossary = ({ match }) => {
  const res = subjects.currentGlossary.value

  const loading = res === "not_found" || (res?.glossary_id && res.glossary_id.toString() === match.params.glossary_id)
  const title = loading ? res?.glossary_name || match.params.glossary_id : undefined

  useDocumentTitle(title, "Glossary")

  if (loading) {
    return (
      <span>
      <span className="breadcrumb-item-text">{title}</span>
      </span>
      )
  }
  return <SkeletonLoader width="7.5vw" />
}

const CurrentTask = ({ match }) => {
  const res = subjects.currentTask.value

  const loading = res === "not_found" || (res?.task_id && res.task_id.toString() === match.params.task_id)
  const title = loading ? res?.task_name || match.params.task_id : undefined

  useDocumentTitle(title, "Task")

  if (loading) {
    return (
      <span>
      <span className="breadcrumb-item-text">{title}</span>
      </span>
      )
  }
  return <SkeletonLoader width="7.5vw" />
}

const CurrentJob = ({ match }) => {
  const res = subjects.currentJob.value

  const loading = res === "not_found" || (res?.job_id && res.job_id.toString() === match.params.job_id)
  const titleRaw = loading ? new Date(res?.job_started_timestamp).toLocaleString('sv-SE') || match.params.job_id : undefined
  const title = loading ? formatData(res?.job_started_timestamp, 'datetime') || match.params.job_id : undefined

  useDocumentTitle(titleRaw, "Job")

  if (loading) {
    return (
      <span>
      <span className="breadcrumb-item-text">{title}</span>
      </span>
      )
  }
  return <SkeletonLoader width="7.5vw" />
}

const CurrentUser = ({ match }) => {
  const res = subjects.currentUser.value

  const loading = res === "not_found" || (res?.user_id && res.user_id.toString() === match.params.user_id)
  const title = loading ? res?.user_fullname || match.params.user_id : undefined

  useDocumentTitle(title, "User")

  if (loading) {
    return (
      <span>
      <span className="breadcrumb-item-text">{title}</span>
      </span>
      )
  }
  return <SkeletonLoader width="7.5vw" />
}

const CurrentUserGroup = ({ match }) => {
  const res = subjects.currentUserGroup.value

  const loading = res === "not_found" || (res?.user_group_id && res.user_group_id.toString() === match.params.user_group_id)
  const title = loading ? res?.user_group_name || match.params.user_group_id : undefined

  useDocumentTitle(title, "User Group")

  if (loading) {
    return (
      <span>
      <span className="breadcrumb-item-text">{title}</span>
      </span>
      )
  }
  return <SkeletonLoader width="7.5vw" />
}

export const routesConfig = [
  {
    path: "/browse_field_descriptions/:field_description_id",
    breadcrumb: CurrentFieldDescription,
    element: FieldDescription,
  },
    {
    path: "/browse_field_descriptions",
    breadcrumb: "Browse Field Descriptions",
    element: FieldDescriptions,
    title: "Field Descriptions"
  },
  {
    path: "/field_units",
    breadcrumb: "Field Units",
    element: FieldUnits,
    roles: [Role.admin],
    title: "Field Units"
  },
  {
    path: "/browse_business_terms",
    breadcrumb: "Browse Business Terms",
    element: Glossaries,
    title: "Glossaries"
  },
  { 
    path: "/browse_business_terms/:glossary_id", 
    breadcrumb: CurrentGlossary,
    //tooltip: "Glossary: " + glossaryObservable.currentGlossaryValue.glossary_name,
    element: Glossary,
  },
  { 
    path: "/browse_business_terms/:glossary_id/:business_term_id", 
    breadcrumb: CurrentBusinessTerm,
    element: BusinessTerm
  },
  {
    path: "/browse_datasets",
    breadcrumb: "Browse Datasets",
    element: Systems,
    title:"Systems"
  },
  { 
    path: "/browse_datasets/:system_id", 
    breadcrumb: CurrentSystem,
    //tooltip: "System: " + systemObservable.currentSystemValue.system_name,
    element: System
  },
  { 
    path: "/browse_datasets/:system_id/:datasource_id", 
    breadcrumb: CurrentDatasource,
    //tooltip: "Datasource: " + datasourceObservable.currentDatasourceValue.datasource_name,
    element: Datasource
  },
  { 
    path: "/browse_datasets/:system_id/:datasource_id/:dataset_group_id", 
    breadcrumb: CurrentDatasetGroup,
    //tooltip: "Dataset Group: " + datasetGroupObservable.currentDatasetGroupValue.dataset_group_name,
    element: DatasetGroup
  },
  { 
    path: "/browse_datasets/:system_id/:datasource_id/:dataset_group_id/:dataset_id", 
    breadcrumb: CurrentDataset,
    //tooltip: "Dataset: " + datasetObservable.currentDatasetValue.dataset_name,
    element: Dataset
  },
    { 
    path: "/browse_datasets/:system_id/:datasource_id/:dataset_group_id/:dataset_id/:field_id", 
    breadcrumb: CurrentField,
    //tooltip: "Field: " + fieldObservable.currentFieldValue.field_name,
    element: Field
  },
  {
    path: "/profile",
    breadcrumb: "Profile",
    element: Profile,
    title: "Profile"
  },
    {
    path: "/tasks/:task_id/:job_id",
    breadcrumb: CurrentJob,
    element: Job
  },
    {
    path: "/tasks/:task_id",
    breadcrumb: CurrentTask,
    element: Task,
    roles: [Role.admin]
  },
  {
    path: "/tasks",
    breadcrumb: "Tasks",
    element: Tasks,
    roles: [Role.admin],
    title: "Tasks"
  },
  {
    path: "/users/:user_id",
    breadcrumb: CurrentUser,
    element: User,
  },
  {
    path: "/users",
    breadcrumb: "Users",
    element: Users,
    roles: [Role.admin],
    title: "Users"
  },
  {
    path: "/user_groups/:user_group_id",
    breadcrumb: CurrentUserGroup,
    element: UserGroup,
    roles: [Role.admin],
  },
  {
    path: "/user_groups",
    breadcrumb: "User Groups",
    element: UserGroups,
    roles: [Role.admin],
    title: "User Groups"
  },
  {
    path: "/settings",
    breadcrumb: "Settings",
    element: Settings,
    title: "Settings"
  },
  {
    path: "/",
    breadcrumb: "Home",
    element: Home,
    title: "Katalogue"
  }
];
