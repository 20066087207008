import config from '../config';
import { subjects, commonRequestOptions } from './'

const is401Error = (response) => {
  if (response) {
    if (response.status === 401 || response.status === 403) {
      return true;
    }
  }
  return false
}

const _createLinkedSignal = (...signals) => {
  signals = signals.filter(signal => !!signal);

  // Debugging is easier when we can avoid wrapping
  if (signals.length === 1) {
    return signals[0]; 
  }

  const controller = new AbortController();
  for (let signal of signals) {
    signal.addEventListener('abort', () => controller.abort());
  }
  return controller.signal;
};

const _customFetch = () => {

  let controller = new AbortController();

  const func = async (request, options, signal, cancelOnReentry=false, hideAbortError=true) => {

    if (cancelOnReentry) {
      controller.abort()
      controller = new AbortController()
    }

    let csrfToken 
    const subject = subjects['csrfToken'].subscribe(s => {
      csrfToken = s
    })

    try {

      let requestOptions = {
        ...options,
        ...commonRequestOptions()
      }

      if (csrfToken) {
        requestOptions.headers['x-katalogue-csrf'] = csrfToken
      }

      // Main API call
      const response = await fetch(request, { ...requestOptions, signal: _createLinkedSignal(controller.signal, signal?.signal)})
   
      // Handle 401s via a refresh
      if (is401Error(response)) {
        
        await fetch(
          `${config.api.url}/auth/refresh_access_token`, 
          {
            method: 'POST',
            headers: {'x-katalogue-csrf': csrfToken },
            ...commonRequestOptions()
          })

        try {
            // Retry the API call
            return await fetch(request, { ...requestOptions, signal: _createLinkedSignal(controller.signal, signal?.signal)})

        } catch (error) {
            // Report retry errors
            throw error;
        }
      }
      return response
    }
    catch (error) {
      // Handle Abort errors
      if (error.name === 'AbortError' && hideAbortError) {
        return; // Request has been canceled, so do nothing
      } else {
        throw error
      }
    }
    finally {
      // Clean up
      subject.unsubscribe()
    }
  }

  const abort = () => {
    controller.abort()
  }

  func.abort = abort
  
  return func
  
}

export const customFetch = _customFetch()