import React from 'react';
import { textareaToPlainText } from '../components';

export const formatTooltip = ({type='string', title, tooltip, value, values={}, label}) => {
  tooltip = (!tooltip || tooltip === null ? undefined : textareaToPlainText(tooltip))

  switch(type) {
    case 'string':
      title=values && value ? values[value].label : title
      break
    case 'integer':
      title=tooltip || title.toLocaleString('sv-SE')
      tooltip= title && tooltip ? undefined : tooltip
      break
    case 'percent':
      title=tooltip || (title*100).toFixed(0) + "%"
      tooltip= title && tooltip ? undefined : tooltip
      break
    case 'boolean':
      title=values[(title) ? 'true' : 'false'].label
      break
    case 'datetime':
      // Return string on the form yyyy-mm-dd hh:mm:ss
      title = new Date(title).toLocaleString('sv-SE')
      break
    case 'icon':
      title=undefined
      break
    case 'user-photo':
    case 'group-photo':
      if (title === undefined) return null
      title=tooltip
      tooltip=undefined
      break
    case 'pill':
      title=tooltip || values[title].label
      tooltip=undefined
      break
    case 'chart':
      title=tooltip
      tooltip=undefined
      break
    case 'button':
      title=label
      break
    default:
      break
    }

    if (title && !tooltip) {
      tooltip=title
      title=undefined
    }
  return {title: title, bodyText: tooltip}
}

export const formatNumberUnit = (num) => {
  const lookup = [
    { value: 1, symbol: "" },
    { value: 1e3, symbol: "k" },
    { value: 1e6, symbol: "M" },
    { value: 1e9, symbol: "G" },
    { value: 1e12, symbol: "T" },
    { value: 1e15, symbol: "P" },
    { value: 1e18, symbol: "E" }
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  var item = lookup.slice().reverse().find(function(item) {
    return num >= item.value;
  });
  return item ? parseFloat((num / item.value).toFixed(num / item.value >= 10 ? 0 : 1)).toLocaleString('sv-SE').replace(rx, "$1") + item.symbol : "0";
}

export const formatDiskSize = (num) => {
  const lookup = [
    { value: 1, symbol: " B" },
    { value: 1024, symbol: " kB" },
    { value: 1024**2, symbol: " MB" },
    { value: 1024**3, symbol: " GB" },
    { value: 1024**4, symbol: " TB" },
    { value: 1024**5, symbol: " PB" }
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  var item = lookup.slice().reverse().find(function(item) {
    return num >= item.value;
  });
  return item ? parseFloat((num / item.value).toFixed(num / item.value >= 10 ? 0 : 1)).toLocaleString('sv-SE').replace(rx, "$1") + item.symbol : "0";
}

export function formatData(str, type='string', value, values={}, chartType, data, label, onClick) {

    if (str == null && !(type === 'user-photo' || type === 'group-photo' || type === 'description') ) {
      return str
    }
    
    switch(type) {
      case 'string':
        return <span>{values && value ? values[value].label : str}</span>
        break
      case 'integer': 
        return <span>{str.toLocaleString('sv-SE')}</span>
        break
      case 'integer-format':
        return <span>{formatNumberUnit(str)}</span>
        break
      case 'integer-disk-size':
        return <span>{formatDiskSize(str)}</span>
        break
      case 'percent':
        return <span>{ (str*100).toFixed(0) + "%" }</span>
        break
      case 'boolean':
        return <span className={values[(str) ? 'true' : 'false'].className}>{ values[(str) ? 'true' : 'false'].label }</span>
        break
      case 'datetime':
        if (str === undefined) return null
        // Return string on the form yyyy-mm-dd hh:mm:ss
        const tmp = new Date(str).toLocaleString('sv-SE')
        return <span>{tmp}</span>
        break
      case 'icon':
        return <span className={ "icon icon-" + str.replace(/ /g, '-').replace(/_/g, '-').toLowerCase() }></span>
        break
      case 'user-photo':
      case 'group-photo':
        if (str === undefined) return null
        if (str) {
          return <img className="photo-img" src={ "data:image/jpeg;base64," + str } alt="" />
        } else {
          return <div className={"icon photo-img placeholder icon-" + type } />
        }
        break
      case 'pill':
         return <span className={values[value].className}>{values[value].label}</span>
         break
      case 'chart':
        switch(chartType) {
          case'bar':
            const style={width: ((data.data / data.max)*100) + "%"}
            return <span className="chart-container"><span className="bar" style={style}></span></span>
            break
          case'percent-bar':
            const styleBar={width: (data.data*100) + "%"}
            const styleBg={width: ((1-data.data)*100) + "%"}
            return <span className="chart-container"><span className="bar" style={styleBar}></span><span className="bar-bg" style={styleBg}></span></span>
            break
          default:
            break
        }
        break
      case 'button':
         return <button className="button" onClick={() => onClick(data.data)}><span>{label}</span></button>
         break
      case 'description':
         return str ? textareaToPlainText(str) : <span className="no-result-text">-</span>
      default:
        return str
        break
    }
  }

export function formatConnectionString(database, hostname, parts, datasource_type_code, datasource_type_category) {

  const delimiters = {
    api: "/",
    folder: "\\",
    database: ".",
    tableau_server: "/"
  }

  const enclosures = {
    api: { start: "", end: ""},
    folder: { start: "", end: ""},
    mssql: { start: "", end: ""}, //mssql: { start: "[", end: "]"},
    postgres: { start: "", end: ""}, //postgres: { start: "\"", end: "\""},
    snowflake: { start: "", end: ""}, //snowflake: { start: "\"", end: "\""},
    oracle: { start: "", end: ""}, //oracle: { start: "\"", end: "\""},
    ibmdb2: { start: "", end: ""}, //ibmdb2: { start: "\"", end: "\""},
    tableau: { start: "", end: ""} //tableau: { start: "", end: ""}
  }

  const enclosureStart = (enclosures[datasource_type_code] && enclosures[datasource_type_code].start) || ""
  const enclosureEnd = (enclosures[datasource_type_code] && enclosures[datasource_type_code].end) || ""

  const delimiter = delimiters[datasource_type_category]

  const allParts = [((datasource_type_category === "database") ? database : hostname), ...parts]

  const conn_string = allParts.map( (part, key) => {
    var fragment = enclosureStart + part + enclosureEnd
    if (key !== allParts.length-1) {
      fragment = fragment + delimiter
    } 

    return <span className="word" key={key}>{fragment}</span>
  })

  return conn_string
}

export function generateURL(url, data) {

    let re = new RegExp(":(([a-zA-Z0-9_])*)/?","gi")
     
    let matches = []
    let params = []

    while(matches = re.exec(url)) {
      params.push(matches[1]);
    }

    let map = {}
    params.forEach( match => {
      map = {...map, [":"+match]: data[match] }
    })

    re = new RegExp(Object.keys(map).join("|"),"gi");

    url = url.replace(re, function(matched){
      return map[matched];
    });

    return url
}

export function formatPath(parts) {

  let path = ""
  parts.forEach( (part, i) => {
    path = path + (i>0 ? " > " : "") + part
  })
  
  return path
}

export function formatUserFriendly(str) {

  if (!str) {
    return ''
  }

  str = str.replace(/[_\s]+/g,' ').trim()

  let niceString = ""

  // Handle differently based on if input string is CamelCase or not
  if (str.match(/[a-z]/) && str.match(/[A-Z]/)) { // is CamelCase
    niceString = str.replace(/([a-z])([A-Z])/g, '$1 $2')
    .replace(/([A-Z])([a-z])/g, ' $1$2')
    .replace(/\ +/g, ' ').trim()

    niceString = niceString[0].toUpperCase() + niceString.substr(1)

  } else { // is not CamelCase
    str = str.split(/[\s]/)
    str.forEach( (word, i) => {
      niceString = (i !== 0 ? niceString + " " : "") + (word[0] !== undefined ? word[0].toUpperCase() : "")  + word.substr(1).toLowerCase()
    })
  }  

  return niceString
}

export function formatDuration(sec) {

  let hours = Math.floor((sec %= 86400) / 3600) || "0";
  if (hours) {
    hours = hours < 10 ? `0${hours}` : hours;
  }
  let minutes = Math.floor((sec %= 3600) / 60) || "0";
  if (minutes) {
    minutes = minutes < 10 ? `0${minutes}` : minutes;
  }
  let seconds = sec % 60 || "0";
  if (seconds) {
    seconds = seconds < 10 ? `0${seconds}` : seconds;
  }

  let duration = `${hours}:${minutes}:${seconds}`
  
  return duration
}
