import React, { useRef, useState } from 'react';
import AriaModal from 'react-aria-modal';
import { SkeletonLoader, Button } from '../'
import { useModalDialog, useTooltip } from '../../hooks'

import './ModalDialog.css';

const withModalContent = (WrappedComponent, props, loadingModalDialog) => {

  // Use a HOC to add loading state. 
  // Putting this in the Context or ModalDialog component causes the modal "body" component to lose state when the loading state changes

  return () => {
    const [loading, setLoading] = useState(false)

    const bodyRef = useRef()

    const {showTooltip, hideTooltip} = useTooltip()
    const {title, contentProps, submitLabel, submitLabelLoading, cancelLabel, onSubmit, onCancel, customButtons} = props || {}

    const handleSubmit = async () => {
      setLoading(prev => true)
      try {
        await onSubmit()
      } catch (err) {
        // Errors handled in onSubmit function 
      } finally {
        setLoading(prev => false)
      }
    }

    const handleClick = async (button) => {
      setLoading(prev => true)
      try {
        await button.onClick(bodyRef)
      } catch (err) {
        // Errors handled in onClick function 
      } finally {
        setLoading(prev => false)
      }
    }

    return (
      <div className="ModalDialog" tabIndex={loadingModalDialog ? "0" : "-1"}>
        <div className="modal-container">

          <div className="modal-container-header">
            <div className="modal-container-title">
              {title}
            </div>
            <div 
              className="toggle-popup"
              onClick={ onCancel }
              onMouseEnter={ () => showTooltip({title: "Cancel"})} 
              onMouseLeave={ hideTooltip }>
            </div>
          </div>

          <div className="modal-container-body" ref={bodyRef}>
            {
              (WrappedComponent && <WrappedComponent {...contentProps} loading={loading} setLoading={setLoading} />) || <React.Fragment><SkeletonLoader /><ul><SkeletonLoader width={150}/></ul></React.Fragment>
            }
          </div>

          <div className="modal-container-footer">
            <div className="modal-container-footer-left">
              { customButtons &&
                customButtons.map( (button, index) => {
                  return (
                    <Button 
                      tabIndex={0} 
                      key={index}
                      onClick={ () => handleClick(button) }
                      className="footer-item button"
                      disabled={loadingModalDialog || loading}
                      tooltip={button.tooltip}
                      value={button.value}
                      valueLoading={button.valueLoading}
                      valueClicked={button.valueClicked}
                    />
                  )
                })
              }
            </div>

            <div className="modal-container-footer-right">
              { cancelLabel &&
                <Button 
                  tabIndex={0} 
                  value={cancelLabel}
                  onClick={ onCancel } 
                  className="footer-item button"
                />
              }
              { submitLabel &&
                <Button 
                  tabIndex={0} 
                  onClick={ handleSubmit } 
                  value={submitLabel}
                  valueLoading={submitLabelLoading}
                  className={"footer-item button main-button"}
                  disabled={loadingModalDialog || loading}

                />

              }
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export const ModalDialog = () => {

  const { show, modalDialog, loadingModalDialog } = useModalDialog() 

  const {title, Content} = modalDialog || {}

  const getApplicationNode = () => {
    return document.getElementById('root');
  };

  const Modal = AriaModal.renderTo('#modal')
  const Component = withModalContent(Content, modalDialog, loadingModalDialog)

  return show && 
    <Modal
      titleText={title}
      verticallyCenter={false}
      focusDialog={true}
      getApplicationNode={getApplicationNode}
      underlayClass={'modal-underlay'}
      focusTrapOptions={{allowOutsideClick:true }}
    >
      <Component />
    </Modal>
};
