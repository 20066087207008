import config from '../config';
import { authHeader, customFetch, handleResponse, jsonHeader, commonRequestOptions } from '../helpers';

const endpointPath='datasource_subtype'

export const datasourceSubtypeService = {
    getAll,
    getByDatasourceTypeCode,
    getDefaultSettings,
    create,
    update,
    destroy
};

function getAll(signal) {
    const requestOptions = { method: 'GET', headers: authHeader(), ...commonRequestOptions() };
    return customFetch(`${config.api.url}/${endpointPath}/all`, requestOptions, signal).then(handleResponse);
}

function getByDatasourceTypeCode(code, signal) {
    const requestOptions = { method: 'GET', headers: authHeader(), ...commonRequestOptions() };
    return customFetch(`${config.api.url}/${endpointPath}/datasource_type/${code}`, requestOptions, signal).then(handleResponse);
}

function getDefaultSettings(code, signal) {
    const requestOptions = { method: 'GET', headers: authHeader(), ...commonRequestOptions() };
    return customFetch(`${config.api.url}/${endpointPath}/default_settings/${code}`, requestOptions, signal).then(handleResponse);
}

function create(items, signal) {
    const requestOptions = { 
        method: 'POST', 
        headers: {
            ...authHeader(),  
            ...jsonHeader()
        }, 
        ...commonRequestOptions(),
        body: JSON.stringify(items) };

    return customFetch(`${config.api.url}/${endpointPath}`, requestOptions, signal).then(handleResponse);
}

function update(items, signal) {
    const requestOptions = { 
        method: 'PUT', 
        headers: {
            ...authHeader(),  
            ...jsonHeader()
        },  
        ...commonRequestOptions(),
        body: JSON.stringify(items) 
    };

    return customFetch(`${config.api.url}/${endpointPath}`, requestOptions, signal).then(handleResponse);
}

function destroy(items, signal) {
    const requestOptions = { 
        method: 'DELETE', 
        headers: {
            ...authHeader(),  
            ...jsonHeader()
        }, 
        ...commonRequestOptions(),
        body: JSON.stringify(items) };

    return customFetch(`${config.api.url}/${endpointPath}`, requestOptions, signal).then(handleResponse);
}
