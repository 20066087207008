import config from '../config';
import { customFetch, authHeader, jsonHeader, handleResponse, commonRequestOptions, subjects } from '../helpers';

const endpointPath='field'

export const fieldService = {
    getAll,
    getById,
    getByDatasetId,
    getByFieldDescriptionId,
    create,
    update,
    checkDestroy,
    destroy,
    updateFieldAssociation
};

function getAll(signal) {
    const requestOptions = { method: 'GET', headers: authHeader(), ...commonRequestOptions() };
    return customFetch(`${config.api.url}/${endpointPath}/all`, requestOptions, signal).then(handleResponse);
}

function getById(id, signal) {
    const requestOptions = { method: 'GET', headers: authHeader(), ...commonRequestOptions() };
    return customFetch(`${config.api.url}/${endpointPath}/${id}`, requestOptions, signal)
    .then(handleResponse)
    .then(res => {
        subjects.currentField.next(res.fields[0] || {field_id: id});
        subjects.currentDataset.next(res.fields[0] ? {
            dataset_id: res.fields[0].dataset_id,
            dataset_name: res.fields[0].dataset_name,
            dataset_type_name: res.fields[0].dataset_type_name,
            dataset_group_id: res.fields[0].dataset_group_id,
            dataset_group_name: res.fields[0].dataset_group_name,
            datasource_hostname: res.fields[0].datasource_hostname,
            datasource_database: res.fields[0].datasource_database,
            datasource_type_name: res.fields[0].datasource_type_name,
            datasource_type_code: res.fields[0].datasource_type_code,
            datasource_type_category: res.fields[0].datasource_type_category
        } : "not_found")
        subjects.currentDatasetGroup.next(res.fields[0] ? {
            dataset_group_id: res.fields[0].dataset_group_id,
            dataset_group_name: res.fields[0].dataset_group_name,
            datasource_hostname: res.fields[0].datasource_hostname,
            datasource_database: res.fields[0].datasource_database,
            datasource_type_name: res.fields[0].datasource_type_name,
            datasource_type_code: res.fields[0].datasource_type_code,
            datasource_type_category: res.fields[0].datasource_type_category
        } : "not_found")
        subjects.currentDatasource.next(res.fields[0] ? {
            datasource_id: res.fields[0].datasource_id,
            datasource_name: res.fields[0].datasource_name,
            datasource_hostname: res.fields[0].datasource_hostname,
            datasource_database: res.fields[0].datasource_database,
            datasource_type_name: res.fields[0].datasource_type_name,
            datasource_type_code: res.fields[0].datasource_type_code,
            datasource_type_category: res.fields[0].datasource_type_category
        } : "not_found")
        subjects.currentSystem.next(res.fields[0] ? {
            system_id: res.fields[0].system_id,
            system_name: res.fields[0].system_name
        } : "not_found")
        return res
    });
}

function getByDatasetId(id, signal) {
    const requestOptions = { method: 'GET', headers: authHeader(), ...commonRequestOptions() };
    return customFetch(`${config.api.url}/${endpointPath}/dataset/${id}`, requestOptions, signal).then(handleResponse);
}

function getByFieldDescriptionId(id, signal) {
    const requestOptions = { method: 'GET', headers: authHeader(), ...commonRequestOptions() };
    return customFetch(`${config.api.url}/${endpointPath}/field_description/${id}`, requestOptions, signal).then(handleResponse);
}

function create(items, signal) {
    const requestOptions = { 
    	method: 'POST', 
    	headers: {
            ...authHeader(),  
    		...jsonHeader()
    	}, 
        ...commonRequestOptions(),
    	body: JSON.stringify(items) };

    return customFetch(`${config.api.url}/${endpointPath}`, requestOptions, signal).then(handleResponse);
}

function update(items, signal) {
    const requestOptions = { 
    	method: 'PUT', 
    	headers: {
            ...authHeader(),  
            ...jsonHeader()
        }, 
        ...commonRequestOptions(),
    	body: JSON.stringify(items) 
    };

    return customFetch(`${config.api.url}/${endpointPath}`, requestOptions, signal).then(handleResponse);
}

function checkDestroy(items, signal) {
    const requestOptions = { 
        method: 'POST', 
        headers: {
            ...authHeader(),  
            ...jsonHeader()
        }, 
        ...commonRequestOptions(),
        body: JSON.stringify(items) };

    return customFetch(`${config.api.url}/${endpointPath}/check_delete`, requestOptions, signal).then(handleResponse);
}

function destroy(items, signal) {
    const requestOptions = { 
    	method: 'DELETE', 
    	headers: {
            ...authHeader(),  
            ...jsonHeader()
        }, 
        ...commonRequestOptions(),
    	body: JSON.stringify(items) };

    return customFetch(`${config.api.url}/${endpointPath}`, requestOptions, signal).then(handleResponse);
}

function updateFieldAssociation(items, signal) {
    const requestOptions = { 
        method: 'PUT', 
        headers: {
            ...authHeader(),  
            ...jsonHeader()
        }, 
        ...commonRequestOptions(),
        body: JSON.stringify(items) 
    };

    return customFetch(`${config.api.url}/${endpointPath}/update_field_association`, requestOptions, signal).then(handleResponse);
}