import React, { useState, useEffect, forwardRef } from 'react'
import { datasetTypeService, datasetGroupService, userService } from '../services';
import { Checkbox, Select, formatSelectOptions, Input, Textarea, textareaInitialState, textareaToRaw } from '../components';
import { formatConnectionString, getBatchEditValue } from '../helpers'
import { useGlobalState, useMessage } from '../hooks'

const _AddDatasetForm = ({ mode, data, submitForm, loading, setLoading }, ref) => { 
	
	const initialFormState = {
		dataset_id: null, 
		dataset_name: '',
		dataset_source_name: '',
		dataset_description: textareaInitialState({}), 
		dataset_type_id: '', 
		dataset_group_id: '',
		dataset_owner_user_id: ''
	}

	const initialBatchEditItems = {
		batch_dataset_type_id: false, 
		batch_dataset_group_id: false,
		batch_dataset_owner_user_id: false
	}

	const [loggedInUser, setLoggedInUser] = useGlobalState('loggedInUser');
	const [currentDatasetGroup, setCurrentDatasetGroup] = useGlobalState('currentDatasetGroup');
	const [formData, setFormData] = useState(initialFormState);
	const [batchEdit, setBatchEdit] = useState(false);
	const [batchEditItems, setBatchEditItems] = useState(initialBatchEditItems)
	const [datasetTypes, setDatasetTypes] = useState([]);
	const [datasetGroups, setDatasetGroups] = useState([]);
	const [users, setUsers] = useState([]);	

	const { showMessage } = useMessage()

	let datasource_id = currentDatasetGroup?.datasource_id || (data[0] && data[0].datasource_id) //datasource_id is not part of URL in e.g. User view

	useEffect( () => {

		// Get form data
  	fetchDatasetTypes()
  	fetchDatasetGroups()
  	fetchUsers()

	}, []);

	useEffect(() => {
		setBatchEdit(false)
	    if (data.length === 1) {
			setFormData({ 
				dataset_id: data[0].dataset_id, 
				dataset_name: data[0].dataset_name || '',
				dataset_source_name:  data[0].dataset_source_name || '',
				dataset_description: textareaInitialState({value: data[0].dataset_description}), 
				dataset_type_id: data[0].dataset_type_id,
				dataset_group_id: data[0].dataset_group_id,
				dataset_owner_user_id: data[0].dataset_owner_user_id
			})
		} else if(data.length > 1 && mode !== 'add') {
			setBatchEdit(true)

			setFormData({ 
				dataset_type_id: getBatchEditValue(data, 'dataset_type_id'),
				dataset_group_id: getBatchEditValue(data, 'dataset_group_id'),
				dataset_owner_user_id: getBatchEditValue(data, 'dataset_owner_user_id')
			})
		} else if(mode === 'add') {
			setFormData({ 
				...formData, 
				dataset_owner_user_id: loggedInUser?.user_id,
				dataset_group_id: currentDatasetGroup.dataset_group_id
			})
		} else {
			resetForm()		
		}
		
	}, [mode, data, loggedInUser, currentDatasetGroup]);

	const fetchDatasetTypes = async () => {
	    datasetTypeService.getAll()
	      .then(res => { 

	      	res && setDatasetTypes(res.dataset_types); 

	      })
	      .catch(err => {showMessage(err, 'error')});
	  }

	const fetchDatasetGroups = async () => {
	    datasetGroupService.getByDatasourceId(datasource_id)
	      .then(res => { 

	      	res && setDatasetGroups(res.dataset_groups); 

	      })
	      .catch(err => {showMessage(err, 'error')});
	  }

	const fetchUsers = async () => {
	    userService.getAll()
	      .then(res => { 

	      	res && setUsers(res.users); 

	      })
	      .catch(err => {showMessage(err, 'error')});
	  }

	const handleInputChange = (event, formPart) => {
		let { name, value } = ""

		// Handle standard form inputs
		if (event.target !== undefined) {
		  name = event.target.name
		  value = event.target.value

		  // If input element is a checkbox, we cannot use "value"
	    if (event.target.type === "checkbox") { value = event.target.checked }

	  // Handle custom form inputs
	  } else {
	  	name = event.name
	  	value = event.value
	  }

	  switch(formPart) {
	  	case 'batch':
	  		setBatchEditItems({ ...batchEditItems, [name]: value })
	  		break
	  	default:
	  		setFormData({ ...formData, [name]: value })
	  }
	}

	const onSubmit = event => {

		event.preventDefault()

		setLoading(true)
		
		// Form validation
		//if (!formData.user_fullname || !formData.user_username) return

		let dataToSubmit

		if (batchEdit) {
			
			let key = ''
			let tmp = {}
			const objects = Object.entries(batchEditItems)

			for (let i = 0; i < objects.length; i++) {
				if (objects[i][1]) {
					key = objects[i][0].replace("batch_","")
					tmp = {...tmp, [key]: formData[ key ]}
				}
			}

			dataToSubmit = data.map( item => {
				return {dataset_id: item.dataset_id, 
								dataset_name: item.dataset_name,
								dataset_source_name: item.dataset_source_name,
								dataset_type_id: item.dataset_type_id,
								dataset_group_id: item.dataset_group_id,
								dataset_owner_user_id: item.dataset_owner_user_id, 
								...tmp
							}
			})

		} else {
			dataToSubmit = {
					...formData,
				dataset_description: textareaToRaw({value: formData.dataset_description}) 
			}
		}
		
		// submit form
		submitForm(dataToSubmit)
		.then(res => resetForm())
		.catch(err => {
			// Errors handled in submitForm function
		})
		.finally(res => setLoading(false))	 
		    
	}

  const resetForm = () => {

  	setBatchEdit(false)
  	setBatchEditItems(initialBatchEditItems)
  	setFormData(prevFormData => {
			return {
				...initialFormState,
				dataset_owner_user_id: (loggedInUser ? loggedInUser.user_id : null),
				dataset_group_id: (currentDatasetGroup ? currentDatasetGroup.dataset_group_id : null) 
			}
		}) 
  }

	const datasetGroup = (datasetGroups.length > 0) ? datasetGroups.find(x => x.dataset_group_id === formData.dataset_group_id) : undefined

  return (
  	<div className="form">
	    <form
			  onSubmit={onSubmit}
			  ref={ref}
			>
				{ !batchEdit &&
				<div className="form-block vertical main">
				      <Input type="text" 
				      	name="dataset_name"
				      	label="Name" 
				      	value={formData.dataset_name} 
				      	onChange={handleInputChange}
				      	disabled={loading}
				      />
			      </div>
				}
				{ !batchEdit &&
				<div className="form-block vertical">
		      <Input type="text" 
		      	name="dataset_source_name" 
		      	label="Source Name"
		      	value={formData.dataset_source_name} 
		      	onChange={handleInputChange}
		      	disabled={loading}
		      />
		      <div className="form-light-text"> 
		      	{ datasetGroup &&
							formatConnectionString(
								datasetGroup.datasource_database, 
								datasetGroup.datasource_hostname, 
								[ datasetGroup.dataset_group_source_name, formData.dataset_source_name], 
								datasetGroup.datasource_type_code, 
								datasetGroup.datasource_type_category
							)
		       	}
		      </div>
	      </div>
				}
				{ !batchEdit &&
				<div className="form-block vertical">
					<label>Description</label>
					<Textarea
		      	name="dataset_description" 
		      	value={formData.dataset_description} 
		      	onChange={handleInputChange}
		      	disabled={loading}
		      />
		    </div>
		  	}
		    <div className="form-block vertical">
					{ batchEdit 
						? <label>
								<Checkbox 
									checked={batchEditItems.batch_dataset_group_id}
									name="batch_dataset_group_id"
									label="Edit Dataset Group"
									onChange={ e => handleInputChange(e, 'batch')}
									disabled={loading}
								/>
							</label>
						: <label>Dataset Group</label>
					}
					<Select 
            name="dataset_group_id"
            value={formData.dataset_group_id === 'multiple' ? undefined : formData.dataset_group_id}
            options={ formatSelectOptions({options: datasetGroups, optionValue: "dataset_group_id", optionLabel: "dataset_group_name", tooltip: "dataset_group_description"}) }
            onChange={handleInputChange} 
            placeholder={formData.dataset_owner_user_id === 'multiple' ? '< Multiple >' : ""}
            disabled={loading || (batchEdit && !batchEditItems.batch_dataset_group_id)}
          />
		    </div>

				<div className="form-block vertical">
					{ batchEdit 
						? <label>
								<Checkbox 
									checked={batchEditItems.batch_dataset_type_id}
									name="batch_dataset_type_id"
									label="Edit Dataset Type"
									onChange={ e => handleInputChange(e, 'batch')}
									disabled={loading}
								/>
							</label>
						: <label>Dataset Type</label>
					}
					<Select 
            name="dataset_type_id"
            value={formData.dataset_type_id === 'multiple' ? undefined : formData.dataset_type_id}
            options={ formatSelectOptions({options: datasetTypes, optionValue: "dataset_type_id", optionLabel: "dataset_type_name", optionIcon: "dataset_type_name"}) }
            onChange={handleInputChange} 
            placeholder={formData.dataset_type_id === 'multiple' ? '< Multiple >': "Select dataset type..."}
            disabled={loading || (batchEdit && !batchEditItems.batch_dataset_type_id)}
          />
		    </div>

		    <div className="form-block vertical">
					{ batchEdit 
						? <label>
								<Checkbox 
									checked={batchEditItems.batch_dataset_owner_user_id}
									name="batch_dataset_owner_user_id"
									label="Edit Owner"
									onChange={ e => handleInputChange(e, 'batch')}
									disabled={loading}
								/>
							</label>
						: <label>Owner</label>
					}
					<Select 
            name="dataset_owner_user_id"
            value={formData.dataset_owner_user_id === 'multiple' ? undefined : formData.dataset_owner_user_id}
            options={ formatSelectOptions({options: users, optionValue: "user_id", optionLabel: "user_fullname", tooltip: "user_username"}) }
            onChange={handleInputChange}
            placeholder={formData.dataset_owner_user_id === 'multiple' ? '< Multiple >' : ""}
            disabled={loading || (batchEdit && !batchEditItems.batch_dataset_owner_user_id)} 
          />
	      </div>

	    </form>
	  </div>
  )
}

export const AddDatasetForm = forwardRef(_AddDatasetForm)
