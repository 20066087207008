import React, { useState, useEffect } from 'react';
import { ChangelogItem, ChangelogToolbar } from './components';
import { SkeletonLoader, UserLink } from '../';
import './Changelog.css';
import { Asset } from '../../helpers';
import { useFilterableData, useChangelog, useQueryParams, useGlobalState } from '../../hooks';

export const Changelog = ({data, objectName, objectId, filterObjects, filterObjectsValueMap, loading}) => {
  /* 
    External state:
    Call fetchChangelog outside of this component and provide params data and loading

    Internal state:
    Provide params objectName and objectId to let this component handle changelog data state
  */
  const schemaChangeAssets = [Asset.DatasetGroup, Asset.Dataset, Asset.Field]

  const { queryParams, setHistory } = useQueryParams()

  const [displayNames] = useGlobalState('displayNames')

  filterObjects = queryParams.filterObjects || (filterObjects && filterObjects.map(x => {
    const labelValue = x.split('.').pop() 
    return ({
      value: x, 
      label: (displayNames[labelValue] && (displayNames[labelValue]?.display_name_alternative_name && labelValue !== (objectName + "_name") ? displayNames[labelValue]?.display_name_alternative_name : displayNames[labelValue]?.display_name)) || labelValue,  
      tooltip: displayNames[labelValue] && displayNames[labelValue]?.display_name_description,
      checked: true
    })
  }))

  const [defaultSchemaChangesOnly, setDefaultSchemaChangesOnly] = useState(schemaChangeAssets.includes(objectName) ? queryParams.schemaChangesOnly : false)
  const [ filteredFilterObjects, setFilteredFilterObjects ] = useState(filterObjects)

  const { changelog, loadingChangelog, fetchChangelog } = useChangelog({objectName, objectId})
  const { filteredItems, requestFilter, filterTerm } = useFilterableData(data || changelog, filteredFilterObjects, filterObjectsValueMap);

  useEffect(() => {
    requestFilter(filterTerm)    
    setHistory({filterObjects: filteredFilterObjects})
  }, [filteredFilterObjects]);

  useEffect(() => {
    objectName && objectId && fetchChangelog(undefined, undefined, defaultSchemaChangesOnly)
  }, [objectName, objectId, defaultSchemaChangesOnly]);

  const handleFilterObjectsFilter = (item) => {

    let array = filteredFilterObjects.map(x => {
      let checked
      if (Array.isArray(item)) {
        let value = item.find(y => y.value === x.value)
        checked = value !== undefined && value.checked
      } else {
        checked = x.value === item.value ? !x.checked : x.checked
      }

      return {
        ...x,
        checked: checked
      }
    })

    setFilteredFilterObjects(array)

  }

  const handleSchemaChange = (event) => {
    const schema_changes_only = event.target.checked

    setDefaultSchemaChangesOnly(schema_changes_only)
    fetchChangelog(undefined, undefined, schema_changes_only)
    setHistory({schemaChangesOnly: schema_changes_only})
  }

  const handleFilter = (value) => {
    // Apply filter
    requestFilter(value)
  }

  const getItemIndex = (item) => {
    const allItems = (data || changelog)
    const index = allItems.findIndex(x => x.created_timestamp.toString() === item.created_timestamp.toString())

    if (allItems.length === 1) {
      return "single-item"
    }
    if (index === allItems.length-1) {
      return "last-item"
    }
    if (index === 0) {
      return "first-item"
    }
  }

	return (
    <div className={"Changelog" + (loading || loadingChangelog ? " loading" : "")}>
    { (loading || loadingChangelog)  
      ? 
        <div className="loading-changelog">
          <div className="ChangelogItem">
            <div className="changelog-item-header">
              <UserLink 
                userId='1'
                userName="dummy"
                userPhoto="dummy"
                header="dummy"
                photoPosition='right'
                className="width-160 photo-background"
                type='USER'
                loading={loading || loadingChangelog}
              />
            </div>
            <div className="changelog-item-body" >
              <div className="changelog-item-body-header">
                <SkeletonLoader width="10vw" />
              </div>
            </div>
          </div>
        </div>
      : 
      <React.Fragment>
        <ChangelogToolbar
            itemTotalCount={ (data && data.length) || (changelog && changelog.length)}
            itemCount={filteredItems.length}
            requestFilter={handleFilter}
            filterTerm={filterTerm}
            onFilterObjectsFilter={handleFilterObjectsFilter}
            filterObjects={filteredFilterObjects}
            showSchemaChangesOnlyOption={schemaChangeAssets.includes(objectName)}
            onSchemaChange={handleSchemaChange}
            schemaChangesOnly={defaultSchemaChangesOnly}
          />
        <div className="changelog-container">

        { (data || changelog) && filteredItems.map( (item, index) => {
            return (
              <ChangelogItem data={item} key={index} objectName={objectName} className={ getItemIndex(item) } />
            )
          })
        }
        </div>
        </React.Fragment>
		}
    </div>
  )
}