import React, { forwardRef, useState, useEffect, useRef } from 'react';
import { useTooltip } from '../../hooks'
import { keyCode } from '../../helpers'
import { Icon } from '../'
import { InlineSelect } from './components'
import './Input.css';

const _Input = ({
	name, 
	type, 
	placeholder, 
	label, 
	labelAsPlaceholder, 
	value, 
	disabled=false, 
	isNullable=false, 
	onChange, 
	onBlur, 
	onFocus, 
	onKeyDown,
	autoComplete, 
	required=false, 
	className, 
	icon, 
	iconAsButton=false, 
	showCapsLockWarning=true,
	showTogglePasswordVisibility=true,
	validation,
	title,
	labelPosition,
	withSelect=false,
	selectProps,
	preventEnterDefault=true,
}, ref) => {
	
	const {showTooltip, hideTooltip} = useTooltip()

	const [inputType, setInputType] = useState(type)
	const [isActive, setIsActive] = useState(false)
	const [expanded, setExpanded] = useState(value ? true : false )
	const [capsLock, setCapsLock] = useState(false)
  
  const tmpRef = useRef()
  const inputRef = ref || tmpRef
  const inputSelectRef = useRef([])

  useEffect( () => {
  	if (!value || value === '') {
	    setIsActive(false);
	  } else {
	    setIsActive(true);
	  }
  }, [value])

  useEffect( () => {
  	if (disabled) {
	    togglePasswordVisibility("password")
	  }
  }, [disabled])

  const clearInput = () => {
  	onChange({value: "", name: name})
		inputRef.current.focus()
  }

  const handleExpand = (el) => {
  	onFocus && onFocus(el)

		inputRef.current.focus()
  	setExpanded(true)
  	
  }
  const handleBlur = (element) => {
  	if (
  			iconAsButton && 
  			value === "" && 
  			inputSelectRef.current.findIndex(el => el === element.relatedTarget) < 0
  	) {
  		setExpanded(false)
  	}
  	setCapsLock(false)
  	onBlur && onBlur(element)
  }
  const handleKeyDown = (keyEvent) => {

  	if (inputType==="password" && showCapsLockWarning) {
	  	if (keyEvent.getModifierState("CapsLock")) {
	      setCapsLock(true)

	    } else {
	      setCapsLock(false)
	    }
  	}

  	if (preventEnterDefault && keyEvent.keyCode === keyCode.ENTER) {
  		keyEvent.preventDefault()
  	}

  	onKeyDown && onKeyDown(keyEvent, value)
  }

  const togglePasswordVisibility = (state) => {
  	if (type==="password" && showTogglePasswordVisibility) {
	  	setInputType(prev => {return state || (prev === "password" ? "text" : "password")})
  	}
  }

	return (
		<div className={
			"CustomInput" + 
			(className ? " "+className : "") + 
			(disabled ? " disabled" : "") + 
			(labelAsPlaceholder ? " label-as-placeholder" : "") + 
			(icon ? " with-icon" : "") + 
			(iconAsButton && !expanded ? " collapsed" : "") + 
			(validation && !validation.valid ? " has-error" : "") + 
			(labelPosition ? " label-position-" + labelPosition : "") +
			(inputType==="password" && showTogglePasswordVisibility ? "" : " show-password") + 
			(withSelect ? " with-select" : "")
		} >
		  { label && 
		  	<label 
		  		className={ isActive ? "active" : ""} 
		  		htmlFor={name} 
		  		onMouseEnter={ () => title && showTooltip({title: title})} 
		  		onMouseLeave={ title && hideTooltip }
		  	>
		    	<span>{label}</span>
		    	{title && <span className="icon-description">i</span>}
		  	</label>
			}
			<div className="input-container-wrapper">
				<div className="input-container" onClick={handleExpand}>
					{ icon &&
						<div className="input-icon">
							<Icon name={icon} />
						</div>
					}
					  <input
					  	className="input"
							type={inputType || type}
							ref={inputRef}
			        name={name}
			        placeholder={labelAsPlaceholder ? '' : placeholder}
			        value={value}
			        onChange={onChange}
			        onKeyDown={handleKeyDown}
			        disabled={disabled}
			        autoComplete={autoComplete}
			        onBlur={handleBlur}
			        onFocus={handleExpand}
			        required={(required || required === 'required') ? 'required': false}
					  />
					  { (
					  		(isNullable && value !== "") ||
							  validation ||
							  capsLock ||
							  withSelect ||
							  type === "password"
						  ) && !disabled &&
					  	<div className="button-container">
					  		{ capsLock && showCapsLockWarning &&
								  <div 
								    className="input-button caps-lock-warning"
								    onMouseEnter={ () => showTooltip({title: "Caps Lock is ON!"})} 
						  			onMouseLeave={ hideTooltip }
								  >
								  	<Icon name="caps-lock" />
								  </div>
								}
								{ validation &&
								  <div 
								    className={"input-button validation-icon" + (validation.valid ? " valid" : " error") }
								    onMouseEnter={ () => showTooltip({title: validation.message})} 
						  			onMouseLeave={ hideTooltip }
								  >
								  </div>
								}
						  	{ isNullable && value !== "" &&
								  <div 
								    className="input-button button-clear-input" 
								    onClick={ clearInput }
								    tabIndex= {1}
								  >
								    <div className="button-clear-input-icon"></div>
								  </div>
								}
								{ type === "password" && showTogglePasswordVisibility &&
								  <div 
								    className="input-button button-show-password"
								    onMouseEnter={ () => showTooltip({title: (inputType==="password" ? "Show password" : "Hide password")})} 
						  			onMouseLeave={ hideTooltip }
						  			onClick={() => togglePasswordVisibility()}
								  >
								  <Icon name="toggle_visibility" />
								  </div>
								}
					  	</div>
					  }
				  </div>
		  		{ withSelect && 
				  	<div className="select-buttons">
				  	{ selectProps.map( (props, i) => (
				  		<InlineSelect 
				  			key={i}
				  			ref={el => inputSelectRef.current[i] = el}
				  			name={props.name}
				  			value={props.value}
				  			label={props.label}
				  			tooltip={props.tooltip}
				  			options={props.options}
				  			onChange={props.onChange}
				  			onBlur={handleBlur}
				  			inputRef={inputRef}
				  		/>
				  		))
				  	}
				  	</div>
				  }
				
				
		  </div>
		  
		</div>
	)
}

export const Input = forwardRef(_Input);