import React from 'react'
import { Asset, formatPath } from '../../../helpers';
import { Icon } from '../../';
import { textareaToPlainText } from '../'

export const SUGGESTION_TRIGGER = '@'
export const ENTER_KEY_CODE = 13
export const TAB_KEY_CODE = 9
export const DOWN_ARROW_KEY_CODE = 40
export const UP_ARROW_KEY_CODE = 38
export const ESCAPE_KEY_CODE = 27
export const A_KEY_CODE = 65

export const formatTooltip = data => {

  if (!data) return ""
    
  let title = data.name
  let subTitle
  let bodyText = (textareaToPlainText(data.description) || "No description available")


  switch (data.object_name) {
    case Asset.BusinessTerm:
      subTitle = formatPath([data.glossary_name, data.name]) 
      break
    case Asset.Dataset:
      subTitle = <React.Fragment><Icon name={data?.dataset_type_name}/>{ formatPath([data.system_name, data.datasource_name, data.dataset_group_name, data.name]) }</React.Fragment>
      break
    case Asset.FieldDescription:
      subTitle = <React.Fragment><Icon name={data?.field_role_name}/>{ data.field_role_name }</React.Fragment>
      break
    case Asset.System:
      subTitle = ""
      break
    default:
      break
  }

  
  return {title, subTitle, bodyText}
}

export const getCurrentTextSelection = (editorState: EditorState): string => {
  const selectionState = editorState.getSelection();
  const anchorKey = selectionState.getAnchorKey();
  const currentContent = editorState.getCurrentContent();
  const currentContentBlock = currentContent.getBlockForKey(anchorKey);
  const start = selectionState.getStartOffset();
  const end = selectionState.getEndOffset();
  const selectedText = currentContentBlock.getText().slice(start, end);

  return {
    start,
    end,
    text: selectedText
  }
};

export const styleMap = {
  'BOLD': {
    fontFamily: 'Rubik-Regular',
  }
};

/*export const blockRenderMap = Immutable.Map({
  'unstyled': {
    element: 'div'
  }
})
const extendedBlockRenderMap = DefaultDraftBlockRenderMap.merge(blockRenderMap);*/