import React, { useState, useLayoutEffect } from 'react';
import { createPortal } from 'react-dom'
import { useMessage } from '../hooks'

export const Message = () => {

  const { show, message, hideMessage } = useMessage()

  let {title, details, type} = message || {}

  const [showMessageDetails, setShowMessageDetails] = useState(true)
  
  const portalRef = document.getElementById("portal")

  useLayoutEffect( () => {
    if (show && type === 'success' && message && !details) {
      const timer = setTimeout( closeMessage, 3000);
      return () => clearTimeout(timer);
    }
  }, [show, message])

  const closeMessage = (e) => {
    hideMessage()
  }

  const toggleMessageDetails = (e, state) => {

    setShowMessageDetails(state || !showMessageDetails)
  }

  return show && message && type && createPortal(
    <div 
      className={"message-container " + (show ? " show" : "") + " " + type }
    >
      <div className="message-text-container">
        <div className="message-text">
        { title }
        <div className="close-button" onClick={ closeMessage }></div>
        </div>
        { details &&
          <React.Fragment>
          <div className={"message-details-toggle-button" + (showMessageDetails ? " show" : "")} onClick={toggleMessageDetails}>Details<span></span></div>
          { showMessageDetails &&
            <div className="message-details">{details}</div>
          }
          </React.Fragment>
        }
      </div>
      
    </div>

    , portalRef
  )
};
