import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { businessTermService, referenceService } from '../services';
import { Role, Asset, formatData } from '../helpers';
import { 
  Changelog,
  MainColumn, 
  ObjectDetails, 
  References,   
  SkeletonLoader, 
  Tabs, 
  TextareaReadOnly, 
  textareaInitialState, 
  fetchToReferencesCount, 
  UserLink } from '../components';
import { 
  useSideDialog, 
  useModalDialog, 
  useQueryParams, 
  useGlobalState, 
  usePhotos, 
  useNotFound, 
  useMessage } from '../hooks';

export const BusinessTerm = () => {

  const { queryParams, resetParams } = useQueryParams()

  const [businessTerm, setBusinessTerm] = useState([]);
  const [loggedInUser, setLoggedInUser] = useGlobalState('loggedInUser');
  const [fromReferences, setFromReferences] = useState([]);
  const [toReferencesCount, setToReferencesCount] = useState({});
  const [selectedItems, setSelectedItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [defaultSelectedTab, setDefaultSelectedTab] = useState({tab: queryParams.tab})

  const { business_term_id } = useParams()

  const clearSelectedItems = () => {
    setSelectedItems([])
  }

  const { showMessage } = useMessage()
  const { showSideDialog, hideSideDialog } = useSideDialog(clearSelectedItems)
  const { showModalDialog, hideModalDialog } = useModalDialog()
  const { fetchPhotos } = usePhotos()
  const { notFound, setNotFound, NotFound } = useNotFound()

  const navigate = useNavigate()
  
  useEffect(() => {

    fetchItems()
    fetchFromReferences()

    fetchToReferencesCount({toObjectName: Asset.BusinessTerm, toObjectId: business_term_id})
      .then(res => setToReferencesCount(res))
      .catch(err => {
        showMessage(err, 'error')
      })

  }, [business_term_id]);

  const fetchItems = async () => {
    
    businessTermService.getById(business_term_id)
      .then(res => {
        if (res.business_terms.length < 1) {
          setNotFound(true)
        } else {
          setBusinessTerm(res.business_terms[0]);
          setLoading(false);
          fetchPhotos(res.business_terms[0], 'business_term_owner_user_id', setBusinessTerm)
        }
      })
      .catch(err => {showMessage(err, 'error')});
  }

  const fetchFromReferences = async () => {
  
    referenceService.getByFromId(Asset.BusinessTerm, business_term_id)
      .then(res => {
   
        setFromReferences(res.references);
        
      })
      .catch(err => {showMessage(err, 'error')});
  }

  const editBusinessTerm = async data => {
    const businessTerms = {business_terms: (Array.isArray(data) ? data : [data])}
    
    await businessTermService.update(businessTerms)
      .then(async res => { 

        fetchItems()
        fetchFromReferences()

        clearSelectedItems()
        showMessage(res.message)
        hideSideDialog()
      })
      .catch(err => {
        showMessage(err, 'error')
        throw err
      });
  }

  const deleteBusinessTerm = async (data) => {    
    await businessTermService.destroy({business_terms: data})
      .then(async res => { 

        showMessage(res.message)
        hideModalDialog()

        // Redirect to parent page
        navigate('/browse_business_terms')

      })
      .catch(err => {
        showMessage(err, 'error')
      });
  }

  const data = loading ? [] : businessTerm

  const editable = loggedInUser && (loggedInUser.user_role_name === Role.admin || loggedInUser.user_role_name === Role.editor)
  
  if (notFound) {
    return <NotFound />
  }

  return (
    <div className="columns narrow-margin">
      <MainColumn>
        <ObjectDetails
          type="Business Term"
          title={data.business_term_name}
          subtitleText={ data && !loading &&
            data.glossary_name 
          }
          details={[
            {title: 'Last Modified', data: formatData(data.modified_timestamp, 'datetime')},
            {title: 'Created', data: formatData(data.created_timestamp, 'datetime')}
          ]}
          description={data.business_term_description}
          fromReferences={fromReferences}
          loading={loading}
          >

            { !loading && data && data.business_term_example &&
              <React.Fragment>
                <h3>{ !loading ? "Example" : <SkeletonLoader width="5vw"/> }</h3>
                <div className="display-linebreak">
                { !loading 
                  ? (!data.business_term_example) 
                    ? <span className="no-result-text">No example available</span> 
                    : <TextareaReadOnly
                        value={ textareaInitialState({value: data.business_term_example, readOnly: true, descriptions: fromReferences }) } 
                      />
                  : <SkeletonLoader width="10vw" count={2} />
                }</div>
              </React.Fragment>
            }

            <h3>{ !loading ? "Owner" : <SkeletonLoader width="5vw"/>}</h3>
            <UserLink 
              userId={data.business_term_owner_user_id}
              userName={data.business_term_owner_user_fullname}
              userPhoto={data.user_photo}
              userTitle={data.user_title}
              userDepartment={data.user_department}
              loading={loading}
            />

          </ObjectDetails>

          { !loading && editable &&
          <div className="main-toolbar">
            <button type="button" className="main-toolbar-item button main-button" onClick={ () => showSideDialog('editBusinessTerm', [data], editBusinessTerm) }><span>EDIT</span></button>
            <button type="button" className="main-toolbar-item button" onClick={ () => showModalDialog('deleteBusinessTerm', [data], deleteBusinessTerm) }><span>DELETE</span></button>
          </div>
          }

      </MainColumn>

      <div className="column">
        <Tabs 
          className="slim left"
          //onTabChange={ onTabChange }
          defaultSelectedTab={defaultSelectedTab}
          disableTabsWithoutResults={ false }
        >
        
          <div label="Related Assets" tabid='related_assets' resultCount={toReferencesCount && toReferencesCount.total}>
            <References 
              showMessage={showMessage}
              toObjectName={Asset.BusinessTerm}
              toObjectId={business_term_id}
              toReferencesCount={toReferencesCount}
              />
          </div>

          <div label="Changelog" tabid="changelog">
            <Changelog 
              objectName={Asset.BusinessTerm} 
              objectId={business_term_id} 
              filterObjects={[
                'changedData.new_data||old_data.business_term_name', 
                'changedData.new_data||old_data.business_term_description', 
                'changedData.new_data||old_data.business_term_example', 
                'changedData.new_data||old_data.glossary_name', 
                'changedData.new_data||old_data.business_term_owner_user_fullname', 
                'changedData.attribute',
                'changed_by_user_username',
                'is_service_account_user'
              ]}
              filterObjectsValueMap={{is_service_account_user: {true: {label:'Datasource Sync'}, false: {label:''}}}}
            />
          </div>

        </Tabs>
      </div>

    </div>
  ) 
}
