import appsettings from './appsettings';

const dev = appsettings.development
const prod = appsettings.production

const config = process.env.NODE_ENV === 'production'
  ? prod
  : dev;

export default {
  ...config
};
