import React, { createContext, useState, useMemo, useEffect } from "react";

export const ModalContext = createContext();

export const ModalProvider = ({ children }) => {
  const [modalDialog, setModalDialog] = useState({})
  const [loadingModalDialog, setLoadingModalDialog] = useState(false)
  const [show, setShow] = useState(false)

  const value = useMemo( () => (
    { 
      modalDialog, 
      setModalDialog,
      loadingModalDialog, 
      setLoadingModalDialog,
      show, 
      setShow 
    }
  ), [
    modalDialog, 
    setModalDialog,
    loadingModalDialog, 
    setLoadingModalDialog,
    show, 
    setShow 
  ])

  return (
    <ModalContext.Provider
      value={value}
    >
      {children}
    </ModalContext.Provider>
  );
}