import React, { useState, useEffect } from 'react';
import { glossaryService } from '../services';
import { DataTable } from '../components';
import { useSideDialog, useModalDialog, useGlobalState, usePhotos, useMessage } from '../hooks';
import { Role } from '../helpers';

export const Glossaries = () => {
  
  const [glossaries, setGlossaries] = useState([]);
  const [loggedInUser, setLoggedInUser] = useGlobalState('loggedInUser');
  const [selectedItems, setSelectedItems] = useState([]);
  const [loading, setLoading] = useState(true);

  const clearSelectedItems = () => {
    setSelectedItems([])
  }

  const { showMessage } = useMessage()
  const { showSideDialog, hideSideDialog } = useSideDialog(clearSelectedItems)
  const { showModalDialog, hideModalDialog } = useModalDialog()
  const { fetchPhotos } = usePhotos()

  useEffect(() => {
    fetchItems()
  }, []);

  const fetchItems = async () => {
    const promise = new Promise( (resolve,reject) => {
      glossaryService.getAll()
        .then(res => {
          setGlossaries(prev => res.glossaries)
          setLoading(false) 
          resolve(res.glossaries)
        })
        .catch(err => {
          setLoading(false) 
          showMessage(err, 'error')
        })
    })

    Promise.all([promise, fetchBusinessTermCount()])
      .then( result => {
        updateFieldCount(result[0], result[1])
        fetchPhotos(result[0], 'glossary_owner_user_id', setGlossaries)
      })
      .catch(err => {showMessage(err, 'error')})
  }

  const fetchBusinessTermCount = async () => {
    return new Promise(  (resolve, reject) => {
      glossaryService.getBusinessTermCount()
        .then(res => {

          resolve(res.business_term_count)

        })
        .catch(err => {
          return null
        });
    })
  }

  const updateFieldCount = (data, statistics) => {

    let dataTmp = data
    let statistic = {}
    let tmp = null

    let maxValue = 0

    statistics.forEach( row => {
      if (row.business_term_count > maxValue) {
        maxValue = row.business_term_count
      }
    })

    data.forEach( (row, index) => {
      statistic = statistics.find(x => x.glossary_id === row.glossary_id)
      tmp = dataTmp[index]
      dataTmp[index] = {
        ...tmp, 
        business_term_count: (statistic ? statistic.business_term_count : 0), 
        business_term_count_max: maxValue
      }
    })

    setGlossaries(prev => [...dataTmp])
  }

  const addGlossary = async data => {
    const glossaries = {glossaries: (Array.isArray(data) ? data : [data])}
    
    await glossaryService.create(glossaries)
      .then(res => {
        fetchItems()
        showMessage(res.message)
      })
      .catch(err => {
        showMessage(err, 'error')
        throw err
      });
  }

  const editGlossary = async data => {
    const glossaries = {glossaries: (Array.isArray(data) ? data : [data])}
    
    await glossaryService.update(glossaries)
      .then(async res => { 

        fetchItems()
        showMessage(res.message)
        clearSelectedItems()
        hideSideDialog()
      })
      .catch(err => {
        showMessage(err, 'error')
        throw err
      });
  }

  const deleteGlossary = async data => {    
   
    await glossaryService.destroy({ glossaries: data })
      .then(async res => { 
        hideModalDialog()
        clearSelectedItems()
        fetchItems()
        showMessage(res.message)
      })
      .catch(err => {
        showMessage(err, 'error')
      });
  }

  
  const editable = loggedInUser && loggedInUser.user_role_name === Role.admin

  return (
    <div className="Glossaries wide-margin">

        <DataTable
          columns={[
            {id: 'glossary_name', name: 'Name', link: '/browse_business_terms/:glossary_id', tooltip: 'glossary_description', className:"bold"},
            {id: 'glossary_description', name: 'Description', type: 'description', viewMode: 'descriptions'},
            {id: 'business_term_count', name: 'Business Terms', type: 'chart', chartType: 'bar', data: {data:'business_term_count', max: 'business_term_count_max'}, viewMode: 'statistics'},
            {id: 'user_photo', name: '', type: 'user-photo', link: '/users/:glossary_owner_user_id', tooltip: 'glossary_owner_user_fullname'},
            {id: 'glossary_owner_user_fullname', name: 'Owner', link: '/users/:glossary_owner_user_id'}
          ]}
          buttons={[ 
            {label: "Add +", action: "add", mainButton: true, onClick: (items) => showSideDialog('addGlossary', items || selectedItems, addGlossary) },
            {label: "Edit", action: "edit", onClick: (items) => showSideDialog('editGlossary', items || selectedItems, editGlossary) },
            {label: "Delete", action: "delete", onClick: (items) => showModalDialog('deleteGlossary', items || selectedItems, deleteGlossary) }
          ]}
          data={glossaries}
          title="Glossaries"
          idColumn='glossary_id'
          selectedItems={selectedItems}
          setSelectedItems={setSelectedItems}
          loading={loading}
          editable= {editable}
          filterable={true}
          filterObjects={['glossary_name', 'glssary_description', 'glossary_owner_user_fullname']}
          showViewModes={true}
        />

      </div>
  );
}