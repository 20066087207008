import { useContext, useCallback, useRef } from 'react';
import { 
  AddBusinessTermForm,
  AddDatasetForm,
  AddDatasetGroupForm,
  AddDatasourceForm,
  AddDatasourceSubtypeForm,
  AddFieldForm,
  AddFieldDescriptionForm,
  AddFieldUnitForm,
  AddGlossaryForm,
  AddSystemForm, 
  AddTaskForm,
  EditProfileForm, 
  AddUserForm,
  AddUserGroupForm
} from '../forms';

import {SideDialogContext} from "../context/SideDialogContext";

export const useSideDialog = (clearSelectedItems) => {

  const {
    sideDialog, 
    setSideDialog,
    loadingSideDialog, 
    setLoadingSideDialog,
    show, 
    setShow 
  } = useContext(SideDialogContext)

  const formRef = useRef()

  const hideSideDialog = useCallback(
    (props) => {
      setShow(prev => false)
      setSideDialog({})
    },
    [setShow]
  )

  const showSideDialog = useCallback( 
    (mode, data, onSubmit, onCancel, submitLabel, submitLabelLoading, cancelLabel) => {

    const itemCount = data.length

    const defaultConfig = {
      mode: '',
      Content: null,
      title: '',
      data: data || [],
      submitForm: onSubmit, 
      onSubmit: () => formRef.current.dispatchEvent( new Event("submit", { cancelable: true, bubbles: true }) ),
      onCancel: onCancel || hideSideDialog,
      submitLabel: submitLabel !== undefined ? submitLabel : "Add",
      submitLabelLoading: submitLabelLoading ? submitLabelLoading : submitLabel || "Saving...",
      cancelLabel: cancelLabel !== undefined ? cancelLabel : "Cancel",
      formRef: formRef
    }

    let config = {}

    switch(mode) {

      case "addBusinessTerm":
        config = {
          mode: 'add',
          Content: AddBusinessTermForm,
          title: "Add Business Term"          
        }
        break
      case "addDataset":
        config = {
          mode: 'add',
          Content: AddDatasetForm,
          title: "Add Dataset"          
        }
        break
      case "addDatasetGroup":
        config = {
          mode: 'add',
          Content: AddDatasetGroupForm,
          title: "Add Dataset Group"          
        }
        break
      case "addDatasource":
        config = {
          mode: 'add',
          Content: AddDatasourceForm,
          title: "Add Datasource"          
        }
        break
      case "addODBCDriver":
        config = {
          mode: 'add',
          Content: AddDatasourceSubtypeForm,
          title: "Add ODBC Driver",
          datasourceTypeCode: 'odbc'         
        }
        break
      case "addField":
        config = {
          mode: 'add',
          Content: AddFieldForm,
          title: "Add Field"          
        }
        break
      case "addFieldDescription":
        config = {
          mode: 'add',
          Content: AddFieldDescriptionForm,
          title: "Add Field Description"          
        }
        break
      case "addFieldUnit":
        config = {
          mode: 'add',
          Content: AddFieldUnitForm,
          title: "Add Field Unit"          
        }
        break
      case "addGlossary":
        config = {
          mode: 'add',
          Content: AddGlossaryForm,
          title: "Add Glossary"          
        }
        break
      case "addSystem":
        config = {
          mode: 'add',
          Content: AddSystemForm,
          title: "Add System"          
        }
        break
      case "addTask":
        config = {
          mode: 'add',
          Content: AddTaskForm,
          title: "Add Task"          
        }
        break
      case "addUser":
        config = {
          mode: 'add',
          Content: AddUserForm,
          title: "Add User"          
        }
        break
      case "addUserGroup":
        config = {
          mode: 'add',
          Content: AddUserGroupForm,
          title: "Add User Group"          
        }
        break


      case "editBusinessTerm":
        config = {
          mode: 'edit',
          Content: AddBusinessTermForm,
          title: itemCount > 1 ? `Edit ${itemCount} Business Terms` : "Edit Business Term",
          submitLabel: "Save"
        }
        break
      case "editDataset":
        config = {
          mode: 'edit',
          Content: AddDatasetForm,
          title: itemCount > 1 ? `Edit ${itemCount} Datasets` : "Edit Dataset",
          submitLabel: "Save"
        }
        break
      case "editDatasetGroup":
        config = {
          mode: 'edit',
          Content: AddDatasetGroupForm,
          title: itemCount > 1 ? `Edit ${itemCount} Dataset Groups` : "Edit Dataset Group",
          submitLabel: "Save"
        }
        break
      case "editDatasource":
        config = {
          mode: 'edit',
          Content: AddDatasourceForm,
          title: itemCount > 1 ? `Edit ${itemCount} Datasources` : "Edit Datasource",
          submitLabel: "Save"
        }
        break
      case "editODBCDriver":
        config = {
          mode: 'edit',
          Content: AddDatasourceSubtypeForm,
          title: itemCount > 1 ? `Edit ${itemCount} Drivers` : "Edit Driver",
          submitLabel: "Save",
          datasourceTypeCode: 'odbc'
        }
        break
      case "editField":
        config = {
          mode: 'edit',
          Content: AddFieldForm,
          title: itemCount > 1 ? `Edit ${itemCount} Fields` : "Edit Field",
          submitLabel: "Save"
        }
        break
      case "editFieldDescription":
        config = {
          mode: 'edit',
          Content: AddFieldDescriptionForm,
          title: itemCount > 1 ? `Edit ${itemCount} Field Descriptions` : "Edit Field Description",
          submitLabel: "Save"
        }
        break
      case "editFieldUnit":
        config = {
          mode: 'edit',
          Content: AddFieldUnitForm,
          title: itemCount > 1 ? `Edit ${itemCount} Field Units` : "Edit Field Unit",
          submitLabel: "Save"
        }
        break
      case "editGlossary":
        config = {
          mode: 'edit',
          Content: AddGlossaryForm,
          title: itemCount > 1 ? `Edit ${itemCount} Glossaries` : "Edit Glossary",
          submitLabel: "Save"
        }
        break
      
      case "editProfile":
        config = {
          mode: 'edit',
          Content: EditProfileForm,
          title: itemCount > 1 ? `Edit ${itemCount} Profiles` : "Edit Profile",
          submitLabel: "Save"
        }
        break
      case "editSystem":
        config = {
          mode: 'edit',
          Content: AddSystemForm,
          title: itemCount > 1 ? `Edit ${itemCount} Systems` : "Edit System",
          submitLabel: "Save"
        }
        break
      case "editTask":
        config = {
          mode: 'edit',
          Content: AddTaskForm,
          title: itemCount > 1 ? `Edit ${itemCount} Tasks` : "Edit Task",
          submitLabel: "Save"       
        }
        break
      case "editUser":
        config = {
          mode: 'edit',
          Content: AddUserForm,
          title: itemCount > 1 ? `Edit ${itemCount} Users` : "Edit User",
          submitLabel: "Save"       
        }
        break
      case "editUserGroup":
        config = {
          mode: 'edit',
          Content: AddUserGroupForm,
          title: itemCount > 1 ? `Edit ${itemCount} User Groups` : "Edit User Group",
          submitLabel: "Save"       
        }
        break
      default:
        break
    }

    setShow(prev => true)
    setSideDialog({...defaultConfig, ...config})

    clearSelectedItems && clearSelectedItems()
  }, [formRef, clearSelectedItems])

  return { 
    showSideDialog, 
    hideSideDialog, 
    sideDialog, 
    setSideDialog,
    loadingSideDialog, 
    setLoadingSideDialog,
    show  
  }
};