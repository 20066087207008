import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { glossaryService, businessTermService, referenceService } from '../services';
import { Role, Asset, formatData } from '../helpers';
import { 
  Changelog,
  DataTable, 
  fetchToReferencesCountPerChild, 
  SkeletonLoader,
  MainColumn, 
  ObjectDetails, 
  Tabs, 
  UserLink } from '../components';
import { 
  useQueryParams,
  useSideDialog, 
  useModalDialog, 
  useGlobalState, 
  usePhotos, 
  useNotFound, 
  useMessage } from '../hooks';

export const Glossary = () => {

  const { queryParams, resetParams } = useQueryParams()

  const [glossary, setGlossary] = useState([]);
  const [businessTerms, setBusinessTerms] = useState([]);
  const [fromReferences, setFromReferences] = useState([]);
  const [loggedInUser, setLoggedInUser] = useGlobalState('loggedInUser');
  const [selectedItems, setSelectedItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingTable, setLoadingTable] = useState(true);
  const [defaultSelectedTab, setDefaultSelectedTab] = useState({tab: queryParams.tab})

  const clearSelectedItems = () => {
    setSelectedItems([])
  }

  const { glossary_id } = useParams()
  const navigate = useNavigate()

  const { showMessage } = useMessage()
  const { showSideDialog, hideSideDialog } = useSideDialog(clearSelectedItems)
  const { showModalDialog, hideModalDialog } = useModalDialog()
  const { fetchPhotos } = usePhotos()
  const { notFound, setNotFound, NotFound } = useNotFound()

  useEffect(() => {

    fetchItems()
    fetchBusinessTerms()
    fetchFromReferences()

  }, [glossary_id]);

  const fetchItems = async () => {
    
    glossaryService.getById(glossary_id)
      .then(res => {
        if (res.glossaries.length < 1) {
          setNotFound(true)
        } else {
          setGlossary(res.glossaries[0]);
          setLoading(false)
          fetchPhotos(res.glossaries[0], 'glossary_owner_user_id', setGlossary)
        }
      })
      .catch(err => {
        setLoading(false)
        showMessage(err, 'error')
      });

  }

  const fetchBusinessTerms = async () => {
    const promise = new Promise( (resolve,reject) => {
      businessTermService.getByGlossaryId(glossary_id)
        .then(res => {
          setBusinessTerms(res.business_terms);
          setLoadingTable(false);  
          resolve(res.business_terms)         
        })
        .catch(err => {showMessage(err, 'error')});
    })

    Promise.all([promise, fetchToReferencesCountPerChild({toObjectName: "business_term", toParentObjectName: "glossary", toParentObjectId: glossary_id})])
      .then( result => {
        updateReferenceCount(result[0], result[1])
        fetchPhotos(result[0], 'business_term_owner_user_id', setBusinessTerms)
      })
      .catch(err => {showMessage(err, 'error')});

  }

  const fetchFromReferences = async () => {
    
    referenceService.getByFromId(Asset.Glossary, glossary_id)
      .then(res => {

        setFromReferences(res.references);
         
      })
      .catch(err => {showMessage(err, 'error')});
  }

const updateReferenceCount = (data, statistics) => {

    let dataTmp = data
    let statistic = {}
    let tmp = null

    let maxValue = 0

    statistics.forEach( row => {
      if (row.to_references_count > maxValue) {
        maxValue = row.to_references_count
      }
    })

    data.forEach( (row, index) => {
      statistic = statistics.find(x => x.to_object_id === row.business_term_id)
      tmp = dataTmp[index]
      dataTmp[index] = {
        ...tmp, 
        to_references_count: (statistic ? statistic.to_references_count : 0), 
        to_references_count_max: maxValue
      }
    })

    setBusinessTerms(prev => [...dataTmp])
  }

  const addBusinessTerm = async data => {
    const businessTerms = {business_terms: (Array.isArray(data) ? data : [data])}
    
    await businessTermService.create(businessTerms)
      .then(res => {

        fetchBusinessTerms()
        showMessage(res.message)
      })
      .catch(err => {
        showMessage(err, 'error')
        throw err
      });
  }

  const editGlossary = async data => {
    const glossaries = {glossaries: (Array.isArray(data) ? data : [data])}
    
    await glossaryService.update(glossaries)
      .then(async res => { 

        fetchItems()
        fetchFromReferences()
        showMessage(res.message)
        hideSideDialog()
      })
      .catch(err => {
        showMessage(err, 'error')
        throw err
      });
  }

  const editBusinessTerm = async data => {
    const businessTerms = {business_terms: (Array.isArray(data) ? data : [data])}
    
    await businessTermService.update(businessTerms)
      .then(async res => { 

        fetchBusinessTerms()
        showMessage(res.message)
        clearSelectedItems()
        hideSideDialog()
      })
      .catch(err => {
        showMessage(err, 'error')
        throw err
      });
  }

  const deleteGlossary = async data => {    
    await glossaryService.destroy({glossaries: data})
      .then(async res => { 

        showMessage(res.message)
        hideModalDialog()

        // Redirect to parent page
        navigate('/browse_business_terms')

      })
      .catch(err => {
        showMessage(err, 'error')
      });
  }

  const deleteBusinessTerm = async data => {    
    await businessTermService.destroy({ business_terms: data })
      .then(async res => { 

        hideModalDialog()
        clearSelectedItems()
        fetchBusinessTerms()
        showMessage(res.message)

      })
      .catch(err => {
        showMessage(err, 'error')
      });
  }

  const data = loading ? [] : glossary

  const editableGlossary = loggedInUser && loggedInUser.user_role_name === Role.admin
  const editableBusinessTerm = loggedInUser && (loggedInUser.user_role_name === Role.admin || loggedInUser.user_role_name === Role.editor)

  if (notFound) {
    return <NotFound />
  }

  return (
    <div className="columns narrow-margin">
      <MainColumn>
        <ObjectDetails
          type="Glossary"
          title={data.glossary_name}
          description={data.glossary_description}
          details={[
            {title: 'Last Modified', data: formatData(data.modified_timestamp, 'datetime')},
            {title: 'Created', data: formatData(data.created_timestamp, 'datetime')}
          ]}
          fromReferences={fromReferences}
          loading={loading}
          >

            <h3>{ !loading ? "Owner" : <SkeletonLoader width="5vw"/>}</h3>
            <UserLink 
              userId={data.glossary_owner_user_id}
              userName={data.glossary_owner_user_fullname}
              userPhoto={data.user_photo}
              userTitle={data.user_title}
              userDepartment={data.user_department}
              loading={loading}
            />
          </ObjectDetails>

          { !loading && editableGlossary &&
          <div className="main-toolbar">
            <button type="button" className="main-toolbar-item button main-button" onClick={ () => showSideDialog('editGlossary', [data], editGlossary) }><span>EDIT</span></button>
            <button type="button" className="main-toolbar-item button" onClick={ () => showModalDialog('deleteGlossary', [data], deleteGlossary) }><span>DELETE</span></button>
          </div>
          }
      </MainColumn>

      <div className="column">
        <Tabs 
          className="slim left"
          //onTabChange={ onTabChange }
          defaultSelectedTab={ defaultSelectedTab }
          >
          <div label="Business Terms" tabid='business_terms' resultCount={loadingTable || businessTerms.length === 0 ? undefined : businessTerms.length}>
        
            <DataTable
              columns={[
                {id: 'business_term_name', name: 'Name', link: '/browse_business_terms/:glossary_id/:business_term_id', tooltip: 'business_term_description', className:"bold"},
                {id: 'business_term_description', name: 'Description', type: 'description', viewMode: 'descriptions'},
                {id: 'to_references_count', name: 'Related Assets', type: 'chart', chartType: 'bar', data: {data:'to_references_count', max: 'to_references_count_max'}, viewMode: 'statistics'},
                {id: 'user_photo', name: '', type: 'user-photo', link: '/users/:business_term_owner_user_id', tooltip: 'business_term_owner_user_fullname'},
                {id: 'business_term_owner_user_fullname', name: 'Owner', link: '/users/:business_term_owner_user_id'},
                {id: 'modified_timestamp', name: 'Modified', type: 'datetime', viewMode: 'statistics'}
              ]}
              buttons={[ 
                {label: "Add +", action: "add", mainButton: true, onClick: (items) => showSideDialog('addBusinessTerm', items || selectedItems, addBusinessTerm) },
                {label: "Edit", action: "edit", onClick: (items) => showSideDialog('editBusinessTerm', items || selectedItems, editBusinessTerm) },
                {label: "Delete", action: "delete", onClick: (items) => showModalDialog('deleteBusinessTerm', items || selectedItems, deleteBusinessTerm) }
              ]}
              data={businessTerms}
              idColumn='business_term_id'
              selectedItems={selectedItems}
              setSelectedItems={setSelectedItems}
              loading={loadingTable}
              editable={editableBusinessTerm}
              filterable={true}
              filterObjects={['business_term_name', 'business_term_description', 'business_term_owner_user_fullname']}
              showViewModes={true}
            />
          </div>

          <div label="Changelog" tabid="changelog">
            <Changelog 
              objectName={Asset.Glossary} 
              objectId={glossary_id}  
              filterObjects={[
                'changedData.new_data||old_data.glossary_name', 
                'changedData.new_data||old_data.glossary_description', 
                'changedData.new_data||old_data.glossary_owner_user_fullname', 
                'changedData.attribute',
                'changed_by_user_username',
                'is_service_account_user'
              ]}
              filterObjectsValueMap={{is_service_account_user: {true: {label:'Datasource Sync'}, false: {label:''}}}}
            />
          </div>

        </Tabs>
      </div>

    </div>
  ) 
}
