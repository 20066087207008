import React, { useState, forwardRef } from 'react';

import './Section.css';

const _Section = ({heading, children}, ref) => {

  const [expanded, setExpanded] = useState(false)

  const handleToggle = (e) => {
    setExpanded(!expanded)
  }
  return (
      <div 
        className={
          "Section" +
          (expanded ? " expanded" : "") 
        }
      >

        <div className="section-header" onClick={handleToggle}>
          <div className="section-toggle">
            <div className="button-section-toggle"></div>
          </div>
          <div className="section-title">
            {heading}
          </div>
        </div>
        <div className="section-body">
          {children}
        </div>

      </div>      
  )
};
export const Section = forwardRef(_Section);
