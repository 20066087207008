import React, { useState, useEffect, forwardRef} from 'react'
import { getBatchEditValue } from '../helpers'
import { Input, TextareaAutosize } from '../components';
import { datasourceSubtypeService } from '../services';
import { useMessage } from '../hooks';

const _AddDatasourceSubtypeForm = ({ mode, data, submitForm, loading, setLoading, datasourceTypeCode }, ref) => { 
	
	const initialFormState = {
		datasource_subtype_id: null,
		datasource_type_id: null,
		datasource_subtype_name: '',
		datasource_subtype_driver_name: '',
		datasource_subtype_settings: ''
	}

	const initialBatchEditItems = {
	}

	const [formData, setFormData] = useState(initialFormState);
	const [defaultSettings, setDefaultSettings] = useState();
	const [batchEdit, setBatchEdit] = useState(false);
	const [batchEditItems, setBatchEditItems] = useState(initialBatchEditItems)

  const { showMessage } = useMessage()


	useEffect(() => {

		fetchDefaultSettings()
		
	}, []);

	useEffect(() => {

		setBatchEdit(false)
	    if (data.length === 1) {
			setFormData({ 
				datasource_subtype_id: data[0].datasource_subtype_id, 
				datasource_type_id: data[0].datasource_type_id, 
				datasource_subtype_name: data[0].datasource_subtype_name || '',
				datasource_subtype_driver_name: data[0].datasource_subtype_driver_name || '',
				datasource_subtype_settings: JSON.stringify(data[0].datasource_subtype_settings || undefined, null, 2) || ''
			})
		} else if(data.length > 1 && mode !== 'add') {
			setBatchEdit(true)

			setFormData({ 
			})
		//} else if(mode === 'add') {
			
		} else {
			resetForm()		
		}
		
	}, [mode, data]);
  
  const fetchDefaultSettings = async () => {
		await datasourceSubtypeService.getDefaultSettings('odbc')
      .then(res => {
        if (res) {
        	data.length === 0 && mode === 'add' && setFormData(prev => {
	       		return {...prev, datasource_type_id: res.datasource_type_id }
	        })
	        setDefaultSettings(res.datasource_subtype_settings)
        }
      })
      .catch(err => {
        showMessage(err, 'error')
      });
  }

	const handleInputChange = (event, formPart) => {
	  let { name, value } = event.target

	  // If input element is a checkbox, we cannot use "value"
    if (event.target.type === "checkbox") { value = event.target.checked }

    switch(datasourceTypeCode) {
  		case 'odbc':
  			if (name.includes("datasource_subtype_settings_")) {

  				let tmpName = name.replace("datasource_subtype_settings_", "")  				
  				let tmpValue = formData.datasource_subtype_settings ? JSON.parse(formData.datasource_subtype_settings) : {}

  				tmpValue.connectionStringMap = {
  					...(tmpValue.connectionStringMap || {}),
  					[tmpName]: value
  				}
  				name = "datasource_subtype_settings"
  				value = JSON.stringify(tmpValue, undefined, 2)
  			}
		    break
		  default:
	  }

	  switch(formPart) {
	  	case 'batch':
	  		setBatchEditItems({ ...batchEditItems, [name]: value })
	  		break
	  	default:
	  		setFormData(prev => { return { ...prev, [name]: value }})
	  }


	}

	const onSubmit = event => {
		event.preventDefault()

		setLoading(true)
		
		// Form validation
		//if (!formData.user_fullname || !formData.user_username) return

		let dataToSubmit
		
		if (batchEdit) {
			
			let key = ''
			let tmp = {}
			const objects = Object.entries(batchEditItems)

			for (let i = 0; i < objects.length; i++) {
				if (objects[i][1]) {
					key = objects[i][0].replace("batch_","")
					tmp = {...tmp, [key]: formData[ key ]}
				}
			}

			dataToSubmit = data.map( item => {
				return {
					datasource_subtype_id: item.datasource_subtype_id, 
					datasource_type_id: item.datasource_type_id, 
					datasource_subtype_name: item.datasource_subtype_name,
					datasource_subtype_driver_name: item.datasource_subtype_driver_name, 
					datasource_subtype_settings: item.datasource_subtype_settings, 
					...tmp
				}
			})

		} else {
			dataToSubmit = formData
		}
		
		// submit form
		submitForm(dataToSubmit)
		.then(res => resetForm())
		.catch(err => {
			// Errors handled in submitForm function
		})
		.finally(res => setLoading(false))          
	}

  const resetForm = () => {
  	setBatchEdit(false)
  	setBatchEditItems(initialBatchEditItems)
  	setFormData(prevFormData => {
			return {
				...initialFormState, 
				//datasource_subtype_settings: JSON.stringify(defaultSettings || undefined, null, 2) || ''  
			}
	  }) 
  }

  const renderSettings = () => {

  	let settings

  	switch(datasourceTypeCode) {
  		case 'odbc':
  			if (defaultSettings) {
					const  settingsObj = formData.datasource_subtype_settings ? JSON.parse(formData.datasource_subtype_settings).connectionStringMap : {}
					settings = <React.Fragment>
					<h4>Connection String Property Map</h4>
					{ Object.entries(defaultSettings.connectionStringMap).map( ([key, value]) => {

							const label = key.charAt(0).toUpperCase() + key.slice(1)
		  				return <Input 
		  					type="text" 
		            name={`datasource_subtype_settings_${key}`}
		            label={label}
		            value={settingsObj[key] || ""} 
		            onChange={handleInputChange}
		            disabled={loading}
		            placeholder={value}
		            labelPosition="left"
		          />

						})
					}
					</React.Fragment>
  			}
	      break

	    default:
	    	settings = <TextareaAutosize
	      	name="datasource_subtype_settings" 
	      	label="Settings"
	      	title=""
	      	value={formData.datasource_subtype_settings} 
	      	onChange={handleInputChange}
	      	disabled={loading}
	      	className="code"
	      	placeholder="Settings JSON..."
	      />
	  }

	  return settings
  }


  const renderConnectionString = () => {
		
		let str = ""
  	
  	if (!(formData && defaultSettings)) {
  		return ""
  	}

  	str += `DRIVER={${formData.datasource_subtype_driver_name}};`

  	let datasourceSubtypeSettings = formData.datasource_subtype_settings ? JSON.parse(formData.datasource_subtype_settings).connectionStringMap : {}

  	Object.entries(defaultSettings.connectionStringMap).forEach(([key, value]) => {
  			const param = datasourceSubtypeSettings[key] || value 
  			str += `${param}=<${key}>;` 		
  	})

  	return str
  }

  return (
  	<div className="form">
	    <form
			  onSubmit={onSubmit}
			  ref={ref}
			>
			{ !batchEdit &&
				<div className="form-block vertical">
		      <Input type="text" 
		      	name="datasource_subtype_driver_name" 
		      	label="Driver Name"
		      	value={formData.datasource_subtype_driver_name} 
		      	onChange={handleInputChange}
		      	disabled={loading}
		      	title="Name of the ODBC driver. Must exactly match the driver name in the OS ODBC datasource manager to let Katalogue find the driver."
		      />
	      </div>
			}
			{ !batchEdit &&
				<div className="form-block vertical">
					<Input type="text" 
		      	name="datasource_subtype_name"
		      	label="Datasource Type" 
		      	value={formData.datasource_subtype_name} 
		      	onChange={handleInputChange}
		      	disabled={loading}
		      	title="Name of the datasource subtype. This is used as the datasource type display name for all datasources in Katalogue that use this ODBC driver."

		      />
			  </div>
			}
			{ !batchEdit &&
				<div className="form-block vertical">
					{ renderSettings() }
			  </div>
			}
			{ !batchEdit && datasourceTypeCode === 'odbc' &&
				<div className="form-block vertical disabled">
					<label>Connection String Template</label>
					<div className="form-light-text no-margin">{ renderConnectionString() }</div>
				</div>
			}
	    </form>
	  </div>
  )
}

export const AddDatasourceSubtypeForm = forwardRef(_AddDatasourceSubtypeForm)
