import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import { Role, Asset } from '../helpers';
import { DataTable, MainColumn, ObjectDetails, Tabs } from '../components';
import { useSideDialog, useModalDialog, useQueryParams, useGlobalState, useMessage } from '../hooks';
import { 
  businessTermService,
  datasetService,
  datasetGroupService,
  datasourceService,
  fieldDescriptionService,
  glossaryService,
  systemService,
  userService 
} from '../services';

export const Profile = () => {

  const { queryParams, resetParams } = useQueryParams()

  const [user, setUser] = useState([]);
  const [loggedInUser, setLoggedInUser] = useGlobalState('loggedInUser');
  const [selectedItems, setSelectedItems] = useState([]);
  const [ownedItems, setOwnedItems] = useState([]);
  const [ownedItemsCount, setOwnedItemsCount] = useState([]);
  const [ownedItemsTotalCount, setOwnedItemsTotalCount] = useState();
  const [loading, setLoading] = useState(true);
  const [loadingTable, setLoadingTable] = useState(true);
  const [defaultSelectedObject, setDefaultSelectedObject] = useState({tab: queryParams.object})

  const clearSelectedItems = () => {
    setSelectedItems([])
  }

  const { showMessage } = useMessage()
  const { showSideDialog, hideSideDialog } = useSideDialog(clearSelectedItems)
  const { showModalDialog, hideModalDialog } = useModalDialog()

  const navigate = useNavigate()

  const user_id = loggedInUser.user_id

  useEffect(() => {
    
    fetchItems()
    fetchOwnedItemsCount()

  }, []);

  const fetchItems = async () => {

    userService.getById(user_id)
      .then(res => {
        setUser(res);
        setLoading(false)
      })
      .catch(err => {
        showMessage(err, 'error')
        setLoading(false)
      });
  }

  const fetchOwnedItemsCount = async () => {
    
    userService.getOwnedItemsCount(user_id)
      .then(res => {

        let tmp = {}
        let defaultObject

        const objectSortOrder = ["field_description", "business_term", "glossary", "dataset", "dataset_group", "datasource", "system"];
        res.owned_items_count.sort((a, b) => objectSortOrder.indexOf(a.object_name) - objectSortOrder.indexOf(b.object_name));

        res.owned_items_count.forEach(result => {
          if (result.owned_items_count > 0 && defaultObject === undefined ) {
            defaultObject = result.object_name

          }
          tmp = {...tmp, [result.object_name]: parseInt(result.owned_items_count)}
        })

        defaultObject = queryParams.object || defaultObject

        setDefaultSelectedObject({tab: defaultObject})
        fetchOwnedItems(defaultObject)
        setOwnedItemsCount(tmp)

        setOwnedItemsTotalCount(
          (tmp.field_description || 0) +
          (tmp.dataset || 0) + 
          (tmp.dataset_group || 0) +
          (tmp.datasource || 0) +
          (tmp.system || 0) +
          (tmp.business_term || 0) +
          (tmp.glossary || 0)
        )

      })
      .catch(err => {showMessage(err, 'error')});
  }

  const fetchOwnedItems = async (objectName) => {
    if (objectName) { // objectName is undefined if user does not own any items
      setLoadingTable(true)
      userService.getOwnedItems(user_id, objectName)
        .then(res => {

          setOwnedItems(res.owned_items)

        })
        .catch(err => {showMessage(err, 'error')})
        .finally(res => setLoadingTable(false))
    } else {
      setLoadingTable(false) // Needed to show "no result" message
    }
  }

  const editProfile = async user => {
    const users = {users: [user]}

    await userService.updateProfile(users)
      .then(async res => { 
        fetchItems()
        showMessage(res.message)
        hideSideDialog()
      })
      .catch(err => { 
        showMessage(err, 'error') 
        throw err
      });
  }
  const editDataset = async data => {
    const items = {datasets: (Array.isArray(data) ? data : [data])}
    
    await datasetService.update(items)
      .then(async res => { 

        fetchOwnedItems(Asset.Dataset)
        showMessage(res.message)
        clearSelectedItems()
        hideSideDialog()
      })
      .catch(err => {
        showMessage(err, 'error')
        throw err
      });
  }

  const editDatasetGroup = async data => {
    const items = {dataset_groups: (Array.isArray(data) ? data : [data])}
    
    await datasetGroupService.update(items)
      .then(async res => { 

        fetchOwnedItems(Asset.DatasetGroup)
        showMessage(res.message)
        clearSelectedItems()
        hideSideDialog()
      })
      .catch(err => {
        showMessage(err, 'error')
        throw err
      });
  }

  const editDatasource = async data => {
    const items = {datasources: (Array.isArray(data) ? data : [data])}
    
    await datasourceService.update(items)
      .then(async res => { 

        fetchOwnedItems(Asset.Datasource)
        showMessage(res.message)
        clearSelectedItems()
        hideSideDialog()
      })
      .catch(err => {
        showMessage(err, 'error')
        throw err
      });
  }

  const editSystem = async data => {
    const items = {systems: (Array.isArray(data) ? data : [data])}
    
    await systemService.update(items)
      .then(async res => { 

        fetchOwnedItems(Asset.System)
        showMessage(res.message)
        clearSelectedItems()
        hideSideDialog()
      })
      .catch(err => {
        showMessage(err, 'error')
        throw err
      });
  }

  const editBusinessTerm = async data => {
    const items = {business_terms: (Array.isArray(data) ? data : [data])}
    
    await businessTermService.update(items)
      .then(async res => { 

        fetchOwnedItems(Asset.BusinessTerm)
        showMessage(res.message)
        clearSelectedItems()
        hideSideDialog()
      })
      .catch(err => {
        showMessage(err, 'error')
        throw err
      });
  }

  const editGlossary = async data => {
    const items = {glossaries: (Array.isArray(data) ? data : [data])}
    
    await glossaryService.update(items)
      .then(async res => { 

        fetchOwnedItems(Asset.Glossary)
        showMessage(res.message)
        clearSelectedItems()
        hideSideDialog()
      })
      .catch(err => {
        showMessage(err, 'error')
        throw err
      });
  }

  const editFieldDescription = async items => {    
    await fieldDescriptionService.update(items)
      .then(async res => { 

        fetchOwnedItems(Asset.FieldDescription)
        showMessage(res.message)
        clearSelectedItems()
        hideSideDialog()
      })
      .catch(err => {
        showMessage(err, 'error')
        throw err
      });
  }

  const deleteBusinessTerm = async data => {    
    await businessTermService.destroy({business_terms: data})
      .then(async res => { 
        showMessage(res.message)
        hideModalDialog()
        fetchOwnedItems(Asset.BusinessTerm)
      })
      .catch(err => {
        showMessage(err, 'error')
      });
  }

  const deleteDataset = async data => {    
    await datasetService.destroy({datasets: data})
      .then(async res => { 
        showMessage(res.message)
        hideModalDialog()
        fetchOwnedItems(Asset.Dataset)
      })
      .catch(err => {
        showMessage(err, 'error')
      });
  }

  const deleteDatasetGroup = async data => {   
    await datasetGroupService.destroy({dataset_groups: data})
      .then(async res => { 
        showMessage(res.message)
        hideModalDialog()
        fetchOwnedItems(Asset.DatasetGroup)
      })
      .catch(err => {
        showMessage(err, 'error')
      });
  }

  const deleteDatasource = async data => {    
    await datasourceService.destroy({datasources: data})
      .then(async res => { 
        showMessage(res.message)
        hideModalDialog()
        fetchOwnedItems(Asset.Datasource)
      })
      .catch(err => {
        showMessage(err, 'error')
      });
  }

  const deleteFieldDescription = async data => {   
    await fieldDescriptionService.destroy({field_descriptions: data})
      .then(async res => { 
        showMessage(res.message)
        hideModalDialog()
        fetchOwnedItems(Asset.FieldDescription)
      })
      .catch(err => {
        showMessage(err, 'error')
      });
  }

  const deleteGlossary = async data => {    
    await glossaryService.destroy({glossaries: data})
      .then(async res => { 
        showMessage(res.message)
        hideModalDialog()
        fetchOwnedItems(Asset.Glossary)
      })
      .catch(err => {
        showMessage(err, 'error')
      });
  }

  const deleteSystem = async data => {    
    await systemService.destroy({systems: data})
      .then(async res => { 
        showMessage(res.message)
        hideModalDialog()
        fetchOwnedItems(Asset.System)
      })
      .catch(err => {
        showMessage(err, 'error')
      });
  }

  const changePassword = async item => {    
    hideSideDialog()
    navigate('/change_password')

  }

  const onObjectChange = (object) => {
    resetParams({object: object})
    fetchOwnedItems(object)
    clearSelectedItems()
  }
    
  const data = loading ? [] : user.users[0]

  const editableAdmin = loggedInUser && loggedInUser.user_role_name === Role.admin
  const editable = loggedInUser && (loggedInUser.user_role_name === Role.admin || loggedInUser.user_role_name === Role.editor)

  return (
    <div className="columns narrow-margin">
      <MainColumn>
        <ObjectDetails
          type="USER"
          title={data.user_fullname}
          secondaryTitle={`${(data.user_title ? data.user_title : '')}${(data.user_department ? '\n' + data.user_department : '')}`}
          photo={data.user_photo}
          subtitleText={ data && !loading &&
            data.user_username
          }
          showDescription={false}
          loading={loading}
          >

            <h3>{ !loading ? "Email" : <Skeleton width="5vw"/>}</h3>
            <div className="display-linebreak">
            { !loading 
              ? (data.user_email === null) ? "-" : <a className="link" href={"mailto:"+data.user_email}>{data.user_email}</a>
              : <Skeleton width="10vw" />
            }
            </div>

            <h3>{ !loading ? "Role" : <Skeleton width="5vw"/>}</h3>
            <div className="display-linebreak">
            { !loading 
              ? (data.user_role_name === null) ? "-" : data.user_role_name
              : <Skeleton width="10vw" />
            }
            </div>
          </ObjectDetails>
          
          { !loading && data.user_auth_method === 'local' &&
          <div className="main-toolbar">
            <button type="button" className="main-toolbar-item main-button button" onClick={ () => showSideDialog('editProfile', user.users, editProfile) }><span>EDIT</span></button>
            <button type="button" className="main-toolbar-item button" onClick={ changePassword }><span>CHANGE PASSWORD</span></button>
          </div>
          }

      </MainColumn>

      <div className="column">
      
        <Tabs className="slim left" disableTabsWithoutResults={false}>
          <div label="Owned Assets" tabid='owned_assets' resultCount={ownedItemsTotalCount}>

          { ownedItemsTotalCount > 0 ?
            <Tabs className="buttons left" 
                  onTabChange={ onObjectChange } 
                  showResultCount={false} 
                  disableTabsWithoutResults={true}
                  defaultSelectedTab={defaultSelectedObject}
                  >

              <div label="Field Descriptions" tabid={Asset.FieldDescription} resultCount={ (ownedItemsCount && ownedItemsCount.field_description) || 0 }>
                <DataTable
                  columns={[
                    {id: 'field_role_name', name: '', type: 'icon', tooltip: 'field_role_name'},
                    {id: 'field_description_name', name: 'Name', link: '/browse_field_descriptions/:field_description_id', tooltip: 'field_description_description', className:"bold"},
                    {id: 'field_unit_code', name: 'Field Unit', tooltip: 'field_unit_name'},
                    {id: 'field_description_description', name: 'Description', type: 'description', viewMode: 'descriptions'},
                    {id: 'modified_timestamp', name: 'Modified', type:'datetime', viewMode: 'statistics'}   
                  ]}
                  buttons={[ 
                    {label: "Edit", action: "edit", onClick: (items) => showSideDialog('editFieldDescription', items || selectedItems, editFieldDescription) },
                    {label: "Delete", action: "delete", onClick: (items) => showModalDialog('deleteFieldDescription', items || selectedItems, deleteFieldDescription) }
                  ]}
                  data={ownedItems}
                  idColumn='field_description_id'
                  selectedItems={selectedItems}
                  setSelectedItems={setSelectedItems}
                  loading={loadingTable}
                  editable={editable}
                  filterable={true}
                  filterObjects={['field_description_name', 'field_description_description', 'field_role_name', 'field_unit_name']}
                  showViewModes={true}
                />
              </div>
              
              <div label="Business Terms" tabid={Asset.BusinessTerm} resultCount={ (ownedItemsCount && ownedItemsCount.business_term) || 0 }>
                <DataTable
                  columns={[
                    {id: 'business_term_name', name: 'Name', link: '/browse_business_terms/:glossary_id/:business_term_id', tooltip: 'business_term_description', className:"bold"},
                    {id: 'business_term_description', name: 'Description', type: 'description', viewMode: 'descriptions'},
                    {id: 'glossary_name', name: 'Glossary', link: '/browse_business_terms/:glossary_id'},
                    {id: 'modified_timestamp', name: 'Modified', type:'datetime', viewMode: 'statistics'}      
                  ]}
                  buttons={[ 
                    {label: "Edit", action: "edit", onClick: (items) => showSideDialog('editBusinessTerm', items || selectedItems, editBusinessTerm) },
                    {label: "Delete", action: "delete", onClick: (items) => showModalDialog('deleteBusinessTerm', items || selectedItems, deleteBusinessTerm) }
                  ]}
                  data={ownedItems}
                  idColumn='business_term_id'
                  selectedItems={selectedItems}
                  setSelectedItems={setSelectedItems}
                  loading={loadingTable}
                  editable={editable}
                  filterable={true}
                  filterObjects={['business_term_name', 'business_term_description', 'glossary_name']}
                  showViewModes={true}
                />
              </div>

              <div label="Glossaries" tabid={Asset.Glossary} resultCount={ (ownedItemsCount && ownedItemsCount.glossary) || 0 }>
                <DataTable
                  columns={[
                    {id: 'glossary_name', name: 'Name', link: '/browse_business_terms/:glossary_id', tooltip: 'glossary_description', className:"bold"},
                    {id: 'glossary_description', name: 'Description', type: 'description', viewMode: 'descriptions'},
                    {id: 'modified_timestamp', name: 'Modified', type:'datetime', viewMode: 'statistics'}   
                  ]}
                  buttons={[ 
                    {label: "Edit", action: "edit", onClick: (items) => showSideDialog('editGlossary', items || selectedItems, editGlossary) },
                    {label: "Delete", action: "delete", onClick: (items) => showModalDialog('deleteGlossary', items || selectedItems, deleteGlossary) }
                  ]}
                  data={ownedItems}
                  idColumn='glossary_id'
                  selectedItems={selectedItems}
                  setSelectedItems={setSelectedItems}
                  loading={loadingTable}
                  editable={editableAdmin}
                  filterable={true}
                  filterObjects={['glossary_name', 'glossary_description']}
                  showViewModes={true}
                />
              </div>

              <div label="Datasets" tabid={Asset.Dataset} resultCount={ (ownedItemsCount && ownedItemsCount.dataset) || 0 }>

                <DataTable
                  columns={[
                    {id: 'dataset_type_name', name: '', type: 'icon', tooltip: 'dataset_type_name'},
                    {id: 'dataset_name', name: 'Name', link: '/browse_datasets/:system_id/:datasource_id/:dataset_group_id/:dataset_id', tooltip: 'dataset_description', className:"bold"},
                    {id: 'dataset_description', name: 'Description', type: 'description', viewMode: 'descriptions'},
                    {id: 'dataset_group_name', name: 'Dataset Group', link: '/browse_datasets/:system_id/:datasource_id/:dataset_group_id' },
                    {id: 'datasource_name', name: 'Datasource', link: '/browse_datasets/:system_id/:datasource_id', viewMode: 'statistics'},
                    {id: 'system_name', name: 'System', link: '/browse_datasets/:system_id', viewMode: 'statistics'},
                  ]}
                  buttons={[ 
                    {label: "Edit", action: "edit", onClick: (items) => showSideDialog('editDataset', items || selectedItems, editDataset) },
                    {label: "Delete", action: "delete", onClick: (items) => showModalDialog('deleteDataset', items || selectedItems, deleteDataset) }
                  ]}
                  data={ownedItems}
                  idColumn='dataset_id'
                  selectedItems={selectedItems}
                  setSelectedItems={setSelectedItems}
                  loading={loadingTable}
                  editable={editable}
                  filterable={true}
                  filterObjects={['dataset_name', 'dataset_description', 'dataset_type_name', 'dataset_group_name', 'datasource_name', 'system_name']}
                  showViewModes={true}
                />
              </div>

              <div label="Dataset Groups" tabid={Asset.DatasetGroup} resultCount={ (ownedItemsCount && ownedItemsCount.dataset_group) || 0 }>
                <DataTable
                  columns={[
                    {id: 'dataset_group_name', name: 'Name', link: '/browse_datasets/:system_id/:datasource_id/:dataset_group_id', tooltip: 'dataset_group_description', className:"bold"},
                    {id: 'dataset_description', name: 'Description', type: 'description', viewMode: 'descriptions'},
                    {id: 'datasource_name', name: 'Datasource', link: '/browse_datasets/:system_id/:datasource_id'},
                    {id: 'system_name', name: 'System', link: '/browse_datasets/:system_id', viewMode: 'statistics'}   
                  ]}
                  buttons={[ 
                    {label: "Edit", action: "edit", onClick: (items) => showSideDialog('editDatasetGroup', items || selectedItems, editDatasetGroup) },
                    {label: "Delete", action: "delete", onClick: (items) => showModalDialog('deleteDatasetGroup', items || selectedItems, deleteDatasetGroup) }
                  ]}
                  data={ownedItems}
                  idColumn='dataset_group_id'
                  selectedItems={selectedItems}
                  setSelectedItems={setSelectedItems}
                  loading={loadingTable}
                  editable={editable}
                  filterable={true}
                  filterObjects={['dataset_group_name', 'dataset_description', 'datasource_name', 'system_name']}
                  showViewModes={true}
                />
              </div>

              <div label="Datasources" tabid={Asset.Datasource} resultCount={ (ownedItemsCount && ownedItemsCount.datasource) || 0 }>
                <DataTable
                  columns={[
                    {id: 'datasource_type_category', name: '', type: 'icon', tooltip: 'datasource_type_name'},
                    {id: 'datasource_name', name: 'Name', link: '/browse_datasets/:system_id/:datasource_id', tooltip: 'datasource_description', className:"bold"},
                    {id: 'datasource_description', name: 'Description', type: 'description', viewMode: 'descriptions'},
                    {id: 'system_name', name: 'System', link: '/browse_datasets/:system_id'}   
                  ]}
                  buttons={[ 
                    {label: "Edit", action: "edit", onClick: (items) => showSideDialog('editDatasource', items || selectedItems, editDatasource) },
                    {label: "Delete", action: "delete", onClick: (items) => showModalDialog('deleteDatasource', items || selectedItems, deleteDatasource) }
                  ]}
                  data={ownedItems}
                  idColumn='datasource_id'
                  selectedItems={selectedItems}
                  setSelectedItems={setSelectedItems}
                  loading={loadingTable}
                  editable={editableAdmin}
                  filterable={true}
                  filterObjects={['datasource_name', 'datasource_description', 'datasource_type_category', 'datasource_type_name', 'system_name']}
                  showViewModes={true}
                />
              </div>

              <div label="Systems" tabid={Asset.System} resultCount={ (ownedItemsCount && ownedItemsCount.system) || 0 }>
                <DataTable
                  columns={[
                    {id: 'system_name', name: 'Name', link: '/browse_datasets/:system_id', tooltip: 'system_description', className:"bold"}, 
                    {id: 'system_description', name: 'Description', type: 'description', viewMode: 'descriptions'} 
                  ]}
                  buttons={[ 
                    {label: "Edit", action: "edit", onClick: (items) => showSideDialog('editSystem', items || selectedItems, editSystem) },
                    {label: "Delete", action: "delete", onClick: (items) => showModalDialog('deleteSystem', items || selectedItems, deleteSystem) }
                  ]}
                  data={ownedItems}
                  idColumn='system_id'
                  selectedItems={selectedItems}
                  setSelectedItems={setSelectedItems}
                  loading={loadingTable}
                  editable={editableAdmin}
                  filterable={true}
                  filterObjects={['system_name', 'system_description']}
                  showViewModes={true}
                />
              </div>

            </Tabs>
          : !loadingTable &&
              <div className="no-result-text">No owned assets</div>           
        }

        </div>
      </Tabs>
        
      </div>

    </div>
  ) 
}
