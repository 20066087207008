import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { authMethod, formatData, Role, Asset } from '../helpers';
import { 
  Changelog,
  DataTable, 
  MainColumn, 
  ObjectDetails, 
  SkeletonLoader,
  Select,
  Tabs } from '../components';
import { 
  useSideDialog, 
  useModalDialog, 
  useQueryParams, 
  useGlobalState, 
  useNotFound, 
  useMessage } from '../hooks';
import { 
  businessTermService,
  datasetService,
  datasetGroupService,
  datasourceService,
  fieldDescriptionService,
  glossaryService,
  systemService,
  userService,
  userGroupService, 
  userGroupMemberService 
} from '../services';


export const User = () => {
  
  const { queryParams, setHistory, resetParams } = useQueryParams()

  const [user, setUser] = useState([]);
  const [userGroups, setUserGroups] = useState([]);
  const [userGroupsAll, setUserGroupsAll] = useState([]);
  const [loggedInUser, setLoggedInUser] = useGlobalState('loggedInUser');
  const [selectedItems, setSelectedItems] = useState([]);
  const [ownedItems, setOwnedItems] = useState([]);
  const [ownedItemsCount, setOwnedItemsCount] = useState({});
  const [ownedItemsTotalCount, setOwnedItemsTotalCount] = useState();
  const [loading, setLoading] = useState(true);
  const [loadingTable, setLoadingTable] = useState(true);
  const [loadingUserGroups, setLoadingUserGroups] = useState(true);
  const [morphButtonState, setMorphButtonState] = useState(false);
  const [defaultSelectedObject, setDefaultSelectedObject] = useState({tab: queryParams.object})
  const [defaultSelectedTab, setDefaultSelectedTab] = useState({tab: queryParams.tab})

  const clearSelectedItems = () => {
    setSelectedItems([])
  }

  let { user_id } = useParams()
  if (user_id === undefined) {
    user_id = loggedInUser.user_id
  }

  const navigate = useNavigate()

  const { showMessage } = useMessage()
  const { showSideDialog, hideSideDialog } = useSideDialog(clearSelectedItems)
  const { showModalDialog, hideModalDialog } = useModalDialog()
  const { notFound, setNotFound, NotFound } = useNotFound()

  useEffect(() => {
    
    fetchItems()
    fetchOwnedItemsCount()
    if (loggedInUser && loggedInUser.user_role_name === Role.admin) {
      fetchUserGroups()
      fetchAllUserGroups()
    }

  }, [user_id]);

  const fetchItems = async () => {
    
    userService.getById(user_id)
      .then(res => {
        if (res.users.length < 1) {
          setNotFound(true)
        } else {
          setUser(res.users[0]);
          setLoading(false)
        } 
      })
      .catch(err => {showMessage(err, 'error')});
  }

  const fetchOwnedItemsCount = async () => {

    userService.getOwnedItemsCount(user_id)
      .then(res => {

        let tmp = {}
        let defaultObject

        const objectSortOrder = ["field_description", "business_term", "glossary", "dataset", "dataset_group", "datasource", "system"];
        res.owned_items_count.sort((a, b) => objectSortOrder.indexOf(a.object_name) - objectSortOrder.indexOf(b.object_name));

        res.owned_items_count.forEach(result => {
          if (result.owned_items_count > 0 && defaultObject === undefined ) {
            defaultObject = result.object_name

          }
          tmp = {...tmp, [result.object_name]: parseInt(result.owned_items_count)}
        })

        defaultObject = res.owned_items_count.find(x => x.object_name === queryParams.object) ? queryParams.object : defaultObject

        //defaultObject = queryParams.object || defaultObject

        setDefaultSelectedObject({tab: defaultObject})
        fetchOwnedItems(defaultObject)
        setOwnedItemsCount(tmp)

        setOwnedItemsTotalCount(
          (tmp.field_description || 0) +
          (tmp.dataset || 0) + 
          (tmp.dataset_group || 0) +
          (tmp.datasource || 0) +
          (tmp.system || 0) +
          (tmp.business_term || 0) +
          (tmp.glossary || 0)
        )

      })
      .catch(err => {showMessage(err, 'error')});
  }

  const fetchOwnedItems = async (objectName) => {
    if (objectName) { // objectName is undefined if user does not own any items
      setLoadingTable(true)
      userService.getOwnedItems(user_id, objectName)
        .then(res => {

          setOwnedItems(res.owned_items)
          setLoadingTable(false)
           
        })
        .catch(err => {showMessage(err, 'error')});
    } else {
      setLoadingTable(false) // Needed to show "no result" message
    }
  }

  const fetchUserGroups = async () => {
    
    userGroupService.getByUserId(user_id)
      .then(res => {

        setUserGroups(res.user_groups);
        setLoadingUserGroups(false)
         
      })
      .catch(err => {showMessage(err, 'error')});
  }

  const fetchAllUserGroups = async () => {
    
    userGroupService.getAll()
      .then(res => {

        setUserGroupsAll(res.user_groups);
         
      })
      .catch(err => {showMessage(err, 'error')});
  }

  const editUser = async data => {
    const users = {users: (Array.isArray(data) ? data : [data])}
    
    await userService.update(users)
      .then(async res => { 

        fetchItems()
        showMessage(res.message)
        hideSideDialog()
      })
      .catch(err => {
        showMessage(err, 'error')
        throw err
      });
  }

  const editUserGroup = async data => {
    const userGroups = {user_groups: (Array.isArray(data) ? data : [data])}

    await userGroupService.update(userGroups)
      .then(async res => { 

        fetchUserGroups()
        showMessage(res.message)
        clearSelectedItems()
        hideModalDialog()
      })
      .catch(err => {
        showMessage(err, 'error')
        throw err
      })
  }

  const editDataset = async data => {
    const items = {datasets: (Array.isArray(data) ? data : [data])}
    
    await datasetService.update(items)
      .then(async res => { 

        fetchOwnedItems(Asset.Dataset)
        showMessage(res.message)
        clearSelectedItems()
        hideSideDialog()
      })
      .catch(err => {
        showMessage(err, 'error')
        throw err
      });
  }

  const editDatasetGroup = async data => {
    const items = {dataset_groups: (Array.isArray(data) ? data : [data])}
    
    await datasetGroupService.update(items)
      .then(async res => { 

        fetchOwnedItems(Asset.DatasetGroup)
        showMessage(res.message)
        clearSelectedItems()
        hideSideDialog()
      })
      .catch(err => {
        showMessage(err, 'error')
        throw err
      });
  }

  const editDatasource = async data => {
    const items = {datasources: (Array.isArray(data) ? data : [data])}
    
    await datasourceService.update(items)
      .then(async res => { 

        fetchOwnedItems(Asset.Datasource)
        showMessage(res.message)
        clearSelectedItems()
        hideSideDialog()
      })
      .catch(err => {
        showMessage(err, 'error')
        throw err
      });
  }

  const editSystem = async data => {
    const items = {systems: (Array.isArray(data) ? data : [data])}
    
    await systemService.update(items)
      .then(async res => { 

        fetchOwnedItems(Asset.System)
        showMessage(res.message)
        clearSelectedItems()
        hideSideDialog()
      })
      .catch(err => {
        showMessage(err, 'error')
        throw err
      });
  }

  const editBusinessTerm = async data => {
    const items = {business_terms: (Array.isArray(data) ? data : [data])}
    
    await businessTermService.update(items)
      .then(async res => { 

        fetchOwnedItems(Asset.BusinessTerm)
        showMessage(res.message)
        clearSelectedItems()
        hideSideDialog()
      })
      .catch(err => {
        showMessage(err, 'error')
        throw err
      });
  }

  const editGlossary = async data => {
    const items = {glossaries: (Array.isArray(data) ? data : [data])}
    
    await glossaryService.update(items)
      .then(async res => { 

        fetchOwnedItems(Asset.Glossary)
        showMessage(res.message)
        clearSelectedItems()
        hideSideDialog()
      })
      .catch(err => {
        showMessage(err, 'error')
        throw err
      });
  }

  const editFieldDescription = async items => {
    await fieldDescriptionService.update(items)
      .then(async res => { 
        fetchOwnedItems(Asset.FieldDescription)
        showMessage(res.message)
        clearSelectedItems()
        hideSideDialog()
      })
      .catch(err => {
        showMessage(err, 'error')
        throw err
      });
  }

  const deleteBusinessTerm = async data => {    
    await businessTermService.destroy({business_terms: data})
      .then(async res => { 
        showMessage(res.message)
        hideModalDialog()
        clearSelectedItems()
        fetchOwnedItems(Asset.BusinessTerm)
      })
      .catch(err => {
        showMessage(err, 'error')
      });
  }

  const deleteDataset = async data => {    
    await datasetService.destroy({datasets: data})
      .then(async res => { 
        showMessage(res.message)
        hideModalDialog()
        clearSelectedItems()
        fetchOwnedItems(Asset.Dataset)
      })
      .catch(err => {
        showMessage(err, 'error')
      });
  }

  const deleteDatasetGroup = async data => {    
    await datasetGroupService.destroy({dataset_groups: data})
      .then(async res => { 
        showMessage(res.message)
        hideModalDialog()
        clearSelectedItems()
        fetchOwnedItems(Asset.DatasetGroup)
      })
      .catch(err => {
        showMessage(err, 'error')
      });
  }

  const deleteDatasource = async data => {    
    await datasourceService.destroy({datasources: data})
      .then(async res => { 
        showMessage(res.message)
        hideModalDialog()
        clearSelectedItems()
        fetchOwnedItems(Asset.Datasource)
      })
      .catch(err => {
        showMessage(err, 'error')
      });
  }

  const deleteFieldDescription = async data => {    
    await fieldDescriptionService.destroy({field_descriptions: data})
      .then(async res => { 
        showMessage(res.message)
        hideModalDialog()
        clearSelectedItems()
        fetchOwnedItems(Asset.FieldDescription)
      })
      .catch(err => {
        showMessage(err, 'error')
      });
  }

  const deleteGlossary = async data => {  
    await glossaryService.destroy({glossaries: data})
      .then(async res => { 
        showMessage(res.message)
        hideModalDialog()
        clearSelectedItems()
        fetchOwnedItems(Asset.Glossary)
      })
      .catch(err => {
        showMessage(err, 'error')
      });
  }

  const deleteSystem = async data => {    
    await systemService.destroy({systems: data})
      .then(async res => { 
        showMessage(res.message)
        hideModalDialog()
        clearSelectedItems()
        fetchOwnedItems(Asset.System)
      })
      .catch(err => {
        showMessage(err, 'error')
      });
  }

  const deleteUser = async data => {    
    await userService.destroy({users: data})
      .then(async res => { 

        showMessage(res.message)
        hideModalDialog()

        // Redirect to parent page
        navigate('/users')

      })
      .catch(err => {
        showMessage(err, 'error')
      });
  }

  const updateMember = async event => {
    let { value } = ""

    value = event.value
    const userId = parseInt(user_id)

    const existingUser = userGroups.find(x => x.user_group_id === value)
    const user_group_members = [{ user_id: userId, user_group_id: value, user_group_member_id: existingUser?.user_group_member_id }]

    if (existingUser) {
      await removeMember(user_group_members)
    } else {
      await addMember(user_group_members)
    }

  }
  const addMember = async data => {
    await userGroupMemberService.create({user_group_members: data})
      .then(async res => { 
        
        fetchUserGroups()
        showMessage(res.message)

      })
      .catch(err => {
        showMessage(err, 'error')
      })
  }

  const removeMember = async (data) => {    

    await userGroupMemberService.destroy({user_group_members: data})
      .then(async res => { 

        hideModalDialog()
        clearSelectedItems()
        fetchUserGroups()
        showMessage(res.message)

      })
      .catch(err => {
        showMessage(err, 'error')
      })
  }

  const morphButtonToggle = () => {
    setMorphButtonState(!morphButtonState)
    clearSelectedItems()
  }

  const requestResults = userGroupsAll.map(result => {

    return {
            value: result.user_group_id, 
            label: result.user_group_name,  
            tooltip: result.user_group_name
          }
  })

  const morphButton = () => {
    return (
      <div className={ "morph-button-wrapper " + ((morphButtonState) ? "show" : "") }> 
        <div className="morph-button-content">
          <form className="form" onSubmit={e => e.preventDefault() }>
            <Select 
              name="user_id"
              value={ userGroups.map(x => {return {value: x.user_group_id, label: x.user_group_name}}) }
              options={ requestResults }
              placeholder="Search User Groups..."
              isMulti={true}
              isClearable={true}
              onChange={ updateMember } 
              onBlur={ morphButtonToggle }
              setFocus={ morphButtonState }
            />
        
          </form>
        </div>
        <button type="button" className="morph-button-toggle main-toolbar-item button main-button" onClick={ morphButtonToggle }><span>ADD +</span></button>
      </div>
      )
  }

  const onObjectChange = (object) => {
    resetParams({'object': object})
    ownedItems.length > 0 && fetchOwnedItems(object)
    setDefaultSelectedObject({tab: object})
    clearSelectedItems()
  }

  const onTabChange = (tab) => {
    clearSelectedItems()
    resetParams({'tab': tab, object: defaultSelectedObject.tab})
    !userGroups && fetchUserGroups()
    tab === 'owned_assets' && fetchOwnedItems(defaultSelectedObject.tab)
  }

  const data = loading ? [] : user

  const editableAdmin = loggedInUser && loggedInUser.user_role_name === Role.admin
  const editable = editableAdmin
                            
  const userGroupTypeValues = Object.entries(authMethod).reduce((a, [key, value]) => ({ ...a, [key]: {label: value}}), {}) 
  
  if (notFound) {
    return <NotFound />
  }

  return (
    <div className="columns narrow-margin">
      <MainColumn>
        <ObjectDetails
          type="USER"
          title={data.user_fullname}
          secondaryTitle={`${(data.user_title ? data.user_title : '')}${(data.user_department ? '\n' + data.user_department : '')}`}
          subtitleText={ data && !loading &&
            data.user_username
          }
          details={[
            {title: 'Created', data: formatData(data.created_timestamp, 'datetime')}
          ]}
          showDescription={false}
          photo={data.user_photo}
          loading={loading}
          >

            <h3>{ !loading ? "Email" : <SkeletonLoader width="5vw"/>}</h3>
            <div className="display-linebreak">
            { !loading 
              ? (data.user_email === null) ? "-" : <a className="link" href={"mailto:"+data.user_email}>{data.user_email}</a>
              : <SkeletonLoader width="10vw" />
            }
            </div>
            { editable &&
              <React.Fragment>

                <h3>{ !loading ? "Role" : <SkeletonLoader width="5vw"/>}</h3>
                <div className="display-linebreak">
                { !loading 
                  ? (data.user_role_name === null) ? "-" : data.user_role_name
                  : <SkeletonLoader width="10vw" />
                }
                </div>

                <h3>{ !loading ? "User Type" : <SkeletonLoader width="5vw"/>}</h3>
                <div className="display-linebreak">
                { !loading 
                  ? (data.user_auth_method === null) ? "-" : authMethod[data.user_auth_method]
                  : <SkeletonLoader width="10vw" />
                }
                </div>

                { data.user_auth_method !== 'local' &&
                  <React.Fragment>
                    <h3>{ !loading ? "User Source Id" : <SkeletonLoader width="5vw"/>}</h3>
                    <div className="display-linebreak">
                    { !loading 
                      ? (data.user_source_id === null) ? "-" : data.user_source_id
                      : <SkeletonLoader width="10vw" />
                    }
                    </div>   
                  </React.Fragment>    
                }

                <h3>{ !loading ? "Status" : <SkeletonLoader width="5vw"/>}</h3>
                <div className="display-linebreak">
                { !loading 
                  ? (data.user_is_disabled) 
                    ? <span className="pill pill-red">Disabled</span> 
                    : <span className="pill">Active</span>
                  : <SkeletonLoader width="10vw" />
                }
                </div>

                <h3>{ !loading ? "Last Login" : <SkeletonLoader width="5vw"/>}</h3>
                <div className="display-linebreak">
                { !loading 
                  ? (data.user_last_login_timestamp === null) ? <span className="no-result-text">Never logged in</span> : formatData(data.user_last_login_timestamp, 'datetime')
                  : <SkeletonLoader width="10vw" />
                }
                </div>

              </React.Fragment>
            }
          </ObjectDetails>
            
          { !loading && editable &&
          <div className="main-toolbar">
            <button type="button" className="main-toolbar-item main-button button" onClick={ () => showSideDialog('editUser', [data], editUser) }><span>EDIT</span></button>
            {loggedInUser && data.user_id !== loggedInUser.user_id &&
            <button type="button" className="main-toolbar-item button" onClick={ () => showModalDialog('deleteUser', [data], deleteUser) }><span>DELETE</span></button>
            }
          </div>
          }

      </MainColumn>
      
      <div className="column">
      
        <Tabs className="slim left" 
              disableTabsWithoutResults={false}
              showNoResultsMessage={true}
              onTabChange={ onTabChange } 
              defaultSelectedTab={defaultSelectedTab}>

          <div label="Owned Assets" tabid='owned_assets' resultCount={ownedItemsTotalCount}>

          { ownedItemsTotalCount > 0 ?
            <Tabs className="buttons left" 
                  onTabChange={ onObjectChange } 
                  showResultCount={false} 
                  disableTabsWithoutResults={true}
                  defaultSelectedTab={defaultSelectedObject}
                  >

              <div label="Field Descriptions" tabid={Asset.FieldDescription} resultCount={ (ownedItemsCount && ownedItemsCount.field_description) || 0 }>
                <DataTable
                  columns={[
                    {id: 'field_role_name', name: '', type: 'icon', tooltip: 'field_role_name'},
                    {id: 'field_description_name', name: 'Name', link: '/browse_field_descriptions/:field_description_id', tooltip: 'field_description_description', className:"bold"},
                    {id: 'field_unit_code', name: 'Field Unit', tooltip: 'field_unit_name'},
                    {id: 'field_description_description', name: 'Description', type: 'description', viewMode: 'descriptions'},
                    {id: 'modified_timestamp', name: 'Modified', type:'datetime', viewMode: 'statistics'}   
                  ]}
                  buttons={[ 
                    {label: "Edit", action: "edit", onClick: (items) => showSideDialog('editFieldDescription', items || selectedItems, editFieldDescription) },
                    {label: "Delete", action: "delete", onClick: (items) => showModalDialog('deleteFieldDescription', items || selectedItems, deleteFieldDescription) }
                  ]}
                  data={ownedItems}
                  idColumn='field_description_id'
                  selectedItems={selectedItems}
                  setSelectedItems={setSelectedItems}
                  loading={loadingTable}
                  editable={editable}
                  filterable={true}
                  filterObjects={['field_description_name', 'field_description_description', 'field_role_name', 'field_unit_name']}
                  showViewModes={true}
                />
              </div>
              
              <div label="Business Terms" tabid={Asset.BusinessTerm} resultCount={ (ownedItemsCount && ownedItemsCount.business_term) || 0 }>
                <DataTable
                  columns={[
                    {id: 'business_term_name', name: 'Name', link: '/browse_business_terms/:glossary_id/:business_term_id', tooltip: 'business_term_description', className:"bold"},
                    {id: 'business_term_description', name: 'Description', type: 'description', viewMode: 'descriptions'},
                    {id: 'glossary_name', name: 'Glossary', link: '/browse_business_terms/:glossary_id'},
                    {id: 'modified_timestamp', name: 'Modified', type:'datetime', viewMode: 'statistics'}      
                  ]}
                  buttons={[ 
                    {label: "Edit", action: "edit", onClick: (items) => showSideDialog('editBusinessTerm', items || selectedItems, editBusinessTerm) },
                    {label: "Delete", action: "delete", onClick: (items) => showModalDialog('deleteBusinessTerm', items || selectedItems, deleteBusinessTerm) }
                  ]}
                  data={ownedItems}
                  idColumn='business_term_id'
                  selectedItems={selectedItems}
                  setSelectedItems={setSelectedItems}
                  loading={loadingTable}
                  editable={editable}
                  filterable={true}
                  filterObjects={['business_term_name', 'business_term_description', 'glossary_name']}
                  showViewModes={true}
                />
              </div>

              <div label="Glossaries" tabid={Asset.Glossary} resultCount={ (ownedItemsCount && ownedItemsCount.glossary) || 0 }>
                <DataTable
                  columns={[
                    {id: 'glossary_name', name: 'Name', link: '/browse_business_terms/:glossary_id', tooltip: 'glossary_description', className:"bold"},
                    {id: 'glossary_description', name: 'Description', type: 'description', viewMode: 'descriptions'},
                    {id: 'modified_timestamp', name: 'Modified', type:'datetime', viewMode: 'statistics'}   
                  ]}
                  buttons={[ 
                    {label: "Edit", action: "edit", onClick: (items) => showSideDialog('editGlossary', items || selectedItems, editGlossary) },
                    {label: "Delete", action: "delete", onClick: (items) => showModalDialog('deleteGlossary', items || selectedItems, deleteGlossary) }
                  ]}
                  data={ownedItems}
                  idColumn='glossary_id'
                  selectedItems={selectedItems}
                  setSelectedItems={setSelectedItems}
                  loading={loadingTable}
                  editable={editableAdmin}
                  filterable={true}
                  filterObjects={['glossary_name', 'glossary_description']}
                  showViewModes={true}
                />
              </div>

              <div label="Datasets" tabid={Asset.Dataset} resultCount={ (ownedItemsCount && ownedItemsCount.dataset) || 0 }>

                <DataTable
                  columns={[
                    {id: 'dataset_type_name', name: '', type: 'icon', tooltip: 'dataset_type_name'},
                    {id: 'dataset_name', name: 'Name', link: '/browse_datasets/:system_id/:datasource_id/:dataset_group_id/:dataset_id', tooltip: 'dataset_description', className:"bold"},
                    {id: 'dataset_description', name: 'Description', type: 'description', viewMode: 'descriptions'},
                    {id: 'dataset_group_name', name: 'Dataset Group', link: '/browse_datasets/:system_id/:datasource_id/:dataset_group_id' },
                    {id: 'datasource_name', name: 'Datasource', link: '/browse_datasets/:system_id/:datasource_id', viewMode: 'statistics'},
                    {id: 'system_name', name: 'System', link: '/browse_datasets/:system_id', viewMode: 'statistics'},
                  ]}
                  buttons={[ 
                    {label: "Edit", action: "edit", onClick: (items) => showSideDialog('editDataset', items || selectedItems, editDataset) },
                    {label: "Delete", action: "delete", onClick: (items) => showModalDialog('deleteDataset', items || selectedItems, deleteDataset) }
                  ]}
                  data={ownedItems}
                  idColumn='dataset_id'
                  selectedItems={selectedItems}
                  setSelectedItems={setSelectedItems}
                  loading={loadingTable}
                  editable={editable}
                  filterable={true}
                  filterObjects={['dataset_name', 'dataset_description', 'dataset_type_name', 'dataset_group_name', 'datasource_name', 'system_name']}
                  showViewModes={true}
                />
              </div>

              <div label="Dataset Groups" tabid={Asset.DatasetGroup} resultCount={ (ownedItemsCount && ownedItemsCount.dataset_group) || 0 }>
                <DataTable
                  columns={[
                    {id: 'dataset_group_name', name: 'Name', link: '/browse_datasets/:system_id/:datasource_id/:dataset_group_id', tooltip: 'dataset_group_description', className:"bold"},
                    {id: 'dataset_description', name: 'Description', type: 'description', viewMode: 'descriptions'},
                    {id: 'datasource_name', name: 'Datasource', link: '/browse_datasets/:system_id/:datasource_id'},
                    {id: 'system_name', name: 'System', link: '/browse_datasets/:system_id', viewMode: 'statistics'}   
                  ]}
                  buttons={[ 
                    {label: "Edit", action: "edit", onClick: (items) => showSideDialog('editDatasetGroup', items || selectedItems, editDatasetGroup) },
                    {label: "Delete", action: "delete", onClick: (items) => showModalDialog('deleteDatasetGroup', items || selectedItems, deleteDatasetGroup) }
                  ]}
                  data={ownedItems}
                  idColumn='dataset_group_id'
                  selectedItems={selectedItems}
                  setSelectedItems={setSelectedItems}
                  loading={loadingTable}
                  editable={editable}
                  filterable={true}
                  filterObjects={['dataset_group_name', 'dataset_description', 'datasource_name', 'system_name']}
                  showViewModes={true}
                />
              </div>

              <div label="Datasources" tabid={Asset.Datasource} resultCount={ (ownedItemsCount && ownedItemsCount.datasource) || 0 }>
                <DataTable
                  columns={[
                    {id: 'datasource_type_category', name: '', type: 'icon', tooltip: 'datasource_type_name'},
                    {id: 'datasource_name', name: 'Name', link: '/browse_datasets/:system_id/:datasource_id', tooltip: 'datasource_description', className:"bold"},
                    {id: 'datasource_description', name: 'Description', type: 'description', viewMode: 'descriptions'},
                    {id: 'system_name', name: 'System', link: '/browse_datasets/:system_id'}   
                  ]}
                  buttons={[ 
                    {label: "Edit", action: "edit", onClick: (items) => showSideDialog('editDatasource', items || selectedItems, editDatasource) },
                    {label: "Delete", action: "delete", onClick: (items) => showModalDialog('deleteDatasource', items || selectedItems, deleteDatasource) }
                  ]}
                  data={ownedItems}
                  idColumn='datasource_id'
                  selectedItems={selectedItems}
                  setSelectedItems={setSelectedItems}
                  loading={loadingTable}
                  editable={editableAdmin}
                  filterable={true}
                  filterObjects={['datasource_name', 'datasource_description', 'datasource_type_category', 'datasource_type_name', 'system_name']}
                  showViewModes={true}
                />
              </div>

              <div label="Systems" tabid={Asset.System} resultCount={ (ownedItemsCount && ownedItemsCount.system) || 0 }>
                <DataTable
                  columns={[
                    {id: 'system_name', name: 'Name', link: '/browse_datasets/:system_id', tooltip: 'system_description', className:"bold"}, 
                    {id: 'system_description', name: 'Description', type: 'description', viewMode: 'descriptions'} 
                  ]}
                  buttons={[ 
                    {label: "Edit", action: "edit", onClick: (items) => showSideDialog('editSystem', items || selectedItems, editSystem) },
                    {label: "Delete", action: "delete", onClick: (items) => showModalDialog('deleteSystem', items || selectedItems, deleteSystem) }
                  ]}
                  data={ownedItems}
                  idColumn='system_id'
                  selectedItems={selectedItems}
                  setSelectedItems={setSelectedItems}
                  loading={loadingTable}
                  editable={editableAdmin}
                  filterable={true}
                  filterObjects={['system_name', 'system_description']}
                  showViewModes={true}
                />
              </div>

            </Tabs>  
          : !loadingTable &&
              <div className="no-result-text">No owned assets</div>           
        }

        </div>
        { editableAdmin &&
          <div label="User Groups" tabid='user_groups' resultCount={loadingUserGroups ? undefined : userGroups.length}>

           <DataTable
            columns={[
              {id: 'user_group_photo', name: '', type: 'group-photo'},
              {id: 'user_group_name', name: 'Name', link: '/user_groups/:user_group_id', className:"bold"},
              {id: 'user_group_default_role_name', name: 'Default Role'},  
              {id: 'user_group_type', name: 'Type', value:'user_group_type', values: userGroupTypeValues},
              ]}
            buttons={[ 
              {customButton: morphButton(), action: 'add', onClick: () => {} },
              {label: "Edit", action: "edit", onClick: (items) => showSideDialog('editUserGroup', items || selectedItems, editUserGroup) },
              {label: "Remove", action: "remove", tooltip: "Remove user from this user group. The User itself will not be deleted.", onClick: (items) => showModalDialog('removeUserGroup', items || selectedItems, removeMember) }
            ]}
            data={userGroups}
            title="User Groups"
            editable={editable}
            idColumn='user_group_id'
            selectedItems={selectedItems}
            setSelectedItems={setSelectedItems}
            loading={loadingUserGroups}
            filterable={true}
            filterObjects={['user_group_name', 'user_group_default_role_name']}
          />

          </div>
        }
        { editableAdmin &&
          <div label="Changelog" tabid="changelog">
            <Changelog 
              objectName={Asset.User} 
              objectId={user_id}  
              filterObjects={[
                'changedData.new_data||old_data.user_fullname', 
                'changedData.new_data||old_data.user_username', 
                'changedData.new_data||old_data.user_email', 
                'changedData.new_data||old_data.user_role_name', 
                'changedData.attribute',
                'changed_by_user_username',
                'is_service_account_user'
              ]}
              filterObjectsValueMap={{is_service_account_user: {true: {label:'Datasource Sync'}, false: {label:''}}}}
            />
          </div>
        }

      </Tabs>
        
      </div>
    </div>
  ) 
}
