import React, { useState, useEffect } from 'react';
import { fieldUnitService } from '../services';
import { DataTable } from '../components';
import { useSideDialog, useModalDialog, useGlobalState, useMessage } from '../hooks';
import { Role } from '../helpers';

export const FieldUnits = () => {

  const [fieldUnits, setFieldUnits] = useState([]);
  const [loggedInUser, setLoggedInUser] = useGlobalState('loggedInUser');
  const [selectedItems, setSelectedItems] = useState([]);
  const [loading, setLoading] = useState(true);

  const clearSelectedItems = () => {
    setSelectedItems([])
  }

  const { showMessage } = useMessage()
  const { showSideDialog, hideSideDialog } = useSideDialog(clearSelectedItems)
  const { showModalDialog, hideModalDialog } = useModalDialog()
  
  useEffect(() => {
    fetchItems();

  }, []);  

  const fetchItems = async () => {
    fieldUnitService.getAll()
      .then(res => {
        setFieldUnits(res.field_units);
        setLoading(false) 
      })
      .catch(err => {
        setLoading(false) 
        showMessage(err, 'error')
      });
  }

  const addFieldUnit = async data => {
    const fieldUnits = {field_units: [data]}
    
    await fieldUnitService.create(fieldUnits)
      .then(res => {

        fetchItems()
        showMessage(res.message)
      })
      .catch(err => {
        showMessage(err, 'error')
        throw err
      });
  }

  const editFieldUnit = async data => {
    const fieldUnits = {field_units: (Array.isArray(data) ? data : [data])}
    
    await fieldUnitService.update(fieldUnits)
      .then(async res => { 

        fetchItems()
        showMessage(res.message)
        clearSelectedItems()
        hideSideDialog()
      })
      .catch(err => {
        showMessage(err, 'error')
        throw err
      });
  }

  const deleteFieldUnit = async data => {    
    await fieldUnitService.destroy({ field_units: data })
      .then(async res => { 

        hideModalDialog()
        clearSelectedItems()
        fetchItems()
        showMessage(res.message)

      })
      .catch(err => {
        showMessage(err, 'error')
      });
  }

  const editable = loggedInUser && loggedInUser.user_role_name === Role.admin  

  return (
    <div className="FieldUnits wide-margin">

        <DataTable
          columns={[
            {id: 'field_unit_code', name: 'Code'},
            {id: 'field_unit_name', name: 'Name'}
          ]}
          buttons={[ 
            {label: "Add +", action: "add", mainButton: true, onClick: (items) => showSideDialog('addFieldUnit', items || selectedItems, addFieldUnit) },
            {label: "Edit", action: "edit", onClick: (items) => showSideDialog('editFieldUnit', items || selectedItems, editFieldUnit) },
            {label: "Delete", action: "delete", onClick: (items) => showModalDialog('deleteFieldUnit', items || selectedItems, deleteFieldUnit) }
          ]}
          title="Field Units"
          data={fieldUnits}
          idColumn='field_unit_id'
          selectedItems={selectedItems}
          setSelectedItems={setSelectedItems}
          loading={loading}
          filterable={true}
          filterObjects={['field_unit_code', 'field_unit_name']}
        />

      </div>
  );
}