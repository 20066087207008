import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom'
import { Asset } from '../helpers';
import { Icon, SearchResult, Tabs } from '../components';
import { useQueryParams, useSearch } from '../hooks';


export const Home = () => {

  const { queryParams, setHistory } = useQueryParams()

  const [showSearchTip, setShowSearchTip] = useState(false);
  const [showAllSearchTips, setShowAllSearchTips] = useState(false);
  const [searchTipIndex, setSearchTipIndex] = useState();

  const [loading, setLoading] = useState(false);
  const [activeObject, setActiveObject] = useState(queryParams.object);

  const refTabContent = useRef()
  const refShowAllSearchTipsButton = useRef()
  const refSearchInput = useRef()
  const refClearSearchButton = useRef()


  const { 
    searchTerm, 
    searchResults, 
    searchResultsCount, 
    setSearchTerm, 
    fetchSearchResults, 
    clearSearchResults, 
    loadNextPage 
  } = useSearch({ 
      setLoading, 
      objectName: activeObject
    })

  

  useEffect(() => {

    if (searchTerm !== "") {
      fetchSearchResults(undefined, false, 1)   
    } 

  }, [activeObject]);
  
  const handleInputChange = async event => {
    let { value } = event.target

    setSearchTerm(value)

    if (value.length > 1) {
      await fetchSearchResults(value, false, 1)     

      setShowSearchTip(false)
    } else {
      setShowSearchTip(true)
    }
    
  }

  const clearSearch = () => {  
    refSearchInput.current.focus()
    setShowSearchTip(true) 
    clearSearchResults() 
  }

  const onSubmit = event => {

    event.preventDefault()

    // Form validation
    // ... 
        
  }

  const onObjectChange = object => {
    setActiveObject(object)
    setHistory({object: object, filter:"", sort: undefined})
  }

  const handleFocus = () => {
    if (!(searchTerm && searchTerm.length > 1)) {
      setShowSearchTip(true)
    }
    !searchTipIndex && setSearchTipIndex(Math.floor(Math.random() * (searchTipList.length - 1 + 1)) + 1)
  }

  const toggleShowAllSearchTips = () => {
    setShowAllSearchTips(!showAllSearchTips)
    refSearchInput.current.focus()
  }

  const handleBlur = (el) => {
    const activeElement = el.relatedTarget
    if (activeElement === null || 
        !(activeElement === refShowAllSearchTipsButton.current  ||
          activeElement === refClearSearchButton.current
        )) {
      setShowSearchTip(false)
      setShowAllSearchTips(false)
      setSearchTipIndex()
    }
  }

  const loadMore = () => {
    loadNextPage()
  }

  const searchTipList = [
    { 
      title: 'Default Search',
      tip: 'The default behaviour is wildcard match with "and"-logic',
      example: `customer name\nThis searches for data assets containing words that begin with "customer" and "name"`
    },
    { 
      title: 'Context Search',
      tip: 'Use a colon to limit your search to a certain context',
      example: `customer : crm\nThis searches for data assets matching "customer" in systems, datasets etc that match "crm"`
    },
    {
      title: 'Exact Name Search',
      tip: 'Use a space at the end to find assets with exact names',
      example: `customer name (space at the end)\nThis searches for data assets with a name that exactly matches "customer name"`
    },
    {
      title: 'Phrase Search',
      tip: 'Use quotation marks to search for exact phrase matches',
      example: `"customer name"\nThis searches for data assets matching exactly the word "customer" directly followed by "name"`
    },
    {
      title: 'Or Logic',
      tip: 'Use a pipe to match assets with "or" logic',
      example: `customer | name\nThis searches for data assets containing words that begin with either "customer" or "name"`
    },
    {
      title: 'Exclude Words',
      tip: 'Use an exclamation mark to exclude words',
      example: `customer ! name\nThis searches for data assets matching "customer" and that does not include the word "name"`
    }
  ]

  let field_descriptions = []
  let fields = []
  let datasets = []
  let dataset_groups = []
  let datasources = []
  let systems = []
  let business_terms = []
  let users = []

  if (searchResults) {
    fields = searchResults.filter(x => x.object_name === "field")
    datasets = searchResults.filter(x => x.object_name === "dataset")
    dataset_groups = searchResults.filter(x => x.object_name === "dataset_group")
    datasources = searchResults.filter(x => x.object_name === "datasource")
    systems = searchResults.filter(x => x.object_name === "system")
    business_terms = searchResults.filter(x => x.object_name === "business_term")
    field_descriptions = searchResults.filter(x => x.object_name === "field_description")
    users = searchResults.filter(x => x.object_name === "user")
  }

  return (
    <div className={"Home" + ((searchResults && searchTerm.length > 1) ? "" : " dark-background")}>
      <div className="search-container">
        <div className={"search-header"}>
        
          <form onSubmit={onSubmit} autoComplete="off">
            <div className="search-input">
              <input
                type="text"
                name="search"
                ref={refSearchInput}
                placeholder="Search Data Assets..."
                value={searchTerm}
                onChange={handleInputChange}
                onFocus={handleFocus}
                onBlur={handleBlur}
              />
              <div className={"button-clear-search" + ((searchTerm !== "") ? " show" : "")} ref={refClearSearchButton} tabIndex={0} onClick={clearSearch} title="Clear search input">
                <div className="button-clear-search-icon"></div>
              </div>
            </div>
          </form>
        
        </div>

        { searchTerm.length < 2 && 
          <div className={"search-tip" + (showSearchTip ? " show" : "") + (showAllSearchTips ? " show-all" : "")}>
            <div className="search-tip-icon">  
              <Icon name="tip" />
            </div>
            <div className="search-tip-list">
              <div className="search-tip-text">
                <p className="search-tip-text-description">{ searchTipIndex && searchTipList[searchTipIndex-1].tip }</p>  
                <p className="search-tip-text-example">Example: { searchTipIndex && searchTipList[searchTipIndex-1].example }</p> 
              </div>
              <div 
                className="search-tip-show-all-button" 
                ref={refShowAllSearchTipsButton}
                tabIndex={0}
                onClick={toggleShowAllSearchTips}
              >
                {showAllSearchTips ? "Hide" : "Show"} all tips
              </div>
            </div>
          </div>
        }

        { searchResults && searchTerm.length > 1 &&
  
            <Tabs 
              onTabChange={onObjectChange} 
              showResultCount={true} 
              showNoResultsMessage={false}
              className='vw-60'
              disableTabsWithoutResults={true}
              defaultSelectedTab={{tab: activeObject}}
              ref={refTabContent}
              loading={loading}
            >
              <div label="All" tabid='all' resultCount={ (searchResultsCount && searchResultsCount.all) || 0}>
                <SearchResult 
                  data={searchResults} 
                  loadMore={loadMore} 
                  containerElement={refTabContent.current}
                  resultCount={ searchResultsCount && searchResultsCount.all} 
                  loading={loading}
                  searchTerm={searchTerm} 
                />
              </div>
              <div label="Business Terms" tabid={Asset.BusinessTerm} resultCount={ (searchResultsCount && searchResultsCount.business_term) || 0}>
                <SearchResult 
                  data={business_terms}
                  loadMore={loadMore} 
                  containerElement={refTabContent.current}
                  resultCount={ searchResultsCount && searchResultsCount.business_term}
                  loading={loading} 
                  searchTerm={searchTerm}
                />
              </div>
              <div label="Field Descriptions" tabid={Asset.FieldDescription} resultCount={ (searchResultsCount && searchResultsCount.field_description) || 0}>
                <SearchResult 
                  data={field_descriptions}
                  loadMore={loadMore}
                  containerElement={refTabContent.current}
                  resultCount={ searchResultsCount && searchResultsCount.field_description}
                  loading={loading} 
                  searchTerm={searchTerm}
                />
              </div>
              <div label="Datasets" tabid={Asset.Dataset} resultCount={ (searchResultsCount && searchResultsCount.dataset) || 0}>
                <SearchResult 
                  data={datasets}
                  loadMore={loadMore} 
                  containerElement={refTabContent.current}
                  resultCount={ (searchResultsCount && searchResultsCount.dataset)  || 0}
                  loading={loading} 
                  searchTerm={searchTerm}
                />
              </div>

              <div label="Dataset Groups" tabid={Asset.DatasetGroup} resultCount={ (searchResultsCount && searchResultsCount.dataset_group) || 0}>
                <SearchResult 
                  data={dataset_groups}
                  loadMore={loadMore} 
                  containerElement={refTabContent.current}
                  resultCount={ (searchResultsCount && searchResultsCount.dataset_group)  || 0} 
                  loading={loading}
                  searchTerm={searchTerm}
                />
              </div>
              <div label="Datasources" tabid={Asset.Datasource} resultCount={ (searchResultsCount && searchResultsCount.datasource) || 0}>
                <SearchResult 
                  data={datasources}
                  loadMore={loadMore} 
                  containerElement={refTabContent.current}
                  resultCount={ searchResultsCount && searchResultsCount.datasource} 
                  loading={loading}
                  searchTerm={searchTerm}
                />
              </div>
              <div label="Systems" tabid={Asset.System} resultCount={ (searchResultsCount && searchResultsCount.system) || 0}>
                <SearchResult 
                  data={systems}
                  loadMore={loadMore} 
                  containerElement={refTabContent.current}
                  resultCount={ searchResultsCount && searchResultsCount.system}
                  loading={loading} 
                  searchTerm={searchTerm}
                />
              </div>
              <div label="Fields" tabid={Asset.Field} resultCount={ (searchResultsCount && searchResultsCount.field) || 0}>
                <SearchResult 
                  data={fields}
                  loadMore={loadMore} 
                  containerElement={refTabContent.current}
                  resultCount={ searchResultsCount && searchResultsCount.field}
                  loading={loading} 
                  searchTerm={searchTerm}
                />
              </div>
              <div label="Users" tabid={Asset.User} resultCount={ (searchResultsCount && searchResultsCount.user) || 0}>
                <SearchResult 
                  data={users}
                  loadMore={loadMore}
                  containerElement={refTabContent.current}
                  resultCount={ searchResultsCount && searchResultsCount.user} 
                  loading={loading}
                  searchTerm={searchTerm}
                />
              </div>

            </Tabs>
        }

        { searchTerm.length < 2 && !showAllSearchTips &&
          <React.Fragment>

            <div className="large-nav-links">

              <div className="large-nav-links-title">BROWSE DATA ASSETS</div>

              <div className="large-nav-link-container">

                <Link to="/browse_datasets" className="large-nav-link">
                  <div className="large-nav-link-icon">
                    <Icon name="datasets"/>
                  </div>
                  <div className="large-nav-link-title">
                    Datasets
                  </div>
                  <div className="large-nav-link-description">
                    Containers with actual data, like tables, views and spreadsheets
                  </div>
                </Link>

                <Link to="/browse_field_descriptions" className="large-nav-link">
                  <div className="large-nav-link-icon">
                    <Icon name="field_descriptions"/>
                  </div>
                  <div className="large-nav-link-title">
                    Field Descriptions
                  </div>
                  <div className="large-nav-link-description">
                    Descriptions of Fields in Datasets and the link to Business Terms
                  </div>
                </Link>

                <Link to="/browse_business_terms" className="large-nav-link">
                  <div className="large-nav-link-icon">
                    <Icon name="business_terms"/>
                  </div>
                  <div className="large-nav-link-title">
                    Business Terms
                  </div>
                  <div className="large-nav-link-description">
                    Definitions of common terminology, like customer, product and revenue
                  </div>
                </Link>

              </div>
            </div>

          </React.Fragment>
        }
        { searchTerm.length < 2 && showAllSearchTips &&
          <React.Fragment>

            <div className="large-nav-links">

              <div className="large-nav-links-title">SEARCH TIPS</div>

              <div className="large-nav-link-container">

                {
                  searchTipList.map((item, index) => {
                    return (
                      <div key={index} className="search-tip-large-text">
                        <p className="search-tip-large-text-title">{ item.title }</p>
                        <p className="search-tip-large-text-description">{ item.tip }</p>  
                        <p className="search-tip-large-text-example">Example: { item.example }</p> 
                      </div>
                    )
                  })
                }

              </div>
            </div>

          </React.Fragment>
        }

        </div>
      </div>
  );
}