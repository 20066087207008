import { useState, useMemo } from 'react';
import { useQueryParams } from './';

export const useSortableData = (items, defaultSortConfig) => {

  const { queryParams, setHistory } = useQueryParams()

  const [sortConfig, setSortConfig] = useState(queryParams.sort || defaultSortConfig);

  const sortedItems = useMemo(() => {
    let sortableItems = [...items];
    if (sortConfig) {

      sortableItems.sort((a, b) => {

        // Do not sort grouped columns
        if (sortConfig.groupByColumn && a[sortConfig.groupByColumn] && b[sortConfig.groupByColumn] && (a[sortConfig.groupByColumn] == b[sortConfig.groupByColumn])) {
          return 0
        }

        if (a[sortConfig.key] === null) {
          return sortConfig.direction === 'asc' ? -1 : 1;
        }
        if (b[sortConfig.key] === null) {
          return sortConfig.direction === 'asc' ? 1 : -1;
        }
        // strings
        if (isNaN(a[sortConfig.key]) && isNaN(b[sortConfig.key])) {
          if (a[sortConfig.key]?.toLowerCase() < b[sortConfig.key]?.toLowerCase()) {
            return sortConfig.direction === 'asc' ? -1 : 1;
          }
          if (a[sortConfig.key]?.toLowerCase() > b[sortConfig.key]?.toLowerCase()) {
            return sortConfig.direction === 'asc' ? 1 : -1;
          }
        // numbers
        } else {
          if (a[sortConfig.key] < b[sortConfig.key]) {
            return sortConfig.direction === 'asc' ? -1 : 1;
          }
          if (a[sortConfig.key] > b[sortConfig.key]) {
            return sortConfig.direction === 'asc' ? 1 : -1;
          }
        }
        return 0;
      });
    }
    return sortableItems;

    }, [items, sortConfig]);

  const requestSort = (key, groupByColumn) => {
    let direction = 'asc';
    if (
      sortConfig &&
      sortConfig.key === key &&
      sortConfig.direction === 'asc'
    ) {
      direction = 'desc';
    }

    setSortConfig({ key, direction, groupByColumn });
    setHistory({'sort': { key, direction, groupByColumn }})
    
  };

  return { items: sortedItems, requestSort, sortConfig };
};