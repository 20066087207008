import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { datasourceService, datasetGroupService, referenceService } from '../services';
import { formatConnectionString, formatData, Role, Asset } from '../helpers';
import { 
  Changelog,
  DataTable, 
  Icon, 
  MainColumn, 
  ObjectDetails,  
  SkeletonLoader, 
  Tabs, 
  UserLink } from '../components';
import { 
  useSideDialog, 
  useModalDialog, 
  useGlobalState, 
  usePhotos, 
  useNotFound, 
  useMessage,
  useQueryParams } from '../hooks';

export const Datasource = () => {

  const { queryParams, resetParams } = useQueryParams()

  const [datasource, setDatasource] = useState([]);
  const [datasetGroups, setDatasetGroups] = useState([]);
  const [fromReferences, setFromReferences] = useState([]);
  const [loggedInUser, setLoggedInUser] = useGlobalState('loggedInUser');
  const [selectedItems, setSelectedItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingTable, setLoadingTable] = useState(true);
  const [defaultSelectedTab, setDefaultSelectedTab] = useState({tab: queryParams.tab})

  const clearSelectedItems = () => {
    setSelectedItems([])
  }

  const { system_id, datasource_id } = useParams()
  const navigate = useNavigate()

  const { showMessage } = useMessage()
  const { showSideDialog, hideSideDialog } = useSideDialog(clearSelectedItems)
  const { showModalDialog, hideModalDialog } = useModalDialog()

  const { fetchPhotos } = usePhotos()
  const { notFound, setNotFound, NotFound } = useNotFound()



  useEffect(() => {

    fetchItems()
    fetchDatasetGroups()
    fetchFromReferences()

  }, [datasource_id]);

  const fetchItems = async () => {

    datasourceService.getById(datasource_id)
      .then(res => {
        if (res.datasources.length < 1) {
          setNotFound(true)
        } else {
          setDatasource(res.datasources[0])
          setLoading(false)
          fetchPhotos(res.datasources[0], 'datasource_owner_user_id', setDatasource)
        }
      })
      .catch(err => {
        showMessage(err, 'error')
        setLoading(false)
      });
  }

  const fetchDatasetGroups = async () => {
    const promise = new Promise(  (resolve, reject) => {
      datasetGroupService.getByDatasourceId(datasource_id)
        .then(res => {

          setDatasetGroups(res.dataset_groups)
          setLoadingTable(false)
          fetchPhotos(res.dataset_groups, 'dataset_group_owner_user_id', setDatasetGroups)
          resolve(res.dataset_groups)
           
        })
        .catch(err => {
          showMessage(err, 'error')
          setLoadingTable(false)
        });
    })

    Promise.all([promise, fetchFieldStatistics()])
      .then( result => {
        updateFieldStatistics(result[0], result[1])
      })
      .catch(err => { showMessage(err, 'error')}) 
  }

  const fetchFromReferences = async () => {
    
    referenceService.getByFromId(Asset.Datasource, datasource_id)
      .then(res => {

        res && setFromReferences(res.references);
         
      })
      .catch(err => {showMessage(err, 'error')});
  }

  const fetchFieldStatistics = async () => {
    return new Promise(  (resolve, reject) => {
      datasetGroupService.getFieldStatistics(datasource_id)
        .then(res => {

          resolve(res.field_statistics)

        })
        .catch(err => {
          return null
        });
    })
  }

  const updateFieldStatistics = (data, statistics) => {

    let dataTmp = data
    let statistic = {}
    let tmp = null

    let maxValue = 0

    statistics.forEach( row => {
      if (row.dataset_count > maxValue) {
        maxValue = row.dataset_count
      }
    })

    data.forEach( (row, index) => {
      statistic = statistics.find(x => x.dataset_group_id === row.dataset_group_id)
      tmp = dataTmp[index]
      dataTmp[index] = {
        ...tmp, 
        dataset_count: (statistic ? statistic.dataset_count : 0), 
        field_description_ratio: (statistic ? statistic.field_description_ratio : 0),
        dataset_count_max: maxValue
      }
    })
    setDatasetGroups([...dataTmp]); 
  }

  const addDatasetGroup = async data => {
    const dataset_groups = {dataset_groups: (Array.isArray(data) ? data : [data])}
    
    await datasetGroupService.create(dataset_groups)
      .then(res => {

        fetchDatasetGroups()
        showMessage(res.message)
      })
      .catch(err => {
        showMessage(err, 'error')
        throw err
      });
  }

  const editDatasource = async data => {
    const datasources = {datasources: (Array.isArray(data) ? data : [data])}
    
    await datasourceService.update(datasources)
      .then(async res => { 

        fetchItems()
        fetchFromReferences()
        showMessage(res.message)
        hideSideDialog()
      })
      .catch(err => {
        showMessage(err, 'error')
        throw err
      });
  }

  const editDatasetGroup = async data => {
    const dataset_groups = {dataset_groups: (Array.isArray(data) ? data : [data])}
    
    await datasetGroupService.update(dataset_groups)
      .then(async res => { 

        fetchDatasetGroups()
        showMessage(res.message)
        clearSelectedItems()
        hideSideDialog()
      })
      .catch(err => {
        showMessage(err, 'error')
        throw err
      });
  }

  const deleteDatasource = async data => {    
    await datasourceService.destroy({ datasources: data })
      .then(async res => { 

        showMessage(res.message)
        hideModalDialog()

        // Redirect to parent page
        navigate(`/browse_datasets/${system_id}`)

      })
      .catch(err => {
        showMessage(err, 'error')
      });
  }

  const deleteDatasetGroup = async data => {    
    await datasetGroupService.destroy({ dataset_groups: data })
      .then(async res => { 

        hideModalDialog()
        clearSelectedItems()
        fetchDatasetGroups()
        showMessage(res.message)

      })
      .catch(err => {
        showMessage(err, 'error')
      });

  }

  const getDatasetGroupName = datasourceTypeCategory => {
    let name = "Dataset Group"
    let pluralName = "Dataset Groups"

    switch(datasourceTypeCategory) {
      case "database":
        name = "Schema"
        pluralName = "Schemas"
        break
    }
    return {name, pluralName}
  }

  const data = loading ? [] : datasource

  const editable = loggedInUser && loggedInUser.user_role_name === Role.admin

  if (notFound) {
    return <NotFound />
  }

  return (
      <div className="columns narrow-margin">
        <MainColumn>
          <ObjectDetails
            type="Datasource"
            title={data.datasource_name}
            subtitleIcon={<Icon name={data.datasource_type_category} tooltip={ data.datasource_type_name } />}
            subtitleText={data && !loading && 
              formatConnectionString(
                      data.datasource_database, 
                      data.datasource_hostname, 
                      [], 
                      data.datasource_type_code, 
                      data.datasource_type_category)
              }
            details={[
              {title: 'Last Modified', data: formatData(data.datasource_source_modified, 'datetime')},
              {title: 'Created', data: formatData(data.datasource_source_created, 'datetime')}
            ]}
            description={data.datasource_description}
            fromReferences={fromReferences}
            loading={loading}
            >
            
            { !loading && data && data.datasource_hostname &&
              <React.Fragment>
                <h3>{ !loading ? "Hostname" : <SkeletonLoader width="5vw"/> }</h3>
                <div className="display-linebreak">
                { !loading 
                  ? (data.datasource_type_code === 'snowflake' || data.datasource_type_code === 'databricks') 
                    ? <a className="link" href={"https://"+data.datasource_hostname} target="_blank">{data.datasource_hostname}</a> 
                    : <span>{data.datasource_hostname + (data.datasource_port ? ':' + data.datasource_port : '')}</span>
                  : <SkeletonLoader width="10vw" />
                }
                </div>
              </React.Fragment>
            }

              <h3>{ !loading ? "Owner" : <SkeletonLoader width="5vw"/>}</h3>
              <UserLink 
                userId={data.datasource_owner_user_id}
                userName={data.datasource_owner_user_fullname}
                userPhoto={data.user_photo}
                userTitle={data.user_title}
                userDepartment={data.user_department}
                loading={loading}
              />
            </ObjectDetails>

            { !loading && editable &&
              <div className="main-toolbar">
                <button type="button" className="main-toolbar-item button main-button" onClick={ () => showSideDialog('editDatasource', [data], editDatasource) }><span>EDIT</span></button>
            <button type="button" className="main-toolbar-item button" onClick={ () => showModalDialog('deleteDatasource', [data], deleteDatasource) }><span>DELETE</span></button>
              </div>
            }

        </MainColumn>

      <div className="column">
        <Tabs 
          className="slim left"
          //onTabChange={ onTabChange }
          defaultSelectedTab={ defaultSelectedTab }
          >
          <div label="Dataset Groups" tabid="dataset_groups" resultCount={loadingTable || datasetGroups.length === 0 ? undefined : datasetGroups.length}>

            <DataTable
              columns={[
                {id: 'dataset_group_name', name: 'Name', link: '/browse_datasets/:system_id/:datasource_id/:dataset_group_id', tooltip: 'dataset_group_description', className:"bold"},
                {id: 'dataset_group_description', name: 'Description', type: 'description', viewMode: 'descriptions'},
                {id: 'dataset_count', name: 'Datasets', type: 'chart', chartType: 'bar', data: {data:'dataset_count', max: 'dataset_count_max'}, viewMode: 'statistics'},
                {id: 'field_description_ratio', name: 'Documentation Level', type: 'chart', chartType: 'percent-bar', data: {data:'field_description_ratio'}, viewMode: 'statistics'},
                {id: 'user_photo', name: '', type: 'user-photo', link: '/users/:dataset_group_owner_user_id', tooltip: 'dataset_group_owner_user_fullname'},
                {id: 'dataset_group_owner_user_fullname', name: 'Owner', link: '/users/:dataset_group_owner_user_id'}
              ]}
              buttons={[ 
                {label: "Add +", action: "add", mainButton: true, onClick: (items) => showSideDialog('addDatasetGroup', items || selectedItems, addDatasetGroup) },
                {label: "Edit", action: "edit", onClick: (items) => showSideDialog('editDatasetGroup', items || selectedItems, editDatasetGroup) },
                {label: "Delete", action: "delete", onClick: (items) => showModalDialog('deleteDatasetGroup', items || selectedItems, deleteDatasetGroup) }
              ]}
              data={datasetGroups}
              idColumn='dataset_group_id'
              selectedItems={selectedItems}
              setSelectedItems={setSelectedItems}
              loading={loadingTable}
              editable={editable}
              filterable={true}
              filterObjects={['dataset_group_name', 'dataset_group_description', 'dataset_group_owner_user_fullname']}
              showViewModes={true}
            />
          </div>

          <div label="Changelog" tabid="changelog">
            <Changelog 
              objectName={Asset.Datasource} 
              objectId={datasource_id} 
              filterObjects={[
                'changedData.new_data||old_data.datasource_name', 
                'changedData.new_data||old_data.datasource_description', 
                'changedData.new_data||old_data.datasource_hostname', 
                'changedData.new_data||old_data.datasource_database', 
                'changedData.new_data||old_data.datasource_account', 
                'changedData.new_data||old_data.system_name', 
                'changedData.new_data||old_data.datasource_type_name', 
                'changedData.new_data||old_data.datasource_owner_user_fullname', 
                'changedData.attribute',
                'changed_by_user_username',
                'is_service_account_user'
              ]}
              filterObjectsValueMap={{is_service_account_user: {true: {label:'Datasource Sync'}, false: {label:''}}}}
            />
          </div>

        </Tabs>
      </div>

    </div>
  ) 
}
