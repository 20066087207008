import React from 'react';
import { useTooltip } from '../../../hooks'

export const FormatMenuButton = ({label, labelClassName, onClick, isActive, tooltip=""}) => {

  const {showTooltip, hideTooltip} = useTooltip()
  
  const handleClick = (e) => {
    e.preventDefault()
    onClick()
  }

  return (
    <div 
      className={"format-menu-button" +(isActive ? " active" : "") }
      onMouseEnter={ () => tooltip && showTooltip({title: tooltip})} 
      onMouseLeave={ () => tooltip && hideTooltip() }
      onClick={e => e.preventDefault()}
      onMouseDown={handleClick}
    >
    
      <span className={labelClassName}>{label}</span>
        
    </div>
  )
}
