import { userService } from '../services';

export const usePhotos = (objectName='user') => {

  const fetchPhotos = async (data, key, setData) => {
    try {
      if (!data) {
        return
      }
      let uniqueUserIds
      if (Array.isArray(data)) { 
        uniqueUserIds = [...new Set(data.map(x => x[key]))] 
      } else {
        uniqueUserIds = [data[key]]
      }
    
      const {photos} = await userService.getPhotos(uniqueUserIds)

      setData && setData(prev => {
        
        if (Array.isArray(prev)) {
          return prev.map(x => {
            const item = x[key] === null ? undefined : photos[x[key].toString()]
            return {
              ...x, 
              user_photo: item?.user_photo,
              user_title: item?.user_title,
              user_department: item?.user_department, 
            }
          })
        } else {
          const item = photos[prev[key].toString()]
          return {
            ...prev, 
            user_photo: item.user_photo,
            user_title: item.user_title,
            user_department: item.user_department
          }
        }
      })

      return photos

    } catch (e) {
      throw e
    }
  }

  return { fetchPhotos }
}