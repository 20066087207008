import { useState } from 'react';
import { useMessage } from './';
import { changelogService } from '../services';
import { handleError } from '../helpers';

export const useChangelog = ({ objectName, objectId, jobId }) => {

  const { showMessage } = useMessage()

  const [changelog, setChangelog] = useState();
  const [loadingChangelog, setLoadingChangelog] = useState(true);

  const fetchChangelog = async (object_name, object_id, schemaChangesOnly) => {
    try {
      setLoadingChangelog(true)
      const res = await changelogService.getById(object_name || objectName, object_id || objectId, schemaChangesOnly)

      setChangelog(res.changelog)
    } catch(e) {
      handleError({e, showMessage}, () => {})
    } finally {
      setLoadingChangelog(false)
    }
  }

  const fetchChangelogByJobId = async (job_id, schemaChangesOnly) => {
    try {
      setLoadingChangelog(true)
      const res = await changelogService.getByJobId(job_id || jobId, schemaChangesOnly)

      let datasets = {}

      res.changelog.field && res.changelog.field.forEach(field => {

          const tmpField = {
            dataset_group_id:   field.dataset_group_id,
            dataset_group_name: field.dataset_group_name,
            dataset_id:         field.dataset_id,
            dataset_name:       field.dataset_name,
            dataset_type_name:  field.dataset_type_name,
            datasource_id:      field.datasource_id,
            datatype_category:  field.datatype_category,
            datatype_fullname:  field.datatype_fullname,
            field_id:           field.field_id,
            field_name:         field.field_name,
            operation:          field.operation,
            system_id:          field.system_id,
          }

          datasets[field.dataset_id] = datasets[field.dataset_id] ? [...datasets[field.dataset_id], tmpField] : [tmpField]
      })

      Object.entries(datasets).forEach(([key, entry]) => {

        const datasetId = res.changelog.dataset && res.changelog.dataset.findIndex(x => x.dataset_id === parseInt(key))

        if (datasetId > -1) {
          res.changelog.dataset[datasetId].fields = entry
        } else {
          res.changelog.dataset.push({
            dataset_id: entry[0].dataset_id,
            dataset_group_id: entry[0].dataset_group_id,
            datasource_id: entry[0].datasource_id,
            system_id: entry[0].system_id,
            dataset_name: entry[0].dataset_name,
            dataset_type_name: entry[0].dataset_type_name,
            dataset_group_name: entry[0].dataset_group_name,
            operation: 'U',
            fields: entry
          })
        }
      })

      if (schemaChangesOnly && res.changelog.dataset) {
        res.changelog.dataset = res.changelog.dataset.sort((a, b) => {
          var keyA = a.dataset_group_name.toLowerCase(),
              keyB = b.dataset_group_name.toLowerCase();
          // Sort by dataset_name
          if (keyA < keyB) return -1;
          if (keyA > keyB) return 1;
          if (keyA === keyB) {
            // Sort by dataset_group_name
            var keyAA = a.dataset_name.toLowerCase(),
                keyBB = b.dataset_name.toLowerCase();
            if (keyAA < keyBB) return -1;
            if (keyAA > keyBB) return 1;
            return 0
          }
        })
      }

      setChangelog(res.changelog)

    } catch(e) {
      handleError({e, showMessage}, () => {})
    } finally {
      setLoadingChangelog(false)
    }
  }

  return { 
    changelog,
    loadingChangelog,
    fetchChangelog,
    fetchChangelogByJobId,
  };
};