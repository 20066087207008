import React from 'react';
import { useTooltip } from '../../../hooks';


export const DataTablePagination = ({
  items,
  primaryColumn,
  pages,
  pageSize,
  currentPage,
  stepPage,
  selectPage
}) => {

  const {showTooltip, hideTooltip} = useTooltip()

  const renderTooltip = (index) => {
    const firstItem = items[(index-1)*pageSize][primaryColumn]
    const lastItem = index === pages.length ? items[items.length-1][primaryColumn] : items[(index)*pageSize-1][primaryColumn]
    const firstItemIndex = (index-1)*pageSize+1
    const LastItemIndex = index === pages.length ? items.length : (index)*pageSize
    return {
      title: `${firstItem} - ${lastItem}`,
      bodyText: `Asset ${firstItemIndex.toLocaleString('sv-SE')} - ${LastItemIndex.toLocaleString('sv-SE')}`
    }
  }

  return (
    
      <div className="pagination">
        <div className={"pagination-page-button step-button prev"} onClick={ (e) => stepPage(-1)}><span className="button-arrow"></span>{"Prev"}</div>
        <div className="pagination-page-buttons">
        { pages.map( index => (
            <div 
              key={index} 
              className={"pagination-page-button" + (index === currentPage ? " active" : "")} 
              onClick={ () => selectPage(index)}
              onMouseEnter={ () => showTooltip(renderTooltip(index))}
              onMouseLeave={ hideTooltip }
            >
              { index }
            </div>
          )) 
        }
        </div>
        <div className={"pagination-page-button step-button next"} onClick={ (e) => stepPage(1)}>{"Next"}<span className="button-arrow"></span></div>
      </div>
  );
};
