import React, { useState, useEffect, useRef, useMemo, useCallback } from 'react';
import { formatRelationships } from './helpers'
import { Graph, PopupTableContainer, GraphLoadingSpinner } from './components'
import { 
  Button, 
  Icon, 
  Select, 
  Slider, 
  formatSelectOptions, 
  textareaToPlainText 
} from '../'

import { useQueryParams, useTooltip, useMessage } from '../../hooks';
import { formatNumberUnit, formatDiskSize, handleError } from '../../helpers';
import { relationshipService } from '../../services';

const _RelationshipGraph = ({objectName, objectId, datasetId, datasets}) => {

  const minZoom = 0.4
  const maxZoom = 1.6
  const defaultZoom = 1.0

  const Dmin = 5
  const Dmax = 40 

  const nodeSizeOptions = {
    'none': {label: 'None', secondaryLabel: ' '},
    'field_count': {label: 'Field Count', secondaryLabel: 'Number of fields in the Dataset'},
    'row_count': {label: 'Row Count', secondaryLabel: 'Number of rows in the Dataset'},
    'disk_size': {label: 'Disk Size', secondaryLabel: 'Dataset total disk size'},
    'relationship_count': {label: 'Relationship Count', secondaryLabel: 'Number of related Datasets'},
    'documentation_level': {label: 'Documentation Level', secondaryLabel: 'Percentage of fields with a Field Description'}
  }

  const { queryParams, setHistory } = useQueryParams()

  const [selectedItems, setSelectedItems] = useState({nodes: [], edges: []})

  const [data, setData] = useState()
  const [loading, setLoading] = useState(true)
  const [abortFetch, setAbortFetch] = useState(new AbortController())

  const [fields, setFields] = useState([])
  const [hoveredItem, setHoveredItem] = useState()
  const [showPopup, setShowPopup] = useState(true)
  const [showGraphOptions, setShowGraphOptions] = useState(false)
  const [options, setOptions] = useState([])
  const [optionsNodeSize, setOptionsNodeSize] = useState([])
  const [optionNodeSize, setOptionNodeSize] = useState(queryParams.nodeSize || 'none')
  const [zoom, setZoom] = useState(defaultZoom)

  const cyRef = useRef()
  const sectionRef = useRef([])

  const {showTooltip, hideTooltip} = useTooltip();
  const { showMessage } = useMessage()

  const {elements, minValue, maxValue} = useMemo(() => {
    
    let tmp = formatRelationships(data)

    let minValue = Number.POSITIVE_INFINITY
    let maxValue = Number.NEGATIVE_INFINITY

    if (tmp) {
      switch (optionNodeSize) {
        case 'field_count':     
          tmp = tmp.map(item => {

            const value = item.data.field_count
            const A = value / item.data.field_count_max
            const Dr = 2*Math.sqrt( (1-A)*(Dmin/2)**2 + A*(Dmax/2)**2 )
            const nodeTooltip = (value === 0 ? 0 : (value ? value.toLocaleString('sv-SE') : "Unknown number of")) + " fields in this Dataset"

            if (minValue > value ) { minValue = value }
            if (maxValue < value ) { maxValue = value }
            return {...item, data:{...item.data, nodeSize: Dr, nodeTooltip}}
          })  
          break
        case 'row_count':     
          tmp = tmp.map(item => {

            const value = parseInt(item.data.row_count) || 0
            const A = value / item.data.row_count_max
            const Dr = 2*Math.sqrt( (1-A)*(Dmin/2)**2 + A*(Dmax/2)**2 )
            const nodeTooltip = (value === 0 ? 0 : (value ? parseInt(value).toLocaleString('sv-SE') : "Unknown number of")) + " rows in this Dataset"

            if (value && minValue > value ) { minValue = value }
            if (value && maxValue < value ) { maxValue = value }
            return {...item, data:{...item.data, nodeSize: Dr, nodeTooltip}}
          })  
          minValue = minValue && minValue < Number.POSITIVE_INFINITY ? minValue : 0
          maxValue = maxValue && maxValue > Number.NEGATIVE_INFINITY && maxValue
          break
        case 'disk_size':     
          tmp = tmp.map(item => {

            const value = parseInt(item.data.disk_size) || 0
            const A = value / item.data.disk_size_max
            const Dr = 2*Math.sqrt( (1-A)*(Dmin/2)**2 + A*(Dmax/2)**2 )
            const nodeTooltip = (value === 0 ? 0 : (value ? formatDiskSize(value) : "Unknown")) + " total disk size for this Dataset"

            if (value && minValue > value ) { minValue = value }
            if (value && maxValue < value ) { maxValue = value }
            return {...item, data:{...item.data, nodeSize: Dr, nodeTooltip}}
          })  
          minValue = minValue && minValue < Number.POSITIVE_INFINITY ? minValue : 0
          maxValue = maxValue && maxValue > Number.NEGATIVE_INFINITY && maxValue
          break
        case 'relationship_count': 

          let relationship_count_max = 0

          tmp.forEach(item => {
            if (relationship_count_max < item.data.relationship_count) {
                relationship_count_max = item.data.relationship_count
            }
          })

          tmp = tmp.map(item => {
            const value = item.data.relationship_count
            const A = value / relationship_count_max
            const Dr = 2*Math.sqrt( (1-A)*(Dmin/2)**2 + A*(Dmax/2)**2 )
            const nodeTooltip = (value === 0 ? 0 : (value ? value.toLocaleString('sv-SE') : "Unknown number of")) + " related Datasets"

            if (minValue > value ) { minValue = value }
            if (maxValue < value ) { maxValue = value }
            return {...item, data:{...item.data, nodeSize: Dr, nodeTooltip}}
          }) 

          break
        case 'documentation_level': 
          tmp = tmp.map(item => {
            const value = item.data.field_description_ratio
            const A = item.data.field_description_ratio
            const Dr = 2*Math.sqrt( (1-A)*(Dmin/2)**2 + A*(Dmax/2)**2 )
            const nodeTooltip = Math.floor(value*100) + "% Documentation Level"

            if (minValue > value ) { minValue = value }
            if (maxValue < value ) { maxValue = value }
            return {...item, data:{...item.data, nodeSize: Dr, nodeTooltip}}
          })  
          break
        default:
          tmp = tmp.map(item => {
            const value = item.data.relationship_count
            const Dr = 20
            const nodeTooltip = (value === 0 ? 0 : (value ? value.toLocaleString('sv-SE') : "Unknown number of")) + " related Datasets"
            return {...item, data:{...item.data, nodeSize: Dr, nodeTooltip}}
          })  
          break
      }
    }

    return {elements:tmp, minValue, maxValue}
    
  }, [data, loading, optionNodeSize])

  useEffect( () => {
    // Cancel fetches
    return ( () => { 
      abortFetch.abort()
    })
  }, [])

  useEffect( () => {
    // Fetch data
    if ((datasets && datasets.length > 0) || datasetId) {
      fetchRelationships()    
    }
  }, [datasets, datasetId])

  useEffect( () => {
    if (data) {
      const {allOptions} = getAllOptions()
      setOptions(allOptions)
      setOptionsNodeSize(getNodeSizeOptions())
    }
  }, [data])

  useEffect( () => {

    // Set width of all tables and descriptions in the popup to be the same as the widest table
    if (sectionRef.current && sectionRef.current.length > 0) {
      
      sectionRef.current.forEach(ref => {
        if (ref) {
          ref.table.style.width = '' //reset width
        }
      })

      const width = Math.max(...sectionRef.current.map(x => x && x.table.clientWidth))
      sectionRef.current.forEach(ref => {
        if (ref) {
          ref.description.style.maxWidth = width + "px"
          ref.table.style.width = width + "px"
        }
      })
    }
  }, [selectedItems])

  useEffect( () => {
    // Restore selection from history
    if (queryParams.selectedItems && cyRef.current) {
      queryParams.selectedItems.nodes && queryParams.selectedItems.nodes.forEach(id => {
        cyRef.current.nodes("[id = '"+id+"']").select()
      })

      queryParams.selectedItems.edges && queryParams.selectedItems.edges.forEach(id => {
        cyRef.current.edges("[id = '"+id+"']").select()
      })  
    }

  }, [loading])

  const fetchRelationships = async () => {

    let promises = []
      promises.push(
        await relationshipService.getByObjectId(objectName, objectId, abortFetch)
        .catch(err => {
          throw err
        })
      )
      
    if (datasetId) {
      promises.push(
        await relationshipService.getRelatedDatasets(datasetId, abortFetch)
        .catch(err => {
          throw err
        })
      )
    }

    Promise.all(promises)
      .then( result => {
        const nodes = datasets || (datasetId ? result[1].datasets : [])
          result && setData({nodes: nodes, edges: result[0].relationships})
      })
      .catch(err => {
        handleError({err, showMessage})
      })
      .finally(res => {
        setLoading(false)
      })
  }

  const handleOnReady = useCallback(() => {
      setLoading(false)
  }, [])

  const togglePopup = useCallback((show) => {
    const state = show || !showPopup
    setShowPopup(state)
  }, [showPopup])

  const toggleGraphOptions = (show) => {
    const state = show || !showGraphOptions
    setShowGraphOptions(state)
  }

  const resetZoomPan = (e) => {
    e.preventDefault()
    //cyRef.current.fit()
    //cyRef.current.zoom(defaultZoom)

    cyRef.current.animate({
      center: { eles: selectedItems.nodes.length > 0 ? cyRef.current.nodes("[id='" + selectedItems.nodes[0].nodeId + "']") : cyRef.current },
      zoom: defaultZoom,
      duration: 150,
      easing: 'ease-out'
    })
  }

  const handleZoom = (e) => {
    e.preventDefault()
    let {value} = e.target
    value = parseFloat(value)
    
    if (value >= 0.9 && value <= 1.1) {
      value = defaultZoom
    }

    cyRef.current.zoom(value)
  }

  const handleInputChange = (event) => {
    let value = event.value
    const name = event.name
    const objectName = event.option.secondaryLabel
    cyRef.current.nodes().unselect()
    cyRef.current.edges().unselect()
   
    switch (name) {
      case 'graph_filter':
        switch (objectName) {
          case 'Dataset':
            cyRef.current.nodes("[id = '"+value+"']").select()
            break
          case 'Field':
            cyRef.current.edges("[field_list *= '|"+value+"|']").select()
            break
          default:
            break
        }
        break
      case 'node_size':
        setOptionNodeSize(value)
        setHistory({nodeSize: value})
        setNodeSizes(value)
        break
      default:
        break
    }
  }

  const handleMouseEnterRow = useCallback((e, id) => {

    selectedItems.edges && selectedItems.edges.forEach(edge => {
      edge.edge.addClass('mouseover-table-blurred-edge')
    })
    selectedItems.nodes && selectedItems.nodes.forEach(node => {
      node.neighborEdgesObj.edges().addClass('mouseover-table-blurred-edge')
    })
    cyRef.current.edges("[id = '"+id+"']").addClass('mouseover-table-highlighted-edge')
  }, [selectedItems, cyRef])

  const handleMouseLeaveRow = useCallback((e, id) => {
    cyRef.current.edges().removeClass('mouseover-table-blurred-edge')
    cyRef.current.edges("[id = '"+id+"']").removeClass('mouseover-table-highlighted-edge')
  }, [cyRef])

  const handleMouseEnterOption = (e, option) => {
    let id = option.value
    const objectType = option.secondaryLabel

    cyRef.current.edges().addClass("mouseover-blurred-edge")
    cyRef.current.nodes('.node').addClass("mouseover-blurred-node")

    switch (objectType) {
      case 'Dataset':    
        cyRef.current.nodes("[id = '"+id+"']").addClass('mouseover-highlighted-node')    
        cyRef.current.nodes("[id = '"+id+"']").neighborhood('node').addClass('mouseover-neighbor-node')
        cyRef.current.nodes("[id = '"+id+"']").neighborhood('edge').addClass('mouseover-highlighted-edge')
        
        // Center viewport on selected node
        cyRef.current.animate({
          center: { eles: cyRef.current.nodes("[id = '"+id+"']")},
          duration: 150,
          easing: 'ease-out'
        })
        break
      case 'Field':
        cyRef.current.edges("[field_list *= '|"+id+"|']").addClass('mouseover-highlighted-edge')
        cyRef.current.edges("[field_list *= '|"+id+"|']").connectedNodes().addClass('mouseover-neighbor-node')
        
        // Center viewport on selected edge
        cyRef.current.animate({
          center: { eles: cyRef.current.edges("[field_list *= '|"+id+"|']")},
          duration: 150,
          easing: 'ease-out'
        })
        break
      default:
        break
    }
    
  }

  const handleMouseLeaveOption = (e, option) => {
    cyRef.current.clearQueue() // clear animation queue
    cyRef.current.edges().removeClass('mouseover-blurred-edge')
    cyRef.current.nodes('.node').removeClass('mouseover-blurred-node')
    cyRef.current.edges().removeClass('mouseover-highlighted-edge')
    cyRef.current.nodes('.node').removeClass('mouseover-neighbor-node')
    cyRef.current.nodes('.node').removeClass('mouseover-highlighted-node')

  }

  const handleSelectItems = useCallback((items) => {
    if (items) { 
      
      let edges = []
      let nodes = []

      if (items.nodes && items.nodes.length > 0) {

        items.nodes.forEach(node => {

          const data = node.data()
          const nodeId = data.id
          const neighborEdgesObj = node.neighborhood('edge')

          let neighborEdges = []
          
          neighborEdgesObj.forEach(el => {
            const item = el.data()

            item.fields.forEach(field => {

              if (nodeId === item.source) {
                neighborEdges.push({
                  ...item,
                  direction:                              "arrow_right",
                  dataset_name:                           item.source_dataset_name,
                  datatype_category:                      field.from_datatype_category,
                  datatype_fullname:                      field.from_datatype_fullname,
                  field_name:                             field.from_field_name,
                  dataset_description:                    item.source_dataset_description,
                  datatype_name:                          field.from_datatype_name,
                  field_description_description:          field.from_field_description_description,
                  field_id:                               field.from_field_id,
                  dataset_id:                             item.source,
                  dataset_group_id:                       item.source_dataset_group_id,
                  datasource_id:                          item.source_datasource_id,
                  system_id:                              item.source_system_id,   
                  related_dataset_name:                   item.target_dataset_name,
                  related_dataset_type_name:              item.target_dataset_type_name,
                  related_datatype_category:              field.to_datatype_category,
                  related_datatype_fullname:              field.to_datatype_fullname,
                  related_field_name:                     field.to_field_name,
                  related_dataset_description:            item.target_dataset_description,
                  related_datatype_name:                  field.to_datatype_name,
                  related_field_description_description:  field.to_field_description_description,
                  related_field_id:                       field.to_field_id,
                  related_dataset_id:                     item.target,
                  related_dataset_group_id:               item.target_dataset_group_id,
                  related_datasource_id:                  item.target_datasource_id,
                  related_system_id:                      item.target_system_id 
                })
              } else {
                neighborEdges.push({
                  ...item,
                  direction:                              "arrow_left",
                  dataset_name:                           item.target_dataset_name,
                  datatype_category:                      field.to_datatype_category,
                  datatype_fullname:                      field.to_datatype_fullname,
                  field_name:                             field.to_field_name,
                  dataset_description:                    item.target_dataset_description,
                  datatype_name:                          field.to_datatype_name,
                  field_description_description:          field.to_field_description_description,
                  field_id:                               field.to_field_id,
                  dataset_id:                             item.target,
                  dataset_group_id:                       item.target_dataset_group_id,
                  datasource_id:                          item.target_datasource_id,
                  system_id:                              item.target_system_id, 
                  related_dataset_name:                   item.source_dataset_name,
                  related_dataset_type_name:              item.source_dataset_type_name,
                  related_datatype_category:              field.from_datatype_category,
                  related_datatype_fullname:              field.from_datatype_fullname,
                  related_field_name:                     field.from_field_name,
                  related_dataset_description:            item.source_dataset_description,
                  related_datatype_name:                  field.from_datatype_name,
                  related_field_description_description:  field.from_field_description_description,
                  related_field_id:                       field.from_field_id,
                  related_dataset_id:                     item.source,
                  related_dataset_group_id:               item.source_dataset_group_id,
                  related_datasource_id:                  item.source_datasource_id,
                  related_system_id:                      item.source_system_id 
                })
              }
            })
          })

          nodes = [...nodes, ...[{nodeId, node, data, neighborEdges, neighborEdgesObj}]]
        })
      }

      if (items.edges && items.edges.length > 0) {

        items.edges.forEach(edge => {

          const item = edge.data()
          item.fields.forEach(field => {
            const data = {
              ...item,
              direction:                              "arrow_right",
              dataset_name:                           item.source_dataset_name,
              dataset_type_name:                      item.source_dataset_type_name,
              datatype_category:                      field.from_datatype_category,
              datatype_fullname:                      field.from_datatype_fullname,
              field_name:                             field.from_field_name,
              dataset_description:                    item.source_dataset_description,
              datatype_name:                          field.from_datatype_name,
              field_description_description:          field.from_field_description_description,
              field_id:                               field.from_field_id,
              dataset_id:                             item.source,
              dataset_group_id:                       item.source_dataset_group_id,
              datasource_id:                          item.source_datasource_id,
              system_id:                              item.source_system_id,   
              related_dataset_name:                   item.target_dataset_name,
              related_dataset_type_name:              item.target_dataset_type_name,
              related_datatype_category:              field.to_datatype_category,
              related_datatype_fullname:              field.to_datatype_fullname,
              related_field_name:                     field.to_field_name,
              related_dataset_description:            item.target_dataset_description,
              related_datatype_name:                  field.to_datatype_name,
              related_field_description_description:  field.to_field_description_description,
              related_field_id:                       field.to_field_id,
              related_dataset_id:                     item.target,
              related_dataset_group_id:               item.target_dataset_group_id,
              related_datasource_id:                  item.target_datasource_id,
              related_system_id:                      item.target_system_id, 
              edge:                                   edge
            }
            edges = [...edges, ...[data]]
          })
        })
      }

      togglePopup(true)

      setSelectedItems(prev => { 
        return {
          nodes: items.nodes && nodes, 
          edges: items.edges && edges
        }  
      })

      setHistory({selectedItems: {
          nodes: items.nodes && nodes && nodes.map(x => x.nodeId), 
          edges: items.edges && edges && edges.map(x => x.id)
        }  
      })

    } else { // Deselect items, i.e. none is selected
      togglePopup(false)
      setSelectedItems({nodes: [], edges: []})
    }

  }, [])

  const handleHoveredItem = useCallback((item) => {

    setHoveredItem(item)
    const tooltipBodyEdge = <React.Fragment>{item && item.edges &&
                            <div className="graph-tooltip-container">
                              <div className="graph-tooltip">

                                <div className="graph-tooltip-edge-source">
                                  <div className="first-row">
                                    <Icon name={item.edges[0].source_dataset_type_name} />
                                    <div>{item.edges[0].source_dataset_name}</div>
                                  </div>
                                  { item.edges[0].fields.map( (field, index) => {
                                      return (
                                        <div className="second-row" key={index}>
                                          <Icon name={field.from_datatype_category} />
                                          <div>{field.from_field_name}</div>
                                        </div>
                                      )
                                    })
                                  }
                                </div>

                                <div className="graph-tooltip-edge-direction">
                                  { item.edges[0].fields.map( (field, index) => {
                                    return <Icon name="arrow-right" key={index} />
                                    })
                                  }
                                </div>

                                <div className="graph-tooltip-edge-target">
                                  <div className="first-row">
                                    <Icon name={item.edges[0].target_dataset_type_name} />
                                    <div>{item.edges[0].target_dataset_name}</div>
                                  </div>
                                  { item.edges[0].fields.map( (field, index) => {
                                      return (
                                        <div className="second-row" key={index}>
                                          <Icon name={field.to_datatype_category} />
                                          <div>{field.to_field_name}</div>
                                        </div>
                                      )
                                    })
                                  }
                                </div>

                              </div>

                              <div className="graph-tooltip-footer">
                                <div>{item.edges[0].relationship_type} relationship</div>
                              </div>
                            </div>
                            }</React.Fragment>

      const tooltipBodyNode =  <React.Fragment>{item && item.nodes && <div className="graph-tooltip">
                    <div className="graph-tooltip-node">
                      <div className="first-row">
                        <Icon name={item.nodes[0].dataset_type_name} />
                        <div>{item.nodes[0].label}</div>
                      </div>
                      <div className="tooltip-subtitle">
                        <div>{item.nodes[0].nodeTooltip}</div>
                      </div>
                      { item.nodes[0].dataset_description &&
                        <div className="tooltip-body">{textareaToPlainText(item.nodes[0].dataset_description)}</div>
                      }
                    </div>
                  </div>} </React.Fragment>

      if (item) {
        if (item.edges) {
          showTooltip({tooltipBody: tooltipBodyEdge})
        } else {
          showTooltip({tooltipBody: tooltipBodyNode})
        }
      } else if (!item) {
        hideTooltip()
      }
  }, [])

 const setNodeSizes = (nodeSize) => {
  switch (nodeSize) {
    case 'field_count': 

      break
    case 'relationship_count': 

      break
    case 'documentation_level': 

      break
    default:

      break
  }
 }

  const getNodeSizeOptions = () => {

    const tmp = Object.keys(nodeSizeOptions).map( key => { return { value: key, label: nodeSizeOptions[key].label, secondaryLabel: nodeSizeOptions[key].secondaryLabel}})

    return formatSelectOptions({options: tmp, optionValue: "value", optionLabel: "label", optionSecondaryLabel: "secondaryLabel", tooltip:"secondaryLabel"})
    
  }

  const getAllOptions = () => {
    
    const allFields = []
    elements.forEach(item => {

      switch (item.data.type) {
        case 'node':
          allFields.push({value: item.data.id, optionLabel: item.data.object_name, optionSecondaryLabel: item.data.object_type, optionIcon: item.data.object_icon, tooltip: item.data.object_description})
          allFields.push({value: item.data.id, optionLabel: item.data.object_name, optionSecondaryLabel: item.data.object_type, optionIcon: item.data.object_icon, tooltip: item.data.object_description})
          break
        case 'edge':
          item.data.fields.forEach(edge => {
            allFields.push({value: edge.from_field_name, optionLabel: edge.from_field_name, optionSecondaryLabel: item.data.object_type, optionIcon: edge.from_datatype_category, tooltip: edge.from_field_description_description})
            allFields.push({value: edge.to_field_name, optionLabel: edge.to_field_name, optionSecondaryLabel: item.data.object_type, optionIcon: edge.to_datatype_category, tooltip: edge.to_field_description_description})
          })
          break
        default:
      }
    })

    const uniqueFields = [...new Map(allFields.map(item => [item['optionLabel'], item])).values()];

    const allOptions = formatSelectOptions({options: uniqueFields, optionValue: "value", optionLabel: "optionLabel", optionSecondaryLabel: "optionSecondaryLabel", optionIcon: "optionIcon", tooltip: "tooltip"})

    const allSelectedOptions = allOptions.map(x => {return {value: x.value}})

    return {allOptions, allSelectedOptions }
  }

  const getLegendNodeSizeValue = (value) => {
    let label = ""
    switch(optionNodeSize) {
      case 'documentation_level':
        label = Math.floor(value*100) + "%"
        break
      case 'disk_size':
        label = formatDiskSize(value)
        break
      default:
        label = formatNumberUnit(value)
      }

    return label
  }

  return (
    <div className="RelationshipGraph">
    { loading  
      ? <div className="loading-graph">
          <GraphLoadingSpinner />
          <div className="loading-graph-text">Drawing the graph...</div>
        </div>
      : <React.Fragment>
      
        <PopupTableContainer
          selectedItems={selectedItems}
          setSelectedItems={setSelectedItems} 
          fields={fields}
          setFields={setFields}
          onMouseEnterRow={handleMouseEnterRow}
          onMouseLeaveRow={handleMouseLeaveRow}
          togglePopup={togglePopup}
          showPopup={showPopup}
          refs={sectionRef}
        />
{/*
        <div className="graph-filters">
          <div className="graph-filter">
            <Select 
              className="graph-filter"
              name="graph_filter"
              value=''
              options={ options }
              iconAsButton={true}
              onChange={handleInputChange}
              //onMouseEnterOption={handleMouseEnterOption}
              //onMouseLeaveOption={handleMouseLeaveOption}
              expandedByDefault={false}
              placeholder="Search assets..."
              title="Search assets in the graph"
              isClearable={true}
            />
          </div>
        </div>
*/}
        <div className="graph-controls">
          <div className="filter-container graph-control">
            <Select 
              className="graph-filter"
              name="graph_filter"
              value=''
              options={ options }
              icon='search'
              iconAsButton={true}
              onChange={handleInputChange}
              onMouseEnterOption={handleMouseEnterOption}
              onMouseLeaveOption={handleMouseLeaveOption}
              expandedByDefault={false}
              placeholder="Search assets..."
              title="Search assets in the graph"
              isClearable={true}
            />
          </div>
          
          <div className="graph-control">
            <Slider 
              name="zoom" 
              min={minZoom} 
              max={maxZoom} 
              step="0.01" 
              value={zoom} 
              onChange={handleZoom} 
              orientation='vertical'
              tooltip="Zoom" 
            />
          </div>

          <div className="graph-control">
            <Button 
              onClick={resetZoomPan} 
              value="" 
              tooltip="Reset pan and zoom" 
              icon="fit" 
              buttonStyle="white" 
            />
          </div>

          <div className="filter-container graph-control">
            <Select 
                className="graph-filter"
                name="node_size"
                value={optionNodeSize}
                options={ optionsNodeSize }
                onChange={handleInputChange}
                icon='node-size'
                iconAsButton={true}
                expandedByDefault={false}
                placeholder="Search assets..."
                title="Select node size measure"
                isClearable={true}
              />
          </div>

        </div>

        <Graph 
          data={elements} 
          setSelectedItems={handleSelectItems} 
          setHoveredItem={handleHoveredItem} 
          onReady={handleOnReady}
          nodeSize={optionNodeSize}
          minZoom={minZoom} 
          maxZoom={maxZoom} 
          setZoom={setZoom} 
          zoom={defaultZoom} 
          ref={cyRef} 
        />

        { optionNodeSize !== 'none' &&
          <div className="graph-legend">
            <div className="graph-legend-icon">
              <div className="graph-legend-circle-max">
                <div className="graph-legend-value">{ getLegendNodeSizeValue(maxValue) }</div>
                <div className="graph-legend-max" style={{width: zoom * Dmax + "px", height: zoom * Dmax + "px"}}></div>
              </div>
              <div className="graph-legend-circle-min">
                <div className="graph-legend-value">{ getLegendNodeSizeValue(minValue) }</div>
                <div className="graph-legend-min" style={{width: zoom * Dmin + "px", height: zoom * Dmin + "px"}}></div>
              </div>
            </div>
            <div className="graph-legend-text">
              Size shows {nodeSizeOptions[optionNodeSize].label}
            </div>
          </div>
        }

        

      </React.Fragment>

    }
    </div>
  )
}

const isEqual = (prevProps, nextProps) => {
  // Trigger re-render only if length of datasets changes.
  return nextProps.datasets && prevProps.datasets && (prevProps.datasets.length === nextProps.datasets.length)

}

export const RelationshipGraph = React.memo(_RelationshipGraph, isEqual)