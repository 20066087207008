import config from '../config';
import { customFetch, authHeader, jsonHeader, handleResponse, commonRequestOptions, subjects } from '../helpers';

const endpointPath='dataset_group'

export const datasetGroupService = {
    getAll,
    getById,
    getByDatasourceId,
    getFieldStatistics,
    create,
    update,
    checkDestroy,
    destroy,
};

function getAll(signal) {
    const requestOptions = { method: 'GET', headers: authHeader(), ...commonRequestOptions() };
    return customFetch(`${config.api.url}/${endpointPath}/all`, requestOptions, signal).then(handleResponse);
}

function getById(id, signal) {
    const requestOptions = { method: 'GET', headers: authHeader(), ...commonRequestOptions() };
    return customFetch(`${config.api.url}/${endpointPath}/${id}`, requestOptions, signal)
    .then(handleResponse)
    .then(res => {
        subjects.currentDatasetGroup.next(res.dataset_groups[0] || {dataset_group_id: id})
        subjects.currentDatasource.next(res.dataset_groups[0] ? {
            datasource_id: res.dataset_groups[0].datasource_id,
            datasource_name: res.dataset_groups[0].datasource_name,
            datasource_type_name: res.dataset_groups[0].datasource_type_name,
            datasource_type_category: res.dataset_groups[0].datasource_type_category
        } : "not_found")
        subjects.currentSystem.next(res.dataset_groups[0] ? {
            system_id: res.dataset_groups[0].system_id,
            system_name: res.dataset_groups[0].system_name
        } : "not_found")
        return res
    });
}

function getByDatasourceId(id, signal) {
    const requestOptions = { method: 'GET', headers: authHeader(), ...commonRequestOptions() };
    return customFetch(`${config.api.url}/${endpointPath}/datasource/${id}`, requestOptions, signal).then(handleResponse);
}

function getFieldStatistics(id, signal) {
    const requestOptions = { method: 'GET', headers: authHeader(), ...commonRequestOptions() };
    return customFetch(`${config.api.url}/${endpointPath}/field_statistics/${id}`, requestOptions, signal).then(handleResponse);
}

function create(items, signal) {
    const requestOptions = { 
    	method: 'POST', 
    	headers: {
            ...authHeader(),  
    		...jsonHeader()
    	}, 
        ...commonRequestOptions(),
    	body: JSON.stringify(items) };

    return customFetch(`${config.api.url}/${endpointPath}`, requestOptions, signal).then(handleResponse);
}

function update(items, signal) {
    const requestOptions = { 
    	method: 'PUT', 
    	headers: {
            ...authHeader(),  
            ...jsonHeader()
        }, 
        ...commonRequestOptions(),
    	body: JSON.stringify(items) 
    };

    return customFetch(`${config.api.url}/${endpointPath}`, requestOptions, signal).then(handleResponse);
}

function checkDestroy(items, signal) {
    const requestOptions = { 
        method: 'POST', 
        headers: {
            ...authHeader(),  
            ...jsonHeader()
        }, 
        ...commonRequestOptions(),
        body: JSON.stringify(items) };

    return customFetch(`${config.api.url}/${endpointPath}/check_delete`, requestOptions, signal).then(handleResponse);
}

function destroy(items, signal) {
    const requestOptions = { 
    	method: 'DELETE', 
    	headers: {
            ...authHeader(),  
            ...jsonHeader()
        }, 
        ...commonRequestOptions(),
    	body: JSON.stringify(items) };

    return customFetch(`${config.api.url}/${endpointPath}`, requestOptions, signal).then(handleResponse);
}