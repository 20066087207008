import { useCallback, useContext } from "react";
import { flushSync } from "react-dom";

import { MessageContext } from "../context/MessageContext";

export const useMessage = () => {
  const {
    message, 
    setMessage,
    show, 
    setShow
  } = useContext(MessageContext);

  const showMessage = useCallback( (msg, type = 'success', isFullscreenMessage=false) => {

      let response = {}

      // success
      if (type === 'success' && msg) {
        response = {
          title: msg.message || msg, 
          details: msg.details || undefined
        }

      // error
      } else { 

        try {

          try {
              msg = JSON.parse(msg)
          }
          catch(e) {
              // Do nothing
          }

          if (Object.keys(msg)[0] === 'dataValidationError') {

            let validationErrors = ''

            msg.dataValidationError.forEach( error => {
              validationErrors += `${error.msg}\n`
            })

            response = {
              title: 'Data Validation Error',
              details: validationErrors
            }
          } else {
            response = {
              title: msg?.message || msg.toString(),
              details: msg?.error || msg?.details
            }
          }

        } catch {
          response = {title: msg}
        }
      }

      response.type = type

      setMessage(response)

      // Prevent batching. hideTooltip and showTooltip is fired at the same time, which causes React to batch the setShow() result. 
      // This results in the "show" prop to never change when moving the mouse between two objects positioned border-to-border.
      !isFullscreenMessage && flushSync(() => setShow(prev => true))
    },
    [setShow, setMessage]
  );

  const hideMessage = useCallback(
    (props) => {
      setMessage()
      // Prevent batching. hideTooltip and showTooltip is fired at the same time, which causes React to batch the setShow() result. 
      // This results in the "show" prop to never change when moving the mouse between two objects positioned border-to-border.
      flushSync(() => setShow(prev => false))
    },
    [setShow]
  );

  return {
    showMessage,
    hideMessage,
    show,
    message
  };
}