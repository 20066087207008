import React from 'react';
import { ReactComponent as Spinner} from '../../../assets/img/icons/graph-loading-spinner.svg';


const _GraphLoadingSpinner = (ref) => {

  return (
    <Spinner className="loading-graph-spinner"  />
    
  );
}

export const GraphLoadingSpinner = _GraphLoadingSpinner