import { useCallback, useContext } from "react";
import { flushSync } from "react-dom";

import {TooltipContext} from "../context/TooltipContext";

export const useTooltip = () => {
  const {
    show,
    setShow,
    tooltip,
    setTooltip
  } = useContext(TooltipContext);

  const showTooltip = useCallback(
    (props) => {
      setTooltip(props);
      // Prevent batching. hideTooltip and showTooltip is fired at the same time, which causes React to batch the setShow() result. 
      // This results in the "show" prop to never change when moving the mouse between two objects positioned border-to-border.
      flushSync(() => setShow(prev => true))
    },
    [setShow, setTooltip]
  );

  const hideTooltip = useCallback(
    (props) => {
      // Prevent batching. hideTooltip and showTooltip is fired at the same time, which causes React to batch the setShow() result. 
      // This results in the "show" prop to never change when moving the mouse between two objects positioned border-to-border.
      flushSync(() => setShow(prev => false))
    },
    [setShow]
  );

  return {
    showTooltip,
    hideTooltip,
    show,
    tooltip
  };
}