import config from '../config';
import { customFetch, authHeader, handleResponse, commonRequestOptions } from '../helpers';

const endpointPath='reference'

export const referenceService = {
    getByFromId,
    getByToId,
    getToReferencesCount,
    getToReferencesCountPerChild
};

function getByFromId(objectName, objectId, signal) {
    const requestOptions = { method: 'GET', headers: authHeader(), ...commonRequestOptions() };
    return customFetch(`${config.api.url}/${endpointPath}/get_by_from_id/${objectName}/${objectId}`, requestOptions, signal).then(handleResponse);
}
function getByToId(toObjectName, toObjectId, fromObjectName, signal) {
    const requestOptions = { method: 'GET', headers: authHeader(), ...commonRequestOptions() };
    return customFetch(`${config.api.url}/${endpointPath}/get_by_to_id/${toObjectName}/${toObjectId}/${fromObjectName}`, requestOptions, signal).then(handleResponse);
}
function getToReferencesCount(toObjectName, toObjectId, signal) {
    const requestOptions = { method: 'GET', headers: authHeader(), ...commonRequestOptions() };
    return customFetch(`${config.api.url}/${endpointPath}/get_to_references_count/${toObjectName}/${toObjectId}`, requestOptions, signal).then(handleResponse);
}
function getToReferencesCountPerChild(toObjectName, toParentObjectName, toParentObjectId, signal) {
    const requestOptions = { method: 'GET', headers: authHeader(), ...commonRequestOptions() };
    return customFetch(`${config.api.url}/${endpointPath}/get_to_references_count_per_child/${toObjectName}/${toParentObjectName}/${toParentObjectId}`, requestOptions, signal).then(handleResponse);
}