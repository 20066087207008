
export function authHeader() {
    return {};
}

export function jsonHeader() {

    return { 
    	'Accept': 'application/json',
    	'Content-Type': 'application/json'  
    };
}

export function commonRequestOptions() {

    return { 
        credentials: 'include'
    };
}