import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import { systemService, datasourceService, referenceService } from '../services';
import { Role, Asset, formatData } from '../helpers';
import {
  Changelog, 
  DataTable, 
  MainColumn, 
  ObjectDetails, 
  References, 
  Tabs, 
  fetchToReferencesCount, 
  UserLink } from '../components';
import { 
  useSideDialog, 
  useModalDialog, 
  useQueryParams, 
  useGlobalState, 
  usePhotos, 
  useNotFound, 
  useMessage } from '../hooks';

export const System = () => {

  const { queryParams, resetParams } = useQueryParams()

  const [system, setSystem] = useState([]);
  const [datasources, setDatasources] = useState([]);
  const [fromReferences, setFromReferences] = useState([]);
  const [toReferencesCount, setToReferencesCount] = useState({});
  const [loggedInUser, setLoggedInUser] = useGlobalState('loggedInUser');
  const [selectedItems, setSelectedItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingTable, setLoadingTable] = useState(true);
  const [defaultSelectedTab, setDefaultSelectedTab] = useState({tab: queryParams.tab})

  const clearSelectedItems = () => {
    setSelectedItems([])
  }

  const { system_id } = useParams()
  const navigate = useNavigate()

  const { showMessage } = useMessage()
  const { showSideDialog, hideSideDialog } = useSideDialog(clearSelectedItems)
  const { showModalDialog, hideModalDialog } = useModalDialog()
  const { fetchPhotos } = usePhotos()
  const { notFound, setNotFound, NotFound } = useNotFound()

  useEffect(() => {

    fetchItems()
    fetchDatasources()
    fetchFromReferences()

    fetchToReferencesCount({toObjectName: Asset.System, toObjectId: system_id})
      .then(res => setToReferencesCount(res))
      .catch(err => {
        showMessage(err, 'error')
      })

  }, [system_id]);

  const fetchItems = async () => {
    
    systemService.getById(system_id)
      .then(res => {
        if (res.systems.length < 1) {
          setNotFound(true)
        } else {
          setSystem(res.systems[0])
          setLoading(false)
          fetchPhotos(res.systems[0], 'system_owner_user_id', setSystem)
        }   
      })
      .catch(err => {
        showMessage(err, 'error')
        setLoading(false)
      });
  }

  const fetchDatasources = async () => {
    const promise = new Promise(  (resolve, reject) => {
      datasourceService.getBySystemId(system_id)
        .then(res => {

          setDatasources(res.datasources)
          setLoadingTable(false)
          fetchPhotos(res.datasources, 'datasource_owner_user_id', setDatasources)
          resolve(res.datasources)
           
        })
        .catch(err => {
          showMessage(err, 'error')
          setLoadingTable(false)
        })
    })

    Promise.all([promise, fetchFieldStatistics()])
      .then( result => {
        updateFieldStatistics(result[0], result[1])
      })
      .catch(err => { showMessage(err, 'error')}) 
  }

  const fetchFromReferences = async () => {
    
    referenceService.getByFromId(Asset.System, system_id)
      .then(res => {

        setFromReferences(res.references);
         
      })
      .catch(err => {showMessage(err, 'error')});
  }

  const fetchFieldStatistics = async () => {
    return new Promise(  (resolve, reject) => {
      datasourceService.getFieldStatistics(system_id)
        .then(res => {

          resolve(res.field_statistics)

        })
        .catch(err => {
          return null
        });
    })
  }

  const updateFieldStatistics = (data, statistics) => {

    let dataTmp = data
    let statistic = {}
    let tmp = null

    let maxValue = 0

    statistics.forEach( row => {
      if (row.dataset_count > maxValue) {
        maxValue = row.dataset_count
      }
    })

    data.forEach( (row, index) => {
      statistic = statistics.find(x => x.datasource_id === row.datasource_id)
      tmp = dataTmp[index]
      dataTmp[index] = {
        ...tmp, 
        dataset_count: (statistic ? statistic.dataset_count : 0), 
        field_description_ratio: (statistic ? statistic.field_description_ratio : 0),
        dataset_count_max: maxValue
      }
    })
    setDatasources([...dataTmp]); 
  }

  const addDatasource = async data => {
    const datasources = {datasources: (Array.isArray(data) ? data : [data])}
    
    await datasourceService.create(datasources)
      .then(res => {

        fetchDatasources()
        showMessage(res.message)
      })
      .catch(err => {
        showMessage(err, 'error')
        throw err
      });
  }

  const editDatasource = async data => {
    const datasources = {datasources: (Array.isArray(data) ? data : [data])}
    
    await datasourceService.update(datasources)
      .then(async res => { 

        fetchDatasources()
        showMessage(res.message)
        clearSelectedItems()
        hideSideDialog()
      })
      .catch(err => {
        showMessage(err, 'error')
        throw err
      });
  }

  const editSystem = async data => {
    const systems = {systems: (Array.isArray(data) ? data : [data])}
    
    await systemService.update(systems)
      .then(async res => { 

        fetchItems()
        fetchFromReferences()
        showMessage(res.message)
        hideSideDialog()
      })
      .catch(err => {
        showMessage(err, 'error')
        throw err
      });
  }

  const deleteDatasource = async data => {    
    await datasourceService.destroy({ datasources: selectedItems })
      .then(async res => { 

        hideModalDialog()
        clearSelectedItems()
        fetchDatasources()
        showMessage(res.message)

      })
      .catch(err => {
        showMessage(err, 'error')
      });
  }

  const deleteSystem = async data => {    
    await systemService.destroy({systems: data})
      .then(async res => { 

        hideModalDialog()
        showMessage(res.message)

        // Redirect to parent page
        navigate('/browse_datasets')

      })
      .catch(err => {
        showMessage(err, 'error')
      });
  }

  const data = loading ? [] : system

  const editable = loggedInUser && loggedInUser.user_role_name === Role.admin

  if (notFound) {
    return <NotFound />
  }

  return (
    <div className="columns narrow-margin">
      <MainColumn>
        <ObjectDetails
          type="System"
          title={data.system_name}
          subtitleText={ data && !loading &&
            data.system_type 
          }
          details={[
            {title: 'Last Modified', data: formatData(data.modified_timestamp, 'datetime')},
            {title: 'Created', data: formatData(data.created_timestamp, 'datetime')}
          ]}
          description={data.system_description}
          fromReferences={fromReferences}
          loading={loading}
          >

            <h3>{ !loading ? "Owner" : <Skeleton width="5vw"/>}</h3>
            <UserLink 
              userId={data.system_owner_user_id}
              userName={data.system_owner_user_fullname}
              userPhoto={data.user_photo}
              userTitle={data.user_title}
              userDepartment={data.user_department}
              loading={loading}
            />
        </ObjectDetails>
          { !loading && editable &&
          <div className="main-toolbar">
            <button type="button" className="main-toolbar-item button main-button" onClick={ () => showSideDialog('editSystem', [data], editSystem) }><span>EDIT</span></button>
            <button type="button" className="main-toolbar-item button" onClick={ () => showModalDialog('deleteSystem', [data], deleteSystem) }><span>DELETE</span></button>
          </div>
          }

      </MainColumn>

      <div className="column">
        <Tabs 
          className="slim left"
          //onTabChange={ onTabChange }
          defaultSelectedTab={ defaultSelectedTab }
          disableTabsWithoutResults = {true}
        >
          <div label="Datasources" tabid="datasources" resultCount={loadingTable || datasources.length === 0 ? undefined : datasources.length}>
            
            <DataTable
              columns={[
                {id: 'datasource_type_category', name: '', type: 'icon', tooltip: 'datasource_type_name'},
                {id: 'datasource_name', name: 'Name', link: '/browse_datasets/:system_id/:datasource_id', tooltip: 'datasource_description', className:"bold"},
                {id: 'datasource_description', name: 'Description', type: 'description', viewMode: 'descriptions'},                
                {id: 'dataset_count', name: 'Datasets', type: 'chart', chartType: 'bar', data: {data:'dataset_count', max: 'dataset_count_max'}, viewMode: 'statistics'},
                {id: 'field_description_ratio', name: 'Documentation Level', type: 'chart', chartType: 'percent-bar', data: {data:'field_description_ratio'}, viewMode: 'statistics'},
                {id: 'user_photo', name: '', type: 'user-photo', link: '/users/:datasource_owner_user_id', tooltip: 'datasource_owner_user_fullname'},
                {id: 'datasource_owner_user_fullname', name: 'Owner', link: '/users/:datasource_owner_user_id'}               
              ]}
              buttons={[ 
                {label: "Add +", action: "add", mainButton: true, onClick: (items) => showSideDialog('addDatasource', items || selectedItems, addDatasource) },
                {label: "Edit", action: "edit", onClick: (items) => showSideDialog('editDatasource', items || selectedItems, editDatasource) },
                {label: "Delete", action: "delete", onClick: (items) => showModalDialog('deleteDatasource', items || selectedItems, deleteDatasource) }
              ]}
              data={datasources}
              idColumn='datasource_id'
              selectedItems={selectedItems}
              setSelectedItems={setSelectedItems}
              loading={loadingTable}
              editable={editable}
              filterable={true}
              filterObjects={['datasource_name', 'datasource_description', 'datasource_type_category', 'datasource_type_name', 'datasource_owner_user_fullname']}
              showViewModes={true}
            />
          </div>

          <div label="Related Assets" tabid="related_assets" resultCount={toReferencesCount && toReferencesCount.total}>
            <References 
              showMessage={showMessage}
              toObjectName={Asset.System}
              toObjectId={system_id}
              toReferencesCount={toReferencesCount}
              />
          </div>

          <div label="Changelog" tabid="changelog">
            <Changelog 
              objectName={Asset.System} 
              objectId={system_id} 
              filterObjects={[
                'changedData.new_data||old_data.system_name', 
                'changedData.new_data||old_data.system_description', 
                'changedData.new_data||old_data.system_type', 
                'changedData.new_data||old_data.system_owner_user_fullname', 
                'changedData.attribute',
                'changed_by_user_username',
                'is_service_account_user'
              ]}
              filterObjectsValueMap={{is_service_account_user: {true: {label:'Datasource Sync'}, false: {label:''}}}}
            />
          </div>

        </Tabs>

      </div>
    </div>
  ) 
}
