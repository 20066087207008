import config from '../config';
import { customFetch, authHeader, jsonHeader, handleResponse, commonRequestOptions, subjects } from '../helpers';

const endpointPath='datasource'

export const datasourceService = {
    getAll,
    getById,
    getBySystemId,
    getFieldStatistics,
    getFormValues,
    getRelationshipImportQuery,
    getDatasourceImportQuery,
    getDefaultImportQuery,
    getDefaultRelationshipQuery,
    testConnection,
    create,
    update,
    checkDestroy,
    destroy
};

function getAll(signal) {
    const requestOptions = { method: 'GET', headers: authHeader(), ...commonRequestOptions() };
    return customFetch(`${config.api.url}/${endpointPath}/all`, requestOptions, signal).then(handleResponse);
}

function getById(id, signal) {
    const requestOptions = { method: 'GET', headers: authHeader(), ...commonRequestOptions() };
    return customFetch(`${config.api.url}/${endpointPath}/${id}`, requestOptions, signal)
    .then(handleResponse)
    .then(res => {
        subjects.currentDatasource.next(res.datasources[0] || {datasource_id: id});
        subjects.currentSystem.next(res.datasources[0] ? {
            system_id: res.datasources[0].system_id,
            system_name: res.datasources[0].system_name
        } : "not_found")
        return res
    });
}

function getBySystemId(id, signal) {
    const requestOptions = { method: 'GET', headers: authHeader(), ...commonRequestOptions() };
    return customFetch(`${config.api.url}/${endpointPath}/system/${id}`, requestOptions, signal).then(handleResponse);
}

function getFieldStatistics(id, signal) {
    const requestOptions = { method: 'GET', headers: authHeader(), ...commonRequestOptions() };
    return customFetch(`${config.api.url}/${endpointPath}/field_statistics/${id}`, requestOptions, signal).then(handleResponse);
}

function getFormValues(signal) {
    const requestOptions = { method: 'GET', headers: authHeader(), ...commonRequestOptions() };
    return customFetch(`${config.api.url}/${endpointPath}/form_values`, requestOptions, signal).then(handleResponse);
}

function getRelationshipImportQuery(id, relationship_map, signal) {
    const requestOptions = { 
        method: 'POST', 
        headers: {
            ...authHeader(),  
            ...jsonHeader()
        }, 
        ...commonRequestOptions(),
        body: JSON.stringify(relationship_map)  
    };
    return customFetch(`${config.api.url}/${endpointPath}/get_relationship_import_query/${id}`, requestOptions, signal).then(handleResponse)
}

function getDatasourceImportQuery(item, signal) {
    const requestOptions = { 
        method: 'POST', 
        headers: {
            ...authHeader(),  
            ...jsonHeader()
        }, 
        ...commonRequestOptions(),
        body: JSON.stringify(item)  
    };
    return customFetch(`${config.api.url}/${endpointPath}/get_datasource_import_query`, requestOptions, signal).then(handleResponse)
}

function getDefaultImportQuery(item, signal) {
    const requestOptions = { 
        method: 'POST', 
        headers: {
            ...authHeader(),  
            ...jsonHeader()
        }, 
        ...commonRequestOptions(),
        body: JSON.stringify(item)  
    };
    return customFetch(`${config.api.url}/${endpointPath}/get_default_import_query`, requestOptions, signal).then(handleResponse)
}

function getDefaultRelationshipQuery(item, signal) {
    const requestOptions = { 
        method: 'POST', 
        headers: {
            ...authHeader(),  
            ...jsonHeader()
        }, 
        ...commonRequestOptions(),
        body: JSON.stringify(item)  
    };
    return customFetch(`${config.api.url}/${endpointPath}/get_default_relationship_query`, requestOptions, signal).then(handleResponse)
}

function testConnection(item, signal) {
    const requestOptions = { 
        method: 'POST', 
        headers: {
            ...authHeader(),
            ...jsonHeader()
        }, 
        ...commonRequestOptions(),
        body: JSON.stringify(item) }; 

    return customFetch(`${config.api.url}/${endpointPath}/test_connection`, requestOptions, signal).then(handleResponse);
}


function create(items, signal) {
    const requestOptions = { 
    	method: 'POST', 
    	headers: {
            ...authHeader(),  
    		...jsonHeader()
    	}, 
        ...commonRequestOptions(),
    	body: JSON.stringify(items) };

    return customFetch(`${config.api.url}/${endpointPath}`, requestOptions, signal).then(handleResponse);
}

function update(items, signal) {
    const requestOptions = { 
    	method: 'PUT', 
    	headers: {
            ...authHeader(),  
            ...jsonHeader()
        }, 
        ...commonRequestOptions(),
    	body: JSON.stringify(items) 
    };

    return customFetch(`${config.api.url}/${endpointPath}`, requestOptions, signal).then(handleResponse);
}

function checkDestroy(items, signal) {
    const requestOptions = { 
        method: 'POST', 
        headers: {
            ...authHeader(),  
            ...jsonHeader()
        }, 
        ...commonRequestOptions(),
        body: JSON.stringify(items) };

    return customFetch(`${config.api.url}/${endpointPath}/check_delete`, requestOptions, signal).then(handleResponse);
}

function destroy(items, signal) {
    const requestOptions = { 
    	method: 'DELETE', 
    	headers: {
            ...authHeader(),  
            ...jsonHeader()
        }, 
        ...commonRequestOptions(),
    	body: JSON.stringify(items) };

    return customFetch(`${config.api.url}/${endpointPath}`, requestOptions, signal).then(handleResponse);
}