import React, {useRef, useEffect} from 'react';

import { Input, Checkbox } from '../../'
import { keyCode } from '../../../helpers'
import { useTooltip } from '../../../hooks'

export const ChangelogToolbar = ({
  itemTotalCount, 
  itemCount, 
  requestFilter, 
  filterTerm, 
  filterObjects, 
  onFilterObjectsFilter, 
  showSchemaChangesOnlyOption, 
  onSchemaChange, 
  schemaChangesOnly 
}) => {

  const inputRef = useRef()
  const {showTooltip, hideTooltip} = useTooltip()

  useEffect( () => {
    window.addEventListener("keydown", handleKeyDown)

    return () => window.removeEventListener("keydown", handleKeyDown)
  }, [])

  const handleKeyDown = (e) => {
    if ((e.ctrlKey || e.metaKey) && e.keyCode === keyCode.F) {        
      e.preventDefault();        
      inputRef.current.focus()    
    }   

    if (e.keyCode === keyCode.ESCAPE) {
      requestFilter("")
      setTimeout(() => inputRef.current.blur(), 100)
    }
  }
  const handleInputChange = (event) => {
    let { value } = ""

    // Handle standard form inputs
    if (event.target !== undefined) {
      value = event.target.value

    // Handle custom form inputs
    } else {
      value = event.value
    }

    if (value === " " && filterTerm === "") {
      // do nothing
    } else {
      requestFilter( value )
    }  
  }

  return (
    
    <div className="DataTableToolbar main-toolbar"> 

      <div 
        className="filter-container" 
        onMouseEnter={ () => showTooltip({title: "Type to filter changelog"})} 
        onMouseLeave={ hideTooltip }
      >
        <form autoComplete="off">
          { filterTerm &&
            <div className="main-toolbar-text">{`Showing ${itemCount} of ${itemTotalCount} events`}</div>
          }
          { showSchemaChangesOnlyOption &&
            <Checkbox 
              name='schema_changes_only'
              label='Schema Changes Only' 
              title='Show schema changes only'
              checked={schemaChangesOnly}
              onChange={onSchemaChange}
              />
          }
          <Input 
            ref={inputRef}
            type="text" 
            name="changelog_filter"
            value={filterTerm} 
            onChange={handleInputChange}
            placeholder="Filter..."
            isNullable={true}
            icon='filter'
            iconAsButton={true}
            withSelect={true}
            selectProps={[{
              name: 'filter_object',
              value: filterObjects.filter(x => x.checked),
              label: "Columns",
              tooltip: "Select columns to filter on",
              options: filterObjects,
              onChange: onFilterObjectsFilter
            }]}
          />
        </form>
      </div>

    </div>
    
  );
};