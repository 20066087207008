import React, { useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Checkbox } from '../../';
import { useTooltip } from '../../../hooks';
import { formatData, formatTooltip, generateURL } from '../../../helpers'


export const DataTableRow = ({
  item, 
  itemCount, 
  idColumn, 
  columns, 
  selected=false, 
  editable, 
  handleSelect, 
  actionEdit, 
  actionDelete, 
  actionRemove,
  actionRun,
  onMouseEnterRow, 
  onMouseLeaveRow,
  expandable,
  expanded,
  handleExpand,
  expandableColumns,
  expandedRowClassName,
  viewModes,
  viewMode,
  groupIndex
}) => {

  const {showTooltip, hideTooltip} = useTooltip()

  const location = useLocation()

  const runIsDisabled = (actionRun && actionRun.disabled && item[actionRun.disabled.field] === actionRun.disabled.value)

  const editItem = () => {
    actionEdit([item])
  }

  const deleteItem = () => {
    actionDelete([item])
  }

  const removeItem = () => {
    actionRemove([item])
  }

  const runItem = () => {
    !runIsDisabled && actionRun.onClick && actionRun.onClick([item])
  }

  const handleMouseEnter = (e, id) => {
    e.preventDefault()
    onMouseEnterRow(e, id)
  }
  const handleMouseLeave = (e, id) => {
    e.preventDefault()
    onMouseLeaveRow(e, id)
  }

  const handleClick = (e, id) => {
    expandable && handleExpand(id)
  }

  let columnCount = 0
  columns.forEach(column => {
    columnCount = column.type === 'chart' ? columnCount + 2 : columnCount + 1
  })
  columnCount = editable ? columnCount + 2 : columnCount
  columnCount = expandable ? columnCount + 1 : columnCount


  let expandableColumnsItemCount = 0

  if (expandableColumns) {
    expandableColumns = Array.isArray(expandableColumns) ? expandableColumns : [expandableColumns]

    expandableColumns.forEach(column => {expandableColumnsItemCount = expandableColumnsItemCount + expandableColumns.length})

    expandableColumns.forEach((column, index) => expandableColumns[index].Component = expandableColumns[index].Component || DataTableRow)
  }

  return useMemo( () => (
    <React.Fragment>
        { 
          <tr 
            key={item[idColumn]} 
            className={"row" + 
              ((editable && selected) ? ' selected' : '') +
              ((expandable && expanded) ? ' expanded ' + (expandedRowClassName || '') : '') +
              (groupIndex > 1 ? ' grouped' : '') 
               }
            onClick={e => handleExpand && expandableColumns && expandableColumnsItemCount > 0 && handleClick(e, item[idColumn])}
            onMouseEnter={e => onMouseEnterRow && handleMouseEnter(e, item[idColumn])}
            onMouseLeave={e => onMouseLeaveRow && handleMouseLeave(e, item[idColumn])}
          >
            { editable &&
              <td className="center checkbox">
              { itemCount > 0 && 
                <Checkbox
                  name={`selected-${item[idColumn]}`}
                  onChange={handleSelect}
                  checked={ selected }
                />
              }
              </td>
            }
            { expandable && 
              <td className="center toggle-expand">
              { expandableColumnsItemCount > 0 
                ? <div className={"button-toggle-expand" + (expanded ? " expanded" : "" ) } onClick={e => handleExpand(item[idColumn])}></div>
                : <div className=""></div>
              }
              </td>
            }
            {columns.map((column, index) => {

                return (
                  <React.Fragment key={index}>
                  { column.type === 'chart' &&
                    <td className={
                      'right' + 
                      ((column.className === undefined) ? '' : ' '+ column.className) + 
                      ((column.type === 'chart') ? ' chart-label small-text' : '') + 
                      ((viewModes && column.viewMode && column.viewMode !== viewMode) ? ' hide-column' : '') 
                    }>
                      {column.link !== undefined
                        ? <Link 
                            className="link" 
                            to={generateURL(column.link, item, )} 
                            state={{from: location.pathname}}
                            onMouseEnter={ () => showTooltip({
                              ...formatTooltip({
                                title: item[column.id],
                                type: column.chartType === 'percent-bar' ? 'percent' : 'integer', 
                                tooltip: item[column.tooltip],
                                value: item[column.value],
                                values: column.values,
                                label: column.label
                              })
                            }) } 
                            onMouseLeave={ hideTooltip }>
                          { formatData(
                              item[column.id], 
                              column.type
                            ) 
                          }
                          </Link> 
                        : <React.Fragment>{ item[column.id] == null
                                            ? <span>-</span> 
                                            : <span
                                                onMouseEnter={ () => showTooltip({
                                                  ...formatTooltip({
                                                    title: item[column.id],
                                                    type: (column.unit || (column.chartType === 'percent-bar' ? 'percent' : 'integer')), 
                                                    tooltip: item[column.tooltip],
                                                    value: item[column.value],
                                                    values: column.values,
                                                    label: column.label
                                                  })
                                                }) } 
                                                onMouseLeave={ hideTooltip }>
                                              { formatData(
                                                  item[column.id], 
                                                  (column.unit || (column.chartType === 'percent-bar' ? 'percent' : 'integer-format')), 
                                                  undefined,
                                                  undefined,
                                                  undefined, 
                                                  undefined,
                                                ) 
                                              }
                                              </span>
                                          }
                          </React.Fragment>
                      }

                    </td>
                  }

                  <td className={
                      ((column.align === undefined) ? ' left' : ' ' + column.align) + 
                      ((column.className === undefined) ? '' : ' '+ column.className) + 
                      ((column.type === 'chart') ? ' chart' : '') +
                      ((column.type === 'icon') ? ' type-icon' : '') +
                      ((column.type === 'button') ? ' type-button' : '') +
                      ((column.type === 'boolean' || column.type === 'pill') ? ' type-boolean' : '') +
                      ((column.type === 'user-photo' || column.type === 'group-photo') ? ' type-photo' : '') +
                      ((viewModes && column.viewMode && column.viewMode !== viewMode) ? ' hide-column' : '') 
                    } 
                  >
                    { groupIndex > 1 && column.isGrouped ?
                    <React.Fragment></React.Fragment>
                    : <React.Fragment>
                    {column.link !== undefined
                      ? <Link 
                          className="link" 
                          to={generateURL(column.link, item, )} 
                          state={{from: location.pathname}}
                          onMouseEnter={ () => showTooltip({
                            ...formatTooltip({
                              title: item[column.id],
                              type: (column.type === 'chart' ? (column.chartType === 'percent-bar' ? 'percent' : 'integer') : column.type),
                              tooltip: item[column.tooltip],
                              value: item[column.value],
                              values: column.values,
                              label: column.label
                            })
                          }) } 
                          onMouseLeave={ hideTooltip }>
                          { formatData(
                              item[column.id], 
                              column.type
                            ) 
                          }
                        </Link> 
                      : <React.Fragment>
                          {column.email !== undefined
                            ? <a 
                                className="link" 
                                href={"mailto:"+item[column.email]}
                                onMouseEnter={ () => showTooltip({
                                  ...formatTooltip({
                                    title: item[column.id],
                                    type: (column.type === 'chart' ? (column.chartType === 'percent-bar' ? 'percent' : 'integer') : column.type),
                                    tooltip: item[column.tooltip],
                                    value: item[column.value],
                                    values: column.values,
                                    label: column.label
                                  })
                                }) } 
                                onMouseLeave={ hideTooltip }>
                                { formatData(
                                    item[column.id], 
                                    column.type
                                  ) 
                                }
                              </a>
                            : <span 
                                onMouseEnter={ () => showTooltip({
                                  ...formatTooltip({
                                    title: item[column.id],
                                    type: (column.type === 'chart' ? (column.chartType === 'percent-bar' ? 'percent' : 'integer') : column.type),
                                    tooltip: item[column.tooltip],
                                    value: item[column.value],
                                    values: column.values,
                                    label: column.label
                                  })
                                }) } 
                                onMouseLeave={ hideTooltip }>
                                { formatData(
                                    item[column.id], 
                                    column.type, 
                                    item[column.value],
                                    column.values,
                                    column.chartType, 
                                    (column.data ? {data: item[column.data.data], max: item[column.data.max]} : undefined),
                                    column.label,
                                    column.onClick
                                  ) 
                                }
                              </span>
                          }
                        </React.Fragment>
                    }</React.Fragment>
                    }

                  </td>

                </React.Fragment>
                )

            })}
            { editable && 
              <td className="last-column">
              { (actionEdit || actionDelete || actionRemove || actionRun) &&
                <div className="buttons">
                  { actionRun && 
                    <div name="run" className={"icon-run" + (runIsDisabled ? " disabled" : "")} onMouseEnter={ () => showTooltip({title: (runIsDisabled ? "Already running" : "Run")})} onMouseLeave={ hideTooltip } onClick={ () => runItem() }></div>
                  }
                  { actionEdit && 
                    <div name="edit" className="icon-edit" onMouseEnter={ () => showTooltip({title: "Edit"})} onMouseLeave={ hideTooltip } onClick={ () => editItem() }></div>
                  }
                  { actionDelete && 
                    <div name="delete" className="icon-delete" onMouseEnter={ () => showTooltip({title: "Delete"})} onMouseLeave={ hideTooltip }  onClick={ () => deleteItem() }></div>
                  }
                  { actionRemove && 
                    <div name="remove" className="icon-remove" onMouseEnter={ () => showTooltip({title: "Remove"})} onMouseLeave={ hideTooltip } onClick={ () => removeItem() }></div>
                  }
                </div>
              }
              </td>
            }
          </tr>
        }
        { expandable && expanded &&
        <tr 
            key={item[idColumn] + "expanded"} 
            className={"row-expansion " + (expandedRowClassName || '') }
          >
          <td colSpan={columnCount} className="cell-expansion">
          { expandableColumns.map((expandableColumn, index) => {

            if (expandableColumn && item[expandableColumn.dataKey] && item[expandableColumn.dataKey].length > 0) {
              return (
                <table key={index} className={(expandableColumn.showHeader ? '' : 'hide-header') + (expandableColumn.className ? ' ' + expandableColumn.className : '')}>
                  <thead>
                    <tr>
                    {expandableColumn.columns.map((column) => (
                      <th key={column.id}>
                        {column.name}
                      </th>
                    ))}
                    </tr>
                  </thead>
                  <tbody>

                      {item[expandableColumn.dataKey].map((column, index) => {

                        return <expandableColumn.Component
                          key={index}
                          item={column}
                          idColumn={expandableColumn.idColumn || idColumn}
                          columns={expandableColumn.columns}
                          editable={false}
                          expandable={expandableColumn.expandable}
                          expandableColumns={expandableColumn.expandableColumns}
                          handleExpand={handleExpand}
                        />
                      })}

                  </tbody>
                </table>
                )
              }
          })}
          </td>
        </tr>
      }
    </React.Fragment>
    ) , [item, selected, expanded, onMouseEnterRow, onMouseLeaveRow, viewMode]
  );
};