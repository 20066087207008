import config from '../config';
import { authHeader, customFetch, handleResponse, commonRequestOptions } from '../helpers';

const endpointPath='changelog'

export const changelogService = {
    getById,
    getByJobId,
    getChangedItemsCount
};

function getById(objectName, objectId, schemaChangesOnly, signal) {
    const requestOptions = { method: 'GET', headers: authHeader(), ...commonRequestOptions() };
    const schemaChangesOnlyQuery = schemaChangesOnly ? '?schema_changes_only=true' : ''
    return customFetch(`${config.api.url}/${endpointPath}/${objectName}/${objectId}${schemaChangesOnlyQuery}`, requestOptions, signal).then(handleResponse);
}

function getByJobId(jobId, schemaChangesOnly, signal) {
    const requestOptions = { method: 'GET', headers: authHeader(), ...commonRequestOptions() };
    const schemaChangesOnlyQuery = schemaChangesOnly ? '?schema_changes_only=true' : ''
    return customFetch(`${config.api.url}/${endpointPath}/job/${jobId}${schemaChangesOnlyQuery}`, requestOptions, signal).then(handleResponse);
}

function getChangedItemsCount(jobId, signal) {
    const requestOptions = { method: 'GET', headers: authHeader(), ...commonRequestOptions() };
    return customFetch(`${config.api.url}/${endpointPath}/changed_items_count/${jobId}`, requestOptions, signal).then(handleResponse);
}