import React, { useState, useEffect, forwardRef } from 'react'
import { userService } from '../services';
import { Checkbox, Select, formatSelectOptions, Input, Textarea, textareaInitialState, textareaToRaw  } from '../components';
import { getBatchEditValue } from '../helpers'
import { useGlobalState, useMessage } from '../hooks'

const _AddSystemForm = ({ mode, data, submitForm, loading, setLoading }, ref) => { 
	
	const initialFormState = {
		system_id: null, 
		system_name: '',
		system_type: '',
		system_description: textareaInitialState({}), 
		system_owner_user_id: ''
	}

	const initialBatchEditItems = {
		batch_system_owner_user_id: false
	}

	const [loggedInUser, setLoggedInUser] = useGlobalState('loggedInUser');
	const [formData, setFormData] = useState(initialFormState);
	const [batchEdit, setBatchEdit] = useState(false);
	const [batchEditItems, setBatchEditItems] = useState(initialBatchEditItems)
	const [users, setUsers] = useState([]);	

	const { showMessage } = useMessage()

	useEffect( () => {
		// Get form data
    fetchUsers();
	}, []);

	useEffect(() => {

		setBatchEdit(false)
	    if (data.length === 1) {
			setFormData({ 
				system_id: data[0].system_id, 
				system_name: data[0].system_name || '',
				system_type: data[0].system_type || '',
				system_description: textareaInitialState({value: data[0].system_description}), 
				system_owner_user_id: data[0].system_owner_user_id
			})
		} else if(data.length > 1 && mode !== 'add') {
			setBatchEdit(true)

			setFormData({  
				system_owner_user_id: getBatchEditValue(data, 'system_owner_user_id')
			})
		} else if(mode === 'add') {
			setFormData({ 
				...formData, system_owner_user_id: loggedInUser?.user_id
			})
		} else {
			resetForm()		
		}
		
	}, [mode, data, loggedInUser]);
 
	const fetchUsers = async () => {
    userService.getAll()
      .then(res => { 

      	setUsers(res.users); 

      })
      .catch(err => { showMessage(err, 'error') });
  }

	const handleInputChange = (event, formPart) => {
		let { name, value } = ""

		// Handle standard form inputs
		if (event.target !== undefined) {
		  name = event.target.name
		  value = event.target.value

		  // If input element is a checkbox, we cannot use "value"
	    if (event.target.type === "checkbox") { value = event.target.checked }

	  // Handle custom form inputs
	  } else {
	  	name = event.name
	  	value = event.value
	  }

	  switch(formPart) {
	  	case 'batch':
	  		setBatchEditItems({ ...batchEditItems, [name]: value })
	  		break
	  	default:
	  		setFormData({ ...formData, [name]: value })
	  }
	}

	const onSubmit = event => {

		event.preventDefault()

		setLoading(true)
				
		// Form validation
		//if (!formData.user_fullname || !formData.user_username) return

		let dataToSubmit

		if (batchEdit) {
			
			let key = ''
			let tmp = {}
			const objects = Object.entries(batchEditItems)

			for (let i = 0; i < objects.length; i++) {
				if (objects[i][1]) {
					key = objects[i][0].replace("batch_","")
					tmp = {...tmp, [key]: formData[ key ]}
				}
			}

			dataToSubmit = data.map( item => {
				return {system_id: item.system_id, 
								system_name: item.system_name,
								system_type: item.system_type,
								system_owner_user_id: item.system_owner_user_id, 
								...tmp
							}
			})

		} else {
			dataToSubmit = {
				...formData,
				system_description: textareaToRaw({value: formData.system_description}),
			}
		}
		
		// submit form
		submitForm(dataToSubmit)
		.then(res => resetForm())
		.catch(err => {
			// Errors handled in submitForm function
		})
		.finally(res => setLoading(false))    
		    
	}


  const resetForm = () => {

  	setBatchEdit(false)
  	setBatchEditItems(initialBatchEditItems)
  	setFormData(prevFormData => {
			return {...initialFormState, 
							system_owner_user_id: (loggedInUser ? loggedInUser.user_id : null)
						}
		}) 
  }

  return (
  	<div className="form">
	    <form
			  onSubmit={onSubmit}
			  ref={ref}
			>
			{ !batchEdit &&
				<div className="form-block vertical">
		      <Input type="text" 
		      	name="system_name" 
		      	label="Name"
		      	value={formData.system_name} 
		      	onChange={handleInputChange}
		      	disabled={loading}
		      />
	      </div>
			}
			{ !batchEdit &&
			<div className="form-block vertical">
	      <Input type="text" 
	      	name="system_type"
	      	label="Type" 
	      	value={formData.system_type} 
	      	onChange={handleInputChange}
	      	disabled={loading}
	      />
      </div>
			}
			{ !batchEdit &&
			<div className="form-block vertical">
				<label>Description</label>
				<Textarea
	      	name="system_description" 
	      	value={formData.system_description} 
	      	onChange={handleInputChange}
	      	disabled={loading}
	      />
	    </div>
	  	}
	    <div className="form-block vertical">
				{ batchEdit 
					? <label>
							<Checkbox 
								checked={batchEditItems.batch_system_owner_user_id}
								name="batch_system_owner_user_id"
								label="Edit Owner"
								onChange={ e => handleInputChange(e, 'batch')}
								disabled={loading}
							/>
						</label>
					: <label>Owner</label>
				}
				<Select 
          name="system_owner_user_id"
          value={formData.system_owner_user_id === 'multiple' ? undefined : formData.system_owner_user_id}
          options={ formatSelectOptions({options: users, optionValue: "user_id", optionLabel: "user_fullname", tooltip: "user_username" }) }
          onChange={handleInputChange} 
          placeholder={formData.system_owner_user_id === 'multiple' ? '< Multiple >' : ""}
          disabled={loading || (batchEdit && !batchEditItems.batch_system_owner_user_id)}
        />
    	</div>

	    </form>
	  </div>
  )
}

export const AddSystemForm = forwardRef(_AddSystemForm)
