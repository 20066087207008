import React, { createContext, useState, useMemo, useEffect } from "react";

export const SideDialogContext = createContext();

export const SideDialogProvider = ({ children }) => {
  const [sideDialog, setSideDialog] = useState({})
  const [loadingSideDialog, setLoadingSideDialog] = useState(false)
  const [show, setShow] = useState(false)

  const value = useMemo( () => (
    { 
      sideDialog, 
      setSideDialog,
      loadingSideDialog, 
      setLoadingSideDialog,
      show, 
      setShow 
    }
  ), [
    sideDialog, 
    setSideDialog,
    loadingSideDialog, 
    setLoadingSideDialog,
    show, 
    setShow 
  ])

  return (
    <SideDialogContext.Provider
      value={value}
    >
      {children}
    </SideDialogContext.Provider>
  );
}