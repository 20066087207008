import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Icon, UserLink } from '../../';
import { useGlobalState } from '../../../hooks';
import { formatData, Operation } from '../../../helpers'
import { formatChangelogValue } from '../helpers'


export const ChangelogItem = ({data, objectName, className}) => {

  const [hidden, setHidden] = useState(true)
  const [displayNames] = useGlobalState('displayNames')


  const toggleItem = (state) => {
    setHidden(state || !hidden)
  }

  let name = ""

  if (data.is_service_account_user) {
    name = "Datasource Sync"
  } else if (data.is_existing_user) {
    name = data.changed_by_user_fullname
  } else {
    name = data.changed_by_user_username
  }

  let headerAttributes = ""
  let headerFields = ""

  const changedAttributeCount = data.changedData ? data.changedData.length : 0
  const changedFieldCount = data.fields ? data.fields.length : 0
  const changedTotalCount = changedFieldCount + changedAttributeCount

  switch (data.operation) {
    case "I": 
      headerAttributes = "Added to Katalogue"
      break
    case "D":
      headerAttributes = "Deleted from Katalogue"
      break
    case "U":
      headerAttributes = `${ changedAttributeCount } attribute${changedAttributeCount > 1 ? 's' : ''} changed`
      headerFields = `${ changedFieldCount } field${changedFieldCount > 1 ? 's' : ''} changed`
      break
    default:
      break
  }

  const expandable = data.operation === 'U'

  return (
    <div className={"ChangelogItem" + (hidden ? " hidden" : "") + (className ? " " + className : "")}>
      <div className="changelog-item-header">
      { data.is_service_account_user && data.job_id && data.task_id && objectName !== 'user' ? 
        <Link to={`/tasks/${data.task_id}/${data.job_id}?tab=changelog&object=${objectName}`}>
        <UserLink 
          userId={data.changed_by_user_id}
          userName={name}
          userPhoto={data.changed_by_user_photo}
          userTitle={undefined}
          userDepartment={undefined}
          header={formatData(data.created_timestamp,'datetime')}
          photoPosition='right'
          className="width-160 photo-background link"
          type={data.is_service_account_user ? 'SERVICE_ACCOUNT' : 'USER'}
        />
        </Link>
        :

        <UserLink 
          userId={data.changed_by_user_id}
          userName={name}
          userPhoto={data.changed_by_user_photo}
          userTitle={undefined}
          userDepartment={undefined}
          header={formatData(data.created_timestamp,'datetime')}
          photoPosition='right'
          className="width-160 photo-background"
          type={data.is_service_account_user ? 'SERVICE_ACCOUNT' : 'USER'}
        />
      }
      </div>
      <div className={"changelog-item-body" + (expandable ? " expandable" : "")} onClick={() => hidden && expandable && toggleItem()}>
        { expandable 
          ? <React.Fragment>
              
              { changedFieldCount > 0 && 
                <div onClick={() => hidden && toggleItem(false)}>
                  <div className={"changelog-item-body-header"} onClick={() => !hidden && toggleItem(false)}>
                    { headerFields }
                    { !hidden &&
                      <div className="toggle-item" onClick={() => toggleItem(false)}></div>
                    }
                  </div>
                  <div className="changelog-item-body-text">
                    <table className="field-list">
                      <thead>
                        <tr>
                          <th>
                          Operation
                          </th>
                          <th>
                          </th>
                          <th>
                          Field
                          </th>
                          <th>
                          Datatype
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                      {
                        data.fields && data.fields.map((field, index) => { 
                          return (
                            <tr key={index}>
                              <td className="type-pill">
                              <span className={Operation[field.operation].className}>{ Operation[field.operation].label }</span>
                              </td>
                              <td className="type-icon">
                                <Icon name={field.datatype_category} tooltip={field.datatype_fullname} />
                              </td>
                              <td className="type-fieldname">
                                { !hidden && field.operation !== 'D'
                                  ? <Link className="link" to={`/browse_datasets/${field.system_id}/${field.datasource_id}/${field.dataset_group_id}/${field.dataset_id}/${field.field_id}`}>{field.field_name}</Link>
                                  : <React.Fragment>{field.field_name}</React.Fragment>
                                }

                              </td>
                              <td className="small-text">
                                {field.datatype_fullname}
                              </td>
                            </tr>
                          )
                        })
                      }
                      </tbody>
                    </table>
                  </div>
                </div>
              }
              { changedAttributeCount > 0 &&
                <div onClick={() => hidden && toggleItem(false)}>
                  <div className={"changelog-item-body-header" + (changedAttributeCount !== changedTotalCount ? " second-row" : "")} onClick={() => !hidden && changedAttributeCount === changedTotalCount && toggleItem(false)}>
                    { headerAttributes }
                    { !hidden &&
                      <div className="toggle-item" onClick={() => toggleItem(false)}></div>
                    }
                  </div>
                  <div className="changelog-item-body-text">
                    <table>
                      <thead>
                        <tr>
                          <th>
                          Attribute
                          </th>
                          <th>
                          To
                          </th>
                          <th>
                          From
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                      { data.changedData && data.changedData.map((value, index) => { 
                          return (
                            <tr key={index}>
                              <td>
                              {displayNames[value.attribute]?.display_name || value.attribute}
                              </td>
                              <td>
                                {formatChangelogValue('new', value)}
                              </td>
                              <td>
                                {formatChangelogValue('old', value)}
                              </td>
                            </tr>
                          )
                        })
                      }

                      </tbody>
                    </table>
                  </div>
                </div>
              } 
            </React.Fragment>
          : <div className="changelog-item-body-header strong">
            { headerAttributes }
            </div>
        }
      </div>
    </div>
  );
};