import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useQueryParams, useTooltip } from '../hooks';

export const MainColumn = ({children}) => {

	const { queryParams, setHistory } = useQueryParams()
  const {showTooltip, hideTooltip} = useTooltip()

  const navigate = useNavigate()

	const [hideMainColumn, setHideMainColumn] = useState(queryParams.hidedetails || (localStorage.getItem('hideMainColumn') === "true" ? true : false))

  const toggleMainColumn = () => {

    const state = !hideMainColumn

    setHideMainColumn(state)
    setHistory({'hidedetails': !hideMainColumn})

    // Persist state in local storage
    localStorage.setItem('hideMainColumn', state.toString())
  }

  const goBack = (e) => {
    // Prevent click event to fire on parent element
    e.stopPropagation()

    // Go back to previous page in browser history
    navigate(-1)

  }

  return (
  	<div className={"MainColumn column" + (hideMainColumn ? " hide" : "")} onClick={() => { if (hideMainColumn) { toggleMainColumn() }} }>
      <button 
        className="button-back" 
        onClick={(e) => goBack(e)} 
        onMouseEnter={ () => showTooltip({title: "Go back to previous page"})} 
        onMouseLeave={ hideTooltip }
      >
        <span className="button-back-arrow"></span>
        <span className="button-back-label">Back</span>
      </button>
  		<div 
        className="button-toggle-main-column" 
        onClick={toggleMainColumn} 
        onMouseEnter={ () => showTooltip({title: hideMainColumn ? "Expand object details pane" : "Minimize object details pane"})} 
        onMouseLeave={ hideTooltip }
      >
        <span></span>
      </div>
  		
      { children }

  	</div>
  	)
}

