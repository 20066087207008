import React, { useEffect, useCallback, forwardRef } from 'react';
import Cytoscape from 'cytoscape';
import fcose from 'cytoscape-fcose';
import CytoscapeComponent from 'react-cytoscapejs';

import { useDebounce } from  '../../../hooks';


Cytoscape.use(fcose);

const _Graph = ({data, setSelectedItems, setHoveredItem, onReady, minZoom=0.5, maxZoom=2, setZoom, zoom=1.1, layoutOptions}, ref) => {

  const selectedItems = {nodes: [], edges: []}

  const selectItems = (items) => {
    setSelectedItems(selectedItems)
  }

  const cyRef = ref

  const debouncedSelectItems = useDebounce(selectItems).debounce

  const handleSelectItems = (items) => {
    switch(items.action) {
      case 'select':
        if (items.nodes) {
          selectedItems.nodes = selectedItems.nodes ? [...selectedItems.nodes, ...items.nodes] : [...items.nodes]
        } 
        if (items.edges) {
          selectedItems.edges = selectedItems.edges ? [...selectedItems.edges, ...items.edges] : [...items.edges]
        }
        break
      case 'unselect':
        if (items.nodes) {
          const node = items.nodes[0].data()
          selectedItems.nodes = selectedItems.nodes.filter(x => x.data().id !== node.id)
        } 
        if (items.edges) {
          const edge = items.edges[0].data()
          selectedItems.edges = selectedItems.edges.filter(x => x.data().id !== edge.id)
        } 
        break
      default:
        break
    }
    debouncedSelectItems()
  }

  useEffect( () => {
    return () => {
      if (cyRef.current) {
        cyRef.current.removeAllListeners();
        cyRef.current = null;
      }
      debouncedSelectItems.cancel();
    }
  }, [])

  const stylesheet = [
    {
      selector: 'node.node',
      style: {
        "label": 'data(label)',
        "width": 'data(nodeSize)',
        "height": 'data(nodeSize)',
        "shape": 'ellipse',
        "border-color": '#222020',
        "border-width": 1,
        "background-color": '#fff',
        "font-family": "Rubik-Light",
        //"text-background-color": "#FAF9F7",
        //"text-background-opacity":0.7,
        "text-margin-y": -4,
        "z-index": 0
      }
    },
    {
      selector: 'node[label]',
      style: {
        "background-color": "#fff",
        "font-size": "0.9em",
        "color": "#B8ADAB",
        "font-family": "Rubik-Light",
         "text-background-padding":"3px",
      }
    },
    {
      selector: ':parent[label]',
      style: {
        "background-color": "#fff",
        "font-size": "0.9em",
        "color": "#222020",
        "font-family": "Rubik-Light",

      }
    },
    {
      selector: ':parent',
      style: {
        "label": 'data(label)',
        "padding": "20px",
        'background-opacity': 0.03,
        "background-color": "#222020",
        'border-width': 0,
        "shape": 'round-rectangle',
        "text-halign": "center",
        "text-valign": "top",
        "text-margin-y": 20,
      }
    },
    {
      selector: 'edge',
      style: {
        width: "0.9px",
        "line-color": '#222020',
        //"target-arrow-color": "#222020",
        //"target-arrow-shape": "triangle",
        //"arrow-scale": 0.8,
        "curve-style": "bezier"
      }
    },
    {
      selector: 'node.blurred-node',
      style: {
        "border-color": '#B8ADAB'
      }
    },
    {
      selector: 'node.highlighted-node',
      style: {
        "text-background-color": "#FAF9F7",
        "text-background-opacity":0.8,
        "background-color": '#222020',
        "border-color": '#222020',
        "border-width": 2,
        "color": "#222020",
        "font-family": "Rubik-Regular",
        "z-index": 30
      }
    },
    {
      selector: 'node.neighbor-node',
      style: {
        "text-background-color": "#FAF9F7",
        "text-background-opacity":0.8,
        "border-width": 2, 
        "border-color": '#222020', 
        "color": "#222020", 
        "z-index": 10 
      }
    },

    {
      selector: 'edge.blurred-edge',
      style: {
         "line-color": '#B8ADAB',
         "width": 0.9
      }
    },
    {
      selector: 'edge.highlighted-edge',
      style: {
         "width": 2, 
         "line-color": '#222020', 
         "z-index": 30
      }
    },

    // Mouseover styles
    {
      selector: 'node.mouseover-blurred-node',
      style: {
        "border-color": '#B8ADAB',
        "border-width": 1,
        "background-color": '#fff',
        "color": "#B8ADAB",
        "font-family": "Rubik-Light",
      }
    },
    {
      selector: 'node.mouseover-highlighted-node',
      style: {
        "text-background-color": "#FAF9F7",
        "text-background-opacity":0.8,
        "background-color": '#222020',
        "border-color": '#222020',
        "border-width": 2,
        "color": "#222020",
        "font-family": "Rubik-Regular",
        "z-index": 30
      }
    },
    {
      selector: 'node.mouseover-neighbor-node',
      style: {
        "text-background-color": "#FAF9F7",
        "text-background-opacity":0.8,
        "border-width": 2, 
        "border-color": '#222020', 
        "color": "#222020", 
        "z-index": 10 
      }
    },

    {
      selector: 'edge.mouseover-blurred-edge',
      style: {
         "width": 0.9,
         "line-color": '#B8ADAB'       
      }
    },
    {
      selector: 'edge.mouseover-highlighted-edge',
      style: {
         "width": 2, 
         "line-color": '#222020', 
         "z-index": 30
      }
    },
    {
      selector: 'edge.mouseover-table-blurred-edge',
      style: {
         "width": 0.9, 
         "line-color": '#222020', 
      }
    },
    {
      selector: 'edge.mouseover-table-highlighted-edge',
      style: {
         "width": 3, 
         "line-color": '#222020', 
      }
    },

  ]

const options = layoutOptions || {
  animate: false,
  zoom: zoom,
  nodeRepulsion: node => 100000,
  edgeElasticity: edge => 0.2,
  idealEdgeLength: 50,
  gravityRangeCompound: 10,
  gravityCompound: 0.1
}
const layout = { name: 'fcose', ...options };

const cyCallback = useCallback(
  (cy) => {
    // this is called each render of the component, don't add more listeners
    if (cyRef.current) return;

    cyRef.current = cy;

    cy.maxZoom(maxZoom)
    cy.minZoom(minZoom)

    // ---------------------------------------------------------------
    // A note on events in Cytoscape.js
    // 
    // When selecting objects (edges or nodes) in Cytoscape,
    // one select event is fired once for each selected object.
    //
    // When selecting other objects than previously selected,
    // one unselect event is fired for each object before 
    // the select event is fired for the new objects.
    //
    // With this in mind, the code below looks a bit complicated
    // to handle Shift-selects (add and remove to current selection)
    //
    // ---------------------------------------------------------------

    cy.on('select', 'node', function(evt){

      if (evt.target.nodes('.node').length > 0) {
        // Blur all nodes
        cy.nodes('.node').addClass('blurred-node')
        // Blur all edges
        cy.edges().addClass('blurred-edge')

        // Highlight selected node
        evt.target.nodes('.node').addClass('highlighted-node')
        // Highlight neighbor nodes
        evt.target.neighborhood('node').addClass('neighbor-node')
        // Highlight neighbor edges
        evt.target.neighborhood('edge').addClass('highlighted-edge')

        handleSelectItems({ nodes: evt.target.nodes('.node'), action: 'select' })
      }
    });

    cy.on('unselect', 'node', function(evt){


      // Remove classes for all if no nodes are selected
      if (cy.nodes(':selected').map(el => el.data()).length === 0) {
        cy.nodes('.node').removeClass('blurred-node')
        cy.edges().removeClass('blurred-edge')
      }

      // Remove classes on nodes that are not related to any other selected nodes
      evt.target.neighborhood('node').forEach(el => {
        if (!cy.nodes(':selected').neighborhood('node').includes(el)) {
          el.removeClass('neighbor-node')
        }
      })

      // Remove classes on edges that are not related to any other selected nodes
      evt.target.neighborhood('edge').forEach(el => {
        if (!cy.nodes(':selected').neighborhood('edge').includes(el)) {
          el.removeClass('highlighted-edge')
        }
      })

      // Remove class from unselected node
      evt.target.nodes('.node').removeClass('highlighted-node')

      handleSelectItems({ nodes: evt.target.nodes(), action: 'unselect' })
    });

    cy.on('select', 'edge', function(evt){

      // Blur all edges
      cy.edges().addClass('blurred-edge')
      // Blur all nodes
      cy.nodes('.node').addClass('blurred-node')

      // Highlight selected edges
      evt.target.edges().addClass('highlighted-edge')
      // Highlight neighbor nodes
      evt.target.connectedNodes().addClass('neighbor-node')

      handleSelectItems({ edges: evt.target.edges(), action: 'select' })

    });

    cy.on('unselect', 'edge', function(evt){

      // Remove classes for all if no edges are selected
      if (cy.edges(':selected').map(el => el.data()).length === 0) {
        cy.edges().removeClass('blurred-edge')
        cy.nodes('.node').removeClass('blurred-node')
      }

      // Remove classes on nodes that are not related to any other selected edges
      evt.target.connectedNodes().forEach(el => {
        if (!cy.edges(':selected').connectedNodes().includes(el)) {
          el.removeClass('neighbor-node')
        }
      })

      // Remove class from unselected edge
      evt.target.edges().removeClass('highlighted-edge')

      handleSelectItems({ edges: evt.target.edges(), action: 'unselect' })
    });

    cy.on('mouseover', 'node', function(evt){
      if (evt.target.nodes('.node').length > 0) {
        cy.edges().addClass("mouseover-blurred-edge")
        cy.nodes('.node').addClass("mouseover-blurred-node")

        evt.target.nodes('.node').addClass('mouseover-highlighted-node')
        evt.target.neighborhood('node').addClass('mouseover-neighbor-node')
        evt.target.neighborhood('edge').addClass('mouseover-highlighted-edge')

        setHoveredItem({
          nodes: evt.target.nodes('.node').map(el => {return el.data()}),
          neighborEdges: evt.target.neighborhood('edge').map(el => {return el.data()}),
        })
      }
    });


    cy.on('mouseover', 'edge', function(evt){

      cy.edges().addClass('mouseover-blurred-edge')
      cy.nodes('.node').addClass('mouseover-blurred-node')
      evt.target.edges().addClass('mouseover-highlighted-edge')
      evt.target.connectedNodes().addClass('mouseover-neighbor-node')

      setHoveredItem({
        edges: evt.target.edges().map(el => {return el.data()})
      })

    });

    cy.on('mouseout', function(evt){
      // One common function for all mouseout events since mouseout event from edges and nodes 
      // is not triggered when going directly from one of them to another object outside of the graph
      cy.edges().removeClass('mouseover-blurred-edge')
      cy.nodes('.node').removeClass('mouseover-blurred-node')
      cy.edges().removeClass('mouseover-highlighted-edge')
      cy.nodes('.node').removeClass('mouseover-neighbor-node')
      cy.nodes('.node').removeClass('mouseover-highlighted-node')
      
      setHoveredItem(undefined)
    });

    cy.on('zoom', function(evt){
      setZoom(cy.zoom())
    });

    cy.on('ready', evt => {
      onReady && onReady()
    })
  },[]);

  return (
    <CytoscapeComponent 
        elements={data} 
        layout={layout} 
        stylesheet={stylesheet} 
        className="graph-canvas"
        cy={cyCallback}
        />
    
  );
}

export const Graph = React.memo(forwardRef(_Graph))
