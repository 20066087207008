import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import { userService } from '../services'
import { Input, FullscreenMessage } from '../components'
import { useGlobalState, useMessage } from '../hooks'

export const ForgotPassword = ( props) => {
  
  const initialFormState = {
    username: ''
  }

  const [formData, setFormData] = useState(initialFormState)
  const [loggedInUser, setLoggedInUser] = useGlobalState('loggedInUser');
  const [isLoggedIn, setIsLoggedIn] = useGlobalState('isLoggedIn')
  const [settings] = useGlobalState('settings')
  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState(false)
  const [time, setTime] = useState(10)
  const [startTimer, setStartTimer] = useState(false)

  const { message, showMessage, hideMessage } = useMessage()
  const navigate = useNavigate()

  useEffect(() => {

    // Redirect to start page if user is already logged in or mailing is not enabled
    if ((isLoggedIn && loggedInUser) || !settings.MAIL_IS_ENABLED) { 
      navigate('/')
    }    
  }, []);

  useEffect(() => {

    // Redirect to start page automatically when timer reached 0
    if (time === 0) { 
      goToLogin()
    }  
  }, [time]);


  useEffect(() => {

    // Start timer for auto redirect
    if (startTimer) {
      setInterval(() => {setTime(prev => prev-1)}, 1000);
    }
  }, [startTimer]);

  const handleInputChange = (event) => {
    const { value, name } = event.target

    hideMessage()
  
    setFormData({ ...formData, [name]: value })
  }

  const onSubmit = async (event) => {

    event.preventDefault();
    setLoading(true)
    hideMessage()
    
    // Validation
    if (!formData.username || formData.username === "") {
      showMessage(
        {dataValidationError: {
          msg: "Please enter your username to continue"
        }}, 
        "error",
        true
      )
      setLoading(false)
      return null
    }
    
    const users = {
      user_username: formData.username
    }
    
    await userService.forgotPassword(users)
      .then(async res => { 
        setSuccess(true)
        setStartTimer(true);
      })
      .catch(error => {
        showMessage(
          error, 
          "error",
          true
        )
      })
      .finally(res => { 
        setLoading(false)
      })

  }

  const goToLogin = () => {
    navigate('/')
  }

  return (
    <div className="ChangePassword fullscreen">
      <div className="fullscreen-content">
      
      <div className="close-button" onClick={ () => navigate(-1) }>
        <div className="close-button-label">Back to login</div>
        <div className="close-button-icon"></div>
      </div>

      <div className="fullscreen-logo"></div>

      { success 
        ? 
          <div className="fullscreen-text">
            <h1>Check Your Email!</h1>
            <p>&nbsp;</p>
            <p>Please check the email address associated with your Katalogue account for instructions on how to reset your password.</p>
            <p>&nbsp;</p>
            <p>Redirecting to login page in {time} seconds...</p>
            <p>&nbsp;</p>
            <button type="button" className="button main-button" onClick={goToLogin}><span>Go to Login</span></button>
          </div>
      : 
        <React.Fragment>
          <div className="fullscreen-text">
            <h1>Forgot Your Password?</h1>
            <p>Enter your Katalogue username to send a password reset link to the email address associated with your account.</p>
            <p>&nbsp;</p>
            <form onSubmit={onSubmit}>
              <Input
                type="text"
                name="username"
                className="large"
                label="Username"
                labelAsPlaceholder={true}
                value={formData.username}
                onChange={handleInputChange}
                disabled={loading}
                autoComplete="new-password"
                required={true}
              />
              
              <p>&nbsp;</p>
              
              <button 
                type="submit" 
                className={"button large-button" + (loading ? ' loading' : '')}
                disabled={loading}
                ><span>{loading ? 'Sending...' : 'Request Reset Link'}</span></button>
            </form>
          </div>

          <FullscreenMessage message={message} />

        </React.Fragment>
      }

      </div>
    </div>
  )

}