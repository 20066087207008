import React, {useState, useRef, useEffect} from 'react';

import { Checkbox, Button, Input, SelectButtons } from '../../'
import { keyCode } from '../../../helpers'
import { useTooltip } from '../../../hooks'

const CustomFilterControl = ({type, name, label, tooltip, onChange, filterObjects, defaultValue=undefined, requestFilter }) => {

    const [controlValue, setControlValue] = useState(defaultValue)


    const handleChange = (event) => {
      let { name, value } = ""

      // Handle standard form inputs
      if (event.target !== undefined) {
        name = event.target.name
        value = event.target.value

        // If input element is a checkbox, we cannot use "value"
        if (event.target.type === "checkbox") { value = event.target.checked }

      // Handle custom form inputs
      } else {
        name = event.name
        value = event.value
      }

      const res = {[name]: value}
      
      setControlValue(value)

      onChange && onChange(res)
      filterObjects && requestFilter(undefined, res)

    }

    let element
    switch(type) {
      case 'checkbox':
        element = <Checkbox 
          name={name}
          label={label} 
          title={tooltip}
          checked={controlValue}
          onChange={handleChange}
          />
        break
      default:
        break
    }
    return element
  }

export const DataTableToolbar = ({
  title, 
  buttons, 
  editable=true, 
  itemTotalCount=0, 
  selectedItems, 
  selectedItemsCount, 
  itemCount, 
  filterable, 
  requestFilter, 
  filterTerm,
  filterControls,
  onFilterObjectsFilter,
  filterObjects,
  viewModes,
  viewMode,
  onViewModeChange
}) => {

  const inputRef = useRef()
  const {showTooltip, hideTooltip} = useTooltip()

  useEffect( () => {
    window.addEventListener("keydown", handleKeyDown)

    return () => window.removeEventListener("keydown", handleKeyDown)
  }, [])

  const handleKeyDown = (e) => {
    if ((e.ctrlKey || e.metaKey) && e.keyCode === keyCode.F) {        
      e.preventDefault();        
      inputRef.current.focus()    
    }   

    if (e.keyCode === keyCode.ESCAPE) {
      requestFilter("")
      setTimeout(() => inputRef.current.blur(), 100)
    }
  }
  const handleInputChange = (event) => {
    let { value } = ""

    // Handle standard form inputs
    if (event.target !== undefined) {
      value = event.target.value

    // Handle custom form inputs
    } else {
      value = event.value
    }

    if (value === " " && filterTerm === "") {
      // do nothing
    } else {
      requestFilter( value )
    }  
  }

  const handleOptionChange = (event) => {
  }

  const isDisabled = (button) => {
    if (!button.disabled) return false 
  
    for (let i = 0; i < selectedItems.length; i++) { 
      if (selectedItems[i][button.disabled.field] === button.disabled.value) {
        return true
      }
    }
  }

  return (
    
    <div className="DataTableToolbar main-toolbar"> 

      { title &&
        <div className="main-toolbar-item main-toolbar-title">{title}</div>
      }

      { editable &&
        <React.Fragment>
        
            { buttons && buttons.map( (button, index) => {
              if (button.customButton) {
                  return <React.Fragment key={index}>
                          {button.customButton}
                          </React.Fragment>
              }
              else if (button.mainButton) {
                return (
                  <button key={index} 
                          type="button" 
                          className="main-toolbar-item button main-button" 
                          onClick={ () => button.onClick() }
                          title={ button.tooltip }
                  >
                    <span>{button.label}</span>
                  </button>
                )
              } else {
                return null
              }
              })
            }
        
        { selectedItemsCount > 0 && 
          <div className="selected-items-options main-toolbar-item">
            
            <div className="main-toolbar-item main-toolbar-text">
              { ((selectedItemsCount === itemTotalCount) ? 'All' : selectedItemsCount)} {((selectedItemsCount > 1 || selectedItemsCount === itemTotalCount) ? 'assets' : 'asset')} selected
            </div>        
            
            { buttons && buttons.map( (button, index) => {
              if (!(button.mainButton || button.customButton)) {
                return (
                  <Button 
                    key={ index } 
                    value={ isDisabled(button) && button.disabled.label ? button.disabled.label : button.label }
                    className="main-toolbar-item button" 
                    onClick={ () => button.onClick() }
                    tooltip={ (isDisabled(button) && button.disabled.tooltip) || button.tooltip }
                    disabled={ isDisabled(button) }
                  />

                )
              } else {
                  return null
                }
            })
          }

          </div>
        }
        </React.Fragment>
      }
      { filterable &&
        <div 
          className="filter-container" 
          onMouseEnter={ () => showTooltip({title: "Type to filter table"})} 
          onMouseLeave={ hideTooltip }>
          
        { filterTerm &&
          <div className="main-toolbar-text">{`Showing ${itemCount} of ${itemTotalCount} assets`}</div>
        }
        <form autoComplete="off">
        { filterControls &&
          filterControls.map( (filterControl, index) => {
            return (
              <CustomFilterControl 
                {...filterControl}
                key={index}  
                requestFilter={requestFilter} 
              />
            )
          })
        }
        <Input 
          ref={inputRef}
          type="text" 
          name="table_filter"

          value={filterTerm} 
          onChange={handleInputChange}
          placeholder="Filter..."
          isNullable={true}
          icon='filter'
          iconAsButton={true}
          withSelect={true}
          selectProps={[{
            name: 'filter_object',
            value: filterObjects.filter(x => x.checked),
            label: "Columns",
            tooltip: "Select columns to filter on",
            options: filterObjects,
            onChange: onFilterObjectsFilter
          }]}
        />
        </form>
        </div>
      }
      { viewModes && 
        <div className="main-toolbar-item view-modes-container">
          <SelectButtons
            name="view_mode"
            value={viewMode}
            options={viewModes}
            onChange={onViewModeChange}
            title="Select view mode"
          />
        </div>
      }

    </div>
    
  );
};