import React, { useState, useEffect } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { changelogService, jobService, jobStepService } from '../services';
import { Asset, formatData, formatDuration, Role, Operation } from '../helpers';
import { 
  Button,
  DataTable, 
  Icon, 
  MainColumn, 
  ObjectDetails, 
  SkeletonLoader,
  Tabs,
  UserLink } from '../components';
import { 
  ChangelogItemRow } from '../components/';
import { 
  useChangelog,
  useInterval,
  useModalDialog, 
  useGlobalState, 
  useNotFound, 
  useMessage,
  usePhotos,
  useQueryParams } from '../hooks';

export const Job = () => {

  const { queryParams, setHistory, resetParams } = useQueryParams()

  const [job, setJob] = useState({});
  const [jobSteps, setJobSteps] = useState([]);
  const [loggedInUser, setLoggedInUser] = useGlobalState('loggedInUser');
  const [changedItems, setChangedItems] = useState();
  const [changedItemsCount, setChangedItemsCount] = useState();
  const [changedItemsTotalCount, setChangedItemsTotalCount] = useState();
  const [loading, setLoading] = useState(true);
  const [loadingJobSteps, setLoadingJobSteps] = useState(true);
  const [loadingChangedItems, setLoadingChangedItems] = useState(true);

  const [defaultSelectedObject, setDefaultSelectedObject] = useState({tab: queryParams.object})
  const [defaultSelectedTab, setDefaultSelectedTab] = useState({tab: queryParams.tab})
  const [defaultSchemaChangesOnly, setDefaultSchemaChangesOnly] = useState(queryParams.schemaChangesOnly)

  const { job_id } = useParams()
  const navigate = useNavigate()

  const { showMessage } = useMessage()
  const { showModalDialog, hideModalDialog } = useModalDialog()
  const { changelog, loadingChangelog, fetchChangelogByJobId } = useChangelog({jobId: job_id})
  const { fetchPhotos } = usePhotos()
  const { notFound, setNotFound, NotFound } = useNotFound()

  const isAdmin = loggedInUser && loggedInUser.user_role_name === Role.admin

  // Update job steps regularly if status is running
  useInterval( () => {
    if (isAdmin) {
      if (job && job.job_status === 'running') {
        fetchItems()
      }
      if (job && job.job_status === 'running' && (defaultSelectedTab.tab === 'job_steps' || !defaultSelectedTab.tab)) {
        fetchJobSteps()
      }
    }
  }, 3000)

  useEffect(() => {

    fetchItems(true)
    isAdmin && fetchJobSteps()

    if (defaultSelectedTab.tab === 'changelog' && !changedItemsCount) {
      fetchChangedItemsCount()
    }
    if (defaultSelectedTab.tab === 'changelog' && !changelog) {
      fetchChangelogByJobId(undefined, defaultSchemaChangesOnly)
    }

  }, []);

  const fetchItems = async (fetchPhoto=false) => {
    
    jobService.getById(job_id)
      .then(res => {
        if (res.jobs.length < 1) {
          setNotFound(true)
        } else {
          setJob({...job, ...res.jobs[0]});
          setLoading(false)
          isAdmin && fetchPhoto && fetchPhotos(res.jobs[0], 'user_id', setJob)
        }
      })
      .catch(err => {showMessage(err, 'error')});
  }

  const fetchJobSteps = async () => {
    
    jobStepService.getByJobId(job_id)
      .then(res => {

        let maxValue = 0

        res.job_steps.forEach( row => {
          if (row.job_step_runtime_ms / 1000 > maxValue) {
            maxValue = row.job_step_runtime_ms / 1000
          }
        })

        const tmp = res.job_steps.map(job_step => {
          return {
            ...job_step, 
            job_step_runtime_seconds: job_step.job_step_runtime_ms / 1000,
            job_step_runtime_seconds_max_value: maxValue,
            job_step_runtime_tooltip: formatDuration(job_step.job_step_runtime_ms / 1000) + " (hh:mm:ss)",
            job_step_affected_rows: job_step.job_step_affected_rows !== null ? job_step.job_step_affected_rows : '-'
          }
        })

        setJobSteps(tmp);
        setLoadingJobSteps(false);
         
      })
      .catch(err => {showMessage(err, 'error')});
  }

  const fetchChangedItemsCount = async () => {

    changelogService.getChangedItemsCount(job_id)
      .then(res => {

        let tmp = {}
        let defaultObject

        const objectSortOrder = ["dataset_group", "dataset", "field", "field_description", "relationship"];
        res.changed_items_count.sort((a, b) => objectSortOrder.indexOf(a.object_name) - objectSortOrder.indexOf(b.object_name));

        res.changed_items_count.forEach(result => {
          if (result.changed_items_count > 0 && defaultObject === undefined ) {
            defaultObject = result.object_name

          }
          tmp = {...tmp, [result.object_name]: parseInt(result.changed_items_count)}
        })

        defaultObject = res.changed_items_count.find(x => x.object_name === defaultSelectedObject.tab) ? defaultSelectedObject.tab : defaultObject

        setDefaultSelectedObject({tab: defaultObject})
        setChangedItemsCount(tmp)

        setChangedItemsTotalCount(
          (tmp.field_description || 0) +
          (tmp.dataset || 0) + 
          (tmp.dataset_group || 0) +
          (tmp.field || 0)
        )

      })
      .catch(err => {showMessage(err, 'error')});
  }

  const handleShowMessage = (data, messageType='showMessage') => {
    showModalDialog(messageType, data)
  }
  
  const handleShowFilterOnly = ({schema_changes_only}) => {
    fetchChangelogByJobId(undefined, schema_changes_only)
    setDefaultSchemaChangesOnly(schema_changes_only)
    setHistory({schemaChangesOnly: schema_changes_only})
  }

  const onObjectChange = (object) => {
    resetParams({object: object})
    setDefaultSelectedObject({tab: object})
  }

  const onTabChange = (tab) => {
    resetParams({tab: tab})

    if (!changedItemsCount) {
      fetchChangedItemsCount()
    }
    if (!changelog) {
      fetchChangelogByJobId()
    }
  }

  const data = loading ? [] : job

  const isRunning = data.job_status === "running"

  if (notFound || (!isAdmin && !loading && data.job_status !== 'completed')) {
    return <NotFound />
  }

  return (
    <div className="columns narrow-margin">
      <MainColumn>
        <ObjectDetails
          type="Job"
          title={ formatData(data.job_started_timestamp, 'datetime') }
          subtitleIcon={<Icon name={data.job_status} tooltip={ data.job_status } />}
          subtitleText={data && !loading && 
            data.task_name + " job " + data.job_status
            }
          showDescription={false}
          description={data.job_completion_message}
          loading={loading}
          details={isAdmin && [
            {title: 'Runtime', data: (data.job_runtime_seconds ? formatDuration(data.job_runtime_seconds) : null), tooltip: `${data.job_runtime_seconds} seconds`},
            {title: 'Started', data: formatData(data.job_started_timestamp, 'datetime')},
            {title: 'Completed', data: (data.job_completed_timestamp ? formatData(data.job_completed_timestamp, 'datetime') : null)}
          ]}
          >
          { isAdmin &&
            <div className="display-linebreak object-description">
            { !loading 
              ? (data.job_status === 'failed' 
                  ? <Button value="Show Error" className="button" onClick={() => handleShowMessage(data.job_completion_message)} /> 
                  : data.job_completion_message)
              : <SkeletonLoader width="10vw" />
            } 
            </div>
          }
          <h3>{ !loading ? "Datasource" : <SkeletonLoader width="5vw"/>}</h3>
          <div className="display-linebreak">
          { !loading 
            ? (data.datasource_name === null) ? "-" : <Link to={"/browse_datasets/"+data.system_id+'/'+data.datasource_id} className="link"><Icon name={data.datasource_type_category} tooltip={data.datasource_type_name} />{ data.datasource_name }</Link>
            : <SkeletonLoader width="10vw" />
          }
          </div>

          { isAdmin &&
            <React.Fragment>
              <h3>{ !loading ? "Execution Method" : <SkeletonLoader width="5vw"/>}</h3>
              <div className="display-linebreak">
              { !loading 
                ? data.job_execution_method
                : <SkeletonLoader width="10vw" />
              } 
              </div>

              <h3>{ !loading ? "Executed By" : <SkeletonLoader width="5vw"/>}</h3>
                <UserLink 
                  userId={data.user_id}
                  userName={data.job_executed_by_user}
                  userPhoto={data.user_photo}
                  userTitle={data.user_title}
                  userDepartment={data.user_department}
                  loading={loading}
                />
            </React.Fragment>
          }
        </ObjectDetails>

      </MainColumn>

      <div className="column">
        <Tabs 
          className="slim left"
          onTabChange={ onTabChange } 
          disableTabsWithoutResults={true}
          showResultCount={false} 
          showNoResultsMessage={false}
          defaultSelectedTab={defaultSelectedTab}>

          { isAdmin && 
            <div label="Job Steps" tabid='job_steps'>

              { jobSteps.length > 0 || loadingJobSteps ?
              <DataTable
              columns={[
                {id: 'job_step_status', type: 'icon', tooltip:'job_step_status'},
                {id: 'job_step_execution_order', name: ''},
                {id: 'job_step_definition_name', name: 'Job Step', className:'bold'},
                {id: 'job_step_started_timestamp', name: 'Started', type: 'datetime'},
                {id: 'job_step_completed_timestamp', name: 'Completed', type: 'datetime'},
                {id: 'job_step_runtime_seconds', name: 'Runtime [s]', type: 'chart', chartType: 'bar', data: {data:'job_step_runtime_seconds', max: 'job_step_runtime_seconds_max_value'}, tooltip: 'job_step_runtime_tooltip'},
                {id: 'job_step_affected_rows', name: 'Affected Rows', type: 'integer', className:'right'},
              ]}
              data={jobSteps}
              idColumn='job_step_id'
              loading={loadingJobSteps}
              editable={false}
              filterable={true}
              filterObjects={['job_status', 'job_step_definition_name']}
            />
              : !loadingJobSteps &&
                <div className="no-result-text">No job steps found for this task</div>
            }
          </div>
        }
        <div label="Changelog" tabid='changelog' resultCount={ job.changed_asset_count || 0 }>

            <Tabs className="buttons left" 
                  onTabChange={ onObjectChange } 
                  disableTabsWithoutResults={true}
                  defaultSelectedTab={defaultSelectedObject}
                  loading={loadingChangelog}
                  > 

              <div label="Dataset Groups" tabid={Asset.DatasetGroup} resultCount={ (changelog && changelog.dataset_group.length) || 0 }>
                <DataTable
                  columns={[
                    {id: 'operation', name: 'Operation', value: 'operation', values: Operation, type: 'pill', className:"type-pill"},
                    {id: 'dataset_group_name', name: 'Name', tooltip: 'dataset_group_description', link: '/browse_datasets/:system_id/:datasource_id/:dataset_group_id'}
                  ]}
                  data={changelog?.dataset_group || []}
                  idColumn='dataset_group_id'
                  loading={loadingChangelog}
                  editable={false}
                  expandable={true}
                  expandableColumns={
                    { dataKey: 'changedData',
                      showHeader: true,
                      Component: ChangelogItemRow,
                      columns: [
                        {id: 'attribute', name: 'Attribute', value: 'attribute', className:"small-bold-text no-break width-150"},
                        {id: 'new', name: 'To', className:"width-150"},
                        {id: 'old', name: 'From', className:""},
                      ]
                    }
                  }
                  expandedRowClassName='background-white'
                  filterable={true}
                  filterObjects={['operation', 'dataset_group_name', 'dataset_type_name']}
                  filterObjectsValueMap={{
                    operation: Operation
                  }}
                  filterControls={[
                    {
                      type: 'checkbox',
                      name: 'schema_changes_only',
                      label: 'Schema Changes Only',
                      tooltip: 'Show schema changes only',
                      onChange: handleShowFilterOnly,
                      defaultValue: defaultSchemaChangesOnly,
                    }
                  ]}
                />
              </div>

              <div label="Datasets" tabid={Asset.Dataset} resultCount={ (changelog && changelog.dataset.length) || 0 }>
                <DataTable
                  columns={[
                    {id: 'operation', name: 'Operation', value: 'operation', values: Operation, type: 'pill', className:"type-pill"},
                    {id: 'dataset_type_name', name: '', type: 'icon', tooltip: 'dataset_type_name', className:"no-right-margin"},
                    {id: 'dataset_name', name: 'Name', tooltip: 'dataset_description', link: '/browse_datasets/:system_id/:datasource_id/:dataset_group_id/:dataset_id?tab=changelog', className:"bold"},
                    {id: 'dataset_group_name', name: 'Dataset Group', link: '/browse_datasets/:system_id/:datasource_id/:dataset_group_id'},

                  ]}
                  data={changelog?.dataset || []}
                  idColumn='dataset_id'
                  loading={loadingChangelog}
                  editable={false}
                  expandable={true}
                  expandableColumns={[
                    { dataKey: 'fields',
                      showHeader: false,
                      className: 'condensed-left',
                      columns: [
                        {id: 'operation', name: 'Operation', value: 'operation', values: Operation, type: 'pill', className:"type-pill"},
                        {id: 'datatype_category', name: '', type: 'icon', tooltip: 'datatype_fullname', className:"no-right-margin"},
                        {id: 'field_name', name: 'Name', link: '/browse_datasets/:system_id/:datasource_id/:dataset_group_id/:dataset_id/:field_id?tab=changelog'},
                        {id: 'datatype_fullname', name: 'Datatype', className:"small-text"},
                      ]
                    },
                    { dataKey: 'changedData',
                      showHeader: true,
                      Component: ChangelogItemRow,
                      className: 'margin-10',
                      columns: [
                        {id: 'attribute', name: 'Attribute', value: 'attribute', className:"small-bold-text no-break width-150"},
                        {id: 'new', name: 'To', className:"width-150"},
                        {id: 'old', name: 'From', className:""},
                      ]
                    }
                  ]}
                  expandedRowClassName='background-white'
                  filterable={true}
                  filterObjects={['operation', 'dataset_name', 'dataset_group_name', 'dataset_type_name', 'fields.field_name']}
                  filterObjectsValueMap={{
                    operation: Operation
                  }}
                  filterControls={[
                    {
                      type: 'checkbox',
                      name: 'schema_changes_only',
                      label: 'Schema Changes Only',
                      tooltip: 'Show schema changes only',
                      onChange: handleShowFilterOnly,
                      defaultValue: defaultSchemaChangesOnly,
                    }
                  ]}
                />
              </div>

              <div label="Fields" tabid={Asset.Field} resultCount={ (changelog && changelog.field.length) || 0 }>
                <DataTable
                  columns={[
                    {id: 'operation', name: 'Operation', value: 'operation', values: Operation, type: 'pill', className:"type-pill"},
                    {id: 'datatype_category', name: '', type: 'icon', tooltip: 'datatype_fullname', className:"no-right-margin"},
                    {id: 'field_name', name: 'Name', className:"bold", link: '/browse_datasets/:system_id/:datasource_id/:dataset_group_id/:dataset_id/:field_id?tab=changelog'},
                    {id: 'datatype_fullname', name: 'Datatype', className:"small-text"},
                    {id: 'dataset_type_name', name: '', type: 'icon', tooltip: 'dataset_type_name', className:"no-right-margin"},
                    {id: 'dataset_name', name: 'Dataset', link: '/browse_datasets/:system_id/:datasource_id/:dataset_group_id/:dataset_id'},
                    {id: 'dataset_group_name', name: 'Dataset Group', link: '/browse_datasets/:system_id/:datasource_id/:dataset_group_id'}
                  ]}
                  data={changelog?.field || []}
                  idColumn='field_id'
                  loading={loadingChangelog}
                  editable={false}
                  expandable={true}
                  expandableColumns={
                    { dataKey: 'changedData',
                      showHeader: true,
                      Component: ChangelogItemRow,
                      columns: [
                        {id: 'attribute', name: 'Attribute', value: 'attribute', className:"small-bold-text no-break width-150"},
                        {id: 'new', name: 'To', className:"width-150"},
                        {id: 'old', name: 'From', className:""},
                      ]
                    }
                  }
                  expandedRowClassName='background-white'
                  filterable={true}
                  filterObjects={['operation', 'field_name', 'dataset_name', 'dataset_group_name', 'old_field_name', 'datatype_fullname','old_datatype_fullname', 'dataset_type_name']}
                  filterObjectsValueMap={{
                    operation: Operation
                  }}
                  filterControls={[
                    {
                      type: 'checkbox',
                      name: 'schema_changes_only',
                      label: 'Schema Changes Only',
                      tooltip: 'Show schema changes only',
                      onChange: handleShowFilterOnly,
                      defaultValue: defaultSchemaChangesOnly,
                    }
                  ]}
                />
              </div>

              <div label="Field Descriptions" tabid={Asset.FieldDescription} resultCount={ (changelog && changelog.field_description.length) || 0 }>
                <DataTable
                  columns={[
                    {id: 'operation', name: 'Operation', value: 'operation', values: Operation, type: 'pill', className:"type-pill"},
                    {id: 'field_role_name', name: '', type: 'icon', tooltip: 'field_role_name', className:"no-right-margin"},
                    {id: 'field_description_name', name: 'Name', tooltip: 'field_description_description', className:"bold", link: '/browse_field_descriptions/:field_description_id?tab=changelog'},
                  ]}
                  data={changelog?.field_description || []}
                  idColumn='field_description_id'
                  loading={loadingChangelog}
                  editable={false}
                  expandable={true}
                  expandableColumns={
                    { dataKey: 'changedData',
                      showHeader: true,
                      Component: ChangelogItemRow,
                      columns: [
                        {id: 'attribute', name: 'Attribute', value: 'attribute', className:"small-bold-text no-break width-150"},
                        {id: 'new', name: 'To', className:"width-150"},
                        {id: 'old', name: 'From', className:""},
                      ]
                    }
                  }
                  expandedRowClassName='background-white'
                  filterable={true}
                  filterObjects={['operation', 'field_role_name', 'field_description_name']}
                />
              </div>
            </Tabs>
        </div>

      </Tabs>
      
      </div>
    </div>
  ) 
}
