import { useState, useMemo } from 'react';
import { useQueryParams, useDebounce } from './';

export const useFilterableData = (items, filterObjects=[], filterObjectsValueMap={}, defaultFilterTerm="", filterControls) => {


  const { queryParams, setHistory } = useQueryParams()
  const [internalFilterTerm, setInternalFilterTerm] = useState(defaultFilterTerm || queryParams.filter);
  const [filterTerm, setFilterTerm] = useState(defaultFilterTerm || queryParams.filter);
  const [activeFilters, setActiveFilters] = useState({})

  const _setInternalFilterTerm = (filterTerm) => {
    setInternalFilterTerm(filterTerm) 
  }

  // Recursive function to construct an array with key-value pairs from parsed strings in filterObjects 
  const _getValues = (path, item, results=[]) => {
    let remainingPath = path
    let currentItem = item

    for (let i=0; i < path.length; ++i) {

      remainingPath = remainingPath.slice(1, remainingPath.length)

      currentItem = currentItem && currentItem[path[i]]

      if ( typeof currentItem == "object" && Array.isArray(currentItem)) {
        currentItem.forEach(currItem => {
          results = _getValues(remainingPath, currItem, results)
        })
      } 

      if (remainingPath.length === 0 && currentItem) {
        results.push({[path[i]]: currentItem})
      }  
    }
    return results
  }

  const debouncedSetInternalFilterTerm = useDebounce(_setInternalFilterTerm, 200).debounce

  const filteredItems = useMemo( () => {

    let filterableItems = items ? [...items] : []

    if (internalFilterTerm.length > 1) {
      filterableItems = filterableItems.filter(item => {

        let match = false

        let parsedFilterObjects = []

        // Parse filterObjects
        filterObjects.forEach(object => {

          // Only include selected filterObjects
          if (object.checked) {
            let path = object.value.split('.')
            
            // Split filter objects if or operator '||' is provided
            path.forEach((p, i) => {
              let subpath = p.split('||')
              let path_tmp = [...path]
              subpath.forEach((sp, j) => {

                if (j===subpath.length-1) {
                  path[i] = sp
                } else {
                  
                  let start = i > 0 ? path_tmp.slice(0,i) : path_tmp[0]
                  let end = i < path_tmp.length ? path_tmp.slice(i+1,path_tmp.length) : []
                  parsedFilterObjects.push({
                    ...object,
                    value: [...start, sp, ...end].join('.')
                  })
                }
              })
            })

            parsedFilterObjects.push({
              ...object,
              value: path.join('.')
            })
          }
        })

        // Apply filters
        parsedFilterObjects.forEach(object => {

          let path = object.value.split('.')

          // Parse filterObject strings
          let key = _getValues(path, item)

          // Search in array
          if (key && typeof key == "object" && Array.isArray(key)) {
            match = key.find( (x) => {
              let [k, v] = Object.entries(x)[0]

              if (typeof v == "boolean") {
                v = v ? "true" : "false"
              }

              return v.toLowerCase().replace(/_/g, " ").includes(internalFilterTerm) || 
               v.toLowerCase().includes(internalFilterTerm) ||
               (filterObjectsValueMap[k] && filterObjectsValueMap[k][v].label.toLowerCase().includes(internalFilterTerm))

            }) ? true : match
          }
        })

        return match
        
      })
    }

    return filterableItems

  }, [items, internalFilterTerm, activeFilters, filterObjects]);

  const requestFilter = (str, val) => {

    const string = str ||  ""
    setFilterTerm(string)
    debouncedSetInternalFilterTerm(string.toLowerCase())

    val && setActiveFilters(prev => ({...prev, ...val}))

    setHistory({'filter': string})
  };

  return { filteredItems, requestFilter, filterTerm };
}