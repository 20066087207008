import React from 'react';
import PrismDecorator from 'draft-js-prism';
//import { Prism } from 'prismjs'
//import {sql} from '../prism-sql'
//import 'prismjs/components/prism-sql'

import { useTooltip} from '../../../hooks';
import { Link } from 'react-router-dom'
import { Asset } from '../../../helpers';
import { formatTooltip } from '../helpers'
import Prism from '../helpers/prism-helper'
export const Reference = ({ children, entityKey, contentState, readOnly, descriptions }) => {

  const {object_id, object_name } = entityKey !== null && contentState.getEntity(entityKey).getData().reference
  
  const {showTooltip, hideTooltip} = useTooltip()

  const body = <span className="textarea-reference">{children}</span>

  if (readOnly && descriptions && descriptions.length > 0) {
    
    const description = descriptions.filter(x => x.to_object_id === object_id && x.to_object_name === object_name)[0]

    // Ugly fix to prevent errors related to the render lifecycle
    if (!description) { return body }

    const { glossary_id, system_id, datasource_id, dataset_group_id } = description

    let url = ""
    
    switch(object_name) {
      case Asset.FieldDescription:
        url=`/browse_field_descriptions/${object_id}`
        break
      case Asset.BusinessTerm:
        url=`/browse_business_terms/${glossary_id}/${object_id}`
        break
      case Asset.Dataset:
        url=`/browse_datasets/${system_id}/${datasource_id}/${dataset_group_id}/${object_id}`
        break
      case Asset.System:
        url=`/browse_datasets/${object_id}`
        break
      default:
        break
    }

    let tooltipData = formatTooltip({...description, object_name: description?.to_object_name})
    tooltipData.preTitle = description?.to_object_name

    return (
      <Link 
        to={url} 
        onMouseEnter={ () => showTooltip(tooltipData) } 
        onMouseLeave={ hideTooltip }
      >
        { body }
      </Link>
    )
  } else {
    return body
  }
}

export const findReferenceEntities = (contentBlock, callback, contentState) => {

  contentBlock.findEntityRanges(
    (character) => {
      const entityKey = character.getEntity();
      // Get data stored in entity
      // console.log(entityKey !== null && contentState.getEntity(entityKey).getData())
      return (
        entityKey !== null &&
        contentState.getEntity(entityKey).getType() === 'REFERENCE'
      );
    },
    callback,
  );
};

export const prismDecorator = new PrismDecorator({
  prism: Prism,
  defaultSyntax: 'sql',
  filter: (block) => {  return block.getType() === 'unstyled' }
})