import React from 'react';
import { SkeletonLoader } from './';
import { Link } from 'react-router-dom';
import { Photo } from './';
import { useTooltip } from '../hooks'

export const UserLink = ({userId, userPhoto, userName, userTitle, userDepartment, className, header, photoPosition="left", type='USER', tooltip="", loading=false }) => {

	const {showTooltip, hideTooltip} = useTooltip()

	tooltip = tooltip || userName

	const tooltipBody =
    <div className="user-profile slim">
	    <Photo
	      size='medium'
	      data={ userPhoto }
	      loading={ loading }
	    />
      <div className="user-profile-info">
        <div className="user-profile-name">{userName}</div>
        {userTitle &&
          <div className="user-profile-title">{userTitle}</div>
        }
        {userDepartment &&
          <div className="user-profile-title">{userDepartment}</div>
        }
      </div>
    </div>

	return (
		<div className={
			"UserLink" + 
			" photo-" + photoPosition + 
			(header ? " with-header" : "")}>
		{ !loading 
      ? <React.Fragment>
      	{ userId ? 
	      	<Link 
	      		to={"/users/"+userId} 
	      		className={"link user-link-body" + (className ? " " + className : "")} 
	      		onMouseEnter={ () => tooltip && showTooltip({tooltipBody:tooltipBody})} 
			  		onMouseLeave={ () => tooltip && hideTooltip() }
	      	>
	      		<Photo data={userPhoto} size="small" type={type} loading={loading} alt={userName}  />
	      		<div className="user-link">
		      		{ header && <div className="user-link-header">{ header }</div> }
		      		<div className="user-link-name">{ userName }</div>
		      	</div>
	      	</Link>
	      : <div className={"user-link-body" + (className ? " " + className : "")}>
	      		<Photo data={userPhoto} size="small" type={type} loading={loading} alt={userName}  />
	      		<div className="user-link">
		      		{ header && <div className="user-link-header">{ header }</div> }
		      		<div className="user-link-name">{ userName }</div>
		      	</div>
	      	</div>
	    	}
	    	</React.Fragment>
      : <div className={"user-link-body" + (className ? " " + className : "")}>
      		<Photo data={userPhoto} size="small" type={type} loading={loading} alt={userName} />
      		<div className="user-link">
		      		{ header && <div className="user-link-header"><SkeletonLoader width="130px" /></div> }
		      		<div className="user-link-name"><SkeletonLoader width="130px" /></div>
		      	</div>
      	</div>
    }
		</div>
	)
}