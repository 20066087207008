import React from 'react';
import { Icon, TextareaReadOnly, textareaInitialState } from '../../';
import { FieldSensitivity, formatData } from '../../../helpers'

export const formatChangelogValue = (version, data) => {

    let key = data.attribute
    let values = version === 'new' ? data.new_data : data.old_data

    let isSecretValue = data.is_secret
    let value = isSecretValue ? '< hidden >' : (values && values[key])

    let element 

    if (value !== undefined && value !== null) {
      switch(key) {

        case 'dataset_id':
          element = values.dataset_name ? <div className="with-icon"><Icon name={values.dataset_type_name} tooltip={ values.dataset_type_name } />{values.dataset_name}</div> 
                                        : <span>{values.dataset_id.toString()}</span>
          break
        case 'dataset_group_id':
          value =  values.dataset_group_name || values.dataset_group_id.toString()
          element = <span>{value}</span>
          break
        case 'datasource_id':
          element = values.datasource_name ? <div className="with-icon"><Icon name={values.datasource_type_category} tooltip={ values.datasource_type_name } />{values.datasource_name}</div> 
                                           : <span>{values.dataset_group_id.toString()}</span>
          break
        case 'field_description_id':
          element = values.field_description_name ? <div className="with-icon"><Icon name={values.field_role_name} tooltip={ values.field_role_name } />{values.field_description_name}</div>
                                                  : <span>{values.field_description_id.toString()}</span>
          break
        case 'glossary_id':
          value =  values.glossary_name || values.glossary_id.toString()
          element = <span>{value}</span>
          break
        case 'system_id':
          value =  values.system_name || values.system_id.toString()
          element = <span>{value}</span>
          break

        case 'datasource_type_id':
          element = <div className="with-icon"><Icon name={values.datasource_type_category} tooltip={ values.datasource_type_name } />{values.datasource_type_name}</div>
          break
        case 'dataset_type_id':
          element = <div className="with-icon"><Icon name={values.dataset_type_name} tooltip={ values.dataset_type_name } />{values.dataset_type_name}</div>
          break
        case 'datatype_id':
          element = <div className="with-icon"><Icon name={values.datatype_category} tooltip={ values.datatype_fullname } />{values.datatype_fullname}</div>
          break
        case 'field_role_id':
          element = <div className="with-icon"><Icon name={values.field_role_name} tooltip={ values.field_role_name } />{values.field_role_name}</div>
          break
        case 'field_sensitivity_id':
          element = <Icon name="field_sensitivity" value={values.field_sensitivity_level} values={FieldSensitivity} />
          break

        case 'business_term_owner_user_id':
          value = values.business_term_owner_user_fullname || "Deleted user with id " + values.business_term_owner_user_id.toString()
          element = <div>{value}</div>
          break
        case 'dataset_owner_user_id':
          value = values.dataset_owner_user_fullname || "Deleted user with id " + values.dataset_owner_user_id.toString()
          element = <div>{value}</div>
          break
        case 'dataset_group_owner_user_id':
          value = values.dataset_group_owner_user_fullname || "Deleted user with id " + values.dataset_group_owner_user_id.toString()
          element = <div>{value}</div>
          break
        case 'datasource_owner_user_id':
          value = values.datasource_owner_user_fullname || "Deleted user with id " + values.datasource_owner_user_id.toString()
          element = <div>{value}</div>
          break
        case 'field_description_owner_user_id':
          value = values.field_description_owner_user_fullname || "Deleted user with id " + values.field_description_owner_user_id.toString()
          element = <div>{value}</div>
          break
        case 'glossary_owner_user_id':
          value = values.glossary_owner_user_fullname || "Deleted user with id " + values.glossary_owner_user_id.toString()
          element = <div>{value}</div>
          break
        case 'system_owner_user_id':
          value = values.system_owner_user_fullname || "Deleted user with id " + values.system_owner_user_id.toString()
          element = <div>{value}</div>
          break

        case 'dataset_technical_definition':
        case 'field_technical_definition':
          value = value || '-'
          element = <div className="code">{value.toString()}</div>
          break

        case 'datasource_filter':
        case 'relationship_map':
          value = value ? JSON.stringify(value) : '-'
          element = <div className="code">{value}</div>
          break

        case 'business_term_description':
        case 'business_term_example':
        case 'dataset_description':
        case 'dataset_group_description':
        case 'datasource_description':
        case 'field_description_description':
        case 'field_description_definition':
        case 'field_description_example':
        case 'glossary_description':
        case 'system_description':
          value = value || '-'
          element = <TextareaReadOnly value={ textareaInitialState({value: value, readOnly: true}) } />
          break

        case 'field_unit_id':
          value = value ? `${values.field_unit_name} (${values.field_unit_code})` : '-'
          element = <span>{value}</span>
          break
        case 'user_role_id':
          value = value ? `${values.user_role_name}` : '-'
          element = <span>{value}</span>
          break

        case 'user_photo':
          value = value || null
          element = formatData(value, 'user-photo')
          break

        case 'is_pii':
        case 'is_primary_key':
        case 'use_relationship_view':
        case 'use_information_schema_view':
        case 'disable_virtual_relationships':
        case 'user_is_disabled':
        case 'password_change_required':
        case 'datasource_is_encrypted_connection':
        case 'datasource_is_trusted_connection':
          value = value ? 'Yes' : 'No'
          element = <span>{value || '-'}</span>
          break

        default:
          element = isSecretValue ? <span className="secret">{value}</span> : <span>{value || '-'}</span>
      }
    } else {
      element = <span>-</span>
    }

    return element
  } 