import config from '../config';
import { authHeader, customFetch, handleResponse, commonRequestOptions } from '../helpers';

const endpointPath='job_step'

export const jobStepService = {
    getByJobId
};

function getByJobId(id, signal) {
    const requestOptions = { method: 'GET', headers: authHeader(), ...commonRequestOptions() };
    return customFetch(`${config.api.url}/${endpointPath}/job/${id}`, requestOptions, signal)
    .then(handleResponse)
}