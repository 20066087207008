import React, { useEffect, useRef, useState } from 'react';
import { SkeletonLoader } from '../../';
import { AssetName } from '../../../helpers';
import { useTooltip } from '../../../hooks';
import { 
  SUGGESTION_TRIGGER,
  formatTooltip
} from '../helpers'

export const TextareaSuggestions = ({showSuggestions, searchTerm, editorState, searchResults, selectedSuggestionIndex, setSelectedSuggestionIndex, onSelectSuggestion, loading}) => {

  const refSuggestionsContainer = useRef([])
  const refSuggestions = useRef([])
  const refSuggestion = useRef([])

  const [style, setStyle] = useState()
  const [triggerCharWidth, setTriggerCharWidth] = useState(0)
  const [firstColumnWidth, setFirstColumnWidth] = useState(0)
  
  const {showTooltip, hideTooltip} = useTooltip()

  useEffect( () => {
    setSelectedSuggestionIndex(0)
  }, [searchResults])


useEffect( () => {
  const typeaheadRange = getTypeaheadRange();
  const tempRange = window.getSelection().getRangeAt(0).cloneRange();

  const triggerChar = window.getSelection().getRangeAt(0).cloneRange();

  triggerChar.setStart(triggerChar.startContainer, typeaheadRange?.start+1);



  tempRange.setStart(tempRange.startContainer, typeaheadRange?.start);

  const rangeRect = tempRange.getBoundingClientRect();

  let [rangeRectLeft, rangeRectTop] = [rangeRect.left, rangeRect.bottom]

  let _firstColumnWidth = refSuggestion.current.length > 0 && searchResults.length > 0 ? refSuggestion.current[0]?.offsetWidth - 3 : 0
  setFirstColumnWidth(_firstColumnWidth)

  const _triggerCharWidth = triggerChar.getBoundingClientRect().left - rangeRectLeft
  setTriggerCharWidth(_triggerCharWidth)

  rangeRectLeft = rangeRectLeft - _firstColumnWidth 

  const textareaRect = window.getSelection().anchorNode.parentNode.offsetParent?.getBoundingClientRect()

  const textareaRectLeft = textareaRect?.left
  const textareaRectTop = textareaRect?.top


  const tmp = (window.innerWidth > (rangeRectLeft + refSuggestionsContainer.current.offsetWidth)) 
                        ? 0 
                        : (rangeRectLeft + refSuggestionsContainer.current.offsetWidth + 20) - window.innerWidth

  setStyle({top: rangeRectTop-textareaRectTop+"px", left: (rangeRectLeft-textareaRectLeft-tmp)+"px"})

  }, [searchTerm, searchResults])

  // Handle scolling
  useEffect( () => {
    const suggestion = refSuggestions.current[selectedSuggestionIndex]
    if (suggestion && suggestion.offsetTop < refSuggestionsContainer.current.scrollTop) {
      suggestion.scrollIntoView({block: "nearest", inline: "nearest"})
    } else if (suggestion && suggestion.offsetTop + suggestion.offsetHeight > refSuggestionsContainer.current.offsetHeight) {
      suggestion.scrollIntoView({block: "nearest", inline: "nearest"})
    }
  }, [selectedSuggestionIndex])

  if (!showSuggestions) return null
  //if (!searchTerm || (searchTerm && searchResults.length < 1)) return null;
  
  const handleClick = (e, index) => {
    e.preventDefault()
    onSelectSuggestion(index)
  }

  const getTypeaheadRange = () => {
    const selection = window.getSelection();

    if (selection.rangeCount === 0) {
      return null;
    }

    const range = selection.getRangeAt(0);
    let text = range.startContainer.textContent;

    // Remove text that appears after the cursor..
    text = text.substring(0, range.startOffset);

    // ..and before the typeahead token.
    const index = text.lastIndexOf(SUGGESTION_TRIGGER);
    if (index === -1) {
      return null;
    }
    text = text.substring(index);

    return {
      text,
      start: index,
      end: range.startOffset
    };
  }


  const formatName = name => {
    const matched = name.substr(0, searchTerm.length)
    const unmatched = name.replace(matched, "")

    return <React.Fragment><span className="bold">{matched}</span><span>{unmatched}</span></React.Fragment>
  }

  return (
    <div 
      className="CustomTextareaSuggestions" 
      style={style}      
    >
      <div className="content" ref={ refSuggestionsContainer }>
        { searchResults.length > 0 
          ? <table>
              <tbody>
                {
                  searchResults.map( (result, index) => (

                    <tr 
                      className={"option" + (selectedSuggestionIndex === index ? " selected" : "")}
                      key={index} 
                      ref={ el => refSuggestions.current[index] = el }
                      onMouseEnter={ () => showTooltip(formatTooltip(result))}
                      onMouseLeave={ hideTooltip }
                      onMouseDown={ (e) => handleClick(e, index) } 
                    > 
                      <td className="object-type" ref={ el => refSuggestion.current[index] = el }>{AssetName[result.object_name]}</td>
                      <td className="object-name" >{formatName(result.name)}</td>
                      
                    </tr>
                  ))
                }
              </tbody>
            </table>
            
          : ""
        }
        { searchTerm.length === 0 &&
          <div className="no-results" style={{paddingLeft : triggerCharWidth + "px"}}>Type to search...</div>
        }
      </div>

      { searchTerm.length > 0 && 
        <div className="footer" style={{paddingLeft : triggerCharWidth + firstColumnWidth + "px"}}>
        { loading && searchResults.length === 0 
          ? <SkeletonLoader width="100px" />
          : searchResults.length + (searchResults.length > 1 ? " items found" : " item found")
        }
        </div>
      }
    </div>
  )
}