import React, { useState, useEffect, forwardRef } from 'react'
import { Link } from 'react-router-dom';
import TextareaAutosize from 'react-textarea-autosize';
import { datatypeService, datasetService, fieldService, fieldDescriptionService, fieldRoleService, fieldSensitivityService, fieldUnitService, userService } from '../services';
import { 
	Checkbox, 
	FormTabs, 
	Select, 
	SelectButtons, 
	formatSelectOptions, 
	Icon,
	Input, 
	Textarea, 
	TextareaReadOnly, 
	textareaToPlainText, 
	textareaInitialState, 
	textareaToRaw 
} from '../components';
import { FieldSensitivity, formatUserFriendly, formatPath, getBatchEditValue } from '../helpers';
import { useGlobalState, useTooltip, useSearch, useMessage } from '../hooks'

const _AddFieldForm = ({ mode, data, submitForm, loading, setLoading }, ref) => { 
	
	const initialFormState = {
		field_id: null, 
		field_name: '',
		field_technical_definition: null, 
		datatype_id: null, 
		dataset_id: null,
		is_primary_key: false,
		field_description_id: null
	}

	const initialFieldDescription = {
		field_description_action: 'update',
		field_description_id: null, 
		field_description_name: '',
		field_description_description: textareaInitialState({}),
		field_description_definition: textareaInitialState({}),
		field_description_example: textareaInitialState({}), 
		is_pii: false,
		field_role_id: null, 
		field_sensitivity_id: '',
		field_unit_id: null,
		field_description_owner_user_id: null
	}

	const initialBatchEditItems = {
		batch_datatype_id: false, 
		batch_dataset_id: false,
		batch_field_description_id: false
	}

	const [loggedInUser, setLoggedInUser] = useGlobalState('loggedInUser');
	const [currentDataset, setCurrentDataset] = useGlobalState('currentDataset');
	const [formData, setFormData] = useState(initialFormState);
	const [batchEdit, setBatchEdit] = useState(false);
	const [batchEditItems, setBatchEditItems] = useState(initialBatchEditItems)
	const [fieldAssociations, setFieldAssociations] = useState([]);
	const [tmpFieldAssociations, setTmpFieldAssociations] = useState([]);
	const [datatypes, setDatatypes] = useState([]);
	const [datasets, setDatasets] = useState([]);
	const [fieldDescriptions, setFieldDescriptions] = useState([]);
	const [fieldDescription, setFieldDescription] = useState(initialFieldDescription);
	const [fieldDescriptionAssocFields, setFieldDescriptionAssocFields] = useState([]);
	const [fieldDescriptionsWithSameName, setFieldDescriptionsWithSameName] = useState([]);
	const [newFieldDescription, setNewFieldDescription] = useState(initialFieldDescription);
	const [addFieldDescription, setAddFieldDescription] = useState(false);
	const [fieldRoles, setFieldRoles] = useState([]);
	const [fieldSensitivities, setFieldSensitivities] = useState([]);
	const [fieldUnits, setFieldUnits] = useState([]);
	const [users, setUsers] = useState([]);	
	const [loadingMoreOptions, setLoadingMoreOptions] = useState(false);
	const [defaultSelectedTab, setDefaultSelectedTab] = useState()
	const [requestResults, setRequestResults] = useState([])

	const { showMessage } = useMessage()
  const {showTooltip, hideTooltip} = useTooltip();
  const { 
    searchTerm, 
    searchResults, 
    searchResultsCount, 
    setSearchTerm, 
    fetchSearchResults, 
    fetchAllSearchResults,
    clearSearchResults, 
    loadNextPage } = useSearch({
    	setLoading: setLoadingMoreOptions, 
    	objectName: 'field', 
    	pagination: true,
    	enableHistory: false 
    })

	let dataset_group_id = currentDataset?.dataset_group_id || (data[0] && data[0].dataset_group_id) //datasource_id is not part of URL in e.g. User view

	useEffect( () => {

    	// Get form data
    	fetchDatatypes();
    	fetchDatasets();
    	fetchFieldDescriptions();
    	fetchFieldRoles();
    	fetchFieldSensitivities();
    	fetchFieldUnits();
    	fetchUsers();

  	}, []);

	useEffect( () => {

  	setRequestResults(
  		searchResults.reduce((res, result) => {

		  	const isSelf = data.length > 0 && result.searchable.field_id === data[0].field_id

		    const selectedByOther = ((result.searchable.field_description_id && result.searchable.field_description_id.toString() !== formData.field_description_id) ? true : false)
		    res.push({
		            value: result.searchable.field_id, 
		            label: result.searchable.field_name, 
		            icon: result.searchable.datatype_category, 
		            secondaryLabel: formatPath([result.searchable.system_name, result.searchable.datasource_name, result.searchable.dataset_group_name, result.searchable.dataset_name]),
		            selectedByOther: selectedByOther,
		            tooltip: isSelf ? "This is the field you are currently editing" : selectedByOther ? `This Field is already associated with Field Description:\n${result.searchable.field_description_name}` : "",
		            disabled: isSelf
		          })
		    return res
		  }, [])
  	)

	}, [searchResults]);

	useEffect(() => {

		setBatchEdit(false)
		setAddFieldDescription(false)

	    if (data.length === 1) {
			setFormData({ 
				field_id: data[0].field_id, 
				field_name: data[0].field_name || '',
				field_technical_definition: data[0].field_technical_definition, 
				datatype_id: data[0].datatype_id,
				dataset_id: data[0].dataset_id,
				is_primary_key: data[0].is_primary_key,
				field_description_id: data[0].field_description_id
			})
		} else if(data.length > 1 && mode !== 'add') {
			setBatchEdit(true)

			setFormData({ 
				datatype_id: getBatchEditValue(data, 'datatype_id'),
				dataset_id: getBatchEditValue(data, 'dataset_id'),
				field_description_id: getBatchEditValue(data, 'field_description_id')
			})
		} else if(mode === 'add') {
			setFormData({ 
				...formData, 
				dataset_id: currentDataset.dataset_id
			})
		} else {
			resetForm()		
		}

		if (data.length > 0 && data[0].field_description_id) {
			setFieldDescription(fieldDescriptions.find(x => x.field_description_id === data[0].field_description_id) )
			fetchFieldDescriptionAssocFields(data[0].field_description_id)
		}

	}, [mode, data, currentDataset]);

	
	const fetchDatatypes = async () => {
	    datatypeService.getAll()
	      .then(res => { 

	      	res && setDatatypes(res.datatypes); 

	      })
	      .catch(err => {showMessage(err, 'error')});
	  }

	const fetchDatasets = async () => {
	    datasetService.getByDatasetGroupId(dataset_group_id)
	      .then(res => { 

	      	res && setDatasets(res.datasets); 

	      })
	      .catch(err => {showMessage(err, 'error')});
	  }

	const fetchFieldDescriptions = async () => {

	    fieldDescriptionService.getAll()
	      .then(res => { 
	      	if (res) {
		      	setFieldDescriptions(res.field_descriptions);
		      	data[0] && setFieldDescription( res.field_descriptions.find(x => x.field_description_id === data[0].field_description_id) )
	      	}
	      })
	      .catch(err => {showMessage(err, 'error')});
	  }

	const fetchFieldDescriptionAssocFields = async (id) => {
		if (id && id !== '') {
			fieldService.getByFieldDescriptionId(id)
		    .then(res => { 
		    	if (res) {
			    	// Remove this field from list to give correct number
			    	const tmp = res.fields.filter( x => { return data[0] && x.field_description_id !== data[0].field_description_id})
			    	setFieldDescriptionAssocFields(tmp);
		    	}
		    })
		    .catch(err => {showMessage(err, 'error')});
	  } else {
	  	setFieldDescriptionAssocFields([])
	  }
	}

	const fetchFieldDescriptionByName = async (name) => {
		if (name && name !== '') {
			fieldDescriptionService.getByName(name)
		    .then(res => { 
		    	res && setFieldDescriptionsWithSameName(res.field_descriptions);
		    })
	      .catch(err => {showMessage(err, 'error')});
		} else {
	  	setFieldDescriptionsWithSameName([])
	  }
	}

	const fetchFieldRoles = async () => {
	    fieldRoleService.getAll()
	      .then(res => { 
	      	res && setFieldRoles(res.field_roles); 
	      })
	      .catch(err => {showMessage(err, 'error')});
	  }

	const fetchFieldSensitivities = async () => {
	    fieldSensitivityService.getAll()
	      .then(res => { 
	      	if (res) {
		      	setFieldSensitivities(res.field_sensitivities);
		      	setNewFieldDescription(prev => {return { 
							...prev, 
							field_sensitivity_id: res.field_sensitivities.find(x => x.is_default).field_sensitivity_id
						}})
					}
	      })
	      .catch(err => {showMessage(err, 'error')});
	  }

	const fetchFieldUnits = async () => {
	    fieldUnitService.getAll()
	      .then(res => { 

	      	res && setFieldUnits(res.field_units); 

	      })
	      .catch(err => {showMessage(err, 'error')});
	  }

	const fetchUsers = async () => {
	    userService.getAll()
	      .then(res => { 

	      	res && setUsers(res.users); 

	      })
	      .catch(err => {showMessage(err, 'error')});
	  }

  const fetchFieldAssociations = async () => {

    fieldService.getByFieldDescriptionId(data[0].field_description_id)
      .then(res => {

      	if (res) {

      		const fields = res.fields.map( field => ({
      			field_id: field.field_id, 
            field_name: field.field_name, 
            field_description_id: data[0].field_description_id, 
            icon: field.datatype_category, 
            secondaryLabel: formatPath([field.system_name, field.datasource_name, field.dataset_group_name, field.dataset_name]),
      		}))
      		setFieldAssociations(fields);
        	setTmpFieldAssociations(fields);
      	}

         
      })
	    .catch(err => {showMessage(err, 'error')});
  }

  const addFieldAssociation = async event => {
    let { value, label, icon, secondaryLabel } = ""

    value = event.value
    label = event.option.label
    icon = event.option.icon
    secondaryLabel = event.option.secondaryLabel

    const field = { 
    	field_id: value, 
    	field_description_id: formData.field_description_id, 
    	field_name: label,
    	icon,
    	secondaryLabel
    }

		let newTmpFieldAssociations = []
    if (tmpFieldAssociations.findIndex(x => x.field_id === value) > -1) {
    	newTmpFieldAssociations = tmpFieldAssociations.filter(x => x.field_id !== value)
    } else {
    	newTmpFieldAssociations = [...tmpFieldAssociations, ...[field]]
    }

    setTmpFieldAssociations(newTmpFieldAssociations)
  }

	const handleSelectAll = async () => {
  	const res = await fetchAllSearchResults()

  	let newTmpFieldAssociations = []
  	
	  	res.searchResults.forEach( result => {

		  	const isSelf = result.searchable.field_id === data[0].field_id

		    const field = { 
		    	field_id: result.searchable.field_id, 
		    	field_description_id: formData.field_description_id, 
		    	field_name: result.searchable.field_name,
		    	icon: result.searchable.datatype_category,
		    	secondaryLabel: formatPath([result.searchable.system_name, result.searchable.datasource_name, result.searchable.dataset_group_name, result.searchable.dataset_name])
		    }

		    if (!isSelf && tmpFieldAssociations.findIndex(x => x.field_id === field.field_id) < 0) {
		   		newTmpFieldAssociations.push(field)
		    }

	  	})

	  if (newTmpFieldAssociations.length > 0) {
	  	setTmpFieldAssociations([...tmpFieldAssociations, ...newTmpFieldAssociations])
	  } else {
	  	newTmpFieldAssociations = [...tmpFieldAssociations]

	  	res.searchResults.forEach( result => {
	  		newTmpFieldAssociations = newTmpFieldAssociations.filter(x => { return x.field_id !== result.searchable.field_id})
	  	})
	  	setTmpFieldAssociations([...newTmpFieldAssociations])
	  }

  	return res
  }

	const removeFieldAssociation = (item) => {
		setTmpFieldAssociations(prev => prev.filter(x => x.field_id !== item.field_id))
	}

	const handleInputChange = (event, formPart) => {
		let { name, value } = ""

		// Handle standard form inputs
		if (event.target !== undefined) {
		  name = event.target.name
		  value = event.target.value

		  // If input element is a checkbox, we cannot use "value"
	    if (event.target.type === "checkbox") { value = event.target.checked }

	  // Handle custom form inputs
	  } else {
	  	name = event.name
	  	value = event.value
	  }

	  switch(name) {
	  	case 'field_description_id':
	  		setFieldDescription( fieldDescriptions.find(x => x.field_description_id === value) )
	  		fetchFieldDescriptionAssocFields(value)
	  		break
	  	case 'batch_field_description_id':
	  		if (!fieldDescription) setFieldDescriptionAssocFields([])
	  	case 'field_description_name':
		  		fetchFieldDescriptionByName(value)
	  		break
	  	case 'add_field_description':
	  		const fieldDescriptionName = (newFieldDescription.field_description_name ? newFieldDescription.field_description_name : formatUserFriendly(formData.field_name))
	  		setNewFieldDescription({
	  			...newFieldDescription,
					field_description_name: fieldDescriptionName,
					field_description_owner_user_id: (newFieldDescription.field_description_owner_user_id ? newFieldDescription.field_description_owner_user_id : loggedInUser.user_id),
					field_description_action: (value ? 'insert' : 'update'),
					field_role_id: fieldRoles?.filter(x => x.field_role_name.toLowerCase() === "dimension")[0].field_role_id
				})
				setAddFieldDescription(value)
				fetchFieldDescriptionByName(fieldDescriptionName)
	  		break
	  	case 'is_pii':
	  		// Check currently selected sensitivity class and change to lowest allowed sensitivity class if current class is not allowed
		  	if (!fieldSensitivities.find(x => x.field_sensitivity_id === newFieldDescription.field_sensitivity_id).is_allowed_for_pii) {
		  		setNewFieldDescription(prev => {return { 
		  			...prev, 
		  			field_sensitivity_id: fieldSensitivities.filter(x => x.is_allowed_for_pii).reduce(function(prev, curr) {
																	    return prev.field_sensitivity_level > curr.field_sensitivity_level ? prev : curr;
																	}).field_sensitivity_id 
					}})
		  	}
	  		break
	  	default:
	  		break
	  }	

	  switch(formPart) {
	  	case 'newFieldDescription':
	  		setNewFieldDescription(prev => {return { ...prev, [name]: value }})
	  		break
	  	case 'addFieldDescription':
	  		// do nothing
	  		break
	  	case 'batch':
	  		setBatchEditItems({ ...batchEditItems, [name]: value })

	  		break
	  	default:
	  		setFormData({ ...formData, [name]: value })
	  }
	}

  const handleSearchFieldInputChange = value => {
    setSearchTerm(value)
  }

	const onSubmit = event => {
		event.preventDefault()

		setLoading(true)
		
		// Form validation
		//if (!formData.user_fullname || !formData.user_username) return

		let dataToSubmit

		if (batchEdit) {
			
			let key = ''
			let tmp = {}
			const objects = Object.entries(batchEditItems)

			for (let i = 0; i < objects.length; i++) {
				if (objects[i][1]) {
					key = objects[i][0].replace("batch_","")
					tmp = {...tmp, [key]: formData[ key ]}
				}
			}

			dataToSubmit = data.map( item => {
				const tmp_field = {field_id: item.field_id, 
													 field_name: item.field_name,
													 field_technical_definition: item.field_technical_definition, 
													 datatype_id: item.datatype_id,
													 dataset_id: item.dataset_id,
													 field_description_id: item.field_description_id, 
													 ...tmp
												  }

				return {field: tmp_field}

			})

		} else {

			if (addFieldDescription) {
				
				let field_associations = tmpFieldAssociations.map(field => ({field_id: field.field_id}))
				
				dataToSubmit = {
					field: formData, 
					field_description: {
						...newFieldDescription,
						field_description_description: textareaToRaw({value: newFieldDescription.field_description_description}),
						field_description_definition: textareaToRaw({value: newFieldDescription.field_description_definition}),
						field_description_example: textareaToRaw({value: newFieldDescription.field_description_example}),
						field_associations
					}
				}
			} else {
				dataToSubmit = {field: formData}
			}
		}

		// submit form
		submitForm(dataToSubmit)
		.then(res => resetForm())
		.catch(err => {
			// Errors handled in submitForm function
		})
		.finally(res => setLoading(false))		    
		    
	}

  const resetForm = () => {

  	setBatchEdit(false)
  	setBatchEditItems(initialBatchEditItems)
  	setDefaultSelectedTab({tab: "description"})
  	setFormData(prevFormData => {
			return {...initialFormState,
							dataset_id: (currentDataset ? currentDataset.dataset_id : null)
						}
		}) 
		setNewFieldDescription(prevFormData => {
			return {...initialFieldDescription}
		}) 
		setAddFieldDescription(false)
  }
  
  const datasetName = () => {
  	const tmp = datasets.find(item => item.dataset_id === formData.dataset_id)
  	return tmp !== undefined ? tmp.dataset_name : ""
  }

  const userFullName = () => {
  	const tmp = users.find(item => item.user_id === fieldDescription.field_description_owner_user_id)
  	return tmp !== undefined ? tmp.user_fullname : ""
  }

  const fieldDescriptionAssocFieldsTooltip = () => {
		return fieldDescriptionAssocFields
			.filter(x => x.field_id !== formData.field_description_id)
			.map( (field, index) => (
				<React.Fragment key={index}>
					<div className="tooltip-title thin"><Icon name={field.datatype_category}/>{field.field_name}</div>
					<div className="tooltip-subtitle inverted-margin">{formatPath([field.system_name, field.datasource_name, field.dataset_group_name, field.dataset_name, field.field_name])}</div>
				</React.Fragment>		
	  	))
	}

	const fieldDescriptionsWithSameNameTooltip = () => {
		return fieldDescriptionsWithSameName.map( (field_description, index) => (
			<React.Fragment key={index}>
				<div className="tooltip-title"><Icon name={field_description.field_role_name}/>{field_description.field_description_name}</div>
				<div className="tooltip-body inverted-margin">{textareaToPlainText(field_description.field_description_description)}</div>
			</React.Fragment>
  	))
	}

	const getSensitivityOptions = () => {
		return newFieldDescription.is_pii 
		? formatSelectOptions({options: fieldSensitivities.map(x => {return {...x, is_not_allowed_for_pii: !x.is_allowed_for_pii}}), optionValue: "field_sensitivity_id", optionLabel: "field_sensitivity_name", optionDisabled: "is_not_allowed_for_pii" })
		: formatSelectOptions({options: fieldSensitivities, optionValue: "field_sensitivity_id", optionLabel: "field_sensitivity_name" })
	}

  return (
  	<div className="form">
	    <form
			  onSubmit={onSubmit}
			  ref={ref}
			>

			{ !batchEdit &&
				<div className="form-block vertical">
		      <Input type="text" 
		      	name="field_name" 
		      	label="Name"
		      	value={formData.field_name} 
		      	onChange={handleInputChange}
		      	disabled={loading}
		      />
	      </div>
			}

			<FormTabs defaultSelectedTab={defaultSelectedTab}>

				<div label="description">
				
				{ !batchEdit &&
				<div className="form-block vertical">
					<SelectButtons 
	          name="add_field_description"
	          value={addFieldDescription}
	          options={ formatSelectOptions({options: [{value: false, label: "Use Existing", tooltip:"Associate with an existing Field Description"}, {value: true, label: "Add New", tooltip:"Add a new Field Description"}], optionValue: "value", optionLabel: "label", tooltip: "tooltip" }) }
	          onChange={(e) => handleInputChange(e,'addFieldDescription')} 
	          disabled={loading}
	        />
		    </div>
		  	}

		    { !addFieldDescription &&
		    	<React.Fragment>
					<div className="form-block vertical">
						{ batchEdit 
							? <label>
									<Checkbox 
										checked={batchEditItems.batch_field_description_id}
										name="batch_field_description_id"
										label="Edit Field Description"
										onChange={ e => handleInputChange(e, 'batch')}
										disabled={loading}
									/>
								</label>
							: <label>Field Description</label>
						}
						<Select 
		          name="field_description_id"
		          value={formData.field_description_id === 'multiple' ? undefined : formData.field_description_id}
		          options={ formatSelectOptions({options: fieldDescriptions, optionValue: "field_description_id", optionLabel: "field_description_name", optionIcon:"field_role_name", tooltip: "field_description_description"}) }
		          isNullable={true}
		          onChange={handleInputChange}
		          placeholder={formData.field_description_id === 'multiple' ? '< Multiple >' : ""}
		          disabled={loading || (batchEdit && !batchEditItems.batch_field_description_id)} 
		        />
		        { fieldDescriptionAssocFields.length > 0 && 
		        	<div
		        		className="form-light-text"
		        		onMouseEnter={ () => { showTooltip({tooltipBody: fieldDescriptionAssocFieldsTooltip()}) }} 
		        		onMouseLeave={hideTooltip}
		        	>
		        	{ fieldDescriptionAssocFields.length } other Fields also use this Field Description
		        	</div>
		      	}
		      </div>

		      { formData.field_description_id !== null && fieldDescription && !batchEdit &&
		      	<React.Fragment>
							<div className="form-block vertical">
								<label>Description</label>
								{ fieldDescription.field_description_description === ""
									?	<span className="no-result-text">No description available</span>
									:	<TextareaReadOnly
                			value={ textareaInitialState({value: fieldDescription.field_description_description, readOnly: true }) } />
            		}
				      </div>
				      <div className="form-block vertical">
								<label>Definition</label>
								{ fieldDescription.field_description_definition === ""
									?	<span className="no-result-text">No definition available</span>
									:	<TextareaReadOnly
	                		value={ textareaInitialState({value: fieldDescription.field_description_definition, readOnly: true }) } />
	            	}
				      </div>
				      <div className="form-block vertical">
								<label>Example</label>
								{ fieldDescription.field_description_example === ""
									?	<span className="no-result-text">No example available</span>
									:	<TextareaReadOnly
	                		value={ textareaInitialState({value: fieldDescription.field_description_example, readOnly: true }) } />
	              }		
				      </div>

				      <div className="form-block vertical">
				     		<label>Data Sensitivity</label>
				     		<div className="horizontal">
                    {fieldDescription.is_pii && <Icon name="pii" tooltip="Classified as Personally Identifiable Information (PII)"/>}
                    <Icon name="field_sensitivity" value={fieldDescription.field_sensitivity_level} values={FieldSensitivity} />
                </div>
              </div>


				      <div className="form-block vertical">
								<label>Owner</label>
								<div><Link to={"/users/"+fieldDescription.field_description_owner_user_id} className="link">{ userFullName() }</Link></div>
				      </div>
			      </React.Fragment>
					}
					</React.Fragment>
		    } 	
		    { addFieldDescription &&
		    	<React.Fragment>
		      <div className="form-block vertical">
						<Input type="text"
		          name="field_description_name"
		          label="Field Description Name"
		          value={newFieldDescription.field_description_name}
		          onChange={(e) => handleInputChange(e,'newFieldDescription')} 
		          disabled={loading}
		        />
		        { fieldDescriptionsWithSameName.length > 0 &&
			        <div 
			        	className="form-light-text"
			        	onMouseEnter={ () => { showTooltip({tooltipBody: fieldDescriptionsWithSameNameTooltip()}) }} 
			        	onMouseLeave={hideTooltip}
			        >
			        { fieldDescriptionsWithSameName.length } Field Description{fieldDescriptionsWithSameName.length > 1 ? 's' : ''} with the same name already exist
			        </div>
		        }
		      </div>	

					<FormTabs>
						<div label="description">

							<div className="form-block vertical">
								<label>Description</label>
								<Textarea
						      	name="field_description_description" 
						      	value={newFieldDescription.field_description_description} 
						      	onChange={(e) => handleInputChange(e,'newFieldDescription')}
						      	disabled={loading}
						      />
				      </div>

				      <div className="form-block vertical">
								<label>Definition</label>
								<Textarea
						      	name="field_description_definition" 
						      	value={newFieldDescription.field_description_definition} 
						      	onChange={(e) => handleInputChange(e,'newFieldDescription')}
						      	disabled={loading}
						      />
				      </div>

				      <div className="form-block vertical">
								<label>Example</label>
								<Textarea 
						      	name="field_description_example" 
						      	value={newFieldDescription.field_description_example} 
						      	onChange={(e) => handleInputChange(e,'newFieldDescription')}
						      	disabled={loading}
						      />
				      </div>

				      <div className="form-block vertical">
								<label>Field Role</label>
								<SelectButtons 
			            name="field_role_id"
			            value={newFieldDescription.field_role_id}
			            options={ formatSelectOptions({options: fieldRoles, optionValue: "field_role_id", optionLabel: "field_role_name", optionIcon: "field_role_name"  }) }
			            onChange={(e) => handleInputChange(e,'newFieldDescription')} 
			            disabled={loading}
			          />
					    </div>

					    <div className="form-block vertical">
								<label>Field Unit</label>
								<Select 
			            name="field_unit_id"
			            value={newFieldDescription.field_unit_id}
			            options={ formatSelectOptions({options: fieldUnits, optionValue: "field_unit_id", optionLabel: "field_unit_code", optionSecondaryLabel: "field_unit_name" }) }
			            isNullable={true}
			            onChange={(e) => handleInputChange(e,'newFieldDescription')}
			            disabled={loading} 
			          />
					    </div>
					    
				      <div className="form-block vertical">
								<label>Owner</label>
								<Select 
				          name="field_description_owner_user_id"
				          value={newFieldDescription.field_description_owner_user_id}
				          options={ formatSelectOptions({options: users, optionValue: "user_id", optionLabel: "user_fullname", tooltip: "user_username" }) }
				          isNullable={true}
				          onChange={(e) => handleInputChange(e,'newFieldDescription')} 
				          disabled={loading}
				        />
				      </div>
						</div>

						<div label="Data Sensitivity">

					    <div className="form-block vertical">
								<label>Sensitivity Class</label>
								<SelectButtons 
			            name="field_sensitivity_id"
			            value={ newFieldDescription.field_sensitivity_id }
			            options={ getSensitivityOptions() }
			            onChange={(e) => handleInputChange(e,'newFieldDescription')}
			            disabled={loading} 
			          />
					    </div>

					    <div className="form-block horizontal">
								<Checkbox 
					      	name='is_pii' 
					      	checked={ newFieldDescription.is_pii } 
					      	onChange={(e) => handleInputChange(e,'newFieldDescription')}
					      	label='Personally Identifiable Information (PII)'
					      	disabled={loading}
					      />
					    </div>
						</div>

						<div label="Fields">

							<div className="form-block vertical">
								<Select 
			            name="field_id"
			            value={ tmpFieldAssociations.map(x => {return {value: x.field_id, label: x.field_name}}) }
			            options={ requestResults }
			            onInputChange={ handleSearchFieldInputChange }
			            loadMoreOptions={ loadNextPage }
			            loading={ loadingMoreOptions }
			            resultCount={ searchResultsCount && searchResultsCount.field }
			            searchTerm={ searchTerm }
			            showSearchResultCount={true}
	            		onSelectAll={handleSelectAll}
			            clearSearchResults={ clearSearchResults }
			            placeholder="Search Fields to add..."
			            isMulti={true}
			            isClearable={true}
			            onChange={ addFieldAssociation } 
			            disabled={loading}
			          />
			        </div>

				     	<div className="form-block vertical">
				     	<label>{ tmpFieldAssociations.length } Other Associated Fields</label>
					      {
					      	tmpFieldAssociations.map((item, index) => {
					      		return (
					      			<div className={"side-dialog-search-result" + (loading ? " disabled" : "")} key={index} onClick={() => {!loading && removeFieldAssociation(item)}}>
						      			<div className="side-dialog-search-result-icon">
					      					<Icon name={item.icon} />
					      				</div>
						      			<div className="side-dialog-search-result-body">
						      				<div className="side-dialog-search-result-title">{item.field_name}</div>
						      				<div className="side-dialog-search-result-subtitle">{item.secondaryLabel}</div>
						      			</div>
						      		</div>
						      	)
					      	})
					      }
				     	</div>
						</div>

						</FormTabs>
		      </React.Fragment>
				}

			</div>
			
			<div label="technical">

				<div className="form-block vertical">
					{ batchEdit 
						? <label>
								<Checkbox 
									checked={batchEditItems.batch_dataset_id}
									name="batch_dataset_id"
									label="Edit Dataset"
									onChange={ e => handleInputChange(e, 'batch')}
									disabled={loading}
								/>
							</label>
						: <label>Dataset</label>
					}
					<Select 
            name="dataset_id"
            value={formData.datatype_id === 'multiple' ? undefined : formData.dataset_id}
            options={ formatSelectOptions({options: datasets, optionValue: "dataset_id", optionLabel: "dataset_name", optionIcon: "dataset_type_name"}) }
            onChange={handleInputChange} 
            placeholder={formData.dataset_id === 'multiple' ? '< Multiple >' : ""}
            disabled={loading || (batchEdit && !batchEditItems.batch_dataset_id)}
          />
		    </div>

				<div className="form-block vertical">
					{ batchEdit 
						? <label>
								<Checkbox 
									checked={batchEditItems.batch_datatype_id}
									name="batch_datatype_id"
									label="Edit Datatype"
									onChange={ e => handleInputChange(e, 'batch')}
									disabled={loading}
								/>
							</label>
						: <label>Datatype</label>
					}
					<Select 
            name="datatype_id"
            value={formData.datatype_id === 'multiple' ? undefined : formData.datatype_id}
            options={ formatSelectOptions({options: datatypes, optionValue: "datatype_id", optionLabel: "datatype_name", optionIcon: "datatype_category"}) }
            onChange={handleInputChange} 
            placeholder={formData.datatype_id === 'multiple' ? '< Multiple >' : ""}
            disabled={loading || (batchEdit && !batchEditItems.batch_datatype_id)}
          />
		    </div>

		    { !batchEdit &&
					<div className="form-block vertical">
						<Checkbox 
			      	name='is_primary_key' 
			      	checked={formData.is_primary_key } 
			      	onChange={handleInputChange}
			      	label='Is Primary Key'
			      	disabled={loading}
			      />
		      </div>
				}	

		    { !batchEdit &&
				<div className="form-block vertical">
					<label>Technical Definition / Calculation</label>
			    <TextareaAutosize 
		      	name="field_technical_definition" 
		      	value={formData.field_technical_definition} 
		      	onChange={handleInputChange}
		      	disabled={loading}
		      	className="code"
		      />
		      </div>
				}	
		    
			</div>

			</FormTabs>
	      
	    </form>
	  </div>
  )
}

export const AddFieldForm = forwardRef(_AddFieldForm)
