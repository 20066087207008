
import { useLocation, useNavigate } from 'react-router-dom';

export const useQueryParams = () => {
  
  const location = useLocation()
  const navigate = useNavigate()

  const params = new URLSearchParams(location.search);

  const defaultParams = {
    hidedetails: undefined,
    search: "",
    object: undefined,
    filter: "",
    filterObjects: undefined,
    tab: undefined,
    sort: undefined,
    sortDirection: "asc",
    sortGroupBy: undefined,
    selectedItems: undefined,
    expandedItems: undefined,
    nodeSize: undefined,
    tablePage: 1,
    tableScroll: 0,
    tableViewMode: undefined,
    schemaChangesOnly: false,
  }

  const paramHideDetailsPane  = (location.state?.hidedetails) || params.get('hidedetails') || defaultParams.hidedetails
  const paramSearchTerm       = (location.state?.search) || params.get('search') || defaultParams.search
  const paramTab              = params.get('tab') || defaultParams.tab
  const paramObject           = params.get('object') || defaultParams.object
  const paramFilterTerm       = (location.state?.filter) || params.get('filter') || defaultParams.filter
  const paramSort             = (location.state?.sort?.key) || params.get('sort') ||  defaultParams.sort
  const paramSortDirection    = (location.state?.sort?.direction) || params.get('sortdirection') ||  defaultParams.sortDirection
  const paramSortGroupBy    = (location.state?.sort?.groupByColumn) || params.get('sortgroupby') ||  defaultParams.sortGroupBy
  const paramTablePage        = (location.state?.tablePage) || params.get('tablepage') || defaultParams.tablePage
  const paramTableScroll      = (location.state?.tableScroll) || defaultParams.tableScroll
  const paramTableViewMode    = (location.state?.tableViewMode) || params.get('tableviewmode') || defaultParams.tableViewMode
  const paramSchemaChangesOnly = (location.state?.schemaChangesOnly) || params.get('schemachangesonly') || defaultParams.schemaChangesOnly

  const paramSelectedItems    = (location.state?.selectedItems) || defaultParams.selectedItems
  const paramExpandedItems    = (location.state?.expandedItems) || defaultParams.expandedItems
  const paramNodeSize         = (location.state?.nodeSize) || defaultParams.nodeSize
  const paramFilterObjects    = (location.state?.filterObjects) || defaultParams.filterObjects

  const queryParams = {
    hidedetails:    (paramHideDetailsPane === true || paramHideDetailsPane === 'true') ? true : false,
    search:         paramSearchTerm,
    object:         paramObject,
    filter:         paramFilterTerm,
    filterObjects:  paramFilterObjects,
    tab:            paramTab,
    sort:           paramSort ? {key: paramSort, direction: paramSortDirection, groupByColumn: paramSortGroupBy} : null,
    selectedItems:  paramSelectedItems,
    expandedItems:  paramExpandedItems,
    nodeSize:       paramNodeSize,
    tablePage:      (paramTablePage && parseInt(paramTablePage)),
    tableScroll:    paramTableScroll,
    tableViewMode:  paramTableViewMode,
    schemaChangesOnly: (paramSchemaChangesOnly === true || paramSchemaChangesOnly === 'true') ? true : false
  }

  const setHistory = (obj) => {

    if (obj.hidedetails && obj.hidedetails !== params.get('hidedetails')) {
      params.delete('hidedetails')
    }

    if (obj.search !== params.get('search')) {
      params.delete('search')
    }

    if (obj.tab && obj.tab !== params.get('tab')) {
      params.delete('tab')
      params.delete('object')
      params.delete('filter')
      params.delete('sort')
      params.delete('sortdirection')

      params.append('tab', obj.tab)
    }

    if (obj.object && obj.object !== params.get('object')) {
      params.delete('object')
      params.delete('filter')
      params.delete('sort')
      params.delete('sortdirection')

      params.append('object', obj.object)
    }

    if (obj.filter && obj.filter !== params.get('filter')) {
      params.delete('filter')

    }

    if (obj.sort && obj.sort.key !== params.get('sort')) {
      params.delete('sort')
      params.delete('sortdirection')
      params.delete('sortgroupby')
    }

    if (obj.sort && obj.sort.direction !== params.get('sortdirection')) {
      params.delete('sortdirection')
    }

    if (obj.sort && obj.sort.sortgroupby !== params.get('sortgroupby')) {
      params.delete('sortgroupby')
    }

    if (obj.tablePage !== params.get('tablepage')) {
      params.delete('tablepage')
    }

    if (obj.tableViewMode && obj.tableViewMode !== params.get('tableviewmode')) {
      params.set('tableviewmode', obj.tableViewMode)
    }

    if (obj.schemaChangesOnly && obj.schemaChangesOnly !== params.get('schemachangesonly')) {
      params.delete('schemachangesonly')
    }

    // Replace url
    navigate(location.pathname + "?" + params.toString(), {state: {...location.state, ...obj}, replace: true })
  }

  const resetParams = (obj={}) => {
    setHistory({...defaultParams, ...obj})
  }

  return { defaultParams, queryParams, setHistory, resetParams }
}