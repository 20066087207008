import React, { useState, useEffect, forwardRef } from 'react'
import { userRoleService, userService } from '../services';
import { Checkbox, formatSelectOptions, Input, SelectButtons  } from '../components';
import { authMethod, getBatchEditValue, formatData, handleError } from '../helpers'
import { useGlobalState, useDebounce, useMessage } from '../hooks'

const _AddUserForm = ({ mode, data, submitForm, loading, setLoading }, ref) => { 
	
	const [settings] = useGlobalState('settings');

	const initialFormState = {
		user_auth_method: settings.authMethods[0],
		user_id: null, 
		user_source_id: null,
		user_fullname: '',
		user_password: '', 
		user_username: '', 
		user_email: '',
		user_photo: null,
		user_title: '',
		user_department: '',
		user_role_id: '',
		password_change_required: true,
		user_is_disabled: false,
		send_welcome_email: settings.MAIL_IS_ENABLED ? true : false
	}

	const initialBatchEditItems = {
		batch_user_role_id: false, 
		batch_user_department: null,
		batch_password_change_required: null,
		batch_user_is_disabled: null
	}

	const [loggedInUser, setLoggedInUser] = useGlobalState('loggedInUser');
	const [formData, setFormData] = useState(initialFormState);
	const [batchEdit, setBatchEdit] = useState(false);
	const [batchEditItems, setBatchEditItems] = useState(initialBatchEditItems)
	const [loadingSearch, setLoadingSearch] = useState(false);
	const [userRoles, setUserRoles] = useState([]);
	const [searchTerm, setSearchTerm] = useState('');
	const [items, setItems] = useState([])
	const [selectedItems, setSelectedItems] = useState([])
	const [accessToken, setAccessToken] = useState()

	const { showMessage } = useMessage()

	useEffect( () => {
		// Get User Roles
    	fetchUserRoles();

  	}, []);

	useEffect(() => {

		setBatchEdit(false)
	    if (data.length === 1) {
			setFormData({ 
				user_auth_method: data[0].user_auth_method,
				user_id: data[0].user_id, 
				user_fullname: data[0].user_fullname || '',
				user_password: undefined, 
				user_username: data[0].user_username || '', 
				user_title: data[0].user_title || '', 
				user_department: data[0].user_department || '', 
				user_email: data[0].user_email || '',
				user_photo: data[0].user_photo || null,
				user_role_id: data[0].user_role_id,
				password_change_required: data[0].password_change_required,
				user_is_disabled: data[0].user_is_disabled,
			})
		} else if(data.length > 1 && mode !== 'add') {
			setBatchEdit(true)

			setFormData({ 
				user_role_id: getBatchEditValue(data, 'user_role_id'),
				password_change_required: getBatchEditValue(data, 'password_change_required'),
				user_is_disabled: getBatchEditValue(data, 'user_is_disabled'),
				user_department: getBatchEditValue(data, 'user_department'),
				user_auth_method: getBatchEditValue(data, 'user_auth_method'),
			})
		} else if(mode === 'add') {
			setFormData({ 
				...formData, 
				password_change_required: true,
				send_welcome_email: settings.MAIL_IS_ENABLED ? true : false
			})
		} else {
			resetForm()		
		}
		
	}, [mode, data]);

	const fetchSearchResults = useDebounce(async (search) => {
  	try {
  		//setLoadingSearch(true)
  		const users = await userService.getFromAD(search, accessToken)

  		setAccessToken(users?.access_token)

  		if (users?.users.length > 0) {
  			setItems(prev => {
					return users.users.map(x => {
						if(selectedItems.find(y => y.user_source_id === x.user_source_id)) {
							return {...x, selected: true}
						}
						return x
					})
				})
				setLoadingSearch(false)
			} else if(users === undefined) {
				// Do nothing
				// users is undefined if request was aborted
			} else {
  			setItems(prev => {return []})
  			setLoadingSearch(false)
  		}

  		
  	} catch(err) {
  		if (err.name !== "AbortError") {
				setLoadingSearch(false)
  		}
  		showMessage(err, "error")
  	}
  }, 200).debounce

	const fetchUserRoles = async () => {
    userRoleService.getAll()
      .then(res => { 

      	setUserRoles(res.user_roles); 

      })
      .catch(err => {showMessage(err, "error")});
  }

  
	const handleInputChange = async (event, formPart) => {
		let { name, value } = ""

		// Handle standard form inputs
		if (event.target !== undefined) {
		  name = event.target.name
		  value = event.target.value

		  // If input element is a checkbox, we cannot use "value"
	    if (event.target.type === "checkbox") { value = event.target.checked }

	  // Handle custom form inputs
	  } else {
	  	name = event.name
	  	value = event.value
	  }

	  switch(name) {
	  	case 'search_user':
	  		setSearchTerm(prev => {return value})

	  		if (value.length > 1) {
	  			setLoadingSearch(true)
					fetchSearchResults(value)
				} else {
					setItems(prev => {return []})					
				}
	  		break
	  	case 'send_welcome_email':
	  		setFormData(prev => ({ 
	  			...prev, 
	  			password_change_required: value ? true : prev.password_change_required
	  		}))
	  }


	  switch(formPart) {
	  	case 'batch':
	  		setBatchEditItems({ ...batchEditItems, [name]: value })
	  		break
	  	default:
	  		setFormData(prev => ({ ...prev, [name]: value }))
	  }
	}

	const onSubmit = event => {

		event.preventDefault()

		setLoading(true)
		
		// Form validation
		//if (!formData.user_fullname || !formData.user_username) return

		let dataToSubmit

		if (batchEdit) {
			
			let key = ''
			let tmp = {}
			const objects = Object.entries(batchEditItems)

			for (let i = 0; i < objects.length; i++) {
				if (objects[i][1]) {
					key = objects[i][0].replace("batch_","")
					tmp = {...tmp, [key]: formData[ key ]}
				}
			}

			dataToSubmit = data.map( item => {
				return {user_auth_method: item.user_auth_method,
								user_id: item.user_id, 
								user_source_id: item.user_source_id,
								user_fullname: item.user_fullname,
								user_password: item.user_password, 
								user_username: item.user_username, 
								user_email: item.user_email,
								user_title: item.user_title,
								user_department: item.user_department,
								user_photo: item.user_photo,
								user_role_id: item.user_role_id,
								password_change_required: item.password_change_required,
								user_is_disabled: item.user_is_disabled, 
								...tmp
							}
			})

		} else if (formData.user_auth_method === 'oidc' && mode === 'add') {
			dataToSubmit = selectedItems.map( item => {
				return {user_auth_method: formData.user_auth_method,
								user_id: item.user_id, 
								user_source_id: item.user_source_id,
								user_fullname: item.user_fullname,
								user_username: item.user_username, 
								user_email: item.user_email,
								user_title: item.user_title,
								user_department: item.user_department,
								user_photo: item.user_photo,
								user_role_id: formData.user_role_id,
								user_is_disabled: formData.user_is_disabled,
								send_welcome_email: formData.send_welcome_email
							}
			})
		} else {
			dataToSubmit = formData
		}

		// submit form
		submitForm(dataToSubmit)
		.then(res => resetForm())
		.catch(err => {
			// Errors handled in submitForm function
		})
		.finally(res => setLoading(false))	    
		    
	}

	const selectItem = (item) => {
		if (!selectedItems.find(x => x.user_source_id === item.user_source_id)) {
			setSelectedItems(prev => {return [...prev, item]})
			
			setItems(prev => {
				return prev.map(x => {
					if(x.user_source_id === item.user_source_id) {
						return {...x, selected: true}
					}
					return x
				})
			})
		}
	}

	const removeItem = (item) => {
		setSelectedItems(prev => prev.filter(x => x.user_source_id !== item.user_source_id))
			
		setItems(prev => {
			return prev.map(x => {
				if(x.user_source_id === item.user_source_id) {
					return {...x, selected: false}
				}
				return x
			})
		})
	}

  const resetForm = () => {

  	setBatchEdit(false)
  	setItems([])
  	setSelectedItems([])
  	setBatchEditItems(initialBatchEditItems)
  	setSearchTerm('')
  	setFormData(prevFormData => {
			return {
				...initialFormState,
				user_auth_method: prevFormData.user_auth_method
			}
		}) 
  }

  const userAuthMethods = settings.authMethods.map(x => {
    const method = Object.entries(authMethod).find(([key, value]) => x === key)
    if (method) {
    	const [key, value] = method
      return {value:key, label: value}
    } 
  })

  return (
  	<div className="form">
	    <form
			  onSubmit={onSubmit}
			  ref={ref}
			>
				{ !batchEdit && userAuthMethods.length > 1 &&
					<div className="form-block vertical">
						<label>User Type</label>
						<SelectButtons 
			        name="user_auth_method"
			        value={formData.user_auth_method}
			        options={ formatSelectOptions({options: userAuthMethods, optionValue: "value", optionLabel: "label"  }) }
			        onChange={handleInputChange} 
			        disabled={loading || mode !== 'add'}
			      />

		      </div>
		    }
		    { formData.user_auth_method === "oidc" && mode === 'add' && 
		    	<React.Fragment>
			    	<div className="form-block vertical side-dialog-search-input">
				      <Input type="text" 
				      	name="search_user" 
				      	label="Search User"
				      	value={searchTerm} 
				      	onChange={handleInputChange}
				      	disabled={loading}
				      	isNullable={true}
				     	/>
     					{ loadingSearch && 
				     		<span className="side-dialog-search-loading-indicator">Searching...</span>
				     	}
				     	{ !loadingSearch && searchTerm.length > 1 && 
				     		<span className="side-dialog-search-result-count">{items.length} users found</span>
				     	}
			     	</div>

			     	{ items.length > 0 && 
			     	<div className="form-block vertical">
				      {
				      	items.map((item, index) => {
				      		return (
				      			<div className={"side-dialog-search-result" + (item?.selected ? " selected" : "")} key={index} onClick={() => !item?.selected && selectItem(item)} title={`${item.user_fullname} (${item.user_username})\n${item.user_title ? item.user_title : ""}\n${item.user_department ? item.user_department : ""}`}>
				      				<div className="side-dialog-search-result-photo">
				      					{ formatData(item.user_photo, 'user-photo') }
				      				</div>
					      			<div className="side-dialog-search-result-body">
					      				<div className="side-dialog-search-result-title">{item.user_fullname}</div>
					      				<div className="side-dialog-search-result-subtitle">{item.user_username}</div>
					      			</div>
					      			{item?.selected &&
					      				<div className="side-dialog-search-result-selected">selected</div>
					      			}
					      		</div>
					      	)
				      	})
				      }
			     	</div>
						}

			     	{ selectedItems.length > 0 && 
				     	<div className="form-block vertical">
				     	<label>{selectedItems.length } Users to add</label>
					      {
					      	selectedItems.map((item, index) => {
					      		return (
					      			<div className="side-dialog-search-result" key={index} onClick={() => removeItem(item)} title={`${item.user_fullname} (${item.user_username})\n${item.user_title ? item.user_title : ""}\n${item.user_department ? item.user_department : ""}`}>
						      			<div className="side-dialog-search-result-photo">
					      					{ formatData(item.user_photo, 'user-photo') }
					      				</div>
						      			<div className="side-dialog-search-result-body">
						      				<div className="side-dialog-search-result-title">{item.user_fullname}</div>
						      				<div className="side-dialog-search-result-subtitle">{item.user_username}</div>
						      			</div>
						      		</div>
						      	)
					      	})
					      }
				     	</div>
						}
			    </React.Fragment>
		  	}

		  	
				{ !batchEdit && (formData.user_auth_method !== "oidc" || mode === 'edit') &&
		      <div className="form-block vertical">
			      <Input type="text" 
			      	name="user_fullname" 
			      	label="Full Name"
			      	value={formData.user_fullname} 
			      	onChange={handleInputChange}
			      	autoComplete='new-password'
			      	disabled={loading || formData.user_auth_method === 'oidc'}
			     	/>
		     	</div>
		    }

			  { formData.user_auth_method === "local" &&
		  		<React.Fragment>
					{ !batchEdit && loggedInUser && (loggedInUser.user_id !== formData.user_id) &&
						<div className="form-block vertical">
				      <Input type="text" 
				      	name="user_username" 
				      	label="Username"
				      	value={formData.user_username} 
				      	onChange={handleInputChange}
				      	autoComplete='new-password'
				      	disabled={loading}
				      />
			      </div>
					}
					{ loggedInUser && (loggedInUser.user_id !== formData.user_id) && mode === 'add' && formData.user_auth_method === "local" &&
				  	<div className="form-block vertical">
				      <Checkbox 
				      	name='send_welcome_email' 
				      	checked={formData.send_welcome_email} 
				      	onChange={handleInputChange}
				      	label='Send Welcome Email With Auto-Generated Password'
				      	title={"Send an email with login instructions to the user. The email will contain a temporary password that the user must change on first login." + (!settings.MAIL_IS_ENABLED ? " Mail notifications must be enabled to use this feature." : "")}
				      	disabled={loading || !settings.MAIL_IS_ENABLED}
				      />
				    </div>
					}

					{ !batchEdit && loggedInUser && (loggedInUser.user_id !== formData.user_id) && !formData.send_welcome_email &&
			      <div className="form-block vertical">
				      <Input type="password" 
				      	name="user_password" 
				      	label="Password"
				      	value={ formData.user_password } 
				      	onChange={handleInputChange}
				      	autoComplete='new-password'
				      	disabled={loading || formData.send_welcome_email}
				      	placeholder={mode !== "add" ? "< hidden >" : ""}
				      />
			      </div>
				  }
				  { !batchEdit &&
			     	<div className="form-block vertical">
				      <Input type="email" 
				      	name="user_email" 
				      	label="Email"
				      	value={formData.user_email} 
				      	onChange={handleInputChange}
				      	disabled={loading}
				      />
			      </div>
					}
				  { !batchEdit &&
			     	<div className="form-block vertical">
				      <Input type="text" 
				      	name="user_title" 
				      	label="Job Title"
				      	value={formData.user_title}
				      	onChange={handleInputChange}
				      	disabled={loading}
				      />
			      </div>
					}
					
					</React.Fragment>
				}		
				{ loggedInUser && (loggedInUser.user_id !== formData.user_id) && formData.user_auth_method === "local" &&
						<div className="form-block vertical">
						{ batchEdit 
							? <label>
									<Checkbox 
										checked={batchEditItems.batch_user_department}
										name="batch_user_department"
										label="Edit Department"
										onChange={ e => handleInputChange(e, 'batch')}
										disabled={loading}
									/>
								</label>
							: <label>Department</label>
						}
						<Input type="text" 
				      	name="user_department" 
				      	value={formData.user_department === 'multiple' ? '' : formData.user_department} 
				      	onChange={handleInputChange}
				      	placeholder={formData.user_department === 'multiple' ? '< Multiple >' : ""}
				      	disabled={loading || (batchEdit && !batchEditItems.batch_user_department)}
				      />
				    </div>
					}
				{ loggedInUser && (loggedInUser.user_id !== formData.user_id) && 
					<React.Fragment>
					<div className="form-block vertical">
						{ batchEdit 
							? <label>
									<Checkbox 
										checked={batchEditItems.batch_user_role_id}
										name="batch_user_role_id"
										label="Edit Role"
										onChange={ e => handleInputChange(e, 'batch')}
										disabled={loading}
									/>
								</label>
							: <label>Role</label>
						}
						<SelectButtons 
		          name="user_role_id"
		          value={formData.user_role_id}
		          options={ formatSelectOptions({options: userRoles, optionValue: "user_role_id", optionLabel: "user_role_name"  }) }
		          onChange={handleInputChange} 
		          disabled={loading || (batchEdit && !batchEditItems.batch_user_role_id)}
		        />
		      </div>
		      </React.Fragment>
				}
				
				{ loggedInUser && (loggedInUser.user_id !== formData.user_id) && 
		      <React.Fragment>
			      <div className="form-block vertical">
			      	{ batchEdit 
								? <label>
										<Checkbox 
											checked={batchEditItems.batch_user_is_disabled}
											name="batch_user_is_disabled"
											label="Edit Disabled"
											onChange={ e => handleInputChange(e, 'batch')}
											disabled={loading}
										/>
									</label>
								: null
							}

							<div className="horizontal">
					      <Checkbox 
					      	name='user_is_disabled' 
					      	checked={formData.user_is_disabled } 
					      	onChange={handleInputChange}
					      	label='Disabled'
					      	title='Disabled users cannot login to the application'
					      	disabled={loading || (batchEdit && !batchEditItems.batch_user_is_disabled)}
					      />
				      </div>
			      </div>
		      </React.Fragment>
			  }
			  { loggedInUser && (loggedInUser.user_id !== formData.user_id) && formData.user_auth_method === "local" &&
			  	<div className="form-block vertical">
			  		{ batchEdit 
							? <label>
									<Checkbox 
										checked={batchEditItems.batch_password_change_required}
										name="batch_password_change_required"
										label="Edit Require Password Change On Next Login"
										onChange={ e => handleInputChange(e, 'batch')}
										disabled={loading}
									/>
								</label>
							: null
						}
			      <div className="horizontal">
				      <Checkbox 
				      	name='password_change_required' 
				      	checked={formData.password_change_required} 
				      	onChange={handleInputChange}
				      	label='Require Password Change On Next Login'
				      	title={'Force the user to set a new password before logging in the next time.' + (formData.send_welcome_email ? " This option is mandatory if the Send Welcome Email option is selected above." : "")}
				      	disabled={loading || (batchEdit && !batchEditItems.batch_password_change_required) || formData.send_welcome_email}
				      />
			      </div>
			    </div>
			  }

			  { loggedInUser && (loggedInUser.user_id !== formData.user_id) && mode === 'add' && formData.user_auth_method === "oidc" &&
			  	<div className="form-block vertical">
			      <Checkbox 
			      	name='send_welcome_email' 
			      	checked={formData.send_welcome_email} 
			      	onChange={handleInputChange}
			      	label='Send Welcome Email'
			      	title={"Send an email with login instructions to the user." + (!settings.MAIL_IS_ENABLED ? " Mail notifications must be enabled and configured to use this feature." : "")}
			      	disabled={loading || !settings.MAIL_IS_ENABLED}
			      />
			    </div>
				}
	    </form>
	  </div>
  )
}

export const AddUserForm = forwardRef(_AddUserForm)
