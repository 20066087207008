import config from '../config';
import { customFetch, authHeader, jsonHeader, handleResponse, commonRequestOptions } from '../helpers';

const endpointPath='display_name'

export const displayNameService = {
    getAllForFrontend
};

function getAllForFrontend(signal) {
    const requestOptions = { method: 'GET', headers: authHeader(), ...commonRequestOptions() };
    return customFetch(`${config.api.url}/${endpointPath}/all_for_frontend`, requestOptions, signal).then(handleResponse);
}