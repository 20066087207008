import React, { useState, useEffect, forwardRef } from 'react'
import TextareaAutosize from 'react-textarea-autosize';
import { taskTypeService, datasourceService } from '../services';
import { Checkbox, Select, formatSelectOptions, Input } from '../components';
import { getBatchEditValue, TaskType } from '../helpers'
import { useGlobalState, useMessage } from '../hooks';

const _AddTaskForm = ({ mode, data, submitForm, loading, setLoading }, ref) => { 
	
	const initialFormState = {
		task_id: null, 
		task_name: '',
		task_description: '', 
		task_type_id: null, 
		task_priority: 100,
		datasource_id: null,
		task_is_disabled: false,
		change_notification_email: ''
	}

	const initialBatchEditItems = {
		task_priority: false,
		task_is_disabled: false,
		change_notification_email: ''
	}

	const [formData, setFormData] = useState(initialFormState);
	const [batchEdit, setBatchEdit] = useState(false);
	const [batchEditItems, setBatchEditItems] = useState(initialBatchEditItems)
	const [taskTypes, setTaskTypes] = useState([]);
	const [taskType, setTaskType] = useState([]);
	const [datasources, setDatasources] = useState([]);
	const [settings, setSettings] = useGlobalState('settings')

	const { showMessage } = useMessage()

	useEffect( () => {
		// Get form data
    	fetchTaskTypes();
    	fetchDatasources();

  	}, []);

	useEffect(() => {
		setBatchEdit(false)
	    if (data.length === 1) {

			setFormData({ 
				task_id: data[0].task_id, 
				task_name: data[0].task_name || '',
				task_description: data[0].task_description || '', 
				task_type_id: data[0].task_type_id,
				task_priority: data[0].task_priority || '',
				datasource_id: data[0].datasource_id,
				task_is_disabled: data[0].task_is_disabled,
				change_notification_email: data[0].change_notification_email || ''
			})
		} else if(data.length > 1 && mode !== 'add') {
			setBatchEdit(true)

			setFormData({ 
				task_priority: getBatchEditValue(data, 'task_priority'),
				task_is_disabled: getBatchEditValue(data, 'task_is_disabled'),
				change_notification_email: getBatchEditValue(data, 'change_notification_email')
			})
		} else {
			resetForm()		
		}

		if (data.length > 0) {
			setTaskType( taskTypes.find(x => x.task_type_id === data[0].task_type_id))
		}

	}, [mode, data]);
  

	const fetchTaskTypes = async () => {
	    taskTypeService.getAll()
	      .then(res => { 
	      	if (res && res.task_types) {
	      		const result = res.task_types.map(x => {

	      			let disabled = false
	      			let tooltip = x.task_type_description
	      			if (x.task_type_code === TaskType.adsync && !settings.OIDC_IS_ENABLED) {
	      				disabled = true
	      				tooltip = "Enable Azure OpenID Connect Authentication to use this feature.\n" + x.task_type_description
	      			}
      				return {
      					...x,
      					disabled: disabled,
      					task_type_description: tooltip
      				}
	      			
	      		})
	      		setTaskTypes(result);     	

	      		if (data.length > 0) {
	      			setTaskType( res.task_types.find(x => x.task_type_id === data[0].task_type_id) )
	      		}  
	      	}
	      })
	      .catch(err => {showMessage(err, 'error')});
	  }

	const fetchDatasources = async () => {
	    datasourceService.getAll()
	      .then(res => { 

	      	setDatasources(res.datasources); 

	      })
	      .catch(err => {showMessage(err, 'error')});
	  }

	const handleInputChange = (event, formPart) => {
	  let { name, value } = ""

		// Handle standard form inputs
		if (event.target !== undefined) {
		  name = event.target.name
		  value = event.target.value

		  // If input element is a checkbox, we cannot use "value"
	    if (event.target.type === "checkbox") { value = event.target.checked }

	  // Handle custom form inputs
	  } else {
	  	name = event.name
	  	value = event.value
	  }

    if (name === 'task_type_id') { setTaskType( taskTypes.find(x => x.task_type_id === value) ) }	
		 
		switch(formPart) {
	  	case 'batch':
	  		setBatchEditItems({ ...batchEditItems, [name]: value })
	  		break
	  	default:
	  		setFormData({ ...formData, [name]: value })
	  }
	}

	const onSubmit = event => {

		event.preventDefault()

		setLoading(true)
		
		// Form validation
		//if (!formData.user_fullname || !formData.user_username) return

		let dataToSubmit

		if (batchEdit) {
			
			let key = ''
			let tmp = {}
			const objects = Object.entries(batchEditItems)

			for (let i = 0; i < objects.length; i++) {
				if (objects[i][1]) {
					key = objects[i][0].replace("batch_","")
					tmp = {...tmp, [key]: formData[ key ]}
				}
			}

			dataToSubmit = data.map( item => {

				return {task_id: item.task_id, 
								task_name: item.task_name,
								task_description: item.task_description, 
								task_type_id: item.task_type_id,
								task_priority: item.task_priority,
								datasource_id: item.datasource_id,
								task_is_disabled: item.task_is_disabled, 
								change_notification_email: item.change_notification_email,
								...tmp
							}
			})

		} else {
			dataToSubmit = {...formData, 
				datasource_id: taskType.task_type_code === TaskType.dssync ? formData.datasource_id : initialFormState["datasource_id"]
			}
		}
		
		// submit form
		submitForm(dataToSubmit)
		.then(res => resetForm())
		.catch(err => {
			// Errors handled in submitForm function
		})
		.finally(res => setLoading(false))		    
		    
	}

  const resetForm = () => {

  	setBatchEdit(false)
  	setBatchEditItems(initialBatchEditItems)
  	setTaskType([])
  	setFormData(prevFormData => {
	      			return {...initialFormState }
	      			} ) 
  }

  return (
  	<div className="form">
	    <form
			  onSubmit={onSubmit}
			  ref={ref}
			>
				{ !batchEdit &&
				<div className="form-block vertical">
				      <Input type="text" 
				      	name="task_name" 
				      	label="Name"
				      	value={formData.task_name} 
				      	onChange={handleInputChange}
				      	disabled={loading}
				      />
			      </div>
				}
				{ !batchEdit &&
				<div className="form-block vertical">
					<label>Description</label>
					<TextareaAutosize
		      	name="task_description" 
		      	value={formData.task_description} 
		      	onChange={handleInputChange}
		      	disabled={loading}
		      />
		    </div>
		  	}

				{ batchEdit 
							? <label>
									<Checkbox 
										checked={batchEditItems.batch_task_is_disabled}
										name="batch_task_is_disabled"
										label="Edit Disabled"
										onChange={ e => handleInputChange(e, 'batch')}
										disabled={loading}
									/>
								</label>
							: null
						}
								     <div className="form-block horizontal">
		      <Checkbox 
		      	name='task_is_disabled' 
		      	checked={formData.task_is_disabled } 
		      	onChange={handleInputChange}
		      	label='Disabled'
		      	disabled={loading || (batchEdit && !batchEditItems.batch_task_is_disabled)}
		      />
	      </div>

		  	<div className="form-block vertical">
			  	{ batchEdit 
						? <label>
								<Checkbox 
									checked={batchEditItems.batch_task_priority}
									name="batch_task_priority"
									label="Edit Priority"
									onChange={ e => handleInputChange(e, 'batch')}
									disabled={loading}
								/>
							</label>
						: <label>Priority</label>
					}
					<Input type="text" 
			      	name="task_priority" 
			      	value={formData.task_priority === 'multiple' ? '' : formData.task_priority} 
			      	onChange={handleInputChange}
			      	placeholder={formData.task_priority === 'multiple' ? '< Multiple >' : ""}
			      	disabled={loading || (batchEdit && !batchEditItems.batch_task_priority)}
			      />
		    </div>

				{ !batchEdit &&
		    <div className="form-block vertical">
					<label>Task Type</label>
					<Select 
            name="task_type_id"
            value={formData.task_type_id}
            options={ formatSelectOptions({options: taskTypes, optionValue: "task_type_id", optionLabel: "task_type_name", tooltip:"task_type_description", optionDisabled: "disabled"}) }
            onChange={handleInputChange} 
            disabled={loading}
          />
		    </div>
		  	}

	      { taskType && (taskType.task_type_code === TaskType.dssync) &&
	      	<React.Fragment>
						{ !batchEdit &&
						<div className="form-block vertical">
							<label>Datasource</label>
							<Select 
		            name="datasource_id"
		            value={formData.datasource_id}
		            options={ formatSelectOptions({options: datasources, optionValue: "datasource_id", optionLabel: "datasource_name", optionSecondaryLabel: "system_name", optionIcon: "datasource_type_category", tooltip: "datasource_description"}) }
		            onChange={handleInputChange} 
		            disabled={loading}
		          />
				    </div>
						}
				    

					    <div className="form-block vertical">
						  	{ batchEdit && 
						  			<label>
											<Checkbox 
												checked={batchEditItems.batch_change_notification_email}
												name="batch_change_notification_email"
												label="Edit Schema Change Notification Email"
												onChange={ e => handleInputChange(e, 'batch')}
												disabled={loading}
											/>
										</label>
								}
								<Input 
									type="text" 
									name="change_notification_email" 
									label="Schema Change Notification Email"
									title={(settings.MAIL_IS_ENABLED ? "" : "Enable and configure email notifications to use this feature.\n") + "List of email addresses, separated by a comma, that will receive a notification email if schema changes are detected when running the task" }
						      value={formData.change_notification_email === 'multiple' ? '' : formData.change_notification_email} 
						      onChange={handleInputChange}
						      placeholder={formData.change_notification_email === 'multiple' ? '< Multiple >' : ""}
						      disabled={loading || (batchEdit && !batchEditItems.batch_change_notification_email) || !settings.MAIL_IS_ENABLED}
						    />
					    </div>
				  	
					</React.Fragment>
			  }				
	    </form>
	  </div>
  )
}

export const AddTaskForm = forwardRef(_AddTaskForm)

