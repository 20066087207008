import React, { useState, useEffect } from 'react';
import { datasourceSubtypeService, settingService } from '../services';
import { useModalDialog, useQueryParams, useMessage, useSideDialog } from '../hooks';
import { Button, Checkbox, DataTable, Input, Section, SelectButtons, formatSelectOptions, Tabs } from '../components'


export const Settings = () => {
  
  const { queryParams, setHistory } = useQueryParams()

  const [settings, setSettings] = useState({});
  const [formData, setFormData] = useState({});
  const [datasourceSubtypes, setDatasourceSubtypes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [sendingEmail, setSendingEmail] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [defaultSelectedTab, setDefaultSelectedTab] = useState({tab: queryParams.tab})

  const clearSelectedItems = () => {
    setSelectedItems([])
  }

  const { showMessage } = useMessage()
  const { showModalDialog, hideModalDialog } = useModalDialog()
  const { showSideDialog, hideSideDialog } = useSideDialog(clearSelectedItems)

  useEffect(() => {

    fetchItems()
    fetchDatasourceSubtypes()
    
  }, []);

  const fetchItems = async () => {
    await settingService.getAll()
      .then(res => {
        let result = {}
        res.settings.forEach(setting => {
          result[setting.setting_code] = {...setting}
        })
        setSettings(result)
        setFormData(result)
        setLoading(false)
      })
      .catch(err => {
        showMessage(err, 'error')
        setLoading(false)
      });
  }

  const fetchDatasourceSubtypes = async () => {
    await datasourceSubtypeService.getByDatasourceTypeCode('odbc')
      .then(res => {
        setDatasourceSubtypes(res.datasource_subtypes)
        setLoading(false)
      })
      .catch(err => {
        showMessage(err, 'error')
        setLoading(false)
      });
  }

  const addDatasourceSubtype = async data => {
    const datasourceSubtypes = {datasource_subtypes: [data]}

    await datasourceSubtypeService.create(datasourceSubtypes)
      .then(res => {

        fetchDatasourceSubtypes()
        showMessage(res.message)
      })
      .catch(err => {
        showMessage(err, 'error')
        throw err
      });
  }

  const editDatasourceSubtype = async data => {
    const datasourceSubtypes = {datasource_subtypes: (Array.isArray(data) ? data : [data])}
    
    await datasourceSubtypeService.update(datasourceSubtypes)
      .then(async res => { 

        fetchDatasourceSubtypes()
        showMessage(res.message)
        clearSelectedItems()
        hideSideDialog()
      })
      .catch(err => {
        showMessage(err, 'error')
        throw err
      });
  }

  const deleteDatasourceSubtype = async data => {    
    await datasourceSubtypeService.destroy({ datasource_subtypes: data })
      .then(async res => { 

        hideModalDialog()
        clearSelectedItems()
        fetchDatasourceSubtypes()
        showMessage(res.message)

      })
      .catch(err => {
        showMessage(err, 'error')
      });
  }
  const editSettings = async data => {
    await settingService.update(data)
      .then(async res => { 

        fetchItems()
        showMessage(res)
      })
      .catch(err => {
        showMessage(err, 'error')
      });
  }

  const saveSettings = (event) => {
    hideModalDialog()
    onSubmit(event)
  }

  const handleInputChange = (event) => {

    let { name, value } = ""

    // Handle standard form inputs
    if (event.target !== undefined) {
      name = event.target.name
      value = event.target.value

      // If input element is a checkbox, we cannot use "value"
      if (event.target.type === "checkbox") { value = event.target.checked }

    // Handle custom form inputs
    } else {
      name = event.name
      value = event.value
    }

    setFormData({ ...formData, [name]: {...formData[name], setting_value: value} })

  }

  const sendTestEmail = async (e) => {
    e.preventDefault()

    setSendingEmail(true)

    try {
      const config = {
        MAIL_IS_ENABLED: true,
        MAIL_SERVER_TYPE: formData.MAIL_SERVER_TYPE.setting_value,
        MAIL_HOST: formData.MAIL_HOST.setting_value,
        MAIL_PORT: formData.MAIL_PORT.setting_value,
        MAIL_IS_SECURE: formData.MAIL_IS_SECURE.setting_value,
        MAIL_USERNAME: formData.MAIL_USERNAME.setting_value,
        MAIL_PASSWORD: formData.MAIL_PASSWORD.setting_value,
        MAIL_FROM: formData.MAIL_FROM.setting_value,
        MAIL_FRONTEND_URL: (formData?.MAIL_FRONTEND_URL?.setting_value !== "" && formData?.MAIL_FRONTEND_URL?.setting_value) || window.location.origin,
        MAIL_SUPPORT_NAME: formData.MAIL_SUPPORT_NAME.setting_value,
        MAIL_SUPPORT_EMAIL: formData.MAIL_SUPPORT_EMAIL.setting_value,
      }
      const res = await settingService.sendTestEmail({config: config}) 
      showMessage(res)

    } catch (err) {
      showMessage(err, 'error')
    } finally {
      setSendingEmail(false)
    }
  }

  const onSubmit = event => {
    event.preventDefault()
    
    // Form validation
    //if (!formData.user_fullname || !formData.user_username) return

    setLoading(true)
    let data = formData

    if (!data?.MAIL_FRONTEND_URL?.setting_value || data?.MAIL_FRONTEND_URL?.setting_value === "") {
      data = {
        ...data, 
        MAIL_FRONTEND_URL: {
          ...data.MAIL_FRONTEND_URL,
          setting_value: window.location.origin
        }
      }
    }

    const dataToSubmit = Object.entries(data).map( ([key, value]) => {
      return { setting_id: value.setting_id, setting_value: value.setting_value }
    })

    // submit form
    editSettings({settings: dataToSubmit})
    .catch(err => {
      // Errors handled in editSettings
    })
    .finally(res => { 
      setLoading(false) 
    })       
        
  }

  const cancel = () => {
    setFormData(settings)
  }

  return (
    <div className="wide-margin">
      <div className="form">
        <form>
          
          <Tabs className="slim left"
            defaultSelectedTab={ defaultSelectedTab }
            disableTabsWithoutResults={ true }
          >
         
            <div label="Authentication" tabid="authentication">
            
              <div className="form-content">

                <div className="form-block vertical">
                  <Checkbox 
                    checked={formData?.LOCAL_IS_ENABLED?.setting_value  || false}
                    name={formData?.LOCAL_IS_ENABLED?.setting_code}
                    label={formData?.LOCAL_IS_ENABLED?.setting_name}
                    onChange={ handleInputChange}
                    disabled={loading}
                    title={formData?.LOCAL_IS_ENABLED?.setting_description}
                  />
                </div>

                <div className="form-block vertical">
                  <Checkbox 
                    checked={formData?.OIDC_IS_ENABLED?.setting_value || false}
                    name={formData?.OIDC_IS_ENABLED?.setting_code}
                    label={formData?.OIDC_IS_ENABLED?.setting_name}
                    onChange={ handleInputChange}
                    disabled={loading}
                    title={formData?.OIDC_IS_ENABLED?.setting_description}
                  />
                </div>
                
                {formData?.OIDC_IS_ENABLED?.setting_value && 
                <div>
                <h4>Azure OpenID Connect Parameters</h4>
                  <div className="form-block vertical">
                    <Input type="text" 
                      name={formData?.OIDC_DIRECTORY_ID?.setting_code}
                      label={formData?.OIDC_DIRECTORY_ID?.setting_name}
                      value={formData?.OIDC_DIRECTORY_ID?.setting_value || ''} 
                      onChange={handleInputChange}
                      disabled={loading}
                      title={formData?.OIDC_DIRECTORY_ID?.setting_description}
                    />
                  </div>

                  <div className="form-block vertical">
                    <Input type="text" 
                      name={formData?.OIDC_APPLICATION_ID?.setting_code}
                      label={formData?.OIDC_APPLICATION_ID?.setting_name}
                      value={formData?.OIDC_APPLICATION_ID?.setting_value || ''} 
                      onChange={handleInputChange}
                      disabled={loading}
                      title={formData?.OIDC_APPLICATION_ID?.setting_description}
                    />
                  </div>

                  <div className="form-block vertical">
                    <Input type="password" 
                      name={formData?.OIDC_CLIENT_SECRET?.setting_code}
                      label={formData?.OIDC_CLIENT_SECRET?.setting_name}
                      value={formData?.OIDC_CLIENT_SECRET?.setting_value || ''} 
                      onChange={handleInputChange}
                      disabled={loading}
                      placeholder="< hidden >"
                      title={formData?.OIDC_CLIENT_SECRET?.setting_description}
                    />
                  </div>

                  <div className="form-block vertical">
                    <Input type="text" 
                      name={formData?.OIDC_REDIRECT_URI?.setting_code}
                      label={formData?.OIDC_REDIRECT_URI?.setting_name}
                      value={formData?.OIDC_REDIRECT_URI?.setting_value || ''} 
                      onChange={handleInputChange}
                      disabled={loading}
                      title={formData?.OIDC_REDIRECT_URI?.setting_description}
                    />
                  </div>
                </div>
                }
              </div>
            </div>

            <div label="User Provisioning" tabid="userProvisioning">
              <div className="form-content">

                  <h4>Azure AD (through Microsoft Graph API)</h4>

                { formData?.OIDC_IS_ENABLED?.setting_value ? 
                  <React.Fragment>
                    <div className="form-block vertical">
                      <Input type="text" 
                        name={formData?.MSGRAPH_USER_BASE_FILTER?.setting_code}
                        label={formData?.MSGRAPH_USER_BASE_FILTER?.setting_name}
                        value={formData?.MSGRAPH_USER_BASE_FILTER?.setting_value || ''} 
                        onChange={handleInputChange}
                        disabled={loading}
                        title={formData?.MSGRAPH_USER_BASE_FILTER?.setting_description}
                      />
                    </div>

                    <div className="form-block vertical">
                      <Input type="text" 
                        name={formData?.MSGRAPH_GROUP_BASE_FILTER?.setting_code}
                        label={formData?.MSGRAPH_GROUP_BASE_FILTER?.setting_name}
                        value={formData?.MSGRAPH_GROUP_BASE_FILTER?.setting_value || ''} 
                        onChange={handleInputChange}
                        disabled={loading}
                        title={formData?.MSGRAPH_GROUP_BASE_FILTER?.setting_description}
                      />
                    </div>

                    <div className="form-block vertical">
                      <Checkbox 
                        checked={formData?.SYNC_NESTED_USER_GROUPS?.setting_value || false}
                        name={formData?.SYNC_NESTED_USER_GROUPS?.setting_code}
                        label={formData?.SYNC_NESTED_USER_GROUPS?.setting_name}
                        onChange={ handleInputChange}
                        disabled={loading}
                        title={formData?.SYNC_NESTED_USER_GROUPS?.setting_description}
                      />
                    </div>

                    

                    <div className="form-block vertical">
                      <h4>User Attribute Mapping</h4>
                      <Input type="text" 
                        name={formData?.USER_SOURCE_ID?.setting_code}
                        label={formData?.USER_SOURCE_ID?.setting_name}
                        value={formData?.USER_SOURCE_ID?.setting_value || ''} 
                        onChange={handleInputChange}
                        disabled={loading}
                        labelPosition="left"
                        title={formData?.USER_SOURCE_ID?.setting_description}
                      />

                      <Input type="text" 
                        name={formData?.USER_USERNAME?.setting_code}
                        label={formData?.USER_USERNAME?.setting_name}
                        value={formData?.USER_USERNAME?.setting_value || ''} 
                        onChange={handleInputChange}
                        disabled={loading}
                        labelPosition="left"
                        title={formData?.USER_USERNAME?.setting_description}
                      />

                      <Input type="text" 
                        name={formData?.USER_FULLNAME?.setting_code}
                        label={formData?.USER_FULLNAME?.setting_name}
                        value={formData?.USER_FULLNAME?.setting_value || ''} 
                        onChange={handleInputChange}
                        disabled={loading}
                        labelPosition="left"
                        title={formData?.USER_FULLNAME?.setting_description}
                      />

                      <Input type="text" 
                        name={formData?.USER_EMAIL?.setting_code}
                        label={formData?.USER_EMAIL?.setting_name}
                        value={formData?.USER_EMAIL?.setting_value || ''} 
                        onChange={handleInputChange}
                        disabled={loading}
                        labelPosition="left"
                        title={formData?.USER_EMAIL?.setting_description}
                      />

                      <Input type="text" 
                        name={formData?.USER_TITLE?.setting_code}
                        label={formData?.USER_TITLE?.setting_name}
                        value={formData?.USER_TITLE?.setting_value || ''} 
                        onChange={handleInputChange}
                        disabled={loading}
                        labelPosition="left"
                        title={formData?.USER_TITLE?.setting_description}
                      />
                    
                      <Input type="text" 
                        name={formData?.USER_DEPARTMENT?.setting_code}
                        label={formData?.USER_DEPARTMENT?.setting_name}
                        value={formData?.USER_DEPARTMENT?.setting_value || ''} 
                        onChange={handleInputChange}
                        disabled={loading}
                        labelPosition="left"
                        title={formData?.USER_DEPARTMENT?.setting_description}
                      />
                    </div>

                    <div className="form-block vertical">
                      <h4>User Group Attribute Mapping</h4>
                      <Input type="text" 
                        name={formData?.GROUP_SOURCE_ID?.setting_code}
                        label={formData?.GROUP_SOURCE_ID?.setting_name}
                        value={formData?.GROUP_SOURCE_ID?.setting_value || ''} 
                        onChange={handleInputChange}
                        disabled={loading}
                        labelPosition="left"
                        title={formData?.GROUP_SOURCE_ID?.setting_description}
                      />

                      <Input type="text" 
                        name={formData?.GROUP_NAME?.setting_code}
                        label={formData?.GROUP_NAME?.setting_name}
                        value={formData?.GROUP_NAME?.setting_value || ''} 
                        onChange={handleInputChange}
                        disabled={loading}
                        labelPosition="left"
                        title={formData?.GROUP_NAME?.setting_description}
                      />

                      <Input type="text" 
                        name={formData?.GROUP_EMAIL?.setting_code}
                        label={formData?.GROUP_EMAIL?.setting_name}
                        value={formData?.GROUP_EMAIL?.setting_value || ''} 
                        onChange={handleInputChange}
                        disabled={loading}
                        labelPosition="left"
                        title={formData?.GROUP_EMAIL?.setting_description}
                      />

                      <Input type="text" 
                        name={formData?.GROUP_DESCRIPTION?.setting_code}
                        label={formData?.GROUP_DESCRIPTION?.setting_name || ''}
                        value={formData?.GROUP_DESCRIPTION?.setting_value} 
                        onChange={handleInputChange}
                        disabled={loading}
                        labelPosition="left"
                        title={formData?.GROUP_DESCRIPTION?.setting_description}
                      />
                    </div>
                  </React.Fragment>

                  : <div className="form-block vertical">
                      To use this feature, authentication through Azure OpenID Connect must be enabled and correctly configured.
                    </div>
              }
              </div>
            
            </div>

            <div label="Notifications" tabid="notifications">
              <div className="form-content">

                <div className="form-block vertical">
                  <Checkbox 
                      checked={formData?.MAIL_IS_ENABLED?.setting_value  || false}
                      name={formData?.MAIL_IS_ENABLED?.setting_code}
                      label={formData?.MAIL_IS_ENABLED?.setting_name}
                      onChange={ handleInputChange}
                      disabled={loading}
                      title={formData?.MAIL_IS_ENABLED?.setting_description}
                    />
                </div>

              { formData?.MAIL_IS_ENABLED?.setting_value &&

                <React.Fragment>
                <div className="form-block vertical">
                  <h4>Email Server</h4>
                  <SelectButtons 
                    name={formData?.MAIL_SERVER_TYPE?.setting_code}
                    label={formData?.MAIL_SERVER_TYPE?.setting_name}
                    value={formData?.MAIL_SERVER_TYPE?.setting_value || 0}
                    options={ formatSelectOptions({options: [{label: 'Azure', value:'msgraph'}, {label: 'SMTP', value:'smtp'}], optionValue: "value", optionLabel: "label"}) }
                    onChange={handleInputChange} 
                    disabled={loading}
                    title={formData?.MAIL_SERVER_TYPE?.setting_description}
                  />
                </div>

                { formData?.MAIL_SERVER_TYPE?.setting_value === "smtp" &&
                  <React.Fragment>
                    <div className="form-block vertical">
                      <Input type="text" 
                        name={formData?.MAIL_HOST?.setting_code}
                        label={formData?.MAIL_HOST?.setting_name}
                        value={formData?.MAIL_HOST?.setting_value || ''} 
                        onChange={handleInputChange}
                        disabled={loading}
                        labelPosition="left"
                        title={formData?.MAIL_HOST?.setting_description}
                      />
                      <Input type="text" 
                        name={formData?.MAIL_PORT?.setting_code}
                        label={formData?.MAIL_PORT?.setting_name}
                        value={formData?.MAIL_PORT?.setting_value || ''} 
                        onChange={handleInputChange}
                        disabled={loading}
                        labelPosition="left"
                        placeholder={formData?.MAIL_IS_SECURE?.setting_value ? 465 : 587}
                        title={formData?.MAIL_PORT?.setting_description}
                      />
                    </div>
                    <div className="form-block vertical">
                      <Checkbox 
                        checked={formData?.MAIL_IS_SECURE?.setting_value  || false}
                        name={formData?.MAIL_IS_SECURE?.setting_code}
                        label={formData?.MAIL_IS_SECURE?.setting_name}
                        onChange={ handleInputChange}
                        disabled={loading}
                        labelPosition="left"
                        title={formData?.MAIL_IS_SECURE?.setting_description}
                      />
                    </div>
                    <div className="form-block vertical">
                      <Input type="text" 
                        name={formData?.MAIL_USERNAME?.setting_code}
                        label={formData?.MAIL_USERNAME?.setting_name}
                        value={formData?.MAIL_USERNAME?.setting_value || ''} 
                        onChange={handleInputChange}
                        disabled={loading}
                        labelPosition="left"
                        title={formData?.MAIL_USERNAME?.setting_description}
                      />
                      <Input type="password" 
                        name={formData?.MAIL_PASSWORD?.setting_code}
                        label={formData?.MAIL_PASSWORD?.setting_name}
                        value={formData?.MAIL_PASSWORD?.setting_value || ''} 
                        onChange={handleInputChange}
                        disabled={loading}
                        placeholder="< hidden >"
                        labelPosition="left"
                        title={formData?.MAIL_PASSWORD?.setting_description}
                      />
                      <Input type="text" 
                        name={formData?.MAIL_FROM?.setting_code}
                        label={formData?.MAIL_FROM?.setting_name}
                        value={formData?.MAIL_FROM?.setting_value || ''} 
                        onChange={handleInputChange}
                        disabled={loading}
                        labelPosition="left"
                        title={formData?.MAIL_FROM?.setting_description}
                      />
                    </div>
                    
                  </React.Fragment>

                }

                { formData?.MAIL_SERVER_TYPE?.setting_value === "msgraph" && 
                  <div className="form-block vertical">
                    
                    { formData?.OIDC_IS_ENABLED?.setting_value 
                      ? 
                      <Input type="text" 
                        name={formData?.MAIL_FROM?.setting_code}
                        label={formData?.MAIL_FROM?.setting_name}
                        value={formData?.MAIL_FROM?.setting_value || ''} 
                        onChange={handleInputChange}
                        disabled={loading}
                        labelPosition={formData?.MAIL_SERVER_TYPE?.setting_value === 'smtp' ? "left" : undefined}
                        title={formData?.MAIL_FROM?.setting_description}
                      />
                      :
                      <div className="form-block vertical">
                        To send emails through Azure (Microsoft Graph API), authentication through Azure OpenID Connect must be enabled and correctly configured.
                      </div>
                    }
                  </div>
                }
                { ((formData?.MAIL_SERVER_TYPE?.setting_value === "msgraph" && formData?.OIDC_IS_ENABLED?.setting_value) || formData?.MAIL_SERVER_TYPE?.setting_value === "smtp") &&
                <React.Fragment>
                  <div className="form-block vertical">
                    <Button 
                      className={"button" + (sendingEmail ? ' loading' : '')} 
                      value={sendingEmail ? "SENDING..." : "SEND TEST EMAIL"}
                      tooltip={(sendingEmail ? "Sending" : "Send") + " a test email to your email address"}
                      disabled={loading || sendingEmail}
                      onClick={ sendTestEmail }
                    />
                  </div>

                  <div className="form-block vertical">
                    <h4>Notification Settings</h4>
                    <Input 
                      type="text" 
                      name={formData?.MAIL_SYSTEM_EMAILS_TO?.setting_code}
                      label={formData?.MAIL_SYSTEM_EMAILS_TO?.setting_name}
                      value={formData?.MAIL_SYSTEM_EMAILS_TO?.setting_value || ''} 
                      onChange={handleInputChange}
                      disabled={loading}
                      title={formData?.MAIL_SYSTEM_EMAILS_TO?.setting_description}
                    />
                  </div>
                  <div className="form-block vertical">
                    <Checkbox 
                      checked={formData?.MAIL_SEND_WELCOME_EMAILS?.setting_value  || false}
                      name={formData?.MAIL_SEND_WELCOME_EMAILS?.setting_code}
                      label={formData?.MAIL_SEND_WELCOME_EMAILS?.setting_name}
                      onChange={ handleInputChange}
                      disabled={loading}
                      title={formData?.MAIL_SEND_WELCOME_EMAILS?.setting_description}
                    />
                  </div>
                  <div className="form-block vertical">
                    <h4>Email Content Customization</h4>
                    <Input 
                      type="text" 
                      name={formData?.MAIL_FRONTEND_URL?.setting_code}
                      label={formData?.MAIL_FRONTEND_URL?.setting_name}
                      value={formData?.MAIL_FRONTEND_URL?.setting_value || ''} 
                      onChange={handleInputChange}
                      disabled={loading}
                      placeholder={window.location.origin}
                      title={formData?.MAIL_FRONTEND_URL?.setting_description}
                    />
                  </div>
                  <div className="form-block vertical">
                    <Input 
                      type="text" 
                      name={formData?.MAIL_SUPPORT_NAME?.setting_code}
                      label={formData?.MAIL_SUPPORT_NAME?.setting_name}
                      value={formData?.MAIL_SUPPORT_NAME?.setting_value || ''} 
                      onChange={handleInputChange}
                      disabled={loading}
                      title={formData?.MAIL_SUPPORT_NAME?.setting_description}
                    />
                  </div>
                  <div className="form-block vertical">
                    <Input 
                      type="text" 
                      name={formData?.MAIL_SUPPORT_EMAIL?.setting_code}
                      label={formData?.MAIL_SUPPORT_EMAIL?.setting_name}
                      value={formData?.MAIL_SUPPORT_EMAIL?.setting_value || ''} 
                      onChange={handleInputChange}
                      disabled={loading}
                      title={formData?.MAIL_SUPPORT_EMAIL?.setting_description}
                    />
                  </div>
                  </React.Fragment>
                }
                </React.Fragment>
              }
              </div>
            </div>

            <div label="Logging" tabid="logging">
              <div className="form-content">

                <div className="form-block vertical">
                  <SelectButtons 
                    name={formData?.LOG_LEVEL?.setting_code}
                    label={formData?.LOG_LEVEL?.setting_name}
                    value={formData?.LOG_LEVEL?.setting_value || 0}
                    options={ formatSelectOptions({options: [{label: 'Info', value:'info'}, {label: 'Debug', value:'debug'}], optionValue: "value", optionLabel: "label"}) }
                    onChange={handleInputChange} 
                    disabled={loading}
                    title={formData?.LOG_LEVEL?.setting_description}
                  />
                </div>

                <div className="form-block vertical">
                  <Checkbox 
                    checked={formData?.HTTP_LOGGING_IS_ENABLED?.setting_value  || false}
                    name={formData?.HTTP_LOGGING_IS_ENABLED?.setting_code}
                    label={formData?.HTTP_LOGGING_IS_ENABLED?.setting_name}
                    onChange={ handleInputChange}
                    disabled={loading}
                    title={formData?.HTTP_LOGGING_IS_ENABLED?.setting_description}
                  />
                </div>

              </div>
            </div>

            <div label="Connectors" tabid="connectors">

              <Section heading="Databricks">
                <h4>Unity Catalog API</h4>
                <div className="form-light-text">
                  Katalogue uses the Databricks Unity Catalog API to access the required metadata.
                </div>
                <div className="form-content">
                  <div className="form-block vertical">
                    <Input 
                      type="text" 
                      name={formData?.DATABRICKS_BASE_ENDPOINT?.setting_code}
                      label={formData?.DATABRICKS_BASE_ENDPOINT?.setting_name}
                      value={formData?.DATABRICKS_BASE_ENDPOINT?.setting_value || ''} 
                      placeholder={formData?.DATABRICKS_BASE_ENDPOINT?.setting_default_value || ''}
                      onChange={handleInputChange}
                      labelPosition="left"
                      disabled={loading}
                      title={formData?.DATABRICKS_BASE_ENDPOINT?.setting_description}
                    />

                    <Input 
                      type="text" 
                      name={formData?.DATABRICKS_CATALOG_ENDPOINT?.setting_code}
                      label={formData?.DATABRICKS_CATALOG_ENDPOINT?.setting_name}
                      value={formData?.DATABRICKS_CATALOG_ENDPOINT?.setting_value || ''} 
                      placeholder={formData?.DATABRICKS_CATALOG_ENDPOINT?.setting_default_value || ''}
                      onChange={handleInputChange}
                      labelPosition="left"
                      disabled={loading}
                      title={formData?.DATABRICKS_CATALOG_ENDPOINT?.setting_description}
                    />

                    <Input 
                      type="text" 
                      name={formData?.DATABRICKS_SCHEMA_ENDPOINT?.setting_code}
                      label={formData?.DATABRICKS_SCHEMA_ENDPOINT?.setting_name}
                      value={formData?.DATABRICKS_SCHEMA_ENDPOINT?.setting_value || ''}
                      placeholder={formData?.DATABRICKS_SCHEMA_ENDPOINT?.setting_default_value || ''} 
                      onChange={handleInputChange}
                      labelPosition="left"
                      disabled={loading}
                      title={formData?.DATABRICKS_SCHEMA_ENDPOINT?.setting_description}
                    />

                    <Input 
                      type="text" 
                      name={formData?.DATABRICKS_TABLE_ENDPOINT?.setting_code}
                      label={formData?.DATABRICKS_TABLE_ENDPOINT?.setting_name}
                      value={formData?.DATABRICKS_TABLE_ENDPOINT?.setting_value || ''}
                      placeholder={formData?.DATABRICKS_TABLE_ENDPOINT?.setting_default_value || ''} 
                      onChange={handleInputChange}
                      labelPosition="left"
                      disabled={loading}
                      title={formData?.DATABRICKS_TABLE_ENDPOINT?.setting_description}
                    />
                  </div>
                </div>
              </Section>

              <Section heading="IBM DB2">
                <div className="form-content">
                  <div className="form-block vertical">
                    <Input 
                      type="text" 
                      name={formData?.IBMDB2_CODEPAGE?.setting_code}
                      label={formData?.IBMDB2_CODEPAGE?.setting_name}
                      value={formData?.IBMDB2_CODEPAGE?.setting_value || ''} 
                      placeholder={formData?.IBMDB2_CODEPAGE?.setting_default_value || ''}
                      onChange={handleInputChange}
                      labelPosition="left"
                      disabled={loading}
                      title={formData?.IBMDB2_CODEPAGE?.setting_description}
                    />
                  </div>
                </div>
              </Section>

              <Section heading="ODBC">
                <h4>ODBC Drivers</h4>
                <div className="form-light-text">
                  Install and configure the ODBC driver in the OS-specific ODBC datasource manager first, then add the driver here to let Katalogue find and use it.
                </div>
                <div className="form-block vertical">
                <DataTable
                  columns={[
                    {id: 'datasource_subtype_driver_name', name: 'Driver Name', className:"bold"},
                    {id: 'datasource_subtype_name', name: 'Datasource Type'}
                  ]}
                  buttons={[ 
                    {label: "Add +", action: "add", mainButton: true, onClick: (items) => showSideDialog('addODBCDriver', items || selectedItems, addDatasourceSubtype) },
                    {label: "Edit", action: "edit", onClick: (items) => showSideDialog('editODBCDriver', items || selectedItems, editDatasourceSubtype) },
                    {label: "Delete", action: "delete", onClick: (items) => showModalDialog('deleteODBCDriver', items || selectedItems, deleteDatasourceSubtype) }
                  ]}
                  data={datasourceSubtypes}
                  idColumn='datasource_subtype_id'
                  selectedItems={selectedItems}
                  setSelectedItems={setSelectedItems}
                  editable={true}
                  loading={loading}
                  filterable={true}
                  filterObjects={['datasource_subtype_driver_name', 'datasource_subtype_name']}
                />
                </div>
              </Section>

            </div>
          </Tabs>
          
          { formData && 
            <div className="form-block horizontal">
              <button 
                type="button" 
                className={"button main-button" + (loading ? ' loading' : '')} 
                disabled={loading}
                onClick={(event) => {event.preventDefault(); showModalDialog('editSettings', undefined, () => saveSettings(event));}}
                >
                <span>{loading ? 'SAVING...' : 'SAVE' }</span>
              </button>
              <button 
                type="button" 
                className="button" 
                onClick={cancel}
                disabled={loading}>
                <span>CANCEL</span>
              </button>
            </div>
          }

        </form>
        </div>

      </div>
  );
}