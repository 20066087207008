import React from 'react'
import { useDocumentTitle } from '../hooks';

export const Page = (props) => {

	const element = props.element
	const title = props.title
	const objectName = props.objectName

	useDocumentTitle(title, objectName)

	return (
		<React.Fragment>
			{element}
		</React.Fragment>
	)
}