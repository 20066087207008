import React, { useState, useEffect } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { taskService, jobService } from '../services';
import { formatData, formatDuration, Role, TaskType } from '../helpers';
import { 
  Button,
  DataTable, 
  Icon, 
  MainColumn, 
  ObjectDetails,  
  SkeletonLoader,
  Tabs } from '../components';
import { 
  useInterval,
  useSideDialog, 
  useModalDialog, 
  useGlobalState, 
  useNotFound, 
  useMessage,
  usePhotos } from '../hooks';

export const Task = () => {

  const [task, setTask] = useState([]);
  const [jobs, setJobs] = useState([]);
  const [loggedInUser, setLoggedInUser] = useGlobalState('loggedInUser');
  const [loading, setLoading] = useState(true);
  const [loadingTable, setLoadingTable] = useState(true);

  const { showMessage } = useMessage()
  const { showSideDialog, hideSideDialog } = useSideDialog()
  const { showModalDialog, hideModalDialog } = useModalDialog()
  const { fetchPhotos } = usePhotos()
  const { notFound, setNotFound, NotFound } = useNotFound()

  const { task_id } = useParams()
  const navigate = useNavigate()

  // Update job steps continuously
  useInterval( () => {
    fetchItems()
    fetchJobs()
  }, 3000)

  useEffect(() => {

    fetchItems()
    fetchJobs()

  }, []);

  const fetchItems = async () => {
    
    taskService.getById(task_id)
      .then(res => {
        if (!res.tasks || res.tasks.length < 1) {
          setNotFound(true)
        } else {
          setTask(res.tasks[0]);
          setLoading(false)
        }
      })
      .catch(err => {showMessage(err, 'error')});
  }

  const fetchJobs = async () => {
    
    jobService.getByTaskId(task_id)
      .then(res => {
        let maxRuntimeValue = 0
        let maxChangedAssetValue = 0

        res.jobs.forEach( row => {
          if (row.job_runtime_seconds > maxRuntimeValue) {
            maxRuntimeValue = row.job_runtime_seconds
          }
          if (row.changed_asset_count > maxChangedAssetValue) {
            maxChangedAssetValue = row.changed_asset_count
          }
        })

        const jobs_tmp = res.jobs.map(job => {
          return {
            ...job, 
            job_runtime_seconds_max_value: maxRuntimeValue,
            job_runtime_tooltip: formatDuration(job.job_runtime_seconds) + " (hh:mm:ss)",
            changed_asset_count_max_value: maxChangedAssetValue        
          }
        })

        setJobs(prev => { return jobs_tmp.map(job => ({...jobs.find(x => x.job_id = job.job_id), ...job})) });
        setLoadingTable(false);
        fetchPhotos(jobs_tmp, 'user_id', setJobs)
         
      })
      .catch(err => {showMessage(err, 'error')});
  }

  const updateJobStatus = (task_arr, job) => {
    setTask(prev => ({
      ...prev, 
      job_status: job.job_status, 
      job_completion_message: job.job_completion_message
      }))

    // Add dummy job
    /*setJobs([
      {
        task_id: job.task_id, 
        job_id: job.job_id, 
        job_status: job.job_status, 
        job_started_timestamp: job.job_started_timestamp, 
        job_execution_method: job.job_execution_method,
        job_executed_by_user: loggedInUser.user_fullname,
        user_id: loggedInUser.user_id
      },
      ...jobs
    ])*/
  }

  const editTask = async data => {
    const tasks = {tasks: (Array.isArray(data) ? data : [data])}
    
    await taskService.update(tasks)
      .then(async res => { 

        fetchItems()
        showMessage(res.message)
        hideSideDialog()
      })
      .catch(err => {
        showMessage(err, 'error')
        throw err
      });
  }

  const runTask = async () => {
    const selectedTasks = [task]

    selectedTasks.map(async task => {
      updateJobStatus([task], {task_id: task.task_id, job_status: 'running', job_completion_message: 'Running'})

      await taskService.run(task.task_id)
        .then(res => { 
          showMessage(res.message)
        })
        .catch(err => {
          showMessage(err, 'error')})
        .finally(res => { 
          fetchItems()
          fetchJobs()
        })
    })

  }

  const deleteTask = async data => {    

    await taskService.destroy({tasks: data})
      .then(async res => { 
        
        hideModalDialog()
        showMessage(res.message)

        // Redirect to parent page
        navigate('/tasks')

      })
      .catch(err => {
        showMessage(err, 'error')
      });
  }

  const handleShowMessage = (data) => {
    showModalDialog('showMessage', data)
  }
  
  const editable = loggedInUser && loggedInUser.user_role_name === Role.admin
  
  const data = loading ? [] : task

  const isRunning = data.job_status === "running"

  if (notFound) {
    return <NotFound />
  }

  return (
    <div className="columns narrow-margin">
      <MainColumn>
        <ObjectDetails
          type="Task"
          title={data.task_name}
          subtitleText={ data && !loading &&
            data.task_type_name + " Task"
          }
          details={[
            {title: 'Last Modified', data: formatData(data.modified_timestamp, 'datetime')},
            {title: 'Created', data: formatData(data.created_timestamp, 'datetime')}
          ]}
          description={data.task_description}
          loading={loading}
          >

            { data.task_type_code === TaskType.dssync &&
              <React.Fragment>
                <h3>{ !loading ? "Datasource" : <SkeletonLoader width="5vw"/>}</h3>
                <div className="display-linebreak">
                { !loading 
                  ? (data.datasource_name === null) ? "-" : <Link to={"/browse_datasets/"+data.system_id+'/'+data.datasource_id} className="link"><Icon name={data.datasource_type_category} tooltip={data.datasource_type_name} />{ data.datasource_name }</Link>
                  : <SkeletonLoader width="10vw" />
                }
                </div>
              </React.Fragment>
            }
            <h3>{ !loading ? "Priority" : <SkeletonLoader width="5vw"/>}</h3> 
            <div className="display-linebreak">
            { !loading 
              ? data.task_priority
              : <SkeletonLoader width="5vw" />
            }
            </div>
            
            <h3>{ !loading ? "Status" : <SkeletonLoader width="5vw"/>}</h3>
            <div className="display-linebreak">
            { !loading 
              ? (data.task_is_disabled) 
                  ? <span className="pill pill-disabled">Disabled</span>
                  : <span className="pill pill-enabled">Active</span>
              : <SkeletonLoader width="10vw" />
            }
            </div>

            <h3>{ !loading ? "Last Successful Run" : <SkeletonLoader width="5vw"/>}</h3>
            <div className="display-linebreak">
            { !loading 
              ? (data.last_success_completed_timestamp === null) ? "Never run" : formatData(data.last_success_completed_timestamp, 'datetime')
              : <SkeletonLoader width="10vw" />
            } 
            </div>

            </ObjectDetails>
          
            { !loading &&
            <div className="main-toolbar">
              <button type="button" className="main-toolbar-item button main-button" onClick={ () => showSideDialog('editTask', [data], editTask) }><span>EDIT</span></button>
              <Button className="main-toolbar-item button" disabled={isRunning} onClick={ runTask } value={isRunning ? "RUNNING..." : "RUN"}/>
              <button type="button" className="main-toolbar-item button" onClick={ () => showModalDialog('deleteTask', [data], deleteTask) }><span>DELETE</span></button>
            </div>
            }

      </MainColumn>

      <div className="column">
        <Tabs className="slim left">
          <div label="Jobs" tabid='jobs'>

            { jobs.length > 0 || loadingTable ?
            <DataTable
            columns={[
              {id: 'job_status', type: 'icon', tooltip:'job_status'},
              {id: 'job_started_timestamp', name: 'Started', link: '/tasks/:task_id/:job_id', type: 'datetime', className:"bold"},
              {id: 'job_completed_timestamp', name: 'Completed', type: 'datetime'},
              {id: 'job_runtime_seconds', name: 'Runtime [s]', type: 'chart', chartType: 'bar', data: {data:'job_runtime_seconds', max: 'job_runtime_seconds_max_value'}, tooltip: 'job_runtime_tooltip'},
              ['adsync', 'dssync'].includes(task.task_type_code) && {id: 'changed_asset_count', name: 'Changed Assets', type: 'chart', chartType: 'bar', data: {data:'changed_asset_count', max: 'changed_asset_count_max_value'}, tooltip: 'changed_asset_count'},              
              {id: 'job_execution_method', name: 'Execution Method'},
              {id: 'user_photo', name: '', type: 'user-photo', link: '/users/:user_id', tooltip: 'job_executed_by_user'},
              {id: 'job_executed_by_user', name: 'Executed By', link: '/users/:user_id'},
              {id: 'job_completion_message', name: 'Message', type: 'button', label: 'Show', onClick: (props) => handleShowMessage(props), data: {data: 'job_completion_message'}}
            ]}
            data={jobs}
            idColumn='job_id'
            loading={loadingTable}
            editable={false}
            filterable={true}
            filterObjects={['job_status', 'job_execution_method', 'job_executed_by_user', 'job_completion_message']}
          />
            : !loadingTable &&
              <div className="no-result-text">No jobs found for this task</div>
          }
        </div>
      </Tabs>
      
      </div>
    </div>
  ) 
}
