export const handleError = ({err, showMessage}, callback) => {

    if (err) {
        try { 

            if (err.name && err.name === "AbortError") {
                return; // Request has been canceled, so do nothing
            }

            showMessage && showMessage(err, 'error')
            callback && callback(err)
          
        } catch (e) {
            throw e
        }

    } 
}