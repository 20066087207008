import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { datasetService, fieldService, referenceService, relationshipService } from '../services';
import { copyToClipboard, FieldSensitivity, formatConnectionString, formatData, handleError, Role, Asset, Operation } from '../helpers';
import { 
  Button,
  Changelog,
  DataTable, 
  Icon, 
  MainColumn, 
  ObjectDetails, 
  References, 
  RelationshipGraph,
  SkeletonLoader, 
  Tabs, 
  TextareaCode, 
  textareaCodeInitialState,
  fetchToReferencesCount,
  UserLink } from '../components';
import { 
  useSideDialog, 
  useModalDialog, 
  useQueryParams, 
  useGlobalState, 
  usePhotos, 
  useNotFound, 
  useMessage } from '../hooks';

export const Dataset = ({ abortFetch }) => {

  const { queryParams, resetParams } = useQueryParams()

  const [dataset, setDataset] = useState([]);
  const [fields, setFields] = useState([]);
  const [fromReferences, setFromReferences] = useState([]);
  const [toReferencesCount, setToReferencesCount] = useState({});
  const [loggedInUser, setLoggedInUser] = useGlobalState('loggedInUser');
  const [selectedItems, setSelectedItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingTable, setLoadingTable] = useState(true);

  const [defaultSelectedTab, setDefaultSelectedTab] = useState({tab: queryParams.tab})

  const clearSelectedItems = () => {
    setSelectedItems([])
  }

  const { system_id, datasource_id, dataset_group_id, dataset_id } = useParams()

  const { showMessage } = useMessage()
  const { showSideDialog, hideSideDialog } = useSideDialog(clearSelectedItems)
  const { showModalDialog, hideModalDialog } = useModalDialog()
  const { fetchPhotos } = usePhotos()
  const { notFound, setNotFound, NotFound } = useNotFound()

  const navigate = useNavigate()

  const refTechnicalDefinition = useRef()

  useEffect(() => {

    // Necessary if one navigates from one dataset to another
    setDefaultSelectedTab({tab: queryParams.tab})

    fetchItems()
    fetchFields()
    fetchFromReferences()  

    fetchToReferencesCount({toObjectName: Asset.Dataset, toObjectId: dataset_id, abortFetch})
      .then(res => setToReferencesCount(res))
      .catch(err => {
        handleError({err, showMessage})
      })

  }, [dataset_id]);

  const fetchItems = async () => {
    setLoading(true)
    datasetService.getById(dataset_id, abortFetch)
      .then(res => {
        if (res.datasets.length < 1) {
          setNotFound(true)
        } else {
          setDataset(res.datasets[0]);
          fetchPhotos(res.datasets[0], 'dataset_owner_user_id', setDataset)
        }
      })
      .catch(err => {
        handleError({err, showMessage})
      })
      .finally(res => setLoading(false))
  }

  const fetchFields = async () => {

    setLoadingTable(true)
    const promises = [
      fieldService.getByDatasetId(dataset_id, abortFetch)
        .catch(err => {
          throw err
        })
      ,
      relationshipService.getByObjectId(Asset.Dataset, dataset_id, abortFetch)
        .catch(err => {
          throw err
        })
    ]

    Promise.all(promises)
      .then( result => {
          const fields = result[0].fields.map(x => {return {...x, pii: (x.is_pii ? 'PII' : null), pii_tooltip: (x.is_pii ? 'Classified as Personally Identifiable Information (PII)' : null) }})

          setFields(fields);
          updateFieldRelationships(fields, result[1].relationships)
          fetchPhotos(fields, 'field_description_owner_user_id', setFields)
      })
      .catch(err => {
        handleError({err, showMessage})
      })
      .finally(res => {
        setLoadingTable(false)
      })
  }

  const fetchFromReferences = async () => {
    
    referenceService.getByFromId(Asset.Dataset, dataset_id, abortFetch)
      .then(res => {

        res && setFromReferences(res.references);
         
      })
      .catch(err => {
        handleError({err, showMessage})
      })
  }

  const updateFieldRelationships = (fields, relationships) => {

    const keyListToString = (type, list) => {
      let l = list.length > 1 ? ` (${list.join(', ')})` : ''
      return `${type}${l}`
    }

    let primaryKeyList = []
    let newFields = fields.map(field => {
      let keyType = null
      let keyList = []

      if (field.is_primary_key) {
        keyType = 'Primary Key'
        primaryKeyList.push(field.field_name)

      } else {
        relationships.forEach(relationship => {
          if (relationship.fields.find(x => x.to_field_id === field.field_id && relationship.relationship_type !== 'virtual')) {
            keyType = 'Primary Key'
            primaryKeyList = relationship.fields.map(x => x.to_field_name)
          } else if (relationship.fields.find(x => x.from_field_id === field.field_id || x.to_field_id === field.field_id)) {
            keyType = 'Foreign Key'
            keyList = relationship.fields.map(x => x.from_field_name)
          }
        })
      }

      return {...field, key_type: keyType, key_list: keyListToString(keyType, keyList)}
    })

    newFields = newFields.map(field => (field.key_type === 'Primary Key' ? {...field, key_list: keyListToString(field.key_type, primaryKeyList)} : field))

    newFields.sort((a, b) => {
      if (a.key_type === 'Primary Key' && b.key_type === 'Foreign Key') {
        return -1;
      }
      if (b.key_type === 'Primary Key' && a.key_type === 'Foreign Key') {
        return 1;
      }
      if (a.key_type === null && b.key_type === null) {
        return 0;
      }
      if (a.key_type === null) {
        return 1;
      }
      if (b.key_type === null) {
        return -1;
      }
      return 0;
    })

    setFields(newFields)
  }

  const addField = async data => {
    const fields = {fields: (Array.isArray(data) ? data : [data])}
    
    await fieldService.create(fields)
      .then(res => {

        fetchFields()
        showMessage(res.message)
      })
      .catch(err => {
        handleError({err, showMessage}, () => {
          throw err
        })
      })
  }

  const editDataset = async data => {
    const datasets = {datasets: (Array.isArray(data) ? data : [data])}
    
    await datasetService.update(datasets)
      .then(async res => { 

        fetchItems()
        fetchFromReferences()
        showMessage(res.message)
        hideSideDialog()
      })
      .catch(err => {
        handleError({err, showMessage}, () => {
          throw err
        })
      })
  }

  const editField = async data => {
    const fields = {fields: (Array.isArray(data) ? data : [data])}
    
    await fieldService.update(fields)
      .then(async res => { 

        fetchFields()
        showMessage(res.message)
        clearSelectedItems()
        hideSideDialog()
      })
      .catch(err => {
        handleError({err, showMessage}, () => {
          throw err
        })
      })
  }

  const deleteDataset = async data => {    
    await datasetService.destroy({datasets: data})
      .then(async res => { 

        showMessage(res.message)
        hideModalDialog()

        // Redirect to parent page
        navigate(`/browse_datasets/${system_id}/${datasource_id}/${dataset_group_id}`)

      })
      .catch(err => {
        handleError({err, showMessage})
      })
  }

  const deleteField = async data => {    
    await fieldService.destroy({ fields: data })
      .then(async res => { 

        fetchFields()
        showMessage(res.message)
        hideModalDialog()
        clearSelectedItems()

      })
      .catch(err => {
        handleError({err, showMessage})
      })
  }

  const data = loading ? [] : dataset

  const editable = loggedInUser && (loggedInUser.user_role_name === Role.admin || loggedInUser.user_role_name === Role.editor)

  if (notFound) {
    return <NotFound />
  }

  return (
    <div className="columns narrow-margin">
      <MainColumn>
        <ObjectDetails
          type="Dataset"
          title={data.dataset_name}
          subtitleIcon={<Icon name={data.dataset_type_name} tooltip={ data.dataset_type_name } />}
          subtitleText={data && !loading && 
            formatConnectionString(
              data.datasource_database, 
              data.datasource_hostname, 
              [data.dataset_group_source_name, data.dataset_source_name], 
              data.datasource_type_code, 
              data.datasource_type_category)
            }
          description={data.dataset_description}
          fromReferences={fromReferences}
          loading={loading}
          details={[
                    {title: 'Rows', data: (data.dataset_row_count ? formatData(parseInt(data.dataset_row_count), 'integer') : null)},
                    {title: 'Disk Size', data: (data.dataset_size_bytes ? formatData(parseInt(data.dataset_size_bytes), 'integer-disk-size') : null)},
                    {title: 'Last Modified', data: formatData(data.dataset_source_modified, 'datetime')},
                    {title: 'Created', data: formatData(data.dataset_source_created, 'datetime')}
                  ]}
          >

            <h3>{ !loading ? "Owner" : <SkeletonLoader width="5vw"/>}</h3>
            <UserLink 
              userId={data.dataset_owner_user_id}
              userName={data.dataset_owner_user_fullname}
              userPhoto={data.user_photo}
              userTitle={data.user_title}
              userDepartment={data.user_department}
              loading={loading}
            />

        </ObjectDetails>
          
        { !loading && editable &&
        <div className="main-toolbar">
          <button type="button" className="main-toolbar-item button main-button" onClick={ () => showSideDialog('editDataset', [data], editDataset) }><span>EDIT</span></button>
          <button type="button" className="main-toolbar-item button" onClick={ () => showModalDialog('deleteDataset', [data], deleteDataset) }><span>DELETE</span></button>
        </div>
        }

      </MainColumn>

      <div className="column">
        <Tabs 
          className="slim left"
          //onTabChange={ onTabChange } 
          defaultSelectedTab={ defaultSelectedTab }
          disableTabsWithoutResults={ true }
        >
          <div label="Fields" tabid="fields" resultCount={loadingTable || fields.length === 0 ? undefined : fields.length}>
            <DataTable
              columns={[
                {id: 'key_type', name: '', type: 'icon', tooltip: 'key_list', className: 'no-right-margin'},
                {id: 'field_role_name', name: '', type: 'icon', tooltip: 'field_role_name', className: 'no-right-margin'},
                {id: 'datatype_category', name: '', type: 'icon', tooltip: 'datatype_fullname'},
                {id: 'field_name', name: 'Name', link: '/browse_datasets/:system_id/:datasource_id/:dataset_group_id/:dataset_id/:field_id', tooltip: 'field_description_description', className:"bold"},
                {id: 'field_description_description', name: 'Description', type: 'description', viewMode:'descriptions'},
                {id: 'datatype_fullname', name: 'Datatype', className:"small-text", viewMode:'statistics'},
                {id: 'field_description_name', name: 'Field Description Name', link: '/browse_field_descriptions/:field_description_id', tooltip: 'field_description_description', viewMode:'statistics'},
                {id: 'user_photo', name: '', type: 'user-photo', link: '/users/:field_description_owner_user_id', tooltip: 'field_description_owner_user_fullname'},                
                {id: 'field_description_owner_user_fullname', name: 'Field Description Owner', link: '/users/:field_description_owner_user_id'},
                {id: 'pii', name: '', type: 'icon', tooltip: 'pii_tooltip', className: 'no-right-margin'},
                {id: 'field_sensitivity_level', name: 'Data Sensitivity', type: 'pill', value: 'field_sensitivity_level', values: FieldSensitivity, tooltip: 'field_sensitivity_name'}
              ]}
              buttons={[ 
                  {label: "Add +", action: "add", mainButton: true, onClick: (items) => showSideDialog('addField', items || selectedItems, addField) },
                  {label: "Edit", action: "edit", onClick: (items) => showSideDialog('editField', items || selectedItems, editField) },
                  {label: "Delete", action: "delete", onClick: (items) => showModalDialog('deleteField', items || selectedItems, deleteField) }
                ]}
              data={fields}
              idColumn='field_id'
              selectedItems={selectedItems}
              setSelectedItems={setSelectedItems}
              editable={editable}
              loading={loadingTable}
              filterable={true}
              filterObjects={[
                'field_name', 
                'field_description_description',
                'key_type', 
                'field_role_name', 
                'datatype_name', 
                'field_description_owner_user_fullname', 
                'field_sensitivity_name', 
                'pii']}
              showViewModes={true}

            />
          </div>

          <div label="Relationships" tabid="relationships">
            { queryParams.tab === "relationships" &&

              <RelationshipGraph
                datasetId={dataset_id} 
                objectName={Asset.Dataset} 
                objectId={dataset_id} 
              />

            }
          </div>

          <div label="Related Assets" tabid="related_assets" resultCount={toReferencesCount && toReferencesCount.total}>
            { queryParams.tab === "related_assets" &&

              <References 
                showMessage={showMessage}
                toObjectName={Asset.Dataset}
                toObjectId={dataset_id}
                toReferencesCount={toReferencesCount}
              />

            }
          </div>

          <div label="Technical Definition" tabid="technical_definition" disabled={!data.dataset_technical_definition}>
            <div className="main-toolbar align-right">
              <Button type="button" className="main-toolbar-item button" onClick={ () => copyToClipboard(data.dataset_technical_definition) } tooltip="Copy query to clipboard" value="COPY" valueClicked="COPIED!"/>
            </div>
          
              <TextareaCode
                name="code-editor" 
                value={textareaCodeInitialState({value: data.dataset_technical_definition})} 
                readOnly={true}
              />
            {//<div className="display-linebreak code" ref={refTechnicalDefinition}>{ data.dataset_technical_definition }</div>
            }
          </div>
            
          <div label="Changelog" tabid="changelog">
            { queryParams.tab === "changelog" &&

              <Changelog 
                objectName={Asset.Dataset} 
                objectId={dataset_id}
                filterObjects={[
                  'changedData.new_data||old_data.dataset_name', 
                  'changedData.new_data||old_data.dataset_description', 
                  'changedData.new_data||old_data.dataset_group_name', 
                  'changedData.new_data||old_data.dataset_type_name', 
                  'changedData.new_data||old_data.dataset_owner_user_fullname', 
                  'fields.field_name',
                  'fields.operation',
                  'changedData.attribute',
                  'changed_by_user_username',
                  'is_service_account_user'
                ]}
                filterObjectsValueMap={{
                  is_service_account_user: {true: {label:'Datasource Sync'}, false: {label:''}},
                  operation: Operation
                }}
              />
              
            }
          </div>
          
          
        </Tabs>
      </div>

    </div>
  ) 
}

