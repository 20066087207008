import React from 'react';
import { 
  Editor,
  EditorState,
  SelectionState,
  RichUtils,
  getDefaultKeyBinding,
  Modifier
} from 'draft-js'
import "./prism.css";
import './Textarea.css';
import { 
  LineNumber
} from './components'
import { 
  TAB_KEY_CODE,
} from './helpers'


const blockRendererFn = () => ({
  component: LineNumber,
  data: {syntax: 'sql'}
});

export const TextareaCode = ({ value, onChange, placeholder, readOnly = false, disabled = false }) => {
  
  const myKeyBindingFn = e => {
    switch (e.keyCode) {
    
      case TAB_KEY_CODE:
        if (e.getModifierState('Shift')) {
          return 'shift-tab';
        } else {
          return 'tab';
        }   
        break;
      default:
        break
    }

    return getDefaultKeyBinding(e);
  }

  const handleKeyCommand = (command) => {

    let newEditorState
    let newContentState = RichUtils.handleKeyCommand(value, command)

    const currentSelection = value.getSelection()
    const currentContent = value.getCurrentContent()


    switch(command) {
      case 'tab':

        const isRangeSelection = 
          currentSelection.getAnchorKey() !== currentSelection.getFocusKey() ||  
          currentSelection.getFocusOffset() - currentSelection.getAnchorOffset() !== 0

        // No range selected -> insert tab at cursor position
        if (!isRangeSelection) {

          const newSelection = new SelectionState({
            anchorKey: value.getSelection().getAnchorKey(),
            focusKey: value.getSelection().getFocusKey(),
            anchorOffset: value.getSelection().getAnchorOffset(),
            focusOffset: value.getSelection().getAnchorOffset()
          })

          newContentState = Modifier.insertText(
            value.getCurrentContent(),
            newSelection,
            `\t`
          );

          const newEditorState = EditorState.push(value, newContentState, 'insert-characters')
          
          onChange && onChange(
            EditorState.forceSelection(
              newEditorState,
              newContentState.getSelectionAfter()
            )
          )

        // Range selected -> insert tab at start of line for each line in selection
        } else {
          const startKey = currentSelection.getStartKey()
          const endKey = currentSelection.getEndKey()

          const currentContent = value.getCurrentContent()

          const blockList = currentContent.getBlockMap().toList()

          const startIndex = blockList.findIndex(item => item.key === startKey)
          const endIndex = blockList.findIndex(item => item.key === endKey)

          newContentState = currentContent

          // Add a tab to each block in selection
          for (let i = startIndex; i <= endIndex; i++) {
            const currentBlock = blockList.get(i)
            
            const newSelection = new SelectionState({
              anchorKey: currentBlock.getKey(),
              focusKey: currentBlock.getKey(),
              anchorOffset: 0,
              focusOffset: 0
            })

            newContentState = Modifier.insertText(
              newContentState,
              newSelection,
              `\t`
              )
          }

          const newSelection = new SelectionState({
            anchorKey: currentSelection.getAnchorKey(),
            focusKey: currentSelection.getFocusKey(),
            anchorOffset: currentSelection.getAnchorOffset()+1,
            focusOffset: currentSelection.getFocusOffset()+1,
            isBackward: currentSelection.getIsBackward()
          })

          const newEditorState = EditorState.push(value, newContentState, 'insert-characters')
          
          onChange && onChange(
            EditorState.forceSelection(
              newEditorState,
              newSelection
            )
          )
        }
        
        break

      // Delete tab or whitespace at the beginning of each row
      case 'shift-tab':

        const startKey = currentSelection.getStartKey()
        const endKey = currentSelection.getEndKey()
        

        const blockList = currentContent.getBlockMap().toList()

        const startIndex = blockList.findIndex(item => item.key === startKey)
        const endIndex = blockList.findIndex(item => item.key === endKey)

        newContentState = currentContent

        let removedFromFirst = false
        let removedFromLast = false

        // Delete tab or whitespace from beginning of each block in selection
        for (let i = startIndex; i <= endIndex; i++) {
          const currentBlock = blockList.get(i)
          const firstCharacter = currentBlock.getText()[0]
          const characterList = [`\t`, ' ']

          if (characterList.includes(firstCharacter)) {
            const newSelection = new SelectionState({
              anchorKey: currentBlock.getKey(),
              focusKey: currentBlock.getKey(),
              anchorOffset: 0,
              focusOffset: 1
            })

            newContentState = Modifier.replaceText(
              newContentState,
              newSelection,
              ``
            )

            if (i === startIndex) removedFromFirst = true
            if (i === endIndex) removedFromLast = true
          }
        }

        const currentAnchorOffset = currentSelection.getAnchorOffset()
        const currentFocusOffset = currentSelection.getFocusOffset()

        const newAnchorOffset = removedFromFirst ? (currentAnchorOffset > 0 ? currentAnchorOffset-1 : 0) : currentSelection.getAnchorOffset()
        const newFocusOffset = removedFromLast ? (currentFocusOffset > 0 ? currentFocusOffset-1 : 0) : currentSelection.getFocusOffset()

        const newSelection = new SelectionState({
          anchorKey: currentSelection.getAnchorKey(),
          focusKey: currentSelection.getFocusKey(),
          anchorOffset: newAnchorOffset,
          focusOffset: newFocusOffset,
          isBackward: currentSelection.getIsBackward()
        })

        const newEditorState = EditorState.push(value, newContentState, 'remove-characters')

        onChange && onChange(
          EditorState.forceSelection(
            newEditorState,
            newSelection
          )
        )
        break

      // Select all
      case 'command-a':
    
        newContentState = value.getSelection().merge({
          anchorKey: currentContent.getFirstBlock().getKey(),
          anchorOffset: 0,  

          focusOffset: currentContent.getLastBlock().getText().length, 
          focusKey: currentContent.getLastBlock().getKey(),
        })
        break
      default:
        break
    }

    if (newContentState) {
      return 'handled';
    }    
    return 'not-handled';
  }

  return (
     <div className={"CustomTextareaCode" + (readOnly ? " read-only" : "") + (disabled ? " disabled" : "")}>
       <Editor 
        editorState={value} 
        onChange={onChange}
        keyBindingFn={ myKeyBindingFn }
        handleKeyCommand={ handleKeyCommand }
        blockRendererFn={blockRendererFn}
        stripPastedStyles={ true }
        placeholder={placeholder}
        readOnly={readOnly || disabled}
       /> 
     </div>
  );
}