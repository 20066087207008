import config from '../config';
import { authHeader, customFetch, handleResponse, commonRequestOptions } from '../helpers';

const endpointPath='search'

export const searchService = {
    getAll,
    getAllByName
};

function getAll(search, objectName, n, append, signal) {
    const requestOptions = { method: 'GET', headers: authHeader(), ...commonRequestOptions() };
    return customFetch(`${config.api.url}/${endpointPath}/all/${search}/${objectName}/${n}/${append}`, requestOptions, signal, true).then(handleResponse)
}

function getAllByName(search, objectName, signal) {
    const requestOptions = { method: 'GET', headers: authHeader(), ...commonRequestOptions() };
    return customFetch(`${config.api.url}/${endpointPath}/all_by_name/${search}/${objectName}`, requestOptions, signal, true).then(handleResponse);
}