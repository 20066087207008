import React, { forwardRef } from 'react';
import { keyCode } from '../helpers';
import { useTooltip } from '../hooks'


const _Checkbox = ({name, onChange, checked, label, disabled=false, className, title, labelPosition}, ref) => {
	
	const {showTooltip, hideTooltip} = useTooltip()

  const handleKeyDown = (e) => {
    switch (e.keyCode) {
      case keyCode.ENTER:
      case keyCode.SPACE:
        onChange();
        break;

      default:
        break;
    }
  }

	return (
		<label 
			className={
				"CustomCheckbox" + 
				(className ? " " + className : "" ) + 
				(disabled ? " disabled" : "" ) +
				(labelPosition ? " label-position-" + labelPosition : "" )
			}
		 	tabIndex={ 0 }
		 	onKeyDown={handleKeyDown}
		 	ref={ref}
		>		
		  <input
		    type="checkbox"
		    name={ name }
		    onChange={ onChange }
		    checked={ checked ? true : false }
		    disabled={ disabled }    
		  />
		  <span className={"checkmark" + (checked === "Multiple items" ? " null" : "")} ref={ref}></span>
		  {label &&
		  <span 
		  	className="label" 
		  	onMouseEnter={ () => title && showTooltip({title: title})} 
		  	onMouseLeave={ () => title && hideTooltip() }
		  >
		  {label}
		  {title && <span className="icon-description">i</span>}
		  </span>
		  }
		</label>
	)
}

export const Checkbox = forwardRef(_Checkbox);