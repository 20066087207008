import React from 'react';
import { EditorBlock } from 'draft-js'

export const LineNumber = (props) => {
  const {block, contentState} = props
  const lineNumber =
    contentState
      .getBlockMap()
      .toList()
      .findIndex(item => item.key === block.key) + 1;

  return (
    <div className="line" data-line-number={lineNumber}>
      <div className="line-text language-sql">
        <EditorBlock {...props} />
      </div>
    </div>
  );
}