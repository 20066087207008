import React, { forwardRef } from 'react';
import { keyCode } from '../helpers';

const _FormTab = ({activeTab, label, onClick}, ref) => {


  const onClickTabItem = () => {
    onClick(label)
  }
  const handleKeyDown = (e) => {
    switch (e.keyCode) {
      case keyCode.ENTER:
      case keyCode.SPACE:
        onClickTabItem();
        break;

      default:
        break;
    }
  }

  const active = ((activeTab === label) ? "active" : "")

  return (
      <div 
        className={"tab-list-item " + active}
        role="tab"
        ref={ref}
        aria-selected={active === "active"}
        tabIndex= {0}
        onClick={onClickTabItem} 
        onKeyDown={handleKeyDown}
      >
        { label.toUpperCase() }
      </div>      
  )
};
export const FormTab = forwardRef(_FormTab);
