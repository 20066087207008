import React, { useCallback, forwardRef, useState, useEffect, useLayoutEffect, useRef } from 'react';
import { SkeletonLoader } from '../';
import { Checkbox } from '../';
import { DataTableRow, DataTableToolbar, DataTablePagination } from './components';
import { useFilterableData, useSortableData, useQueryParams, useDebounce, useTooltip, useGlobalState } from '../../hooks';


export const _DataTable = ({
  data, 
  title, 
  buttons, 
  hideToolbar, 
  idColumn, 
  primaryColumn=idColumn.replace("_id", "_name"),
  columns, 
  selectedItems = [], 
  setSelectedItems, 
  editable=true, 
  expandable=false,
  expandableColumns,
  expandedRowClassName,
  loading=false, 
  loadingMoreRows=false, 
  filterable=false, 
  header=true, 
  filterObjects, 
  filterObjectsValueMap,
  filterControls,
  defaultFilterTerm, 
  defaultSort, 
  className, 
  onMouseEnterRow, 
  onMouseLeaveRow,
  paginated=true,
  pageSize=200,
  currentPage=1,
  showViewModes=false,
  viewModes,
  groupByColumn,

}, 
  ref) => {
  const [displayNames] = useGlobalState('displayNames')

  const { queryParams, setHistory } = useQueryParams()

  filterObjects = queryParams.filterObjects || (filterObjects && filterObjects.map(x => {
    const labelValue = x.split('.').pop()
    return ({
    value: x, 
    label: (displayNames[labelValue] && (displayNames[labelValue]?.display_name_alternative_name && labelValue !== primaryColumn ? displayNames[labelValue]?.display_name_alternative_name : displayNames[labelValue]?.display_name)) || labelValue,  
    tooltip: displayNames[labelValue] && displayNames[labelValue]?.display_name_description,
    checked: true})
  }))

  const [ filteredFilterObjects, setFilteredFilterObjects ] = useState(filterObjects)

  const { filteredItems, requestFilter, filterTerm } = useFilterableData(data, filteredFilterObjects, filterObjectsValueMap, defaultFilterTerm, filterControls);
  const { items, requestSort, sortConfig } = useSortableData(filteredItems, defaultSort);
  const [page, setPage] = useState(queryParams.tablePage || currentPage)
  const [scroll, setScroll] = useState(queryParams.tableScroll)
  const [expandedItems, setExpandedItems] = useState(queryParams.expandedItems || [])
  const [viewMode, setViewMode] = useState(queryParams.tableViewMode || localStorage.getItem('tableViewMode') || 'statistics')

  const [_viewModes] = useState(showViewModes ? (viewModes || [{icon: 'statistics', value: "statistics", tooltip:"Toggle statistics columns"}, {icon: 'descriptions', value: "descriptions", tooltip:"Toggle description column"}]) : undefined)
  
  const {showTooltip, hideTooltip} = useTooltip()

  const tmpRef = useRef()
  const wrapperRef = ref || tmpRef

  useEffect(() => {
    const element = wrapperRef.current
    if (element) {

      element.addEventListener('scroll', trackScrolling)
      return () => { 
        element.removeEventListener('scroll', trackScrolling) 
      }
    }
  }, [wrapperRef.current]);

  useEffect(() => {
    requestFilter(filterTerm)    
    setHistory({filterObjects: filteredFilterObjects})
  }, [filteredFilterObjects]);

  useLayoutEffect(() => {
    // Restore scroll
    wrapperRef.current.scrollTop=scroll
  }, [wrapperRef.current, items]);


  useEffect(() => {
    setHistory({tableScroll: scroll, tablePage: page})
  }, [scroll, page, filterTerm]);

  useEffect(() => {
    //setHistory({tableViewMode: viewMode})
  }, [viewMode]);

  useEffect( () => {
    setHistory({expandedItems: expandedItems})
  }, [expandedItems])

  const trackScrolling = useDebounce( () => { // Debounce to reduce number of calls to setState
    setScroll(wrapperRef.current.scrollTop)
  }, 200).debounce 

  const handleFilter = (str, value) => {
    // Go to first page and scroll to top
    selectPage(1)

    // De-select any selected items
    setSelectedItems && setSelectedItems([])

    // Apply filter
    requestFilter(str, value)
  }

  const handleFilterObjectsFilter = (item) => {

    let array = filteredFilterObjects.map(x => {
      let checked
      if (Array.isArray(item)) {
        let value = item.find(y => y.value === x.value)
        checked = value !== undefined && value.checked
      } else {
        checked = x.value === item.value ? !x.checked : x.checked
      }

      return {
        ...x,
        checked: checked
      }
    })

    setFilteredFilterObjects(array)

  }
  

  const handleSort = (value) => {
    // Go to first page and scroll to top
    selectPage(1)

    // Apply sort
    requestSort(value, groupByColumn)
  }

  const handleViewModeChange = (event) => {

    const mode = event.value
    setViewMode(mode)
    setHistory({tableViewMode: mode})

    // Persist state in local storage
    localStorage.setItem('tableViewMode', mode)
  }

  const resetScroll = () => {
    wrapperRef.current.scrollTop = 0
  }

  const getClassNamesFor = (name) => {
    if (!sortConfig) {
      return;
    }
    return sortConfig.key === name ? sortConfig.direction : undefined;
  };

  const selectItem = (items) => {

    let array = selectedItems

    items.forEach( item => {
      
      if (item.checked) {
        if (array.findIndex(x => x[idColumn].toString() === item.itemId) === -1) { // add only if not already in array
          setSelectedItems(prevState => [...prevState, ...[data.find(x => x[idColumn].toString() === item.itemId)]] )
        }
      } else {
        setSelectedItems(prevState => prevState.filter(x => x[idColumn].toString() !== item.itemId))
      }    
    })
  }
  
  const handleSelect = useCallback( (event) => {

    const itemId = event.target.name.split("-")[1]
    const checked = event.target.checked

    // Add item to selection array
    selectItem([{ itemId: itemId , checked: checked }])

  }, [items, selectedItems])

  const handleSelectAll = (event) => {

    const checked = event.target.checked

    let array = []
    
    items.forEach(item => {
      let itemId = item[idColumn].toString()

      array.push({ itemId: itemId , checked: checked }) 
    })

    // Add items to selection array
    selectItem(array)
  }

  const handleCollapseAll = () => {
    setExpandedItems([])
  }

  const handleExpand = (itemId) => {

    if (expandedItems.find(x => x === itemId)) {
      setExpandedItems(prev => prev.filter(x => x !== itemId))
      
    } else {
      setExpandedItems(prev => [...prev, itemId])
    }
  }

  const itemCount = items.length
  const selectedItemsCount = selectedItems.length

  const rowActionEdit = buttons && buttons.find(x => x.action === 'edit')?.onClick
  const rowActionDelete = buttons && buttons.find(x => x.action === 'delete')?.onClick
  const rowActionRemove = buttons && buttons.find(x => x.action === 'remove')?.onClick
  const rowActionRun = buttons && buttons.find(x => x.action === 'run')

  const rowActionCount = (rowActionEdit ? 1 : 0) + (rowActionDelete ? 1 : 0) + (rowActionRemove ? 1 : 0) + (rowActionRun ? 1 : 0)

  const paginatedItems = paginated ? items.slice((page-1)*pageSize,page*pageSize) : items
  const pageCount = Math.ceil(items.length / pageSize)

  const stepPage = (steps) => {
    if (steps < 0 ) {
      if (page + steps < 1) {
        selectPage(1)
      } else {
        selectPage(page + steps)
      }
    } else {
      if (page + steps > pageCount) {
        selectPage(pageCount)
      } else {
        selectPage(page + steps)
      }
    }
  }

  const selectPage = (pageNum) => {
    resetScroll()
    setPage(pageNum)
  }

  const pages = []
  let i = 0
  while (i < pageCount) {
    pages.push(i+1)
    i++
  }

  let columnCount = editable ? 2 : 0
  columns.forEach( (column) => {
    columnCount = columnCount + (column.type === "chart" ? 2 : 1)
  })

  const renderRow = () => {
    let groupIndex = 1
    let prevRowGroup

    return paginatedItems.map( (item, index) => {
      
      groupIndex = (item[groupByColumn] && item[groupByColumn] === prevRowGroup) ? groupIndex + 1 : 1
      prevRowGroup = item[groupByColumn]

      return (
        <DataTableRow
          key={index}
          item={item}
          itemCount={itemCount}
          idColumn={idColumn}
          groupIndex={groupIndex}
          columns={columns}
          selected={ selectedItems.find(x => { return (x[idColumn] === item[idColumn]); }) }
          editable={editable}
          handleSelect={handleSelect}
          actionEdit={rowActionEdit}
          actionDelete={rowActionDelete}
          actionRemove={rowActionRemove}
          actionRun={rowActionRun}
          onMouseEnterRow={onMouseEnterRow}
          onMouseLeaveRow={onMouseLeaveRow}
          expandable={expandable}
          expandableColumns={expandableColumns}
          expandedRowClassName={expandedRowClassName}
          expanded={expandedItems.find(x => { return (x === item[idColumn]); }) }
          handleExpand={handleExpand}
          viewModes={_viewModes}
          viewMode={viewMode}
        />
      )
    })
  }

  return (
    <React.Fragment>

    { !hideToolbar &&
    
      <DataTableToolbar
        title={title}
        buttons={buttons}
        itemTotalCount={data?.length}
        selectedItems={selectedItems}
        selectedItemsCount={selectedItemsCount}
        itemCount={itemCount}
        editable={editable}
        filterable={filterable}
        requestFilter={handleFilter}
        onFilterObjectsFilter={handleFilterObjectsFilter}
        filterObjects={filteredFilterObjects}
        filterControls={filterControls}
        filterTerm={filterTerm}
        viewMode={viewMode}
        onViewModeChange={handleViewModeChange}
        viewModes={_viewModes}
      />

    }

    <div className="DataTableWrapper" ref={wrapperRef} >
    <table className={"DataTable" + (className ? " " + className : "")}>
      {header && 
        <thead>
          <tr>
            { editable && 
              <th className="checkbox">
              { items.length > 0  &&
                <Checkbox
                  name={`select-all`}
                  onChange={handleSelectAll}
                  checked={(items.length > 0 && items.length === selectedItems.length) ? true : false}
                />
              }
              </th>
            }
            { expandable && 
              <th className="toggle-expand">
              { expandedItems.length > 0 && 
                <div 
                  className="button-toggle-collapse-all" 
                  onClick={handleCollapseAll}
                  onMouseEnter={ () => showTooltip({title: 'Collapse all'}) }
                  onMouseLeave={ hideTooltip}
                >
                  <div className="arrow-1"></div>
                  <div className="arrow-2"></div>
                </div>}
              </th>
            }
            { columns.map((column, index) => (
              <th key={index}
                onClick={() => (column.type === 'user-photo' || column.type === 'group-photo' ? null : handleSort(column.id))}
                className={
                  (column.type === 'user-photo' || column.type === 'group-photo' ? "no-right-margin" : 'sortable ') +
                  (column.type === 'icon' ? "type-icon-header " : '') +
                  getClassNamesFor(column.id) + ' ' + column.className + 
                  ((column.align === undefined) ? ' left' : ' '+ column.align) + 
                  ((_viewModes && column.viewMode && column.viewMode !== viewMode) ? ' hide-column' : '') 
                }
                onMouseEnter={ () => displayNames[column.id] && showTooltip({title: displayNames[column.id].display_name_description}) }
                onMouseLeave={ hideTooltip}
                colSpan={column.type === 'chart' ? 2 : 1 }
              >
                {column.name}

              </th>
            ))
            }
            
            { editable &&
                <th className={"last-column width-"+ rowActionCount.toString() }></th>
              }
          </tr>
        </thead>
      }
      <tbody>

      { !loading &&
        renderRow()
      }
      { (loading || loadingMoreRows) &&  
          <tr>
            { editable && 
              <td></td>
            }
            { expandable && 
              <td></td>
            }
            {columns.map((column, index) => (
              <td 
                key={index} 
                colSpan={column.type === 'chart' ? 2 : 1 }
                className={((_viewModes && column.viewMode && column.viewMode !== viewMode) ? ' hide-column' : '') }
              >
              { (['icon', 'user-photo', 'group-photo']).includes(column.type)
                ? <SkeletonLoader width="1em" />
                : <SkeletonLoader width="5vw" />
              }
              </td>
              
            ))}
            { editable &&
              <td className={"last-column width-"+rowActionCount.toString()}></td>
            }
          </tr>
      }

      </tbody>
    </table>
    {!loading && items.length === 0 ? <div className="empty-text">No assets</div> : null}

    </div>
        {!loading && paginated && pageCount > 1 &&
      <DataTablePagination 
        items={items}
        primaryColumn={primaryColumn}
        pages={pages}
        pageSize={pageSize}
        currentPage={page}
        stepPage={stepPage}
        selectPage={selectPage}
      />
    }
    </React.Fragment>
  );
};

export const DataTable = forwardRef(_DataTable)