import config from '../config';
import { authHeader, customFetch, handleResponse, commonRequestOptions } from '../helpers';

const endpointPath='dataset_type'

export const datasetTypeService = {
    getAll
};

function getAll(signal) {
    const requestOptions = { method: 'GET', headers: authHeader(), ...commonRequestOptions() };
    return customFetch(`${config.api.url}/${endpointPath}/all`, requestOptions, signal).then(handleResponse);
}