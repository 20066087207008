import React, { useState, useEffect, useMemo } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { datasetGroupService, datasetService, referenceService } from '../services';
import { FieldSensitivity, formatConnectionString, handleError, Role, Asset } from '../helpers';
import { 
  Changelog,
  DataTable, 
  MainColumn, 
  ObjectDetails, 
  RelationshipGraph,
  SkeletonLoader,  
  Tabs, 
  TextareaReadOnly, 
  textareaInitialState, 
  UserLink } from '../components';
import { 
  useSideDialog, 
  useModalDialog, 
  useQueryParams, 
  useGlobalState, 
  usePhotos, 
  useNotFound, 
  useMessage } from '../hooks';


export const DatasetGroup = ({abortFetch}) => {

  const { queryParams, resetParams } = useQueryParams()

  const [datasetGroup, setDatasetGroup] = useState([]);
  const [datasets, setDatasets] = useState();
  const [fromReferences, setFromReferences] = useState([]);
  const [loggedInUser, setLoggedInUser] = useGlobalState('loggedInUser');
  const [selectedItems, setSelectedItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingTable, setLoadingTable] = useState(true);
  const [defaultSelectedTab, setDefaultSelectedTab] = useState({tab: queryParams.tab})

  const clearSelectedItems = () => {
    setSelectedItems([])
  }

  const { system_id, datasource_id, dataset_group_id } = useParams()
  const navigate = useNavigate()

  const { showMessage } = useMessage()
  const { showSideDialog, hideSideDialog } = useSideDialog(clearSelectedItems)
  const { showModalDialog, hideModalDialog } = useModalDialog()

  const { fetchPhotos } = usePhotos()
  const { notFound, setNotFound, NotFound } = useNotFound()



  useEffect(() => {

    fetchItems()
    fetchDatasets()
    fetchFromReferences()

  }, [dataset_group_id]);

  const fetchItems = async () => {
    
    datasetGroupService.getById(dataset_group_id, abortFetch)
      .then(res => {
        if (res.dataset_groups.length < 1) {
          setNotFound(true)
        } else {
          setDatasetGroup(res.dataset_groups[0])
          setLoading(false)
          fetchPhotos(res.dataset_groups[0], 'dataset_group_owner_user_id', setDatasetGroup)
        }
      })
      .catch(err => {
        handleError({err, showMessage}, () => {
          setLoading(false)
        })
      });
  }

  const fetchDatasets = async () => {

    const promise = new Promise(  (resolve, reject) => {
      datasetService.getByDatasetGroupId(dataset_group_id, abortFetch)
        .then(res => {

          const datasets = res && res.datasets.map(x => {return {...x, pii: (x.is_pii ? 'PII' : null), pii_tooltip: (x.is_pii ? 'Contains Fields classified as Personally Identifiable Information (PII)' : null) }})

          setDatasets(datasets)
          setLoadingTable(false)
          fetchPhotos(datasets, 'dataset_owner_user_id', setDatasets)
          resolve(datasets)
        })
        .catch(err => {
          handleError({err, showMessage}, () => {
            setLoading(false)
          })
        })
    })

    Promise.all([promise, fetchFieldStatistics()])
      .then( result => {
        updateFieldStatistics(result[0], result[1])
      })
      .catch(err => {
        handleError({err, showMessage})
      }) 
  }

  const fetchFromReferences = async () => {
    
    referenceService.getByFromId(Asset.DatasetGroup, dataset_group_id, abortFetch)
      .then(res => {

        res && setFromReferences(res.references);
         
      })
      .catch(err => {
        handleError({err, showMessage})
      })
  }

  const fetchFieldStatistics = async () => {
    return new Promise(  (resolve, reject) => {
      datasetService.getFieldStatistics(dataset_group_id, abortFetch)
        .then(res => {
          resolve(res.field_statistics)
        })
        .catch(err => {
          return null
        })
    })
  }

  const updateFieldStatistics = (data, statistics) => {

    let dataTmp = data
    let statistic = {}
    let tmp = null

    let maxFieldCount = 0
    let maxRowCount = 0
    let maxDiskSize = 0

    statistics.forEach( row => {
      if (row.field_count > maxFieldCount) {
        maxFieldCount = row.field_count
      }
    })
    
    data.forEach( row => {
      if (parseInt(row.dataset_row_count) > maxRowCount) {
        maxRowCount = parseInt(row.dataset_row_count)
      }
      if (parseInt(row.dataset_size_bytes) > maxDiskSize) {
        maxDiskSize = parseInt(row.dataset_size_bytes)
      }
    })

    data.forEach( (row, index) => {
      statistic = statistics.find(x => x.dataset_id === row.dataset_id)
      tmp = dataTmp[index]
      dataTmp[index] = {
        ...tmp, 
        field_count: (statistic ? statistic.field_count : 0), 
        field_description_ratio: (statistic ? statistic.field_description_ratio : 0),
        field_count_max: maxFieldCount,
        row_count: row.dataset_row_count ? parseInt(row.dataset_row_count) : null, 
        row_count_max: maxRowCount,
        disk_size: row.dataset_size_bytes ? parseInt(row.dataset_size_bytes) : null, 
        disk_size_max: maxDiskSize
      }
    })
    setDatasets([...dataTmp]); 
  }


  const addDataset = async data => {
    const datasets = {datasets: (Array.isArray(data) ? data : [data])}
    
    await datasetService.create(datasets)
      .then(res => {

        fetchDatasets()
        showMessage(res.message)
      })
      .catch(err => {
        handleError({err, showMessage}, () => {
          throw err
        })
      });
  }

  const editDatasetGroup = async data => {
    const datasetGroups = {dataset_groups: (Array.isArray(data) ? data : [data])}
    
    await datasetGroupService.update(datasetGroups)
      .then(async res => { 

        fetchItems()
        fetchFromReferences()
        showMessage(res.message)
        hideSideDialog()
      })
      .catch(err => {
        handleError({err, showMessage}, () => {
          throw err
        })
      })
  }

  const editDataset = async data => {
    const datasets = {datasets: (Array.isArray(data) ? data : [data])}
    
    await datasetService.update(datasets)
      .then(async res => { 

        fetchDatasets()
        showMessage(res.message)
        clearSelectedItems()
        hideSideDialog()
      })
      .catch(err => {
        handleError({err, showMessage}, () => {
          throw err
        })
      })
  }

  const deleteDatasetGroup = async data => {    
    await datasetGroupService.destroy({dataset_groups: data})
      .then(async res => { 

        showMessage(res.message)
        hideModalDialog()

        // Redirect to parent page
        navigate(`/browse_datasets/${system_id}/${datasource_id}`)

      })
      .catch(err => {
        handleError({err, showMessage}, () => {
        })
      })
  }

  const deleteDataset = async data => {    
    await datasetService.destroy({ datasets: data })
      .then(async res => { 

        fetchDatasets()
        showMessage(res.message)
        hideModalDialog()
        clearSelectedItems()

      })
      .catch(err => {
        handleError({err, showMessage}, () => {
        })
      })
  }

  const onTabChange = (tab) => {
    resetParams({tab: tab})
  }

  const data = loading ? [] : datasetGroup

  const editableDatasetGroup = loggedInUser && loggedInUser.user_role_name === Role.admin
  const editableDataset = loggedInUser && (loggedInUser.user_role_name === Role.admin || loggedInUser.user_role_name === Role.editor)

  if (notFound) {
    return <NotFound />
  }

  return (
    <div className="columns narrow-margin">
      <MainColumn>
        <ObjectDetails
          type="Dataset Group"
          title={data.dataset_group_name}
          subtitleText={data && !loading && 
            formatConnectionString(
              data.datasource_database, 
              data.datasource_hostname, 
              [data.dataset_group_source_name], 
              data.datasource_type_code, 
              data.datasource_type_category)
            }
          description={data.dataset_group_description}
          fromReferences={fromReferences}
          loading={loading}
          >

            <h3>{ !loading ? "Owner" : <SkeletonLoader width="5vw"/>}</h3>
            <UserLink 
              userId={data.dataset_group_owner_user_id}
              userName={data.dataset_group_owner_user_fullname}
              userPhoto={data.user_photo}
              userTitle={data.user_title}
              userDepartment={data.user_department}
              loading={loading}
            />
          </ObjectDetails>

          { !loading && editableDatasetGroup &&
          <div className="main-toolbar">
            <button type="button" className="main-toolbar-item button main-button" onClick={ () => showSideDialog('editDatasetGroup', [data], editDatasetGroup) }><span>EDIT</span></button>
            <button type="button" className="main-toolbar-item button" onClick={ () => showModalDialog('deleteDatasetGroup', [data], deleteDatasetGroup) }><span>DELETE</span></button>
          </div>
          }

      </MainColumn>

      <div className="column">
        <Tabs className="slim left"
          onTabChange={ onTabChange }
          defaultSelectedTab={ defaultSelectedTab }
          disableTabsWithoutResults = {true}
          >
          <div label="Datasets" tabid="datasets" resultCount={loadingTable || datasets.length === 0 ? undefined : datasets.length}>

            <DataTable
              columns={[
                {id: 'dataset_type_name', name: '', type: 'icon', tooltip: 'dataset_type_name'},
                {id: 'dataset_name', name: 'Name', link: '/browse_datasets/:system_id/:datasource_id/:dataset_group_id/:dataset_id', tooltip: 'dataset_description', className:"bold"},
                {id: 'dataset_description', name: 'Description', type: 'description', viewMode: 'descriptions'},
                {id: 'field_count', name: 'Fields', type: 'chart', chartType: 'bar', data: {data:'field_count', max: 'field_count_max'}, viewMode: 'statistics'},
                {id: 'row_count', name: 'Rows', type: 'chart', chartType: 'bar', data: {data:'row_count', max: 'row_count_max'}, viewMode: 'statistics'},
                {id: 'field_description_ratio', name: 'Documentation Level', type: 'chart', chartType: 'percent-bar', data: {data:'field_description_ratio'}, viewMode: 'statistics'},
                {id: 'user_photo', name: '', type: 'user-photo', link: '/users/:dataset_owner_user_id', tooltip: 'dataset_owner_user_fullname'},                
                {id: 'dataset_owner_user_fullname', name: 'Owner', link: '/users/:dataset_owner_user_id'},
                {id: 'pii', name: '', type: 'icon', tooltip: 'pii_tooltip', className: 'no-right-margin'},
                {id: 'field_sensitivity_level', name: 'Data Sensitivity', type: 'pill', value: 'field_sensitivity_level', values: FieldSensitivity, tooltip: 'field_sensitivity_name'}
              ]}
              buttons={[ 
                {label: "Add +", action: "add", mainButton: true, onClick: (items) => showSideDialog('addDataset', items || selectedItems, addDataset) },
                {label: "Edit", action: "edit", onClick: (items) => showSideDialog('editDataset', items || selectedItems, editDataset) },
                {label: "Delete", action: "delete", onClick: (items) => showModalDialog('deleteDataset', items || selectedItems, deleteDataset) }
              ]}
              data={datasets}
              idColumn='dataset_id'
              selectedItems={selectedItems}
              setSelectedItems={setSelectedItems}
              loading={loadingTable}
              editable={editableDataset}
              filterable={true}
              filterObjects={['dataset_name', 'dataset_description', 'dataset_type_name', 'dataset_owner_user_fullname', 'field_sensitivity_name', 'pii']}
              showViewModes={true}
            />
      
          </div>

          <div label="Relationships" tabid="relationships">
            { queryParams.tab === "relationships" &&
              <RelationshipGraph 
                datasets={datasets}
                objectName={Asset.DatasetGroup}
                objectId={dataset_group_id}
              />
            }
          </div>

          <div label="Changelog" tabid="changelog">
            { queryParams.tab === "changelog" &&
              <Changelog 
                objectName={Asset.DatasetGroup} 
                objectId={dataset_group_id} 
                filterObjects={[
                  'changedData.new_data||old_data.dataset_group_name', 
                  'changedData.new_data||old_data.dataset_group_description', 
                  'changedData.new_data||old_data.datasource_name', 
                  'changedData.new_data||old_data.datasource_type_name', 
                  'changedData.new_data||old_data.dataset_group_owner_user_fullname', 
                  'changedData.attribute',
                  'changed_by_user_username',
                  'is_service_account_user'
                ]}
                filterObjectsValueMap={{is_service_account_user: {true: {label:'Datasource Sync'}, false: {label:''}}}}
              />
            }
          </div>

        </Tabs>
      </div>

    </div>
  ) 
}
