import { useState, useEffect } from 'react';
import { useQueryParams, useMessage } from './';
import { searchService } from '../services';

export const useSearch = ({objectName=undefined, setLoading=undefined, pagination=true, enableHistory=true}) => {

  const { queryParams, setHistory } = useQueryParams()
  const { showMessage } = useMessage()

  const [searchTerm, _setSearchTerm] = useState(enableHistory && queryParams.search)
  const [searchResults, setSearchResults] = useState([]);
  const [searchResultsCount, setSearchResultsCount] = useState({});
  const [page, setPage] = useState(1);

  useEffect(() => {
    
    if (searchTerm) {
      fetchSearchResults(searchTerm)
    }

  }, [])

  useEffect(() => {

    //if (searchTerm !== queryParams.search) {
      enableHistory && setHistory({search: searchTerm})
      pagination && setPage(1)
    //}

  }, [searchTerm])

  const fetchSearchResults = async (search = undefined, append = false, n = undefined, usePagination = undefined) => {
    try {

      const res = await fetchSearchResultsData(search, append, n, usePagination)

      n && setPage(prev => n)

      if (append) {
        setSearchResults(prev => {
          return [...prev, ...res.searchResults]
        })
      } else {
        setSearchResults(prev => res.searchResults)
      }

      setSearchResultsCount(prev => res.searchResultsCount)

      return res

    } catch (err) {
      showMessage(err, 'error');
    }
  }

  const fetchSearchResultsData = (search = undefined, append = false, n = undefined, usePagination = undefined) => { //, append = false, nForced=undefined) => {
    return new Promise( async (resolve, reject) => {
    setLoading && setLoading(true)

    let tmpSearch = searchTerm
    if (search !== undefined) {
      tmpSearch = search
    }

    const string = encodeURIComponent(tmpSearch.replaceAll('%', '%25'))

    const object = (objectName === 'all') ? undefined : objectName

    const pageNumber = pagination && usePagination !== false ? ((n !== undefined) ? n : page) : undefined

    await searchService.getAll(string, object, pageNumber, append)
      .then(res => {
        if (res) {
          let tmp = {}
          let allCount = 0
          res.search_results_count.forEach(result => {
            tmp = {...tmp, [result.object_name]: parseInt(result.count)}
            allCount = allCount + parseInt(result.count)
          })

          tmp = {...tmp, all: allCount}        
          
          resolve({searchResults: res.search_results, searchResultsCount: tmp})

          setLoading && setLoading(prev => false)
        }
      })
      .catch(err => {
        setLoading && setLoading(prev => false)
        reject(err)
      })
    })
  }

  const clearSearchResults = () => {  
    _setSearchTerm("")
    enableHistory && setHistory({search: ""})
    setSearchResults([])  
    setSearchResultsCount({}) 
    setPage(1)
  }

  const setSearchTerm = value => {

    if (value.length > 1) { // Only trigger search query for search terms with more than 1 character
      fetchSearchResults(value, false, 1)
    } else if (searchTerm.length > 1 && value.length <= 1) { // Clear search results if search term is shortened to less than 2 characters
      clearSearchResults()
    }

    _setSearchTerm(value)

  }

  const fetchAllSearchResults = async search => {
    try {

      const res = await fetchSearchResults(search || searchTerm, undefined, undefined, false)
      return res
    } catch (err) {
      // do nothing, errors handles in fetchSearchResults
    }
  }

  const loadNextPage = () => {
    const tmpPage = page + 1 
    fetchSearchResults(undefined, true, tmpPage)
  }

  return { 
    searchTerm, 
    searchResults, 
    searchResultsCount, 
    setSearchTerm, 
    fetchSearchResults,
    fetchAllSearchResults,
    fetchSearchResultsData, 
    clearSearchResults, 
    loadNextPage };
};