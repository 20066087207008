import React, { useRef, useCallback, useContext, useState, forwardRef } from 'react';
import { 
  businessTermService,
  datasetService,
  datasetGroupService,
  datasourceService,
  fieldService,
  fieldDescriptionService,
  glossaryService,
  systemService,
  userService,
  userGroupService
} from '../services';
import { copyToClipboard } from '../helpers';
import { useMessage, useSideDialog } from './';
import { ModalContext } from "../context/ModalContext";
import { TextareaCode, textareaCodeInitialState, editorStateToPlainText } from '../components';

const objectMap = {
  business_term_count: 'Business Term',
  dataset_count: 'Dataset',
  dataset_group_count: 'Dataset Group',
  datasource_count: 'Datasource',
  driver_count: 'Driver',
  field_count: 'Field',
  field_description_count: 'Field Description',
  field_unit_count: 'Field Unit',
  glossary_count: 'Glossary',
  system_count: 'System',
  task_count: 'Task',
  user_count: 'User',
  user_group_count: 'User Group',
}

const renderCheckDeleteBody = (checkDelete) => {
  return (
    <div>
      <div>Are you sure you want to delete the following items?</div>
      <ul>
      { Object.keys(checkDelete.result).map( (key, index) => {
            return checkDelete.result[key] > 0  
                    ? <li key={key}>{checkDelete.result[key] + ' ' + objectMap[key] + (checkDelete.result[key] > 1 ? 's':'')}</li> 
                    : null
        })
      }
      </ul>
    </div>
  )
}

const _QueryEditor = ({data, onSubmit, loading, readOnly=false}, ref) => {

  const [value, setValue] = useState(textareaCodeInitialState({value: data}))

  const handleInputChange = (event) => {
    setValue(event)
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    onSubmit(editorStateToPlainText(value))
  }

  return (
    <div className="modal-container-body-code">
      <form ref={ref} onSubmit={handleSubmit}>
        <TextareaCode
          name="code-editor" 
          value={value} 
          onChange={handleInputChange}
          placeholder='SELECT * FROM schema.vw_custom_view'
          disabled={loading}
          readOnly={readOnly}
        />
      </form>
    </div>
   )
}
const QueryEditor = forwardRef(_QueryEditor)

export const useModalDialog = () => {

  const {
    modalDialog, 
    setModalDialog,
    loadingModalDialog, 
    setLoadingModalDialog,
    show, 
    setShow
  } = useContext(ModalContext)

  const refContainerBody = useRef()
  const refContentForm = useRef()

  const { showMessage } = useMessage()
  const { hideSideDialog } = useSideDialog()

  const checkDeleteBusinessTerm = async (items) => {    
    return businessTermService.checkDestroy(items)
      .then(async res => { 
        return res
      })
      .catch(err => {showMessage(err, 'error')});
  }

  const checkDeleteDataset = async (items) => {    
    return datasetService.checkDestroy(items)
      .then(async res => { 
        return res
      })
      .catch(err => {showMessage(err, 'error')});
  }

  const checkDeleteDatasetGroup = async (items) => {    
    return datasetGroupService.checkDestroy(items)
      .then(async res => { 
        return res
      })
      .catch(err => {showMessage(err, 'error')});
  }

  const checkDeleteDatasource = async (items) => {    
    return datasourceService.checkDestroy(items)
      .then(async res => { 
        return res
      })
      .catch(err => {showMessage(err, 'error')});
  }

  const checkDeleteField = async (items) => {    
    return fieldService.checkDestroy(items)
      .then(async res => { 
        return res
      })
      .catch(err => {showMessage(err, 'error')});
  }

  const checkDeleteFieldDescription = async (items) => {    
    return fieldDescriptionService.checkDestroy(items)
      .then(async res => { 
        return res
      })
      .catch(err => {showMessage(err, 'error')});
  }

  const checkDeleteGlossary = async (items) => {    
    return glossaryService.checkDestroy(items)
      .then(async res => { 
        return res
      })
      .catch(err => {showMessage(err, 'error')});
  }

  const checkDeleteSystem = async (items) => {    
    return systemService.checkDestroy(items)
      .then(async res => { 
        return res
      })
      .catch(err => {showMessage(err, 'error')});
  }

  const checkDeleteUser = async (items) => {    
    return userService.checkDestroy(items)
      .then(async res => { 
        return res
      })
      .catch(err => {showMessage(err, 'error')});
  }

  const checkDeleteUserGroup = async (items) => {    
    return userGroupService.checkDestroy(items)
      .then(async res => { 
        return res
      })
      .catch(err => {showMessage(err, 'error')});
  }

  const getRelationshipImportQuery = async (items) => {    
    return datasourceService.getRelationshipImportQuery(items.datasource_id, {relationship_map: items.relationship_map, disable_virtual_relationships: items.disable_virtual_relationships})
      .then(async res => { 
        return res
      })
      .catch(err => {
        showMessage(err, 'error')
        return {query: "Error when compiling query"}
      });
  }

  const getDatasourceImportQuery = async (items) => {    
    return datasourceService.getDatasourceImportQuery({datasource: items})
      .then(async res => { 
        return res
      })
      .catch(err => {
        showMessage(err, 'error')
        return {query: "Error when compiling query"}
      });
  }

  const getDefaultImportQuery = async (items) => {    
    return datasourceService.getDefaultImportQuery({datasource: items})
      .then(async res => { 
        return res
      })
      .catch(err => {
        showMessage(err, 'error')
      });
  }

  const getDefaultRelationshipQuery = async (items) => {    
    return datasourceService.getDefaultRelationshipQuery({datasource: items})
      .then(async res => { 
        return res
      })
      .catch(err => {
        showMessage(err, 'error')
      });
  }

  const hideModalDialog = useCallback(
    (props) => {
      setShow(prev => false)
      setModalDialog({})
      
      // Hacky way to un-blur side dialog. 
      // That component cannot use this hook to get the "show" value since that causes a re-render and I found no other way to prevent that
      // David 2023-01-17
      document.getElementById("SideDialog").classList.remove('filter-blur')
    },
    [setShow]
  );

  const showModalDialog = useCallback(
    async (mode, data, onSubmit, onCancel, submitLabel, submitLabelLoading, cancelLabel) => {

    setLoadingModalDialog(prev => true)



    let defaultConfig = {
          active: true,
          title: "You are about to delete some stuff",
          submitLabel: submitLabel !== undefined ? submitLabel : "OK",
          submitLabelLoading: submitLabelLoading ? submitLabelLoading : submitLabel || "Deleting...",
          cancelLabel: cancelLabel !== undefined ? cancelLabel : "Cancel",
          onSubmit: () => onSubmit ? onSubmit(data) : hideModalDialog(),
          onCancel: onCancel || hideModalDialog,
          customButtons: undefined,
          Content: undefined,
          contentProps: undefined
    }

    let config = {}
    let checkDelete = {}

    switch(mode) {
      case "removeFieldAssociation":
        hideSideDialog()
        config = {
          ...config,
          title: "You are about to remove some field associations",
          submitLabelLoading: "Removing...",

        }
        break

      case "removeMember":
        hideSideDialog()
        config = {
          ...config,
          title: "You are about to remove some users from a user group",
          submitLabelLoading: "Removing...",
        }
        break

      case "showRelationshipQuery":
        config = {
          ...config,
          title: "Relationship Upsert Query",
          cancelLabel: undefined,
          submitLabel: "Close",
          submitLabelLoading: "OK",
        }
        break

      case "showFilterQuery":
        config = {
          ...config,
          title: "Complete Datasource Import Query With Filters",
          cancelLabel: undefined,
          submitLabel: "Close",     
          submitLabelLoading: "OK",
        }
        break

      case "editImportQuery":
        config = {
          ...config,
          title: "Edit Base Import Query",
          cancelLabel: "Cancel",
          submitLabel: "Save",
          customButtons: [
          {
            value: "Insert Default Query",
            valueLoading: "Inserting Default Query...",
            tooltip: "Insert default SQL query. This will overwrite the current query above",
            onClick: async () => { 
              const defaultQuery = await getDefaultImportQuery(data)
              const tmp = {...data, custom_import_query: defaultQuery.query}
              showModalDialog('editImportQuery', tmp, onSubmit, onCancel, submitLabel, submitLabelLoading, cancelLabel) 
            }
          }],        
          submitLabelLoading: "Saving...",
          contentProps: {
            data: data.custom_import_query, 
            onSubmit: onSubmit,
            ref: refContentForm
          },
          onSubmit: () => refContentForm.current.dispatchEvent( new Event("submit", { cancelable: true, bubbles: true }) )
        }
        break

      case "editRelationshipQuery":
        config = {
          ...config,
          title: "Edit Relationship Query",
          cancelLabel: "Cancel",
          submitLabel: "Save",
          customButtons: [
          {
            value: "Insert Default Query",
            valueLoading: "Inserting Default Query...",
            tooltip: "Insert default SQL query. This will overwrite the current query above",
            onClick: async () => { 
              const defaultQuery = await getDefaultRelationshipQuery(data)
              const tmp = {...data, custom_relationship_query: defaultQuery.query}
              showModalDialog('editRelationshipQuery', tmp, onSubmit, onCancel, submitLabel, submitLabelLoading, cancelLabel) 
            }
          }],        
          submitLabelLoading: "Saving...",
          contentProps: {
            data: data.custom_relationship_query, 
            onSubmit: onSubmit,
            ref: refContentForm
          },
          onSubmit: () => refContentForm.current.dispatchEvent( new Event("submit", { cancelable: true, bubbles: true }) )
        }
        break

      case "showMessage":
        config = {
          ...config,
          title: "Job Completion Message",
          cancelLabel: undefined,
          submitLabel: "Close",
          customButtons: [{
            value: "Copy",
            valueClicked: "Copied!",
            tooltip: "Copy query to clipboard",
            onClick: () => { copyToClipboard(refContainerBody)}
          }],
          submitLabelLoading: "OK",
        }
        break

      case "editSettings":
        config = {
          ...config,
          title: "Saving settings will trigger a reboot",
          submitLabel: "Save",
          submitLabelLoading: "OK",
        }
        break

      default:
        hideSideDialog()
        break
    }

    setShow(prev => true)
    setModalDialog({...defaultConfig, ...config})

    // David Svensson 2023-01-17
    // Hacky way to blur side dialog. 
    // That component cannot use this hook to get the "show" value since that causes a re-render and I found no other way to prevent that
    document.getElementById("SideDialog").classList.add('filter-blur')

    switch(mode) {
      case "deleteBusinessTerm":
        checkDelete = await checkDeleteBusinessTerm({ business_terms: data })
        config = {
          ...config,
          Content: () => renderCheckDeleteBody(checkDelete)
        }
        break
      case "deleteDataset":
        checkDelete = await checkDeleteDataset({ datasets: data })
        config = {
          ...config,
          Content: () => renderCheckDeleteBody(checkDelete)
        }
        break
      case "deleteDatasetGroup":
        checkDelete = await checkDeleteDatasetGroup({ dataset_groups: data })
        config = {
          ...config,
          Content: () => renderCheckDeleteBody(checkDelete)
        }
        break
      case "deleteDatasource":
        checkDelete = await checkDeleteDatasource({ datasources: data })
        config = {
          ...config,
          Content: () => renderCheckDeleteBody(checkDelete)
        }
        break
      case "deleteODBCDriver":
        config = {
          ...config,
          Content: () => renderCheckDeleteBody({result: {driver_count: data.length}})
        }
        break
      case "deleteField":
        checkDelete = await checkDeleteField({ fields: data })
        config = {
          ...config,
          Content: () => renderCheckDeleteBody(checkDelete)
        }
        break
      case "deleteFieldDescription":
        checkDelete = await checkDeleteFieldDescription({ field_descriptions: data })
        config = {
          ...config,
          Content: () => <div>
                  <div>Are you sure you want to to proceed with the following changes?</div>
                  <ul>
                  { checkDelete.result['field_description_count'] > 0 &&
                    <li>{checkDelete.result['field_description_count'] + ' ' + objectMap['field_description_count'] + (checkDelete.result['field_description_count'] > 1 ? 's':'')} will be deleted</li>
                  }
                  { checkDelete.result['field_count'] > 0 &&
                    <li>{checkDelete.result['field_count'] + ' ' + objectMap['field_count'] + (checkDelete.result['field_count'] > 1 ? 's will loose their descriptions':' will loose its description')}</li> 
                  }
                  </ul>
                </div>
        }
        break
      case "removeFieldAssociation":
        config = {
          ...config,
          Content: () => <div>
                  <div>Are you sure you want to proceed with the following changes?</div>
                  <ul>
                  { data.length > 0 &&
                    <li>{data.length + ' ' + objectMap['field_count'] + (data.length > 1 ? 's will loose their descriptions':' will loose its description')} </li>
                  }
                  </ul>
                </div>
        }
        break;
      case "deleteFieldUnit":
        config = {
          ...config,
          Content: () => renderCheckDeleteBody({result: {field_unit_count: data.length}})
        }
        break
      case "deleteGlossary":
        checkDelete = await checkDeleteGlossary({ glossaries: data })
        config = {
          ...config,
          Content: () => renderCheckDeleteBody(checkDelete)
        }
        break
      case "deleteSystem":
        checkDelete = await checkDeleteSystem({ systems: data })
        config = {
          ...config,
          Content: () => renderCheckDeleteBody(checkDelete)
        }
        break
      case "deleteTask":
        config = {
          ...config,
         Content: () => <div>
                  <div>Are you sure you want to proceed with the following changes?</div>
                  <ul>
                  { data.length > 0 &&
                    <li>{data.length + ' Task' + (data.length > 1 ? 's':'')} and related Job logs will be deleted</li>
                  }
                  </ul>
                </div>
        }
        break
      case "deleteUser":
        checkDelete = await checkDeleteUser({ users: data })

        if (checkDelete) {
          config = {
            ...config,
            Content: () => <div>
                    <div>Are you sure you want to proceed with the following changes?</div>
                    <ul>
                    { checkDelete.result.user_delete_count > 0 &&
                      <li>{checkDelete.result.user_delete_count + ' User' + (checkDelete.result.user_delete_count > 1 ? 's':'')} will be deleted</li>
                    }
                    { checkDelete.result.user_disable_count > 0 &&
                      <li>{checkDelete.result.user_disable_count + ' User' + (checkDelete.result.user_disable_count > 1 ? 's still own items and will be disabled':' still owns items and will be disabled')}</li> 
                    }
                    </ul>
                  </div>
          }
        } else {
          hideModalDialog()
        }
        break
      case "deleteUserGroup":
        checkDelete = await checkDeleteUserGroup({ user_groups: data })
        config = {
          ...config,
          Content: () => renderCheckDeleteBody(checkDelete)
        }
        break
      case "removeMember":
        config = {
          ...config,
          Content: () => <div>
                  <div>Are you sure you want to proceed with the following changes?</div>
                  <ul>
                  { data.length > 0 &&
                    <li>{data.length + ' Users will be removed'} </li>
                  }
                  </ul>
                </div>
        }
        break;
      case "removeUserGroup":
        config = {
          ...config,
          Content: () => <div>
                  <div>Are you sure you want to proceed with the following changes?</div>
                  <ul>
                  { data.length > 0 &&
                    <li>{'User will be removed from ' + data.length + ' User Groups'} </li>
                  }
                  </ul>
                </div>
        }
        break;
      case "showRelationshipQuery":
        const relationshipQuery = await getRelationshipImportQuery(data)
        config = {
          ...config,
          customButtons: [{
            value: "Copy",
            valueClicked: "Copied!",
            tooltip: "Copy query to clipboard",
            onClick: () => { 
              copyToClipboard(relationshipQuery.query) 
            }
          }],
          contentProps: {
            data: relationshipQuery.query, 
            ref: refContentForm,
            readOnly: true
          },
          Content: QueryEditor
        }
        break
      case "showFilterQuery":
        const filterQuery = await getDatasourceImportQuery(data)
        config = {
          ...config,
          customButtons: [{
            value: "Copy",
            valueClicked: "Copied!",
            tooltip: "Copy query to clipboard",
            onClick: () => { 
              copyToClipboard(filterQuery.query) 
            }
          }],
          contentProps: {
            data: filterQuery.query, 
            ref: refContentForm,
            readOnly: true
          },
          Content: QueryEditor
        }
        break
      case "editImportQuery":
        config = {
          ...config,
          Content: QueryEditor
        }
        break
      case "editRelationshipQuery":
        config = {
          ...config,
          Content: QueryEditor
        }
        break
      case "showMessage":
        config = {
          ...config,
          Content: () => <div className="modal-container-body-text wide" ref={refContainerBody}>
                  <p>{data}</p>
                </div>
        }
        break
      case "editSettings":
        config = {
          ...config,
          Content: () => <div className="modal-container-body-text">
                  <p>WARNING!</p>
                  <p>Saving settings will reboot the backend API service. Katalogue will be unresponsible for all users until the reboot is finished. This normally takes a couple of seconds.</p>
                  <p>If the backend server fails to reboot due to corrupt setting values, update those directly in the public.setting table in the Katalogue repository database and reboot the backend service manually.</p>
                </div>
        }
        break
      default:
        break
    }
    
    setLoadingModalDialog(prev => false)
    setModalDialog({...defaultConfig, ...config})

    }, [])

  return { 
    showModalDialog, 
    hideModalDialog,
    show, 
    loadingModalDialog, 
    setLoadingModalDialog,
    modalDialog,
    setModalDialog
  }
};