import { 
  EditorState, 
  ContentState, 
  CompositeDecorator, 
  convertFromRaw,
  convertToRaw
} from 'draft-js'

import { findReferenceEntities, Reference, prismDecorator } from './decorators'



export const textareaInitialState = ({ value=undefined, readOnly=false, descriptions=[] }) => {

  var raw

  try {
    value = value && JSON.parse(value)
  } catch(e) {
    raw = value
  }

  if (value && typeof value === 'object') {

    return EditorState.createWithContent(
      convertFromRaw(value),
      new CompositeDecorator([{
        strategy: findReferenceEntities,
        component: Reference,
        props: {
          readOnly: readOnly,
          descriptions: descriptions
        }
      }]))
  } else if (raw) {
    return EditorState.createWithContent(
      ContentState.createFromText(raw),
      new CompositeDecorator([{
        strategy: findReferenceEntities,
        component: Reference,
        props: {
          readOnly: readOnly,
          descriptions: descriptions
        }
      }]))
  } else {
    return EditorState.createEmpty(
      new CompositeDecorator([{
        strategy: findReferenceEntities,
        component: Reference,
        props: {
          readOnly: readOnly,
          descriptions: descriptions
        }
      }]))
  }
}

export const textareaCodeInitialState = ({ value=undefined, readOnly=false }) => {

  var raw

  try {
    value = value && JSON.parse(value)
  } catch(e) {
    raw = value
  }

  if (value && typeof value === 'object') {
    return EditorState.createWithContent(convertFromRaw(value), prismDecorator)
  } else if (raw) {
    return EditorState.createWithContent(ContentState.createFromText(raw), prismDecorator)
  } else {
    return EditorState.createEmpty(prismDecorator)
  }
}

/* *********************************************
 * 
 * Convert a raw draftjs object represented as a string to plaintext
 * 
 * *********************************************/
export const textareaToPlainText = (value) => {
  
  let plainText

  try {
    value = value && JSON.parse(value)
  } catch(e) {
    plainText = value 
  }

  if (value && typeof value === 'object') {
    plainText = convertFromRaw(value).getPlainText()
  }
  return plainText
}

/* *********************************************
 * 
 * Convert a draftjs ContentState to a object represented as a string
 * 
 * *********************************************/
export const textareaToRaw = ({value}) => {
  const currentContent = value.getCurrentContent()

  return currentContent.getPlainText() !== "" ? JSON.stringify(convertToRaw(currentContent)) : ""
}

/* *********************************************
 * 
 * Convert a draftjs EditorState to a plaintext string
 * 
 * *********************************************/
export const editorStateToPlainText = (value) => {
  const currentContent = value.getCurrentContent()

  return currentContent.getPlainText()
}
