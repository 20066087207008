import React, { useState, useEffect } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useGlobalState, useSideDialog, useQueryParams } from '../hooks'

export const PrivateRoute = ({ element: Component, roles}) => {

  const [abortFetch, setAbortFetch] = useState(new AbortController())
  const [loggedInUser, setLoggedInUser] = useGlobalState('loggedInUser')
  const [isLoggedIn, setIsLoggedIn] = useGlobalState('isLoggedIn')

  let location = useLocation()
  const { hideSideDialog } = useSideDialog()


  useEffect( () => {

    // Cancel fetches
    return ( () => { 
      abortFetch.abort()
      hideSideDialog()
    })
  }, [])

  useEffect( () => {
    hideSideDialog()
  }, [Component])

  if (!isLoggedIn) {
    // not logged in so redirect to login page with the return url
    return <Navigate to='/login' state={{from: location}} replace={true} />
  }

  if (isLoggedIn && loggedInUser && loggedInUser.password_change_required) {
    // logged in but a password change is required so redirect to password change page
    return <Navigate to='/change_password' state={{from: location}} replace={true} />
  }

  // check if route is restricted by role
  if (roles && roles.indexOf(loggedInUser.user_role_name) === -1) {
    // role not authorised so redirect to home page
    return <Navigate to='/' replace={true} />
  }

  // authorised so return component
  return (
    <React.Fragment>
      <Component abortFetch={abortFetch} replace={true}/>
      
    </React.Fragment>
  )

}