import React, { useState, forwardRef } from 'react';
import { keyCode } from '../helpers';
import { Icon } from './';
import { useTooltip } from '../hooks'


const _Button = ({name, value, valueLoading, valueClicked, onClick, disabled=false, className, tooltip, buttonStyle, icon}, ref) => {

	const [loading, setLoading] = useState(false)
	const [clicked, setClicked] = useState(false)

  const {showTooltip, hideTooltip} = useTooltip()

  const handleKeyDown = (e) => {
    switch (e.keyCode) {
      case keyCode.ENTER:
      case keyCode.SPACE:
        !disabled && onClick();
        break;

      default:
        break;
    }
  }

  const handleClick = async (e) => {
  	if (!disabled && !loading && !clicked && onClick) {
  		setLoading(true)
	  	try {
	  			await onClick(e)
	  			if (valueClicked) {
	  				setClicked(true)
	  				setTimeout(() => setClicked(false), 1000)
	  			}	
	  	}
	  	catch {
	  		// assume errors are handled in onClick function
	  	}
	  	finally {
	  		setLoading(false)
	  	}
  	}
  }

	return (
		<div className={
				"CustomButton" + 
				(className ? " " + className : "" ) + 
				(disabled || loading || clicked ? " disabled" : "" ) + 
				(buttonStyle ? " " + buttonStyle : "" ) +
				(loading ? " loading" : "" ) +
				(clicked ? " clicked" : "" ) 
			}
		 	tabIndex={ 0 }
		 	onKeyDown={handleKeyDown}
		 	ref={ref}
		 	onMouseEnter={ () => tooltip && showTooltip({title: tooltip})} 
      onMouseLeave={ () => tooltip && hideTooltip() }
		 	name={ name }
     	onClick={ handleClick }
		>		
		{icon && <Icon name={icon} tooltip={tooltip} /> }
		<span>{loading && valueLoading ? valueLoading : clicked ? valueClicked : value}</span>
		</div>
	)
}

export const Button = forwardRef(_Button);