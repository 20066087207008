import React, { useState, useEffect } from 'react'
import { Route, Routes, Link, useLocation, useNavigate } from 'react-router-dom'
import { Role } from '../helpers'
import { authenticationService, settingService, displayNameService } from '../services'
import { PrivateRoute } from '../middleware'
import { 
  Breadcrumbs, 
  Icon, 
  Message, 
  ModalDialog, 
  SearchBar, 
  Photo, 
  Tooltip, 
  NavLink, 
  Page,
  SideDialog
} from '../components'
import { routesConfig } from '../routesConfig'
import { ChangePassword, Login, LoadApp, NotFound, ForgotPassword, ResetPassword } from './'
import packageJson from '../../package.json';
import { useGlobalState, useMessage, useModalDialog } from '../hooks';

export const App = () => {
  return (
    <div className="App-wrapper">
      <_App />
      <SideDialog />
      <ModalDialog />     
      <Message />
      <Tooltip />
    </div>
  )
}

const _App = () => {

  const [loading, setLoading] = useState(true)
  const [loggedInUser, setLoggedInUser] = useGlobalState('loggedInUser')
  const [isLoggedIn, setIsLoggedIn] = useGlobalState('isLoggedIn')
  const [csrfToken, setCsrfToken] = useGlobalState('csrfToken')
  const [settings, setSettings] = useGlobalState('settings')
  const [displayNames, setDisplayNames] = useGlobalState('displayNames')
  const [showNavMenu, setShowNavMenu] = useState(true)

  const { showMessage, hideMessage } = useMessage()
  const { show } = useModalDialog()

  const location = useLocation()
  const navigate = useNavigate()

  useEffect( () => {
    // Initialize app
    initialize()
  }, [])

  const initialize = async () => {

    try {

      Promise.all([
        // Get settings from backend
        await getSettings()
        ,
        // Get display names from backend
        await getDisplayNames()
        ,
        // Get login state
        await getLoginState()
      ])
      .catch(e => { throw e })
      
      // Get Nav menu state from local storage
      setShowNavMenu(localStorage.getItem('showNavMenu') === "false" ? false : true)

      setLoading(false)

    } catch (err) {
      setLoading(false)
      showMessage(err, 'error', true)
    }
  }

  const getSettings = async () => {
    try {
      let settings = await settingService.getAllForFrontend()

      settings = settings.settings
      settings = {
        ...settings,
        authMethods: [
          ...(settings.OIDC_IS_ENABLED  ? ['oidc'] : []),
          ...(settings.LOCAL_IS_ENABLED ? ['local'] : [])
        ]
      }
      setSettings(settings)
    } catch(err) {
      throw err
    }
  }

  const getDisplayNames = async () => {
    try {
      let result = await displayNameService.getAllForFrontend()

      result = result.display_names
      
      const obj = result.reduce((accumulator, value) => {
        return {
          ...accumulator, 
          [value.display_name_code]: {
            display_name: value.display_name, 
            display_name_alternative_name: value.display_name_alternative_name,
            display_name_description: value.display_name_description
          }};
      }, {});

      setDisplayNames(obj)
    } catch(err) {
      throw err
    }
  }

  const getLoginState = async () => {
    try {
      const loginState = await authenticationService.endLogin(window.location.href, undefined)

      if (loginState.userInfo) {
        setLoggedInUser(loginState.userInfo)
      } 

      if (loginState.csrf) {
        setCsrfToken(loginState.csrf)
      } 

      if (loginState.loginHandled) {

        const state = JSON.parse(loginState.state)

        /// Restore url
        navigate({...state.from, ...state})

      }

      setIsLoggedIn(loginState.isLoggedIn)

    } catch(err) {
      throw err
    }
  }

  const login = async ({username, password, state}) => {
    try {

      const loginState = await authenticationService.login(username, password)

      if (loginState.userInfo) {
        setLoggedInUser(loginState.userInfo)
      } 

      if (loginState.csrf) {
        setCsrfToken(loginState.csrf)
      } 

      if (loginState.loginHandled) {

        setIsLoggedIn(loginState.isLoggedIn)
        hideMessage()
        
        // Restore url
        navigate({...state.from, ...state})

      }

    } catch (e) {
      // Errors handled in Login.js
      throw e  
    }
  }

  const logout = async () => {
    try {
      const logout = await authenticationService.logout()

      const tmpUser = loggedInUser
      setIsLoggedIn(false)
      setLoggedInUser({})

      hideMessage()
      
      if (tmpUser.user_auth_method !== 'local') {
        location.href = logout.logoutURL
      }

    } catch(e) {
      showMessage(e, 'error')
    }
  }

  const toggleNavMenu = () => {
    const state = !showNavMenu

    setShowNavMenu(state)

    // Persist state in local storage
    localStorage.setItem('showNavMenu', state.toString())
  }

  const navMenuState = ((showNavMenu) ? 'show' : '');

  return (
    <div className={'App' + (show ? " filter-blur" : "")}>
      { isLoggedIn && 
        !( location.pathname === "/change_password" || location.pathname === "/login") && 
        !loading &&

        <div className={ "navbar " + navMenuState }>
          
          <div className="navbar-header">
            <Link to="/">
              <Icon name="katalogue-white" tooltip='Go to start page'/>
              <div className="logo-text">katalogue</div>
            </Link>
          </div>
          
          <div className="navbar-content">

            {
            /*<div className="navbar-group">
              <NavLink end to="/" className={ ({ isActive }) => "nav-link home" + (isActive ? " active" : "") } tooltip="Home"><Icon name="home"/><span>Home</span></NavLink>
            </div>*/
            }

            <div className="navbar-group">
              <div className="navbar-group-title grey-text"><span>BROWSE</span></div>
              <NavLink to="/browse_datasets" className={ ({ isActive }) => "nav-link datasets" + (isActive ? " active" : "") } tooltip="Browse Datasets"><Icon name="datasets"/><span>Datasets</span></NavLink>
              <NavLink to="/browse_field_descriptions" className={ ({ isActive }) => "nav-link field-descriptions" + (isActive ? " active" : "") } tooltip=" Browse Field Descriptions"><Icon name="field_descriptions"/><span>Field Descriptions</span></NavLink>
              <NavLink to="/browse_business_terms" className={ ({ isActive }) => "nav-link business-terms" + (isActive ? " active" : "") } tooltip="Browse Business Terms"><Icon name="business_terms"/><span>Business Terms</span></NavLink>
            </div>
            
            { loggedInUser && loggedInUser.user_role_name === Role.admin  &&
            <div className="navbar-group">
              <div className="navbar-group-title grey-text"><span>MANAGE</span></div>
              <NavLink to="/users" className={ ({ isActive }) => "nav-link" + (isActive ? " active" : "") } tooltip="Manage Users"><Icon name="users"/><span>Users</span></NavLink>
              <NavLink to="/user_groups" className={ ({ isActive }) => "nav-link" + (isActive ? " active" : "") } tooltip="Manage User Groups"><Icon name="user_groups"/><span>User Groups</span></NavLink>
              <NavLink to="/tasks" className={ ({ isActive }) => "nav-link" + (isActive ? " active" : "") } tooltip="Manage Tasks"><Icon name="tasks"/><span>Tasks</span></NavLink>
              <NavLink to="/field_units" className={ ({ isActive }) => "nav-link" + (isActive ? " active" : "") } tooltip="Manage Field Units"><Icon name="field_units"/><span>Field Units</span></NavLink>
              <NavLink to="/settings" className={ ({ isActive }) => "nav-link" + (isActive ? " active" : "") } tooltip="Manage Settings"><Icon name="settings"/><span>Settings</span></NavLink>
            </div>
            }

          </div>
          <div className="navbar-footer">
            <div className="navbar-toggle-button" onClick={ toggleNavMenu } title={ (showNavMenu ? "Minimize" : "Expand") + " navigation menu"}><span></span></div>
          </div>
        </div>
      }
      <div className="main-container">
        { isLoggedIn && 
          !( location.pathname === "/change_password" || location.pathname === "/login") && 
          !loading &&

        <div className="main-content-header-wrap">
          <div className={"main-content-header" + (location.pathname === "/" ? " home" : "")} >
            
            <Breadcrumbs />
            
            <div className="logo"></div>
            
            <div className="menu">

              { location.pathname !== "/" &&
                <div className="menu-item">
                  <div className="menu-button">
                    <SearchBar />
                  </div>
                </div>
              }
              <div className="menu-item">
                <div className="menu-button">
                  <div className="about-icon">i</div>
                </div>
                <div className="menu-content">
                  <div className="about-logo"></div>
                  <div className="about-version">Version {packageJson.version}</div>
                  <div className="about-copyright">©{new Date().getFullYear()} <a href="http://www.kayenta.se" rel="noopener noreferrer" target="_blank">Kayenta Consulting AB</a>.<br/>All rights reserved</div>
                </div>
              </div>

              <div className="menu-item last">
                <div className="menu-button">
                  <span className="user-name">
                    <Photo
                      size='small'
                      data={ loggedInUser.user_photo }
                      loading={ loading }
                    />
                  </span>
                </div>
                <div className="menu-content"> 
                  <div className="user-profile">
                  <Photo
                    size='medium'
                    data={ loggedInUser.user_photo }
                    loading={ loading }
                  />
                    <div className="user-profile-info">
                      <div className="user-profile-name">{loggedInUser.user_fullname}</div>
                      {loggedInUser.user_title &&
                        <div className="user-profile-title">{loggedInUser.user_title}</div>
                      }
                      <div className="user-profile-username">{loggedInUser.user_username}</div>
                    </div>
                  </div>
                  <div className={loggedInUser.user_auth_method !== 'local' ? "horizontal" : ""}>
                    <Link className="menu-link" to={ "/profile" }><Icon name="profile"/><span>Profile</span></Link>
                    { loggedInUser.user_auth_method === 'local' && 
                      <Link className="menu-link" to={ "/change_password" }><Icon name="password"/><span>Change Password</span></Link>
                    }
                    <div className="menu-link" onClick={ logout }><Icon name="signout"/><span>Sign Out</span></div>
                  </div>
                </div>
              </div>

            </div>

          </div>
        </div>
        }
        <div className="main-content">
          { loading 
            ? <LoadApp />
            : 
              <Routes>

                <Route path="/login" element={
                  <Page title="Login" element = {
                    <Login
                      login={login} />
                    }/>
                }/>

                { settings.MAIL_IS_ENABLED && // Only enable these endpoints if mailing is enabled
                  <React.Fragment>
                    <Route path="/forgot_password" element={
                      <Page title="Forgot Password" element = {
                        <ForgotPassword />
                      }/>
                    }/>
                    <Route path="/reset_password" element={
                      <Page title="Reset Password" element = {
                        <ResetPassword />
                      }/>
                    }/>
                  </React.Fragment>
                }

                <Route path="/change_password" element={
                  <Page title="Change Password" element = {
                    <ChangePassword />
                  }/>
                }/>
                
                {routesConfig.map(({ path, name, element, roles, title, objectName }, key) => (
                  <Route key={key} path={path} element={
                      <Page title={title} objectName={objectName} element={
                        <PrivateRoute 
                          roles={ roles }
                          element={ element }          
                        />
                      }/>
                    }
                  />
                ))}
                
                <Route path='*' element={
                  <Page title="Not Found" element = {
                    <NotFound />
                  }/>
                }/>

              </Routes>
              
          }
        </div>
      </div>     
    </div>
  )
}