import React, { useState, useEffect } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { fieldService, fieldDescriptionService, relationshipService, referenceService } from '../services';
import { FieldSensitivity, formatConnectionString, handleError, Role, Asset } from '../helpers';
import { 
  Changelog,
  DataTable, 
  Icon, 
  MainColumn, 
  ObjectDetails,   
  SkeletonLoader, 
  Tabs, 
  TextareaReadOnly, 
  textareaInitialState } from '../components';
import { 
  useSideDialog, 
  useModalDialog,
  useQueryParams,  
  useGlobalState, 
  useNotFound, 
  useMessage } from '../hooks';

export const Field = ({ abortFetch }) => {

  const { queryParams, resetParams } = useQueryParams()

  const [field, setField] = useState([]);
  const [fieldDescription, setFieldDescription] = useState({});
  const [fromReferences, setFromReferences] = useState([]);
  const [relationships, setRelationships] = useState([]);
  const [loggedInUser, setLoggedInUser] = useGlobalState('loggedInUser');
  const [loading, setLoading] = useState(true);
  const [loadingTable, setLoadingTable] = useState(true);
  const [defaultSelectedTab, setDefaultSelectedTab] = useState({tab: queryParams.tab})

  const { system_id, datasource_id, dataset_group_id, dataset_id, field_id } = useParams()
  const navigate = useNavigate()

  const { showMessage } = useMessage()
  const { showSideDialog, hideSideDialog } = useSideDialog()
  const { showModalDialog, hideModalDialog } = useModalDialog()
  const { notFound, setNotFound, NotFound } = useNotFound()


  useEffect( () => {

    fetchItems()
    fetchRelationships()

  }, [field_id]);

  const fetchFields = async () => {
    
    await fieldService.getById(field_id, abortFetch)
      .then(res => {
        if (res.fields.length < 1) {
          setNotFound(true)
        } else {
          setField(res.fields[0]);
          if (res.fields[0].field_description_id) {
            fetchFromReferences(res.fields[0].field_description_id)
          }
        }
      })
      .catch(err => {
        handleError({err, showMessage})
      })
  }

  const fetchFieldDescriptions = async () => {
    
    await fieldDescriptionService.getByFieldId(field_id, abortFetch)
      .then(res => {

        res && setFieldDescription(res.field_descriptions[0]);

      })
      .catch(err => {
        handleError({err, showMessage})
      })
  }

  const fetchItems = () => {
    
    setLoading(true)

    Promise.all([fetchFields(),fetchFieldDescriptions()])
    .then(res => {
      setLoading(false)
    })
   .catch(err => {
      handleError({err, showMessage}, () => {
        setLoading(false)
      })
    })    
  }

  const fetchFromReferences = async (fieldDescriptionId) => {

    await referenceService.getByFromId(Asset.FieldDescription, fieldDescriptionId, abortFetch)
      .then(res => {

        res && setFromReferences(res.references);
         
      })
      .catch(err => {
        handleError({err, showMessage})
      })
  }

  const fetchRelationships = async () => {
    setLoadingTable(true)
    await relationshipService.getByObjectId(Asset.Field, field_id, abortFetch)
      .then( result => {

          result && setRelationships(formatRelationships(result.relationships))
          setLoadingTable(false)
      })
      .catch(err => {
        handleError({err, showMessage}, () => {
          setLoadingTable(false)
        })
      }) 
  }

const formatRelationships = (data) => {
  return data.map(item => {

    if (parseInt(field_id) === item.from_field_id) {
      return {
        relationship_type:           item.relationship_type,
        direction_icon:              'arrow_right',
        direction:                   item.from_field_name + ' references the related field',
        dataset_name:                item.from_dataset_name,
        datatype_category:           item.from_datatype_category,
        datatype_fullname:           item.from_datatype_fullname,
        field_name:                  item.from_field_name,
        dataset_description:         item.from_dataset_description,
        datatype_name:               item.from_datatype_name,
        field_description_description:          item.from_field_description_description,
        field_id:                    item.from_field_id,
        dataset_id:                  item.from_dataset_id,
        dataset_group_id:            item.from_dataset_group_id,
        datasource_id:               item.from_datasource_id,
        system_id:                   item.from_system_id,   
        related_dataset_name:        item.to_dataset_name,
        related_dataset_type_name:   item.to_dataset_type_name,
        related_datatype_category:   item.to_datatype_category,
        related_datatype_fullname:   item.to_datatype_fullname,
        related_field_name:          item.to_field_name,
        related_dataset_description: item.to_dataset_description,
        related_datatype_name:       item.to_datatype_name,
        related_field_description_description:   item.to_field_description_description,
        related_dataset_group_name:  item.to_dataset_group_name,
        related_datasource_name:     item.to_datasource_name,
        related_system_name:         item.to_system_name,
        related_field_id:            item.to_field_id,
        related_dataset_id:          item.to_dataset_id,
        related_dataset_group_id:    item.to_dataset_group_id,
        related_datasource_id:       item.to_datasource_id,
        related_system_id:           item.to_system_id, 
      }
    } else {
      return {
        relationship_type:           item.relationship_type,
        direction_icon:              'arrow_left',
        direction:                   'The related field references ' + item.to_field_name,
        dataset_name:                item.to_dataset_name,
        datatype_category:           item.to_datatype_category,
        datatype_fullname:           item.to_datatype_fullname,
        field_name:                  item.to_field_name,
        dataset_description:         item.to_dataset_description,
        datatype_name:               item.to_datatype_name,
        field_description_description:          item.to_field_description_description,
        field_id:                    item.to_field_id,
        dataset_id:                  item.to_dataset_id,
        dataset_group_id:            item.to_dataset_group_id,
        datasource_id:               item.to_datasource_id,
        system_id:                   item.to_system_id, 
        related_dataset_name:        item.from_dataset_name,
        related_dataset_type_name:   item.from_dataset_type_name,
        related_datatype_category:   item.from_datatype_category,
        related_datatype_fullname:   item.from_datatype_fullname,
        related_field_name:          item.from_field_name,
        related_dataset_description: item.from_dataset_description,
        related_datatype_name:       item.from_datatype_name,
        related_field_description_description:   item.from_field_description_description,
        related_dataset_group_name:  item.from_dataset_group_name,
        related_datasource_name:     item.from_datasource_name,
        related_system_name:         item.from_system_name,
        related_field_id:            item.from_field_id,
        related_dataset_id:          item.from_dataset_id,
        related_dataset_group_id:    item.from_dataset_group_id,
        related_datasource_id:       item.from_datasource_id,
        related_system_id:           item.from_system_id, 
      }
    }
  })
}

  const editField = async data => {
    const fields = {fields: (Array.isArray(data) ? data : [data])}
    
    await fieldService.update(fields)
      .then(async res => { 

        fetchItems()
        showMessage(res.message)
        hideSideDialog()
        
      })
      .catch(err => {
        handleError({err, showMessage})
        throw err
      })
  }

  const deleteField = async (data) => {    
    await fieldService.destroy({fields: data})
      .then(async res => { 

        showMessage(res.message)
        hideModalDialog()

        // Redirect to parent page
        navigate(`/browse_datasets/${system_id}/${datasource_id}/${dataset_group_id}/${dataset_id}`)

      })
      .catch(err => {
        handleError({err, showMessage})
      })
  }

  const getKeyIcon = () => {
    if (data.is_primary_key || relationships.find(x => x.field_id === data.field_id && x.relationship_type !== 'virtual' && x.direction_icon === 'arrow_left')) {
      return <Icon name="primary key" tooltip="primary key" />
    } else if (relationships.length > 0) {
      return <Icon name="foreign key" tooltip="foreign key" />
    } else {
      return null
    }
  }

  const data = loading ? [] : field

  const editable = loggedInUser && (loggedInUser.user_role_name === Role.admin || loggedInUser.user_role_name === Role.editor)

  if (notFound) {
    return <NotFound />
  }

  return (
    <div className="columns narrow-margin">
      <MainColumn>
        <ObjectDetails
          type="Field"
          title={ !loading && <span>{data.field_name} <span className="light-text">{ (!fieldDescription) ? "" : fieldDescription.field_unit_code }</span></span> }
          subtitleIcon={[getKeyIcon(),<Icon name={data.datatype_category} tooltip={ data.datatype_fullname } />]}
          subtitleText={data && !loading && 
            formatConnectionString(
              data.datasource_database, 
              data.datasource_hostname, 
              [data.dataset_group_source_name, data.dataset_source_name, data.field_name], 
              data.datasource_type_code, 
              data.datasource_type_category)
            }
          description={data.field_description_description}
          fromReferences={fromReferences}
          loading={loading}
          >

            { !loading && data && data.field_description_definition &&
              <React.Fragment>
                <h3>{ !loading ? "Definition" : <SkeletonLoader width="5vw"/> }</h3>
                <div className="display-linebreak">
                { !loading 
                  ? (!(fieldDescription && fieldDescription.field_description_definition)) 
                    ? <span className="no-result-text">No definition available</span> 
                    : <TextareaReadOnly
                        value={ textareaInitialState({value: fieldDescription.field_description_definition, readOnly: true, descriptions: fromReferences }) } 
                      />
                  : <SkeletonLoader width="10vw" count={2} />
                }
                </div>
              </React.Fragment>
            }
            { !loading && data && data.field_description_example &&
              <React.Fragment>
                <h3>{ !loading ? "Example" : <SkeletonLoader width="5vw"/> }</h3>
                <div className="display-linebreak">
                { !loading 
                  ? (!(fieldDescription && fieldDescription.field_description_example)) 
                    ? <span className="no-result-text">No example available</span> 
                    : <TextareaReadOnly
                        value={ textareaInitialState({value: fieldDescription.field_description_example, readOnly: true, descriptions: fromReferences }) } 
                      />
                  : <SkeletonLoader width="10vw" count={2} />
                }</div>
              </React.Fragment>
            }
            { !loading && data && data.field_sensitivity_name &&
              <React.Fragment>
                <h3>{ !loading ? "Data Sensitivity" : <SkeletonLoader width="5vw"/>}</h3>
                <div className="display-linebreak">
                { !loading
                  ? (!data.field_sensitivity_name) 
                    ? <span className="no-result-text">Sensitivity class not set</span> 
                    : <React.Fragment>
                        {data.is_pii && <Icon name="pii" tooltip="Classified as Personally Identifiable Information (PII)"/>}
                        <Icon name="field_sensitivity" value={data.field_sensitivity_level} values={FieldSensitivity} />
                      </React.Fragment>
                  : <SkeletonLoader width="5vw" />
                }</div>
              </React.Fragment>
            }
            { !loading && data && data.field_description_id &&
              <React.Fragment>
                <h3>{ !loading ? "Field Description Name" : <SkeletonLoader width="5vw"/> }</h3>
                <div className="display-linebreak">
                { !loading 
                  ? (!data.field_description_id) 
                    ? <span className="no-result-text">No associated Field Description</span> 
                    : <Link to={"/browse_field_descriptions/"+data.field_description_id} className="link">
                        <Icon name={fieldDescription.field_role_name} tooltip={fieldDescription.field_role_name} />{ data.field_description_name }
                      </Link>
                  : <SkeletonLoader width="5vw%" />
                }
                </div>
              </React.Fragment>
            }
          </ObjectDetails>

          { !loading && editable &&
          <div className="main-toolbar">
            <button type="button" className="main-toolbar-item button main-button" onClick={ () => showSideDialog('editField', [data], editField) }><span>EDIT</span></button>
            <button type="button" className="main-toolbar-item button" onClick={ () => showModalDialog('deleteField', [data], deleteField) }><span>DELETE</span></button>
          </div>
          }

      </MainColumn>

      <div className="column">
        <Tabs 
          className="slim left"
          //onTabChange={ onTabChange } 
          defaultSelectedTab={ defaultSelectedTab }
          disableTabsWithoutResults={ true }
        >

          <div label="Relationships" tabid="relationships">

            <DataTable
              columns={[
                {id: 'direction_icon', name:'', type: 'icon', tooltip: 'direction'},
                {id: 'related_datatype_category', name: '', type: 'icon', tooltip: 'related_datatype_fullname'},
                {id: 'related_field_name', name: 'Related Field', link: '/browse_datasets/:related_system_id/:related_datasource_id/:related_dataset_group_id/:related_dataset_id/:related_field_id', tooltip: 'related_field_description_description'},
                {id: 'related_dataset_type_name', name: '', type: 'icon', tooltip: 'related_dataset_type_name'},
                {id: 'related_dataset_name', name: 'Related Dataset', link: '/browse_datasets/:related_system_id/:related_datasource_id/:related_dataset_group_id/:related_dataset_id', tooltip: 'related_dataset_description'},
                {id: 'related_dataset_group_name', name: 'Related Dataset Group', link: '/browse_datasets/:related_system_id/:related_datasource_id/:related_dataset_group_id'},
                {id: 'related_datasource_name', name: 'Related Datasource', link: '/browse_datasets/:related_system_id/:related_datasource_id'},
                {id: 'related_system_name', name: 'Related System', link: '/browse_datasets/:related_system_id'},
                {id: 'relationship_type', name:'Relationship Type'},
              ]}
              data={relationships}
              idColumn='related_field_id'
              editable={false}
              loading={loadingTable}
              filterable={true}
              filterObjects={['related_field_name', 'related_dataset_name', 'related_dataset_group_name', 'related_datasource_name', 'related_system_name', 'relationship_type']}

            />
      
          </div>

          <div label="Changelog" tabid="changelog">
            <Changelog 
              objectName={Asset.Field} 
              objectId={field_id} 
              filterObjects={[
                'changedData.new_data||old_data.field_name', 
                'changedData.new_data||old_data.field_description_name', 
                'changedData.new_data||old_data.datatype_name', 
                'changedData.new_data||old_data.dataset_name', 
                'changedData.new_data||old_data.field_technical_definition', 
                'changedData.new_data||old_data.is_primary_key', 
                'changedData.attribute',
                'changed_by_user_username',
                'is_service_account_user'
              ]}
              filterObjectsValueMap={{is_service_account_user: {true: {label:'Datasource Sync'}, false: {label:''}},
                                      is_primary_key: {true: {label:'Primary Key'}, false: {label:''}}}}
            />
          </div>

        </Tabs>
      </div>

    </div>
  ) 
}
