import React, { createContext, useState, useMemo } from "react";

export const MessageContext = createContext();

export const MessageProvider = ({ children }) => {
  const [message, setMessage] = useState({})
  const [show, setShow] = useState(false)

  const value = useMemo( () => (
    { 
      message, 
      setMessage,
      show, 
      setShow 
    }
  ), [
    message, 
    setMessage,
    show, 
    setShow 
  ])

  return (
    <MessageContext.Provider
      value={value}
    >
      {children}
    </MessageContext.Provider>
  );
}