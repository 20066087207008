import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import { authenticationService, userService } from '../services'
import { Input, FullscreenMessage } from '../components'
import { useGlobalState, useMessage } from '../hooks'

export const ChangePassword = ( props) => {
  
  const initialFormState = {
    old_password: '', 
    new_password: '',
    confirm_new_password: ''
  }

  const [formData, setFormData] = useState(initialFormState)
  const [loggedInUser, setLoggedInUser] = useGlobalState('loggedInUser');
  const [isLoggedIn, setIsLoggedIn] = useGlobalState('isLoggedIn');
  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState(false)
  const [time, setTime] = useState(5)
  const [startTimer, setStartTimer] = useState(false)

  const { message, showMessage, hideMessage } = useMessage()
  const navigate = useNavigate()

  useEffect(() => {

    // Redirect to start page automatically when timer reached 0
    if (time === 0) { 
      goToLogin()
    }  
  }, [time]);


  useEffect(() => {

    // Start timer for auto redirect
    if (startTimer) {
      setInterval(() => {setTime(prev => prev-1)}, 1000);
    }
  }, [startTimer]);

  const handleInputChange = (event) => {
    const { value, name } = event.target

    hideMessage()
  
    setFormData({ ...formData, [name]: value })
  }

  const onSubmit = async (event) => {

    event.preventDefault();
    setLoading(true)
    hideMessage()
    
    // Validation
    let hasError = false
    let error = []

    if (formData.new_password.length < 12) {
      hasError = true
      error = [...error,
      {
        msg: "Password must be at least 12 characters long"
      }]
    }
    if (formData.new_password !== formData.confirm_new_password) {
      hasError = true
      error = [...error,
      {
        msg: "New passwords does not match"
      }]
    }
    if (formData.new_password === formData.old_password) {
      hasError = true
      error = [...error,
      {
        msg: "New password cannot be the same as the old one"
      }]
    }

    if (hasError) {
      showMessage(
        {dataValidationError: error}, 
        "error",
        true
      )
      setLoading(false)
      return null
    }

    const users = {
      user_username: loggedInUser.user_username,
      old_password: formData.old_password,
      new_password: formData.new_password
    }
    
    await userService.changePassword(users)
      .then(res => {
        setSuccess(true)
        setStartTimer(true)
        authenticationService.logout()
        return res
      })
      .then(res => { 
        setLoggedInUser()
        setIsLoggedIn()
      })
      .catch(error => {
        showMessage(
          error, 
          "error",
          true
        )
      })
      .finally(res => {
        setLoading(false)
      })

  }

  const goToLogin = () => {
    navigate('/')
  }
  
  const validateNewPassword = () => {
    if (formData.new_password.length > 0) {
      if (formData.new_password.length < 12) {
        return { valid: false, message: "Password must be at least 12 characters long"} 
      }

      if (formData.new_password === formData.old_password) {
        return { valid: false, message: "New password cannot be the same as the old one"} 
      }

      return { valid: true, message: "Password ok"} 
    }
  }

  return (
    <div className="ChangePassword fullscreen">
      <div className="fullscreen-content">
      
      { (loggedInUser && !loggedInUser.password_change_required) &&
      <div className="close-button" onClick={ () => navigate(-1) }>
        <div className="close-button-label">Cancel</div>
        <div className="close-button-icon"></div>
      </div>
      }

      <div className="fullscreen-logo"></div>

      { success 
        ? 
          <React.Fragment>
            <h1>Password Changed!</h1>
            <p>&nbsp;</p>
            <p>Redirecting to login page in {time} seconds...</p>
            <p>&nbsp;</p>
            <button type="button" className="button main-button" onClick={goToLogin}><span>Go to Login</span></button>
          </React.Fragment>
      : 
        <React.Fragment>
          <h1>{ (loggedInUser && loggedInUser.password_change_required) ? "Please change your password to proceed" : "Change password"}</h1>
          <p>&nbsp;</p>
          <form onSubmit={onSubmit}>
            <Input
              type="password"
              name="old_password"
              className="large"
              label="Old password"
              labelAsPlaceholder={true}
              value={formData.old_password}
              onChange={handleInputChange}
              disabled={loading}
              autoComplete="new-password"
              required={true}
              preventEnterDefault={false}
            />
            <Input
              type="password"
              name="new_password"
              className="large"
              label="New password"
              labelAsPlaceholder={true}
              value={formData.new_password}
              onChange={handleInputChange}
              disabled={loading}
              autoComplete="new-password"
              required={true}
              validation={ validateNewPassword() }
              preventEnterDefault={false}
            />
            <Input
              type="password"
              name="confirm_new_password"
              className="large"
              label="Confirm new password"
              labelAsPlaceholder={true}
              value={formData.confirm_new_password}
              onChange={handleInputChange}
              disabled={loading}
              autoComplete="new-password"
              validation={ formData.confirm_new_password.length > 0 && (
                (formData.confirm_new_password !== formData.new_password) 
                  ? { valid: false, message: "Passwords does not match"} 
                  : { valid: true, message: "Passwords match"} 
                )}
              preventEnterDefault={false}
            />
            
            <p>&nbsp;</p>
            
            <button 
              type="submit" 
              className={"button large-button" + (loading ? ' loading' : '')}
              disabled={loading}
              ><span>{loading ? 'Saving...' : 'Save'}</span></button>
          </form>

          <FullscreenMessage message={message} />

        </React.Fragment>
      }

      </div>
    </div>
  )

}