import React from 'react';
import { useGlobalState } from '../../../hooks';
import { formatChangelogValue } from '../helpers'


export const ChangelogItemRow = ({item, columns, idColumn}) => {

  const [displayNames] = useGlobalState('displayNames')

  return (
                
    <tr className="row" >
      <td className={columns[0].className}>
        {item.attribute === idColumn ? 'Id' : displayNames[item.attribute]?.display_name || item.attribute}
      </td>
      <td className={columns[1].className}>
        {formatChangelogValue('new', item)}
      </td>
      <td className={columns[2].className}>
        {formatChangelogValue('old', item)}
      </td>
    </tr>

  );
};