import React, { useState, useEffect, useRef } from 'react';
import { 
  Editor, 
  EditorState, 
  RichUtils, 
  Modifier, 
  getDefaultKeyBinding, 
  KeyBindingUtil, 
} from 'draft-js'
import { searchService } from '../../services';
import { useMessage } from '../../hooks';
import { FormatMenu, TextareaSuggestions } from './components'
import { 
  SUGGESTION_TRIGGER,
  ENTER_KEY_CODE,
  TAB_KEY_CODE,
  DOWN_ARROW_KEY_CODE,
  UP_ARROW_KEY_CODE,
  ESCAPE_KEY_CODE,
  A_KEY_CODE,
  styleMap
} from './helpers'
import './Textarea.css';



export const Textarea = ({ name, value, onChange, placeholder=undefined, onBlur=undefined, disabled=false, readOnly=false  }) => {

  const editorState = value

  const {hasCommandModifier} = KeyBindingUtil;

  const [showFormatMenu, setShowFormatMenu] = useState(false)
  const [showSuggestions, setShowSuggestions] = useState(false)
  const [triggerCount, setTriggerCount] = useState(0) 
  const [enableSuggestions, setEnableSuggestions] = useState(true) 
  const [searchTerm, setSearchTerm] = useState(null)
  const [searchResults, setSearchResults] = useState([])
  const [selectedSuggestionIndex, setSelectedSuggestionIndex] = useState(0)
  const [loading, setLoading] = useState(false)

  const editorRef = useRef()
  const firstTimeRender = useRef(true)

  const {showMessage} = useMessage()

  useEffect(() => {    

    const currentSelectionState = editorState.getSelection();
    const anchorKey = currentSelectionState.getAnchorKey();
    const currentContent = editorState.getCurrentContent();
    const currentBlock = currentContent.getBlockForKey(anchorKey);

   if (!firstTimeRender.current) {

      const triggerRange = getTriggerRange(SUGGESTION_TRIGGER)

      if (triggerRange === null) {

        closeSuggestions()
        return
      }

      if (!enableSuggestions) {
        return
      }

      if ( document.activeElement !== editorRef.current.editor) return

      setShowSuggestions(true)

      setSearchTerm( triggerRange.text.slice(1, triggerRange.text.length) )
    }

  }, [editorState])

  useEffect(() => { 

    if (showSuggestions) {
      fetchSearchResults(searchTerm)
    }

  }, [searchTerm])

  useEffect(() => { 
    firstTimeRender.current = false 
  }, [])

  const handleChange = (val) => {
    onChange({name: name, value: val})
    //setEditorState(val)
  }

  const handleBlur = (state) => {
    closeSuggestions()
    setShowFormatMenu(false)

    if (onBlur) { // execute onBlur prop function if provided 
        onBlur()
      }
  }

  const handleFocus = (state) => {
    setShowFormatMenu(true)
  }


/* ----------*
* Utils
------------*/
  const getTriggerRange = (trigger) => {

    const selection = window.getSelection();
    if (selection.rangeCount === 0) return null;
    const range = selection.getRangeAt(0);
    const text = range.startContainer.textContent.substring(0, range.startOffset);
    
    //if (/\s+$/.test(text)) return null;

    const index = text.lastIndexOf(trigger);
    if (index === -1) return null;

    const count = (text.match(/@/g) || []).length;
    if (count !== triggerCount) {
      setEnableSuggestions(true)
      setTriggerCount(count)
    }

    return {
      text: text.substring(index),
      start: index,
      end: range.startOffset,
    };
  };

  const getInsertRange = () => {
    const currentSelectionState = editorState.getSelection();
    const end = currentSelectionState.getAnchorOffset();
    const anchorKey = currentSelectionState.getAnchorKey();
    const currentContent = editorState.getCurrentContent();
    const currentBlock = currentContent.getBlockForKey(anchorKey);
    const blockText = currentBlock.getText();
    const start = blockText.substring(0, end).lastIndexOf(SUGGESTION_TRIGGER);

    return {
      start,
      end,
    };
  };


/* ----------*
* Modifiers
------------*/
  const addReference = (reference) => {
    /* 1 */
    const { start, end } = getInsertRange();

    /* 2 */
    const currentSelectionState = editorState.getSelection();
    const selection = currentSelectionState.merge({
      anchorOffset: start,
      focusOffset: end,
    });

    /* 3 */
    const contentState = editorState.getCurrentContent();
    const contentStateWithEntity = contentState.createEntity(
      'REFERENCE',
      'IMMUTABLE',
      { reference: {
          object_id: reference.object_id,
          object_name: reference.object_name
        }
      },
    );
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();

    /* 4 */
    let newContentState = Modifier.replaceText(
      contentStateWithEntity,
      selection,
      `${reference.name}`,
      null,
      entityKey,
    );

    /* 5 */
    const newEditorState = EditorState.push(
      editorState,
      newContentState,
      `insert-reference`,
    );

    return EditorState.forceSelection(
      newEditorState,
      newContentState.getSelectionAfter(),
    );
  };

  const renderSuggestion = (index) => {
    
    const text = searchResults[index]
    handleChange(
      addReference(text) 
    )
    
    closeSuggestions()
    
  }

  const closeSuggestions = () => {
    setShowSuggestions(false)
    setSearchTerm(null)
    setSearchResults([])
  }

  const disableSuggestions = () => {
    closeSuggestions()
    setEnableSuggestions(false)
  }

  /*const myBlockRendererFn = (contentBlock) => {
    const type = contentBlock.getType();
    if (type === 'REFERENCE') {
      return {
        component: Reference,
        editable: false,
        props: {
          foo: 'bar',
        },
      };
    }
  }*/

  const myKeyBindingFn = e => {

    switch (e.keyCode) {
      case ENTER_KEY_CODE:
        if (showSuggestions) {
          return 'enter'
        }
        if (e.nativeEvent.shiftKey) {
          return 'shift-enter'
        } 
        break;

      case TAB_KEY_CODE:
        
        if (showSuggestions) return 'tab';
        break;

      case DOWN_ARROW_KEY_CODE:
        return 'down-arrow';
        break;

      case UP_ARROW_KEY_CODE:
        return 'up-arrow';
        break;

      case ESCAPE_KEY_CODE:
        return 'escape';
        break;

      case A_KEY_CODE && hasCommandModifier(e):
        return 'command-a';
        break;

      default:
        break;
    }

    return getDefaultKeyBinding(e);
  }

  const handleKeyCommand = (command) => {

    let newState = RichUtils.handleKeyCommand(editorState, command)

    switch(command) {
      case 'escape':
        disableSuggestions()
        break

      case 'enter':
      case 'tab':
        showSuggestions && renderSuggestion(selectedSuggestionIndex)
        break

      case 'shift-enter':
        newState = RichUtils.insertSoftNewline(editorState, command)
        break

      case 'down-arrow':
        focusNextItem('down')
        break

      case 'up-arrow':
        focusNextItem('up')
        break

      case 'command-a':
        let currentContent = editorState.getCurrentContent();
    
        newState = editorState.getSelection().merge({
          anchorKey: currentContent.getFirstBlock().getKey(),
          anchorOffset: 0,  

          focusOffset: currentContent.getLastBlock().getText().length, 
          focusKey: currentContent.getLastBlock().getKey(),
        })
        break
      default:
        break
    }

    if (newState) {
      handleChange(newState);
      return 'handled';
    }    
    return 'not-handled';
  }

  const focusNextItem = (direction) => {

    if (selectedSuggestionIndex >= 0) {
      if (direction === 'down' && selectedSuggestionIndex+1 < searchResults.length) {
        setSelectedSuggestionIndex(prev => {return prev+1})
      } else if (direction === 'up' && selectedSuggestionIndex > 0) {
        setSelectedSuggestionIndex(prev => {return prev-1})
      }
    } 
  }

  const fetchSearchResults = async (search = undefined) => {

    if (search.length > 0) { // Only trigger search query for search terms with at least 1 character
      
      setLoading(true)

       await searchService.getAllByName(encodeURIComponent(search.replaceAll('%', '%25')), 'business_term')
      .then(res => {
        if (res) {
          setSearchResults(prev => {return res.search_results})
        }        
      })
      .catch(err => { 
        showMessage(err, 'error')
      })
      .finally(res => {
        setLoading(false)
      })
      
    } else {
      setSearchResults([])
    }
  }

//const plainText = editorState.getCurrentContent().getPlainText()

  return (
    <div 
      className={"CustomTextarea" + (showFormatMenu ? " has-focus" : "") + (disabled ? " disabled" : "")}
      onClick={() => editorRef.current.focus()}
      >

      <Editor 
        customStyleMap={ styleMap }
        ref={ editorRef}
        editorState={ editorState } 
        handleKeyCommand={ handleKeyCommand }
        keyBindingFn={ myKeyBindingFn }
        //blockRenderMap={extendedBlockRenderMap}
        onChange={ handleChange }
        onBlur={ handleBlur }
        onFocus={ handleFocus }
        stripPastedStyles={ true }
        placeholder={ placeholder }
        readOnly={ readOnly || disabled }
      />

      <FormatMenu onChange={handleChange} editorState={editorState} />

      { enableSuggestions && showSuggestions &&

        <TextareaSuggestions 
            showSuggestions={ enableSuggestions && showSuggestions }
            searchResults={ searchResults }
            searchTerm={ searchTerm }
            editorState={ editorState }
            selectedSuggestionIndex={ selectedSuggestionIndex}
            setSelectedSuggestionIndex={ setSelectedSuggestionIndex }
            onSelectSuggestion={ renderSuggestion }
            loading={ loading }
          />
      }
    </div>
  )
}