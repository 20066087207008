import { useMemo, useEffect } from 'react';

const _debounce = (func, wait) => {
  let timeout;

  function cancel() {
    clearTimeout(timeout)
  }

  function executedFunction() {
    let context = this;
    let args = arguments;
   
    let later = function() {
      timeout = null;
      func.apply(context, args);
    };
  
    cancel(timeout);

    timeout = setTimeout(later, wait);

  }

  executedFunction.cancel = cancel
  return executedFunction

};

export const useDebounce = (func, wait=100, dependencies=[]) => {
  
  const debouncedFunc = useMemo( 
      () => _debounce(func, wait)
    , [...dependencies])

  useEffect( () => {
    return () => { 
      debouncedFunc.cancel() 
    }
  }, [])

  return {
    debounce: debouncedFunc
  }
}