import React, { useState, useEffect } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ReactComponent as Spinner} from '../assets/img/logo_loading.svg';
import { authenticationService, userService } from '../services'
import { Input, FullscreenMessage } from '../components'
import { useGlobalState, useMessage } from '../hooks'
import { LoadApp } from '.'

export const ResetPassword = ( props) => {
  
  const initialFormState = {
    old_password: '', 
    new_password: '',
    confirm_new_password: ''
  }

  const [user, setUser] = useState()
  const [formData, setFormData] = useState(initialFormState)
  const [loggedInUser, setLoggedInUser] = useGlobalState('loggedInUser');
  const [isLoggedIn, setIsLoggedIn] = useGlobalState('isLoggedIn');
  const [initialLoading, setInitialLoading] = useState(true)
  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState(false)
  const [time, setTime] = useState(5)
  const [startTimer, setStartTimer] = useState(false)

  const { message, showMessage, hideMessage } = useMessage()
  const navigate = useNavigate()
  
  const [searchParams, setSearchParams] = useSearchParams()
  const token = searchParams.get('t')
  const user_id = searchParams.get('u')

  useEffect(() => {

    // Redirect to start page if token is missing
    if (!token) { 
      navigate('/')
    } else {

      fetchUser()
    }   
  }, []);

  useEffect(() => {

    // Redirect to start page automatically when timer reached 0
    if (time === 0) { 
      goToLogin()
    }  
  }, [time]);


  useEffect(() => {

    // Start timer for auto redirect
    if (startTimer) {
      setInterval(() => {setTime(prev => prev-1)}, 1000);
    }
  }, [startTimer]);

  const fetchUser = async () => {
    await userService.getByPasswordResetToken({token, user_id})
      .then(res => {
        setUser(res.users)
        setInitialLoading(false)
      })
      .catch(error => {
        showMessage(
          {
            message: error.message,
            details: error.details,
          }, 
          "error",
          true
        )
      })
  }

  const handleInputChange = (event) => {
    const { value, name } = event.target

    hideMessage()
  
    setFormData({ ...formData, [name]: value })
  }

  const onSubmit = async (event) => {

    event.preventDefault();
    setLoading(true)
    hideMessage()
    

    // Validation
    let hasError = false
    let error = []

    if (formData.new_password.length < 12) {
      hasError = true
      error = [...error,
      {
        msg: "Password must be at least 12 characters long"
      }]
    }
    if (formData.new_password !== formData.confirm_new_password) {
      hasError = true
      error = [...error,
      {
        msg: "New passwords does not match"
      }]
    }

    if (hasError) {
      showMessage(
        {dataValidationError: error}, 
        "error",
        true
      )
      setLoading(false)
      return null
    }

    const users = {
      user_id: user_id,
      reset_password_token: token,
      new_password: formData.new_password
    }
    
    await userService.resetPassword(users)
      .then(res => {
        setSuccess(true)
        setStartTimer(true)
        if (isLoggedIn && loggedInUser) {
          authenticationService.logout()
        }
        return res
      })
      .then(res => { 
        setLoggedInUser()
        setIsLoggedIn()
      })
      .catch(error => {

        showMessage(
          error, 
          "error",
          true
        )
      })
      .finally(res => {
        setLoading(false)
      })

  }

  const goToLogin = () => {
    navigate('/')
  }
  
  const validateNewPassword = () => {
    if (formData.new_password.length > 0) {
      if (formData.new_password.length < 12) {
        return { valid: false, message: "Password must be at least 12 characters long"} 
      }

      return { valid: true, message: "Password ok"} 
    }
  }

  if (initialLoading) {
    return <LoadApp/>
  }

  return (
    <div className="ChangePassword fullscreen">
      <div className="fullscreen-content">
      
      { initialLoading
      ?
        <React.Fragment>
          { !message 
            ? <Spinner className="fullscreen-loading-logo" />
            : <div className="fullscreen-logo"></div>
          }
          {message && message.type === "error"
            ? <FullscreenMessage message={message} />
            : <div className="fullscreen-loading-text">Loading Katalogue...</div>
          }

        </React.Fragment>
      :
        <React.Fragment>
        { (loggedInUser && !loggedInUser.password_change_required) &&
        <div className="close-button" onClick={ goToLogin }>
          <div className="close-button-label">Go to login</div>
          <div className="close-button-icon"></div>
        </div>
        }

        <div className="fullscreen-logo"></div>

        { success 
          ? 
            <React.Fragment>
              <h1>Password Changed!</h1>
              <p>&nbsp;</p>
              <p>Redirecting to login page in {time} seconds...</p>
              <p>&nbsp;</p>
              <button type="button" className="button main-button" onClick={goToLogin}><span>Go to Login</span></button>
            </React.Fragment>
        : 
          <React.Fragment>
            <h1>Hi {user && user.user_fullname.split(' ')[0] },</h1>
            <p>&nbsp;</p>
            <p>Enter a new password below to change your password.</p>
            <p>&nbsp;</p>
            <form onSubmit={onSubmit}>
              <Input
                type="password"
                name="new_password"
                className="large"
                label="New password"
                labelAsPlaceholder={true}
                value={formData.new_password}
                onChange={handleInputChange}
                disabled={loading}
                autoComplete="new-password"
                required={true}
                validation={ validateNewPassword() }
              />
              <Input
                type="password"
                name="confirm_new_password"
                className="large"
                label="Confirm new password"
                labelAsPlaceholder={true}
                value={formData.confirm_new_password}
                onChange={handleInputChange}
                disabled={loading}
                autoComplete="new-password"
                validation={ formData.confirm_new_password.length > 0 && (
                  (formData.confirm_new_password !== formData.new_password) 
                    ? { valid: false, message: "Passwords does not match"} 
                    : { valid: true, message: "Passwords match"} 
                  )}
              />
              
              <p>&nbsp;</p>
              
              <button 
                type="submit" 
                className={"button large-button" + (loading ? ' loading' : '')}
                disabled={loading}
                ><span>{loading ? 'Saving...' : 'Save'}</span></button>
            </form>

            <FullscreenMessage message={message} />

          </React.Fragment>
        }
        </React.Fragment>
      }
      </div>
    </div>
  )

}