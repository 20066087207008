import React from 'react';
import { SkeletonLoader } from './';

export const Photo = ({data, alt, className, type='USER', size='small', tooltip="", loading }) => {

	let avatar = "group-photo"

	switch(type) {
		case "USER":
			avatar = "user-photo"
			break
		case "SERVICE_ACCOUNT":
			avatar = "service-account-photo"
			break
		case "GROUP":
			avatar = "group-photo"
			break
		default:
			break
	}

	return (
		<div className={
			"Photo " + 
			(!data ? " placeholder " : " ") + 
			type.toLowerCase() + " " + 
			size + 
			(className ? " " + className : "" ) 
		}>
		{!loading 
			? data 
				? 	<img 
						className="photo-img"
					 	title={tooltip}
					 	src={ "data:image/jpeg;base64," + data }
					 	alt={alt}
					/>		
				: 	<div className={"photo-img icon-" + avatar }  title={ tooltip } />
			: <SkeletonLoader className={"photo-loader " + size} />
		}
		</div>
	)
}