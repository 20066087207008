import config from '../config';
import { authHeader, customFetch, handleResponse, commonRequestOptions, subjects } from '../helpers';

const endpointPath='job'

export const jobService = {
    getAll,
    getAllLatest,
    getById,
    getByTaskId
};

function getAll(signal) {
    const requestOptions = { method: 'GET', headers: authHeader(), ...commonRequestOptions() };
    return customFetch(`${config.api.url}/${endpointPath}/all`, requestOptions, signal).then(handleResponse);
}

function getAllLatest(signal) {
    const requestOptions = { method: 'GET', headers: authHeader(), ...commonRequestOptions() };
    return customFetch(`${config.api.url}/${endpointPath}/all_latest`, requestOptions, signal).then(handleResponse);
}

function getById(id, signal) {
    const requestOptions = { method: 'GET', headers: authHeader(), ...commonRequestOptions() };
    return customFetch(`${config.api.url}/${endpointPath}/${id}`, requestOptions, signal)
    .then(handleResponse)
    .then(res => {
        subjects.currentJob.next(res.jobs[0] || {job_id: id});
        subjects.currentTask.next(res.jobs[0] ? {
            task_id: res.jobs[0].task_id,
            task_name: res.jobs[0].task_name
        } : "not_found");
        return res
    });
}

function getByTaskId(id, signal) {
    const requestOptions = { method: 'GET', headers: authHeader(), ...commonRequestOptions() };
    return customFetch(`${config.api.url}/${endpointPath}/task/${id}`, requestOptions, signal)
    .then(handleResponse)
}