import React, {forwardRef} from 'react'
import Textarea from 'react-textarea-autosize';
import { useTooltip } from '../../hooks';

const _TextareaAutosize = (props, ref) => {

	const {showTooltip, hideTooltip} = useTooltip()

	const tooltip = props.title
	const label = props.label

	let newProps = {...props}
	props.title && delete newProps.title
	props.label && delete newProps.label

	return (
		<div className="TextareaAutosize">
			<label 
		  		onMouseEnter={ () => tooltip && showTooltip({title: tooltip})} 
		  		onMouseLeave={ tooltip && hideTooltip }
		  	>
		    	<span>{label}</span>
		    	{tooltip && <span className="icon-description">i</span>}
		  	</label>
			<Textarea {...newProps} ref={ref} />
		</div>
	)
}

export const TextareaAutosize = forwardRef(_TextareaAutosize);