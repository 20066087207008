import config from '../config';
import { customFetch, authHeader, jsonHeader, handleResponse, commonRequestOptions, subjects } from '../helpers';

const endpointPath='field_description'

export const fieldDescriptionService = {
    getAll,
    getById,
    getByName,
    getByFieldId,
    getFieldCount,
    create,
    update,
    checkDestroy,
    destroy
};


function getAll(signal) {
   
    const requestOptions = { method: 'GET', headers: authHeader(), ...commonRequestOptions() };
    return customFetch(`${config.api.url}/${endpointPath}/all`, requestOptions).then(handleResponse);
}

function getById(id, signal) {
    const requestOptions = { method: 'GET', headers: authHeader(), ...commonRequestOptions() };
    return customFetch(`${config.api.url}/${endpointPath}/${id}`, requestOptions, signal)
    .then(handleResponse)
    .then(res => {
        subjects.currentFieldDescription.next(res.field_descriptions[0] || {field_description_id: id});
        return res
    });
}

function getByName(name, id, signal) {
    const requestOptions = { method: 'GET', headers: authHeader(), ...commonRequestOptions() };
    return customFetch(`${config.api.url}/${endpointPath}/name/${name}/${id}`, requestOptions, signal).then(handleResponse)

}

function getByFieldId(id, signal) {
    const requestOptions = { method: 'GET', headers: authHeader(), ...commonRequestOptions() };
    return customFetch(`${config.api.url}/${endpointPath}/field/${id}`, requestOptions, signal)
    .then(handleResponse)
    .then(res => {
        subjects.currentFieldDescription.next(res.field_descriptions[0]);
        return res
    });
}

function getFieldCount(id, signal) {
    const requestOptions = { method: 'GET', headers: authHeader(), ...commonRequestOptions() };
    return customFetch(`${config.api.url}/${endpointPath}/field_count/${id}`, requestOptions, signal).then(handleResponse);
}

function create(items, signal) {
    const requestOptions = { 
    	method: 'POST', 
    	headers: {
            ...authHeader(),  
    		...jsonHeader()
    	}, 
        ...commonRequestOptions(),
    	body: JSON.stringify(items) };

    return customFetch(`${config.api.url}/${endpointPath}`, requestOptions, signal).then(handleResponse);
}

function update(items, signal) {
    const requestOptions = { 
    	method: 'PUT', 
    	headers: {
            ...authHeader(),  
            ...jsonHeader()
        }, 
        ...commonRequestOptions(),
    	body: JSON.stringify(items) 
    };

    return customFetch(`${config.api.url}/${endpointPath}`, requestOptions, signal).then(handleResponse);
}

function checkDestroy(items, signal) {
    const requestOptions = { 
        method: 'POST', 
        headers: {
            ...authHeader(),  
            ...jsonHeader()
        }, 
        ...commonRequestOptions(),
        body: JSON.stringify(items) };

    return customFetch(`${config.api.url}/${endpointPath}/check_delete`, requestOptions, signal).then(handleResponse);
}

function destroy(items, signal) {
    const requestOptions = { 
    	method: 'DELETE', 
    	headers: {
            ...authHeader(),  
            ...jsonHeader()
        }, 
        ...commonRequestOptions(),
    	body: JSON.stringify(items) };

    return customFetch(`${config.api.url}/${endpointPath}`, requestOptions, signal).then(handleResponse);
}