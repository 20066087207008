export const copyToClipboard = (ref) => {
  const refType = typeof ref
  let text
  switch (refType) {
    case 'string':
      text = ref
      break
    default:
      text = ref.current.textContent
  }
  
  navigator.clipboard.writeText(text)
}