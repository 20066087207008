export const Asset = {
  Field: 'field',
  Dataset: 'dataset',
  DatasetGroup: 'dataset_group',
  Datasource: 'datasource',
  System: 'system',
  FieldDescription: 'field_description',
  BusinessTerm: 'business_term',
  Glossary: 'glossary',
  User: 'user',
  FieldUnit: 'field_unit',
  Task: 'task',
  Job: 'job'
}

export const AssetName = {
  field: 'Field',
  dataset: 'Dataset',
  dataset_group: 'Dataset Group',
  datasource: 'Datasource',
  system: 'System',
  field_description: 'Field Description',
  business_term: 'Business Term',
  glossary: 'Glossary',
  user: 'User',
  field_unit: 'Field Unit',
  task: 'Task',
  job: 'Job'
}

export const authMethod = {
    oidc: 'Azure AD', 
    local:'Local'
  }
  
export const Role = {
  admin: 'Admin',
  editor: 'Editor',
  viewer: 'Viewer'
}

export const Operation = {
  D: {label: 'Delete', className: 'pill pill-red'}, 
  I: {label: 'Insert', className: 'pill'},
  U: {label: 'Update', className: 'pill pill-blue'}
}

export const TaskType = {
  adsync: 'adsync',
  dssync: 'dssync',
  rebuildsearchindex: 'rebuildsearchindex'
}

export const keyCode = {
  SPACE: 32,
  ENTER: 13,
  DOWN_ARROW: 40,
  UP_ARROW: 38,
  LEFT_ARROW: 37,
  RIGHT_ARROW: 39,
  ESCAPE: 27,
  TAB: 9,
  A: 65,
  F: 70
}

export const FieldSensitivity = {
  1: {label: 'Confidential', className: 'pill pill-wide pill-full-red'}, 
  2: {label: 'Restricted', className: 'pill pill-wide pill-red'},
  3: {label: 'Internal', className: 'pill pill-wide'},
  4: {label: 'Public', className: 'pill pill-wide pill-grey'}
}
