import React, { useState, useEffect } from 'react';
import { systemService } from '../services';
import { DataTable } from '../components';
import { useSideDialog, useModalDialog, useGlobalState, usePhotos, useMessage } from '../hooks';
import { Role } from '../helpers';

export const Systems = () => {
  
  const [systems, setSystems] = useState([]);
  const [loggedInUser, setLoggedInUser] = useGlobalState('loggedInUser')
  const [selectedItems, setSelectedItems] = useState([]);
  const [loading, setLoading] = useState(true);

  const clearSelectedItems = () => {
    setSelectedItems([])
  }

  const { showMessage } = useMessage()
  const { showSideDialog, hideSideDialog } = useSideDialog(clearSelectedItems)
  const { showModalDialog, hideModalDialog } = useModalDialog()
  const { fetchPhotos } = usePhotos()
  
  useEffect(() => {

    fetchItems()
    
  }, []);

  const fetchItems = async () => {
    const promise = new Promise(  (resolve, reject) => {
      systemService.getAll()
        .then(res => {
          setSystems(res.systems)
          setLoading(false)
          resolve(res.systems)
        })
        .catch(err => {
          showMessage(err, 'error')
          setLoading(false)
        });
    })

    Promise.all([promise, fetchFieldStatistics()])
      .then( result => {
        updateFieldStatistics(result[0], result[1])
        fetchPhotos(result[0], 'system_owner_user_id', setSystems)
      })
      .catch(err => { showMessage(err, 'error')})
  }

  const fetchFieldStatistics = async () => {
    return new Promise(  (resolve, reject) => {
      systemService.getFieldStatistics()
        .then(res => {

          resolve(res.field_statistics)


        })
        .catch(err => {
          return null
        })
    })
  }

  const updateFieldStatistics = (data, statistics) => {

    let dataTmp = data
    let statistic = {}
    let tmp = null

    let maxValue = 0

    statistics.forEach( row => {
      if (row.dataset_count > maxValue) {
        maxValue = row.dataset_count
      }
    })

    data.forEach( (row, index) => {
      statistic = statistics.find(x => x.system_id === row.system_id)
      tmp = dataTmp[index]
      dataTmp[index] = {
        ...tmp, 
        dataset_count: (statistic ? statistic.dataset_count : 0), 
        field_description_ratio: (statistic ? statistic.field_description_ratio : 0),
        dataset_count_max: maxValue
      }
    })

    setSystems([...dataTmp]);
  }

  const addSystem = async data => {
    const systems = {systems: (Array.isArray(data) ? data : [data])}
    
    await systemService.create(systems)
      .then(async res => {

        fetchItems()
        showMessage(res.message)
      })
      .catch(err => {
        showMessage(err, 'error')
        throw err
      });
  }

  const editSystem = async data => {
    const systems = {systems: (Array.isArray(data) ? data : [data])}
    
    await systemService.update(systems)
      .then(async res => { 

        fetchItems()
        showMessage(res.message)
        clearSelectedItems()
        hideSideDialog()
      })
      .catch(err => {
        showMessage(err, 'error')
        throw err
      });
  }

  const deleteSystem = async data => {    
    await systemService.destroy({ systems: data })
      .then(async res => { 
        
        fetchItems()
        hideModalDialog()
        clearSelectedItems()
        showMessage(res.message)

      })
      .catch(err => {
        showMessage(err, 'error')
      });
  }

  const editable = loggedInUser && loggedInUser.user_role_name === Role.admin

  return (
    <div className="Systems wide-margin">

        <DataTable
          columns={[
            {id: 'system_name', name: 'Name', link: '/browse_datasets/:system_id', tooltip: 'system_description', className:"bold"},
            {id: 'system_type', name: 'Type'},
            {id: 'system_description', name: 'Description', type: 'description', viewMode: 'descriptions'},
            {id: 'dataset_count', name: 'Datasets', type: 'chart', chartType: 'bar', data: {data:'dataset_count', max: 'dataset_count_max'}, viewMode: 'statistics'},
            {id: 'field_description_ratio', name: 'Documentation Level', type: 'chart', chartType: 'percent-bar', data: {data:'field_description_ratio'}, viewMode: 'statistics'},
            {id: 'user_photo', name: '', type: 'user-photo', link: '/users/:system_owner_user_id', tooltip: 'system_owner_user_fullname'},
            {id: 'system_owner_user_fullname', name: 'Owner', link: '/users/:system_owner_user_id'}
          ]}
          buttons={[ 
            {label: "Add +", action: "add", mainButton: true, onClick: (items) => showSideDialog('addSystem', items || selectedItems, addSystem) },
            {label: "Edit", action: "edit", onClick: (items) => showSideDialog('editSystem', items || selectedItems, editSystem) },
            {label: "Delete", action: "delete", onClick: (items) => showModalDialog('deleteSystem', items || selectedItems, deleteSystem) }
          ]}
          title="Systems"
          data={systems}
          idColumn='system_id'
          selectedItems={selectedItems}
          setSelectedItems={setSelectedItems}
          loading={loading}
          editable= {editable}
          filterable={true}
          filterObjects={['system_name', 'system_description', 'system_type', 'system_owner_user_fullname']}
          showViewModes={true}
        />

      </div>
  );
}