import React, { useState, useEffect, forwardRef } from 'react'
//import TextareaAutosize from 'react-textarea-autosize';
import { datasourceService, datasourceSubtypeService, datasourceTypeService, systemService, userService } from '../services';
import { Button, Checkbox, Select, FormTabs, formatSelectOptions, Input, Textarea, TextareaAutosize, textareaInitialState, textareaToRaw } from '../components';
import { getBatchEditValue, handleError } from '../helpers'
import { useModalDialog, useGlobalState, useMessage } from '../hooks';

const _AddDatasourceForm = ({ mode, data, submitForm, loading, setLoading }, ref) => { 
	
	const initialFormState = {
		datasource_id: null, 
		datasource_name: '',
		datasource_hostname: '',
		datasource_port: '',
		datasource_database: '',
		datasource_description: textareaInitialState({}), 
		datasource_type_id: '',
		datasource_subtype_id: '', 
		datasource_account: '',
		datasource_service: '',
		datasource_username: '',
		datasource_password: '',
		datasource_connection_string: '',
		datasource_is_trusted_connection: false,
		datasource_is_encrypted_connection: false,
		use_custom_import_query: false,
		custom_import_query: '',
		use_custom_relationship_query: false,
		custom_relationship_query: '',
		datasource_filter: '',
		relationship_map: '',
		disable_virtual_relationships: false,
		system_id: '',
		datasource_owner_user_id: ''
	}

	const initialBatchEditItems = {
		batch_datasource_type_id: false,
		batch_datasource_subtype_id: false, 
		batch_system_id: false,
		batch_datasource_owner_user_id: false
	}

	const [loggedInUser, setLoggedInUser] = useGlobalState('loggedInUser');
	const [currentSystem, setCurrentSystem] = useGlobalState('currentSystem');
	const [displayNames] = useGlobalState('displayNames')
	const [formData, setFormData] = useState(initialFormState);
	const [batchEdit, setBatchEdit] = useState(false);
	const [batchEditItems, setBatchEditItems] = useState(initialBatchEditItems)
	const [datasourceTypes, setDatasourceTypes] = useState([]);
	const [datasourceType, setDatasourceType] = useState({});
	const [datasourceSubtypes, setDatasourceSubtypes] = useState([]);
	const [datasourceSubtype, setDatasourceSubtype] = useState();
	const [systems, setSystems] = useState([]);
	const [formValues, setFormValues] = useState({});
	const [users, setUsers] = useState([]);	
	const [connectionTestResult, setConnectionTestResult] = useState({success: null, msg: ''});
	const [testing, setTesting] = useState(false);
	const [defaultSelectedTab, setDefaultSelectedTab] = useState()

	const [abortTestConnection, setAbortTestConnection] = useState(new AbortController());

	const { showMessage } = useMessage()
	const { showModalDialog, hideModalDialog } = useModalDialog()


	useEffect( () => {

		// Get form data
  	fetchDatasourceTypes();
  	fetchSystems();
  	fetchUsers();
  	fetchFormValues();

    // Cleanup
    return () => {
      abortTestConnection.abort()
    }

	}, []);

	useEffect( () => {

		if (datasourceType?.datasource_type_code) {
	  	fetchDatasourceSubtypes();
	  }

	}, [datasourceType]);

	useEffect(() => {
		setBatchEdit(false)
	    if (data.length === 1) {
			setFormData({ 
				datasource_id: mode !== 'add' ? data[0].datasource_id : null, 
				datasource_name: data[0].datasource_name || '',
				datasource_hostname:  data[0].datasource_hostname || '',
				datasource_port:  data[0].datasource_port || '',
				datasource_database: data[0].datasource_database || '',
				datasource_description: textareaInitialState({value: data[0].datasource_description}), 
				datasource_type_id: data[0].datasource_type_id,
				datasource_subtype_id: data[0].datasource_subtype_id,
				datasource_account: data[0].datasource_account || '',
				datasource_service: data[0].datasource_service || '',
				datasource_username: data[0].datasource_username || '',
				datasource_password: '',
				datasource_connection_string: data[0].datasource_connection_string || '',
				datasource_is_trusted_connection: data[0].datasource_is_trusted_connection,
				datasource_is_encrypted_connection: data[0].datasource_is_encrypted_connection,
				use_custom_import_query: data[0].use_custom_import_query || false,
				custom_import_query: data[0].custom_import_query || '',
				use_custom_relationship_query: data[0].use_custom_relationship_query || false,
				custom_relationship_query: data[0].custom_relationship_query || '',
				datasource_filter: JSON.stringify(data[0].datasource_filter || undefined, null, 2) || '',
				relationship_map: JSON.stringify(data[0].relationship_map || undefined, null, 2) || '',
				disable_virtual_relationships: data[0].disable_virtual_relationships || false,
				system_id: data[0].system_id,
				datasource_owner_user_id: data[0].datasource_owner_user_id
			})
		} else if(data.length > 1 && mode !== 'add') {
			setBatchEdit(true)

			setFormData({ 
				datasource_type_id: getBatchEditValue(data, 'datasource_type_id'),
				datasource_subtype_id: getBatchEditValue(data, 'datasource_subtype_id'),
				system_id: getBatchEditValue(data, 'system_id'),
				datasource_owner_user_id: getBatchEditValue(data, 'datasource_owner_user_id')
			})
		} else if(mode === 'add') {
			setFormData({ 
				...formData, 
				datasource_owner_user_id: loggedInUser?.user_id,
				system_id: currentSystem.system_id
			})
		} else {
			resetForm()		
		}

		if (data.length > 0) {
			setDatasourceType( datasourceTypes.find(x => x.datasource_type_id === data[0].datasource_type_id))
			setDatasourceSubtype( datasourceSubtypes.find(x => x.datasource_subtype_id === data[0].datasource_subtype_id))
		}

	}, [mode, data, loggedInUser, currentSystem]);
	
	const fetchDatasourceTypes = async () => {
    datasourceTypeService.getAll()
      .then(res => { 
      	setDatasourceTypes(res.datasource_types);   
      	data[0] && setDatasourceType( res.datasource_types.find(x => x.datasource_type_id === data[0].datasource_type_id) )  	
      })
	    .catch(err => {showMessage(err, 'error')});
  }

  const fetchDatasourceSubtypes = async () => {
    datasourceSubtypeService.getByDatasourceTypeCode(datasourceType.datasource_type_code)
      .then(res => { 
      	setDatasourceSubtypes(res.datasource_subtypes);   
      	data[0] && setDatasourceSubtype( res.datasource_subtypes.find(x => x.datasource_subtype_id === data[0].datasource_subtype_id) )  	
      })
	    .catch(err => {showMessage(err, 'error')});
  }

	const fetchSystems = async () => {
    systemService.getAll()
      .then(res => { 

      	setSystems(res.systems); 

      })
	    .catch(err => {showMessage(err, 'error')});
  }

	const fetchUsers = async () => {
    userService.getAll()
      .then(res => { 

      	setUsers(res.users); 

      })
	    .catch(err => {showMessage(err, 'error')});
  }
  
	const fetchFormValues = async () => {
	  datasourceService.getFormValues()
	    .then(res => { 

	    	if (res) {
	    		const result = res.formValues
	    		setFormValues(result); 
	    		
	    		// Set default values
    			let tmp = {}
    			Object.entries(formData).forEach(([key, value]) => {
						
						const ds = result[datasourceType.datasource_type_code]
						const prop = ds && (ds[key] || {})
						const defaultValue = prop && prop?.defaultValue
						const disabled = result[datasourceType.datasource_type_code]?.[key]?.disabled

    				if (defaultValue && ((data.length < 1 && mode === 'add') || disabled)) {
	    					tmp[key] = defaultValue
    				} 
    			})
    			setFormData(prev=> {return {...prev, ...tmp}})	
	    	}

	    })
	    .catch(err => {
	    	showMessage(err, 'error')
	    })
	}
	const handleInputChange = (event, formPart) => {
	  let { name, value } = ""

		// Handle standard form inputs
		if (event.target !== undefined) {
		  name = event.target.name
		  value = event.target.value

		  // If input element is a checkbox, we cannot use "value"
	    if (event.target.type === "checkbox") { value = event.target.checked }

	  // Handle custom form inputs
	  } else {
	  	name = event.name
	  	value = event.value
	  }

    if (name === 'datasource_type_id') { setDatasourceType( datasourceTypes.find(x => x.datasource_type_id === value) ) }	
    if (name === 'datasource_subtype_id') { setDatasourceSubtype( datasourceSubtypes.find(x => x.datasource_subtype_id === value) ) }	
		
		switch(formPart) {
	  	case 'batch':
	  		setBatchEditItems({ ...batchEditItems, [name]: value })
	  		break
	  	default:
	  		setFormData({ ...formData, [name]: value })
	  }

		resetConnectionTestResult()
	}

	const onSubmit = event => {
		event.preventDefault()

		setLoading(true)
		
		// Form validation
		//if (!formData.user_fullname || !formData.user_username) return

		let dataToSubmit

		if (batchEdit) {
			
			let key = ''
			let tmp = {}
			const objects = Object.entries(batchEditItems)

			for (let i = 0; i < objects.length; i++) {
				if (objects[i][1]) {
					key = objects[i][0].replace("batch_","")
					tmp = {...tmp, [key]: formData[ key ]}
				}
			}

			dataToSubmit = data.map( item => {
				return {datasource_id: item.datasource_id, 
								datasource_name: item.datasource_name,
								datasource_hostname:  item.datasource_hostname,
								datasource_port:  item.datasource_port,
								datasource_database: item.datasource_database,
								datasource_type_id: item.datasource_type_id,
								datasource_subtype_id: item.datasource_subtype_id,
								datasource_account: item.datasource_account,
								datasource_service: item.datasource_service,
								datasource_username: item.datasource_username,
								datasource_password: item.datasource_password,
								datasource_connection_string: item.datasource_connection_string,
								datasource_is_trusted_connection: item.datasource_is_trusted_connection,
								datasource_is_encrypted_connection: item.datasource_is_encrypted_connection,
								use_custom_import_query: item.use_custom_import_query,
								custom_import_query: item.custom_import_query,
								use_custom_relationship_query: item.use_custom_relationship_query,
								custom_relationship_query: item.custom_relationship_query,
								datasource_filter: item.datasource_filter,
								relationship_map: item.relationship_map,
								disable_virtual_relationships: item.disable_virtual_relationships,
								system_id: item.system_id,
								datasource_owner_user_id: item.datasource_owner_user_id,
								...tmp
							}
			})

		} else { 
			dataToSubmit = {
				...formData,
				datasource_description: textareaToRaw({value: formData.datasource_description}),
				datasource_database: datasourceType.datasource_type_code === 'oracle' && formData.datasource_database === '' ? formData.datasource_service : formData.datasource_database,
				use_custom_import_query: (formValues[datasourceType.datasource_type_code] && "defaultValue" in formValues[datasourceType.datasource_type_code].use_custom_import_query 
					      						? formValues[datasourceType.datasource_type_code].use_custom_import_query.defaultValue 
					      						: formData.use_custom_import_query),
				use_custom_relationship_query: (formValues[datasourceType.datasource_type_code] && "defaultValue" in formValues[datasourceType.datasource_type_code].use_custom_relationship_query 
					      						? formValues[datasourceType.datasource_type_code].use_custom_relationship_query.defaultValue 
					      						: formData.use_custom_relationship_query)
			}
		}
		
		// submit form
		submitForm(dataToSubmit)
		.then(res => resetForm())
		.catch(err => {
			// Errors handled in submitForm function
		})
		.finally(res => setLoading(false))				    
	}

  const resetForm = () => {
  	resetConnectionTestResult()
  	setBatchEdit(false)
  	setBatchEditItems(initialBatchEditItems)
  	setDefaultSelectedTab({tab: "general"})
  	setDatasourceType([])
  	setFormData(prevFormData => {
			return {
				...initialFormState,
				datasource_owner_user_id: (loggedInUser ? loggedInUser.user_id : null),
				system_id: ( currentSystem ? currentSystem.system_id : null) 
			}
		}) 
  }

  const resetConnectionTestResult = () => {
  	setConnectionTestResult({success: null, msg: ''})
  }

  const testConnection = async () => {
    resetConnectionTestResult()
    setTesting(true)

    await datasourceService.testConnection({datasources: [{...formData, 
    														datasource_type_code: datasourceType.datasource_type_code,
    														datasource_type_name: datasourceType.datasource_type_name
    													}]}, abortTestConnection)
      .then(async res => { 
      	if (res) {
      		setConnectionTestResult({status: res.status, msg: res.message})
      	}
      })
      .catch(err => {
      	handleError({err}, () => {
      		setConnectionTestResult({status: 'failed', msg: `${err.message}:\n ${err.error}` })
      	})
      });

    setTesting(false)
  }

  const cancelTestConnection = () => {
  	abortTestConnection.abort()
  	setAbortTestConnection(new AbortController())
  }

  const saveImportQuery = (query) => {
  	setFormData(prev => {
			return {
				...prev,
				custom_import_query: query
			}
		}) 
  	hideModalDialog()
  }

  const saveRelationshipQuery = (query) => {
  	setFormData(prev => {
			return {
				...prev,
				custom_relationship_query: query
			}
		}) 
  	hideModalDialog()
  }

  const renderConnectionString = () => {
		
		let str = ""
  	
  	if (!datasourceSubtype) {
  		return ""
  	}

  	str += `DRIVER={${datasourceSubtype.datasource_subtype_driver_name}};`

  	Object.entries(datasourceSubtype.datasource_subtype_settings.connectionStringMap).forEach(([key, value]) => {
  		if (formData[`datasource_${key}`] && key !== 'password') {
  			const param = value
  			const val = formData[`datasource_${key}`]
  			str += `${param}=${val};`
  		} else if (key === 'password' && (mode !== 'add' || formData.datasource_password)) {
  			const param = value
  			const val = '<hidden>'
  			str += `${param}=${val};`
  		}
  	})

  	str += formData.datasource_connection_string

  	return str
  }

  const hasConnector = datasourceType && formValues[datasourceType.datasource_type_code] ? true : false

  return (
  	<div className="form">
	    <form
			  onSubmit={onSubmit}
			  ref={ref}
			>
				{ !batchEdit &&
				<div className="form-block vertical">
		      <Input type="text" 
		      	name="datasource_name" 
		      	label="Name"
		      	value={formData.datasource_name} 
		      	onChange={handleInputChange}
		      	autoComplete='new-password'
		      	disabled={loading}
		      />
	      </div>
				}
				
				<div className="form-block vertical">
					{ batchEdit 
						? <label>
								<Checkbox 
									checked={batchEditItems.batch_datasource_type_id}
									name="batch_datasource_type_id"
									label="Edit Datasource Type"
									onChange={ e => handleInputChange(e, 'batch')}
									disabled={loading}
								/>
							</label>
						: <label>Datasource Type</label>
					}
					<Select 
            name="datasource_type_id"
            value={formData.datasource_type_id === 'multiple' ? undefined : formData.datasource_type_id}
            options={ formatSelectOptions({options: datasourceTypes, optionValue: "datasource_type_id", optionLabel: "datasource_type_name", optionIcon: "datasource_type_category", optionDisabled: "datasource_type_is_disabled"}) }
            onChange={handleInputChange}
            placeholder={formData.datasource_type_id === 'multiple' ? '< Multiple >' : "Select datasource type..."}
            disabled={loading || (batchEdit && !batchEditItems.batch_datasource_type_id)}
          />
		    </div>

		    <FormTabs defaultSelectedTab={defaultSelectedTab}>
					<div label="general">

						{ !batchEdit &&
						<div className="form-block vertical">
							<label>Description</label>
							<Textarea
				      	name="datasource_description" 
				      	value={formData.datasource_description} 
				      	onChange={handleInputChange}
				      	disabled={loading}
				      />
				    </div>
				  	}

						<div className="form-block vertical">
							{ batchEdit 
								? <label>
										<Checkbox 
											checked={batchEditItems.batch_system_id}
											name="batch_system_id"
											label="Edit System"
											onChange={ e => handleInputChange(e, 'batch')}
											disabled={loading}
										/>
									</label>
								: <label>System</label>
							}
							<Select 
		            name="system_id"
		            value={formData.system_id === 'multiple' ? undefined : formData.system_id}
		            options={ formatSelectOptions({options: systems, optionValue: "system_id", optionLabel: "system_name", tooltip: "system_description"}) }
		            onChange={handleInputChange} 
		            placeholder={formData.system_id === 'multiple' ? '< Multiple >' : ""}
		            disabled={loading || (batchEdit && !batchEditItems.batch_system_id)}
		          />
				    </div>

				    <div className="form-block vertical">
							{ batchEdit 
								? <label>
										<Checkbox 
											checked={batchEditItems.batch_datasource_owner_user_id}
											name="batch_datasource_owner_user_id"
											label="Edit Owner"
											onChange={ e => handleInputChange(e, 'batch')}
											disabled={loading}
										/>
									</label>
								: <label>Owner</label>
							}
							<Select 
		            name="datasource_owner_user_id"
		            value={formData.datasource_owner_user_id === 'multiple' ? undefined : formData.datasource_owner_user_id}
		            options={ formatSelectOptions({options: users, optionValue: "user_id", optionLabel: "user_fullname", tooltip: "user_username" }) }
		            onChange={handleInputChange} 
		            placeholder={formData.datasource_owner_user_id === 'multiple' ? '< Multiple >' : ""}
		            disabled={loading || (batchEdit && !batchEditItems.batch_datasource_owner_user_id)}
		          />
			      </div>

			    </div>

			    { !batchEdit && hasConnector &&

					<div label="connection">

					{ formValues[datasourceType.datasource_type_code].driver.show &&
				    <div className="form-block vertical">
							{ batchEdit &&
								<label>
									<Checkbox 
										checked={batchEditItems.batch_datasource_subtype_id}
										name="batch_datasource_subtype_id"
										label={`Edit ${datasourceType && datasourceType.datasource_type_code === 'odbc' ? 'Driver' : 'Datasource Subtype'}`}
										onChange={ e => handleInputChange(e, 'batch')}
										disabled={loading}
									/>
								</label>
							}
							<Select 
		            name="datasource_subtype_id"
		            value={formData.datasource_subtype_id === 'multiple' ? undefined : formData.datasource_subtype_id}
		            options={ formatSelectOptions({options: datasourceSubtypes, optionValue: "datasource_subtype_id", optionLabel: "datasource_subtype_driver_name", optionSecondaryLabel: "datasource_subtype_name"}) }
		            onChange={handleInputChange}
		            placeholder={formData.datasource_subtype_id === 'multiple' ? '< Multiple >' : formValues[datasourceType.datasource_type_code].driver.placeholder}
		            disabled={loading || (batchEdit && !batchEditItems.batch_datasource_subtype_id)}
		            label={formValues[datasourceType.datasource_type_code].driver.label}
		            title={formValues[datasourceType.datasource_type_code].driver.tooltip}
		          />
				    </div>
				  	}

					{
						Object.entries(formValues[datasourceType.datasource_type_code]).map( ([key, value]) => {
							if (value.show && value.type) {
								let element = <span></span>

								let label = value.label
								let tooltip = value?.tooltip && value.tooltip !== "" ? value.tooltip : undefined
								let placeholder = value.placeholder
								let disabled = value.disabled || false								

								if (value.states) {
									Object.entries(value.states).forEach( ([key, value]) => {
										const formDataValue = formData[key].toString()
										placeholder = value[formDataValue]?.placeholder || placeholder
										disabled = value[formDataValue]?.disabled || disabled
										label = value[formDataValue]?.label || label
										tooltip = value[formDataValue]?.tooltip || tooltip
									})
								}

								switch(value.type) {
									case 'input-text':
										element =
											<Input type="text" 
								      	name={value.dbFieldName} 
								      	label={label}
								      	value={formData[value.dbFieldName]} 
								      	onChange={handleInputChange}
								      	autoComplete='new-password'
								      	disabled={loading || disabled}
								      	title={tooltip}
												placeholder={placeholder}
						      		/>
						      	break
						      case 'input-password':
										element =
											<Input type="password" 
								      	name={value.dbFieldName} 
								      	label={label}
								      	value={formData[value.dbFieldName]} 
								      	onChange={handleInputChange}
								      	autoComplete='new-password'
								      	disabled={loading || disabled}
								      	title={tooltip}
												placeholder={mode !== "add" ? "< hidden >" : placeholder}
						      		/>
						      	break
						      case 'checkbox':
						      	element =
											<Checkbox 
								      	name={value.dbFieldName} 
								      	checked={ formData[value.dbFieldName] } 
								      	onChange={handleInputChange}
								      	label={label}
								      	disabled={loading || disabled}
								      	title={tooltip}
								      />
						      	break
						      default:
						      	break
								}
								
								return <div key={key} className="form-block vertical">{element}</div>
							}
								
						})
					}

					{ formValues[datasourceType.datasource_type_code].connection_string_preview.show === true &&
						formData.datasource_subtype_id &&
					<div className="form-block vertical disabled">
						<label>Connection String</label>
						<div className="form-light-text no-margin">{ renderConnectionString() }</div>
					</div>
					}

		      <div className={"form-block" + ( !connectionTestResult.status || connectionTestResult.status === 'success' ? " horizontal" : " vertical")}>
						<button type="button" 
							className={ "button secondary-button" + (testing ? " loading" : "") } 
							onClick={ testConnection }
							disabled={ testing || loading }>
						<span>TEST CONNECTION</span></button>		
						
						<div className={"connection-test-result" + (!connectionTestResult.status ? "" : " " + connectionTestResult.status) }>
						
							{ testing 
								? <React.Fragment><div>Connecting...</div><div className="connection-test-cancel" onClick={cancelTestConnection}>Cancel</div></React.Fragment> 
								: <React.Fragment><div className={"connection-test-result-icon " + (connectionTestResult.status ? connectionTestResult.status : "")}></div>{connectionTestResult.msg}</React.Fragment> 
							}
						</div>
						
					</div>

					</div>
					}
					
					{ !batchEdit && hasConnector && formValues[datasourceType.datasource_type_code].datasource_filter.show === true &&

						<div label="filters">

							<div className="form-block vertical">
								<TextareaAutosize
					      	name={formValues[datasourceType.datasource_type_code].datasource_filter.dbFieldName} 
					      	label={formValues[datasourceType.datasource_type_code].datasource_filter.label}
					      	title={formValues[datasourceType.datasource_type_code].datasource_filter.tooltip}
					      	value={formData.datasource_filter} 
					      	onChange={handleInputChange}
					      	disabled={loading || formValues[datasourceType.datasource_type_code].datasource_filter.disabled}
					      	className="code"
					      	placeholder={formValues[datasourceType.datasource_type_code].datasource_filter.placeholder}
					      />
					    </div>

					    <div className="form-block vertical">
						    <Button 
						    		value="SHOW FILTER QUERY"
										className={ "button secondary-button" } 
										onClick={ () => showModalDialog('showFilterQuery', formData) }/>
					    </div>

						</div>

					}

					{ !batchEdit && hasConnector &&

						<div label="advanced">
						{ formValues[datasourceType.datasource_type_code].use_custom_import_query.show === true &&

						<React.Fragment>
					    <div className="form-block horizontal">
								<Checkbox 
					      	name={formValues[datasourceType.datasource_type_code].use_custom_import_query.dbFieldName}
					      	checked={"defaultValue" in formValues[datasourceType.datasource_type_code].use_custom_import_query 
					      						? formValues[datasourceType.datasource_type_code].use_custom_import_query.defaultValue 
					      						: formData.use_custom_import_query 
					      					} 
					      	onChange={handleInputChange}
					      	label={formValues[datasourceType.datasource_type_code].use_custom_import_query.label}
					      	title={formValues[datasourceType.datasource_type_code].use_custom_import_query.tooltip}
					      	disabled={loading || formValues[datasourceType.datasource_type_code].use_custom_import_query.disabled}
					      />
					    </div>

					    { ("defaultValue" in formValues[datasourceType.datasource_type_code].use_custom_import_query 
					      						? formValues[datasourceType.datasource_type_code].use_custom_import_query.defaultValue 
					      						: formData.use_custom_import_query) &&
						    <div className="form-block vertical">
						    <Button 
						    		value="EDIT BASE IMPORT QUERY"
										className={ "button secondary-button" } 
										onClick={ () => showModalDialog('editImportQuery', formData, saveImportQuery) }/>
						    </div>
						  }
						</React.Fragment>
						}
						{ formValues[datasourceType.datasource_type_code].use_custom_relationship_query.show === true &&
						<React.Fragment>
				      <div className="form-block horizontal">
								<Checkbox 
					      	name={formValues[datasourceType.datasource_type_code].use_custom_relationship_query.dbFieldName}
					      	checked={"defaultValue" in formValues[datasourceType.datasource_type_code].use_custom_relationship_query 
					      						? formValues[datasourceType.datasource_type_code].use_custom_relationship_query.defaultValue 
					      						: formData.use_custom_relationship_query 
					      					} 
					      	onChange={handleInputChange}
					      	label={formValues[datasourceType.datasource_type_code].use_custom_relationship_query.label}
					      	title={formValues[datasourceType.datasource_type_code].use_custom_relationship_query.tooltip}
					      	disabled={loading || formValues[datasourceType.datasource_type_code].use_custom_relationship_query.disabled}
					      />
					    </div>

					    { ("defaultValue" in formValues[datasourceType.datasource_type_code].use_custom_relationship_query 
					      						? formValues[datasourceType.datasource_type_code].use_custom_relationship_query.defaultValue 
					      						:formData.use_custom_relationship_query) &&
						    <div className="form-block vertical">
									<Button 
						    		value="EDIT RELATIONSHIP QUERY"
										className={ "button secondary-button" } 
										onClick={ () => showModalDialog('editRelationshipQuery', formData, saveRelationshipQuery) }/>
						    </div>
						  }
						</React.Fragment>
						}
						{ formValues[datasourceType.datasource_type_code].relationship_map.show === true &&
						<React.Fragment>
							<div className="form-block vertical">
								<TextareaAutosize
					      	name={formValues[datasourceType.datasource_type_code].relationship_map.dbFieldName}
					      	value={formData.relationship_map} 
					      	onChange={handleInputChange}
					      	disabled={loading || formValues[datasourceType.datasource_type_code].relationship_map.disabled}
					      	label={formValues[datasourceType.datasource_type_code].relationship_map.label}
					      	title={formValues[datasourceType.datasource_type_code].relationship_map.tooltip}
					      	className="code"
					      	placeholder={formValues[datasourceType.datasource_type_code].relationship_map.placeholder}
					      />
					    </div>

					    <div className="form-block horizontal">
						  	<Button 
						  			value="SHOW RELATIONSHIP UPSERT QUERY"
										className={ "button secondary-button" } 
										onClick={ () => showModalDialog('showRelationshipQuery', formData) }/>
							</div>
						</React.Fragment>
						}
						{ formValues[datasourceType.datasource_type_code].disable_virtual_relationships.show === true &&
						<React.Fragment>
							<div className="form-block horizontal">
								<Checkbox 
					      	name={formValues[datasourceType.datasource_type_code].disable_virtual_relationships.dbFieldName} 
					      	checked={formData.disable_virtual_relationships } 
					      	onChange={handleInputChange}
					      	label={formValues[datasourceType.datasource_type_code].disable_virtual_relationships.label}
					      	title={formValues[datasourceType.datasource_type_code].disable_virtual_relationships.tooltip}
					      	disabled={loading || formValues[datasourceType.datasource_type_code].disable_virtual_relationships.disabled}
					      />
					    </div>
						</React.Fragment>
						}
						</div>
					}

				</FormTabs>

	    </form>
	  </div>
  )
}

export const AddDatasourceForm = forwardRef(_AddDatasourceForm)

