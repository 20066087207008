import React from 'react'
import { NavLink as NavigationLink } from 'react-router-dom'
import { useTooltip } from '../hooks';

export const NavLink = (props) => {

	const {showTooltip, hideTooltip} = useTooltip()

	const tooltip = props.tooltip
	const children = props.children

	return (
		<NavigationLink 
			{...props}
			onMouseEnter={ () => tooltip && showTooltip({title: tooltip})} 
		  onMouseLeave={ hideTooltip }
		 >
			{children}
		</NavigationLink>
	)
}